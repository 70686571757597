import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, TextField } from '@material-ui/core'

import { ReactiveTabs } from './ReactiveTabs'
import { MyServerTable } from './MyServerTable'
import { PullOrdersWithErrorsButton } from './PullOrdersWithErrorsButton'

import { useLazyEffect } from '../hooks/commonHooks'
import { useLogic, useStore } from '../hooks/storeHook'

import { useMoySkladOrderColumns } from '../types/moySkladOrderColumns'

export const MoySkladOrdersScreen =
  observer(() => {
    const {orderStore: {moySkladOrders}, syncStore: {moySkladAccount}} = useStore()
    const moySkladOrderColumns = useMoySkladOrderColumns()

    const {api, msOrderLoaders} = useLogic()

    useLazyEffect(() => moySkladOrders.setPropFilter('hasError', JSON.stringify(false)))

    useEffect(() => msOrderLoaders.addHandler(moySkladOrders.load), [msOrderLoaders, moySkladOrders])

    const isErrorsTab = moySkladOrders.findPropFilterValue('hasError') === JSON.stringify(true)

    const onTabChange =
      (newTabIndex: number) =>
        moySkladOrders.reload(() => moySkladOrders.setPropFilter('hasError', JSON.stringify(newTabIndex === 1)))

    if (moySkladAccount == null) {
      return <Box padding={3}>Добавьте аккаунт МоегоСклада для работы с заказами.</Box>
    }

    return (
      <ReactiveTabs titles={['Активные', 'С ошибками']} tab={isErrorsTab ? 1 : 0} onTabChange={onTabChange}>
        <Box margin={2} display="flex" justifyContent="space-between">
          <Box>
            <TextField
              id="search-ms-orders"
              size="small"
              variant="outlined"
              label="Поиск"
              helperText="Id / GUID в МоёмСкладе / Id в Ozon / Id в Wildberries / Id в YandexMarket"
              onChange={e => moySkladOrders.setGlobalFilter(e.target.value)}
              style={{minWidth: 400, maxWidth: 400}}
            />
          </Box>

          {
            isErrorsTab &&
            <PullOrdersWithErrorsButton
              ordersTable={moySkladOrders}
              pullOrders={api.order.pullMoySkladOrdersWithErrors}
            />
          }
        </Box>

        <MyServerTable
          table={moySkladOrders}
          columns={moySkladOrderColumns}
          labelNoRows="Заказов нет"
          labelNotFound="Заказы не найдены"
          labelRowsPerPage="Заказов на странице:"
        />
      </ReactiveTabs>
    )
  })