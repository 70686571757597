// noinspection JSDeprecatedSymbols - intentional, for the whole file

import numeral from 'numeral'
import 'numeral/locales/ru'

numeral.locale('ru')

export const priceFormat = '0[,][.]00'

export const balanceFormat = '0[,][.][00]'

export const formatNumber =
  (number: number, format: string): string =>
    numeral(number).format(format)

export const formatNumberOrEmpty =
  (number: number | null | undefined, format: string): string =>
    number == null ? '' : numeral(number).format(format)

export const formatPrice =
  (price: number): string =>
    formatNumber(price, priceFormat)

export const formatPriceOrEmpty =
  (price: number | null | undefined): string =>
    formatNumberOrEmpty(price, priceFormat)

export const formatBalance =
  (balance: number): string =>
    formatNumber(balance, balanceFormat)

export const formatBalanceOrEmpty =
  (balance: number | null | undefined): string =>
    formatNumberOrEmpty(balance, balanceFormat)