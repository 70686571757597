import React from 'react'
import { observer } from 'mobx-react-lite'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

import { useLogic, useStore } from '../hooks/storeHook'

export const AppDialog = observer(
  () => {
    const {dialog} = useStore()

    const logic = useLogic()

    if (dialog === null) {
      return null
    }

    return (
      <Dialog open onClose={logic.declineDialog}>
        {
          dialog.title &&
          <DialogTitle>
            {dialog.title}
          </DialogTitle>
        }

        <DialogContent>
          <DialogContentText style={{whiteSpace: 'pre-line'}}>
            {dialog.text}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={logic.declineDialog} color="secondary" autoFocus>
            {dialog.declineButton}
          </Button>

          <Button onClick={logic.acceptDialog} color="primary">
            {dialog.acceptButton}
          </Button>
        </DialogActions>
      </Dialog>
    )
  })