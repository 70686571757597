import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { fromUnixTime } from 'date-fns'

import { TableCell, TableRow } from '@material-ui/core'

import { useTableStyles } from '../hooks/tableStylesHook'

import { emDash } from '../common/stringUtility'
import { hasDiffType } from '../common/diffUtils'
import { formatBalance } from '../common/numberUtils'
import { dateFormats, formatDate, formatDateOnly, tryFormatDateTimeUnix } from '../common/dateTimeUtility'

import { integrationNames } from '../types/integrationTypeName'
import { ProductDiffType } from '../server/mpsklad_core/Entity/ProductDiffType'
import { MyProductDiffModel } from '../server/mpsklad_core/Models/MyProductDiffModel'

export type OrdersTableRowProps = {
  diff: MyProductDiffModel
}

export const DiffsTableRow =
  observer(
    ({diff}: OrdersTableRowProps) => {
      const classes = useTableStyles()

      const date = fromUnixTime(diff.dateUnix)

      return (
        <TableRow hover>
          <TableCell align="center" className={classes.cellRightLine}>
            {formatDateOnly(date)}
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            {formatDate(date, dateFormats.timeOnlySeconds)}
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            {diff.name}
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            {diff.article}
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            {diff.barcodes.join('\n')}
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            {formatBalance(diff.balance - diff.value)}
          </TableCell>

          <TableCell
            align="center"
            className={classes.cellRightLine}
            title={tryFormatDateTimeUnix(diff.orderDateUnix)}
          >
            {formatDiffType(diff)}
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            {formatDiffSource(diff)}
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            {diff.moySkladOrderId ?? emDash}
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            {
              diff.ozonOrderId != null
              ? `Ozon ${diff.ozonOrderId}`
              : diff.wbOrderId != null
                ? `Wildberries ${diff.wbOrderId}`
                : diff.yandexMarketOrderId != null
                  ? `Yandex.Market ${diff.yandexMarketOrderId}`
                  : emDash
            }
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            {diff.orderAccountName ?? emDash}
          </TableCell>

          <TableCell align="center" className={classes.cellRightLine}>
            <span
              className={clsx(
                classes.diffCell,
                diff.value > 0 ? classes.positiveDiff : classes.negativeDiff
              )}
            >
              {formatBalance(diff.value)}
            </span>
          </TableCell>

          <TableCell
            align="center"
            className={clsx(
              classes.cellRightLine,
              diff.balance === 1 && classes.warnCell,
              diff.balance === 0 && classes.zeroCell
            )}
          >
            {formatBalance(diff.balance)}
          </TableCell>
        </TableRow>
      )
    })

const formatDiffType =
  (diff: MyProductDiffModel): string => {
    if (hasDiffType(diff, ProductDiffType.Ozon) || hasDiffType(diff, ProductDiffType.Wb) || hasDiffType(diff,
      ProductDiffType.YandexMarket)) {
      return diff.orderStatus ?? emDash
    }

    if (hasDiffType(diff, ProductDiffType.MoySklad) || hasDiffType(diff, ProductDiffType.User)) {
      return diff.value > 0 ? 'Пополнение' : 'Списание'
    }

    return emDash
  }

const formatDiffSource =
  (diff: MyProductDiffModel) => {
    // NOTE: Order matters here
    if (hasDiffType(diff, ProductDiffType.Ozon)) {
      return integrationNames.Ozon
    }

    if (hasDiffType(diff, ProductDiffType.Wb)) {
      return integrationNames.Wildberries
    }

    if (hasDiffType(diff, ProductDiffType.YandexMarket)) {
      return integrationNames.YandexMarket
    }

    if (hasDiffType(diff, ProductDiffType.MoySklad)) {
      return integrationNames.MoySklad
    }

    if (hasDiffType(diff, ProductDiffType.User)) {
      return 'Пользователь'
    }

    if (hasDiffType(diff, ProductDiffType.Import)) {
      return 'Импорт'
    }

    return emDash
  }