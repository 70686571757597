import React, { ErrorInfo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { AppErrorMessage } from './AppErrorMessage'

import { ErrorApiLogic } from '../store/logic/errorApiLogic'

export type AppErrorBoundaryProps = {
  createErrorApiLogic: () => ErrorApiLogic

  children: JSX.Element
}

type AppErrorBoundaryState = {
  hasError: boolean

  errorId: string | null
}

export class AppErrorBoundary
  extends React.Component<AppErrorBoundaryProps, AppErrorBoundaryState> {
  constructor(props: AppErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      errorId: null
    }
  }

  static getDerivedStateFromError(): Partial<AppErrorBoundaryState> {
    return {hasError: true}
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    try {
      // TODO: setState in componentDidCatch will be deprecated
      const errorId = uuidv4()
      this.setState({errorId})

      console.error('React render error', errorId, error, errorInfo)

      await this.props.createErrorApiLogic().logRenderError(errorId, error, errorInfo)
    } catch (e) {
      console.error('Error when logging render error', e)
    }
  }

  render() {
    return this.state.hasError ? <AppErrorMessage errorId={this.state.errorId}/>
                               : this.props.children
  }
}