import React from 'react'
import { observer } from 'mobx-react-lite'

import { SyncStateRow } from './SyncStateRow'

import { IntegrationTypeName } from '../types/integrationTypeName'
import { SyncStatesModel } from '../server/mpsklad_core/Models/SyncStatesModel'

export type SyncStatesProps = {
  type: IntegrationTypeName

  syncStates: SyncStatesModel
}

export const PullProductsStateRow =
  observer(
    ({type, syncStates}: SyncStatesProps) =>
      <SyncStateRow title="Получение товаров" type={type} state={syncStates.pullProductsState}/>
  )