import React from 'react'
import { observer } from 'mobx-react-lite'

import { Sync } from '@material-ui/icons'
import { Box, Button, CircularProgress } from '@material-ui/core'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { IServerTableData } from '../types/tableTypes'
import { OrderModelBase } from '../server/mpsklad_core/Models/Orders/OrderModelBase'

export type PullOrdersWithErrorsButtonProps<TOrder extends OrderModelBase, TColumnId> = {
  ordersTable: IServerTableData<TOrder, TColumnId>

  pullOrders: () => Promise<void>
}

export const PullOrdersWithErrorsButton =
  observer(
    <TOrder extends OrderModelBase, TColumnId>(
      {ordersTable, pullOrders}: PullOrdersWithErrorsButtonProps<TOrder, TColumnId>) => {
      const {showSuccess} = useMessages()

      const [isLoading, setLoading, setFinished] = useBoolState()

      const onClick =
        async () => {
          setLoading()

          try {
            await pullOrders()
            ordersTable.load()
            showSuccess('Обновление заказов в процессе...')
          } finally {
            setFinished()
          }
        }

      if (ordersTable.filteredRowCount <= 0) {
        return null
      }

      // TODO: This seems like a good button with progress, extract and reuse
      return (
        <Box>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            title="Обновить все заказы с ошибками"
            disabled={isLoading}
            onClick={onClick}
            startIcon={isLoading ? <CircularProgress size={20}/> : <Sync/>}>
            {isLoading ? 'Обновляем' : 'Обновить'}
          </Button>
        </Box>
      )
    })