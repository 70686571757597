//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class PriceSyncController
{
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  
  private readonly axios: AxiosInstance
  
  checkPriceActivationOzon(_config?: AxiosRequestConfig) : Promise<boolean>
  {
    return this.axios.get<boolean>(`PriceSync/CheckPriceActivationOzon`, _config).then(getAxiosData)
  }
  

  checkPriceActivationWb(_config?: AxiosRequestConfig) : Promise<boolean>
  {
    return this.axios.get<boolean>(`PriceSync/CheckPriceActivationWb`, _config).then(getAxiosData)
  }
  

  checkPriceActivationYandexMarket(_config?: AxiosRequestConfig) : Promise<boolean>
  {
    return this.axios.get<boolean>(`PriceSync/CheckPriceActivationYandexMarket`, _config).then(getAxiosData)
  }
  

  enablePriceSyncOzon(_config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`PriceSync/EnablePriceSyncOzon`, null, _config).then(getAxiosData)
  }
  

  enablePriceSyncWb(_config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`PriceSync/EnablePriceSyncWb`, null, _config).then(getAxiosData)
  }
  

  enablePriceSyncYandexMarket(_config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`PriceSync/EnablePriceSyncYandexMarket`, null, _config).then(getAxiosData)
  }
  

  /** Gets the full relative URL for the action */
  checkPriceActivationOzonUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/PriceSync/CheckPriceActivationOzon`
  }
  

  /** Gets the full relative URL for the action */
  checkPriceActivationWbUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/PriceSync/CheckPriceActivationWb`
  }
  

  /** Gets the full relative URL for the action */
  checkPriceActivationYandexMarketUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/PriceSync/CheckPriceActivationYandexMarket`
  }
  

}
