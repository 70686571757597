import { AxiosResponse } from 'axios'

import { UiErrorResponse } from '../server/mpsklad_core/Models/UiErrorResponse'

export type RequestValidationError = {
  status: 400

  errors: RequestValidationErrors
}

export type RequestValidationErrors = {
  [propertyName: string]: string[]
}

export const isValidationErrorResponse =
  (response: unknown): response is RequestValidationError =>
    typeof response === 'object' && !!response
    && 'status' in response && response.status === 400
    && 'errors' in response && typeof response.errors === 'object'

export const isUiErrorResponse =
  (responseData: unknown): responseData is UiErrorResponse =>
    typeof responseData === 'object' && !!responseData
    && 'uiMessage' in responseData && typeof responseData.uiMessage === 'string'

export const isResponseException =
  (exception: unknown): exception is  {response: AxiosResponse<object>} =>
    typeof exception === 'object' && !!exception
    && 'response' in exception && typeof exception.response === 'object' && !!exception.response

export const isUiException =
  (exception: unknown): exception is {response: AxiosResponse<UiErrorResponse>} =>
    isResponseException(exception)
    && 'data' in exception.response && isUiErrorResponse(exception.response.data)