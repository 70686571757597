import React from 'react'

import { Box, CircularProgress } from '@material-ui/core'

export const BoxLoader =
  ({text}: {text?: string}) =>
    <Box padding={2} display="flex" alignItems="center">
      <CircularProgress size={25}/>
      {
        text ? <Box component="span" marginLeft={2}>{text}</Box>
             : null
      }
    </Box>