import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { ReactiveTabs } from './ReactiveTabs'
import { HomeScreenTable } from './HomeScreenTable'
import { HomeTab, HomeTabTitle } from './HomeTabTitle'

import { useLazyUnEffect } from '../hooks/commonHooks'
import { useLogic, useStore } from '../hooks/storeHook'

import { exact } from '../common/tsUtils'
import { appRoutes } from '../common/appRoutes'
import { required } from '../common/objectUtils'
import { makeOzonAccountName, makeWbAccountName, makeYandexMarketAccountName } from '../common/accountUtils'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'

export const HomeScreen =
  observer(() => {
    const logic = useLogic()
    const store = useStore()
    const {
      syncStore: {moySkladAccount, ozonAccounts, wbAccounts, yandexMarketAccounts, allowCombinedTab},
      allowedIntegrations
    } = store

    const tabs: HomeTab[] = useMemo(() => {
      const result: HomeTab[] = []

      if (allowedIntegrations[StoreType.Ozon]) {
        result.push(
          ...ozonAccounts.map(ozonAccount => exact<HomeTab>({
            ozonAccount,
            title: makeOzonAccountName(ozonAccount)
          })))
      }

      if (allowedIntegrations[StoreType.Wildberries]) {
        result.push(
          ...wbAccounts.map(wbAccount => exact<HomeTab>({
            wbAccount,
            title: makeWbAccountName(wbAccount)
          })))
      }

      if (allowedIntegrations[StoreType.YandexMarket]) {
        result.push(
          ...yandexMarketAccounts.map(yandexMarketAccount => exact<HomeTab>({
            yandexMarketAccount,
            title: makeYandexMarketAccountName(yandexMarketAccount)
          })))
      }

      if (allowCombinedTab) {
        result.unshift(exact<HomeTab>({title: 'Все'}))
      }

      return result
    }, [allowCombinedTab, allowedIntegrations, ozonAccounts, wbAccounts, yandexMarketAccounts])

    const [tabIndex, setTabIndex] = useState(0)

    useLazyUnEffect(() => {
      if (store.getHomeNav()) {
        logic.setHomeNavInitial()
      }
    })

    if (moySkladAccount == null) {
      return <Box padding={3}>
        Для работы с товарами добавьте <Link to={appRoutes.Sync.moySklad}>аккаунт МоегоСклада</Link>.
      </Box>
    }

    if (!store.getHomeNav()
        || (ozonAccounts.length === 0 && wbAccounts.length === 0 && yandexMarketAccounts.length === 0)) {
      return <Box padding={3}>
        Для работы с товарами добавьте <Link to={appRoutes.Sync.base}>аккаунт маркетплейса</Link>.
      </Box>
    }

    const onTabChange =
      (newTabIndex: number) => {
        const newTab = tabs[newTabIndex]

        if (allowCombinedTab && !newTab.ozonAccount && !newTab.wbAccount && !newTab.yandexMarketAccount) {
          store.setHomeNavCombined()
        } else {
          const newAccount = required(newTab.ozonAccount ?? newTab.wbAccount ?? newTab.yandexMarketAccount)
          store.setHomeNav(newAccount.storeType, newAccount.id)
        }

        setTabIndex(newTabIndex)
      }

    return (
      <div className="body_wrapper_zero">
        <Box display="flex" alignItems="center" marginLeft={2}>
          <h1>
            Товары
          </h1>
        </Box>

        <ReactiveTabs
          tab={tabIndex}
          onTabChange={onTabChange}
          titles={tabs.map(tab => <HomeTabTitle tab={tab}/>)}
        >
          <HomeScreenTable/>
        </ReactiveTabs>
      </div>
    )
  })