import React from 'react'

import { LoadOrdersForm } from './LoadOrdersForm'

import { useApi } from '../hooks/storeHook'
import { YandexMarketAccountIdProps } from '../types/accountProps'

export const YandexMarketLoadOrdersForm =
  ({yandexMarketAccountId}: YandexMarketAccountIdProps) => {
    const api = useApi()

    return <LoadOrdersForm accountId={yandexMarketAccountId} loadOrders={api.userSync.loadYandexMarketOrders}/>
  }