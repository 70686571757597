import React from 'react'
import { observer } from 'mobx-react-lite'

import { renderWbInput } from './WbProductSinglePicker'
import { ProductMultiPicker, ProductMultiPickerBaseProps } from '../ProductMultiPicker'

import { useLogic } from '../../hooks/storeHook'

export const WbProductMultiPicker =
  observer(
    (props: ProductMultiPickerBaseProps) => {
      const {loadWbProductOptions} = useLogic()

      return <ProductMultiPicker renderInput={renderWbInput} loadProductOptions={loadWbProductOptions} {...props}/>
    })