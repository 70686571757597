import { configure } from 'mobx'

export const configureMobx = () =>
  configure({
    computedRequiresReaction: true,
    // NOTE: May be useful for tracking missed observer HOCs, but it triggers in all store constructors
    //observableRequiresReaction: true,
    // NOTE: These warnings have useless stack traces
    //reactionRequiresObservable: true,
    // These warnings are useless because of async
    enforceActions: 'never'
  })