import React from 'react'
import { observer } from 'mobx-react-lite'

import { TextField } from '@material-ui/core'
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete'

import { ExternalProductPicker, ProductSinglePickerBaseProps } from './ExternalProductPicker'

import { useLogic } from '../../hooks/storeHook'

export const WbProductSinglePicker =
  observer(
    ({msProductId, ...passProps}: ProductSinglePickerBaseProps) => {
      const logic = useLogic()

      return (
        <ExternalProductPicker
          loadOptions={searchTerm => logic.loadWbProductOptions(msProductId, searchTerm)}
          renderInput={renderWbInput}
          {...passProps}
        />
      )
    })

export const renderWbInput =
  (props: AutocompleteRenderInputParams) =>
    <TextField
      {...props}
      autoFocus
      multiline
      maxRows={3}
      label="Артикул / Штрихкод / Код размера"
      variant="outlined"
      size="small"
    />