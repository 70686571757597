import React from 'react'
import { observer } from 'mobx-react-lite'

import { Link, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useStore } from '../hooks/storeHook'

export const UserSyncWarning =
  observer(() => {
    const {user} = useStore()

    if (!user || user.allowSync) {
      return null
    }

    return (
      <Snackbar open key="syncDisallowedSnackbar" anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert variant="filled" severity="warning">
          <Link color="inherit" href="tel:+74994445269">
            Тестовый режим окончен, свяжитесь с вашим менеджером: +7 499 444 5269.
          </Link>
        </Alert>
      </Snackbar>
    )
  })