import { add, endOfDay, format, formatDistanceStrict, fromUnixTime, startOfDay } from 'date-fns'

import ru from 'date-fns/locale/ru'

import { PrintFileModel } from '../server/mpsklad_core/Models/PrintFileModel'

export const tryFromUnixTime =
  (dateUnixOrNull: number | null | undefined) =>
    dateUnixOrNull ? fromUnixTime(dateUnixOrNull)
                   : undefined

/**
 * See https://date-fns.org/v2.14.0/docs/format
 */
export const formatDate =
  (date: Date, formatString: string): string =>
    format(date, formatString, {locale: ru})

export const tryFormatDate =
  (date: Date | null | undefined, formatString: string) =>
    date ? formatDate(date, formatString)
         : undefined

/**
 * See https://date-fns.org/v2.14.0/docs/formatDistanceStrict
 */
export const formatDuration =
  (startDate: Date,
   endDate: Date,
   options: {
     addSuffix?: boolean
     unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
     roundingMethod?: 'floor' | 'ceil' | 'round'
   } = {}): string =>
    formatDistanceStrict(startDate, endDate, {roundingMethod: 'floor', ...options, locale: ru})

export const formatShipmentDatesRange =
  ({startDateUnix, endDateUnix}: Pick<PrintFileModel, 'startDateUnix' | 'endDateUnix'>) =>
    `${formatDateTimeUnix(startDateUnix, false)} - ${formatDateTimeUnix(endDateUnix, false)}`

export const formatDateOnly =
  (date: Date) => formatDate(date, dateFormats.dateOnly)

export const formatDateTime =
  (date: Date, withSeconds: boolean = true) =>
    formatDate(date, withSeconds ? dateFormats.dateTimeSeconds
                                 : dateFormats.dateTimeMinutes)

export const formatDateOnlyUnix =
  (dateUnix: number) => formatDateOnly(fromUnixTime(dateUnix))

export const formatDateTimeUnix =
  (dateUnix: number, withSeconds: boolean = true) =>
    formatDateTime(fromUnixTime(dateUnix), withSeconds)

export const tryFormatDateOnly =
  (date: Date | null | undefined) =>
    date ? formatDateOnly(date)
         : undefined

export const tryFormatDateTime =
  (date: Date | null | undefined, withSeconds: boolean = true) =>
    date ? formatDateTime(date, withSeconds)
         : undefined

export const tryFormatDateOnlyUnix =
  (dateUnix: number | null | undefined) =>
    dateUnix ? formatDateOnlyUnix(dateUnix)
             : undefined

export const tryFormatDateTimeUnix =
  (dateUnix: number | null | undefined, withSeconds: boolean = true) =>
    dateUnix ? formatDateTimeUnix(dateUnix, withSeconds)
             : undefined

export const dateFormats = {
  dateOnly: 'dd.MM.yyyy',
  timeOnlyMinutes: 'HH:mm',
  timeOnlySeconds: 'HH:mm:ss',
  dateTimeMinutes: 'dd.MM.yyyy, HH:mm',
  dateTimeSeconds: 'dd.MM.yyyy, HH:mm:ss'
}

export const dateUtils = {
  get now() {
    return new Date()
  },

  get endOfToday() {
    return endOfDay(this.now)
  },

  get startOfYesterday() {
    return startOfDay(add(this.now, {days: -1}))
  }
}