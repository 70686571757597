import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core'

import { PhoneNumberInput } from './PhoneNumberInput'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useStore } from '../hooks/storeHook'

import { required } from '../common/objectUtils'

export type MoySkladNamePhoneFormPanelProps = {
  onFinish: VoidFunction
}

export const MoySkladNamePhoneFormPanel =
  observer(
    ({onFinish}: MoySkladNamePhoneFormPanelProps) => {
      const {showWarning, showSuccess} = useMessages()

      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const user = required(useStore().user)
      const [name, setName] = useState(user.name)
      const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)

      const api = useApi()

      const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if (!name || !phoneNumber) {
          showWarning('Поля не могут быть пустыми.')
          return
        }

        setIsLoading()

        try {
          await api.auth.editUserInfo({name, phoneNumber})

          user.name = name
          user.phoneNumber = phoneNumber

          showSuccess('Сохранено!')
          onFinish()
        } catch (e) {
          console.error('Failed to save personal data form', e)
        } finally {
          setNotLoading()
        }
      }

      return (
        <Paper>
          <Grid container alignContent="center">
            <Grid item xs={8}>
              <form onSubmit={onSubmit}>
                <Box padding={2}>
                  <Box marginBottom={3}>
                    <Typography variant="h6">
                      Ввод личных данных
                    </Typography>
                  </Box>

                  <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="name"
                        label="Имя"
                        autoComplete="fname"
                        InputLabelProps={{shrink: true}}
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="phone"
                        name="phone"
                        label="Телефон"
                        autoComplete="tel"
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                          inputComponent: PhoneNumberInput,
                          inputProps: {onAccept: setPhoneNumber}
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box marginTop={2}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Button variant="contained" type="submit" disabled={isLoading}>
                          {isLoading ? 'Сохраняем...' : 'Далее'}
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button variant="contained" onClick={onFinish}>
                          Пропустить
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </form>
            </Grid>

            <Grid item xs={4}>
              <Grid container alignItems="center" style={{height: '100%'}}>
                <Box padding={2} textAlign="center">
                  <Typography variant="h6">
                    Поможем с настройкой.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )
    })