import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { AppNotificationBar } from './AppNotificationBar'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'
import { UserAuthModel } from '../server/mpsklad_core/Models/UserAuthModel'
import { formatDateOnly } from '../common/dateTimeUtility'
import { getWbTokenInfo } from '../common/wbTokenUtils'
import { makeWbAccountName } from '../common/accountUtils'
import { required } from '../common/objectUtils'

export const WbApiTokensExpirationNotificationBars =
  observer(() => {
    const {user, syncStore: {wbAccounts}} = useStore()

    if (!user || wbAccounts.length === 0) {
      return null
    }

    const expiringWbAccounts =
      // TODO: Should we filter by sync enabled?
      wbAccounts.filter(_ => _.syncEnabled && !!_.tokenCombined && !!_.tokenCombinedExpiresAtUnix)
                .map(_ => ({
                  id: _.id,
                  name: makeWbAccountName(_),
                  tokenInfo: required(getWbTokenInfo(_.tokenCombinedExpiresAtUnix))
                }))
                .filter(_ => _.tokenInfo.expiresSoon)

    if (expiringWbAccounts.length === 0) {
      return null
    }

    return <>
      {
        expiringWbAccounts.map(wbAccount =>
          <AppNotificationBar
            severity={wbAccount.tokenInfo.expired ? 'error' : 'warning'}
            notificationId={createNotificationId(user, wbAccount.id, wbAccount.tokenInfo.expiresAt)}
          >
            {wbAccount.tokenInfo.expired ? 'Истекло' : 'Истекает'} время жизни
            токена <Link to={appRoutes.Sync.wildberries}>Wildberries</Link> для
            аккаунта {wbAccount.name}.
          </AppNotificationBar>)
      }
    </>
  })

const createNotificationId =
  // Email is used to show notifications to admins. Id and date - for per-account per-token notifications.
  (user: UserAuthModel, wbAccountId: number, tokenExpiresAt: Date) =>
    `wbApiTokenExpiration_${wbAccountId}_${formatDateOnly(tokenExpiresAt)}_${user.email}`