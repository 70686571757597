import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

import { Color } from '@material-ui/lab/Alert/Alert'

import { useStore } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { isLocalStorageAllowed } from '../common/commonUtils'

export type AppNotificationBarProps = {
  notificationId: string

  severity: Color

  children: ReactNode
}

// Use this component to create a notification, dismissable per-browser
export const AppNotificationBar =
  observer(
    ({notificationId, severity, children}: AppNotificationBarProps) => {
      const classes = useStyles()

      const {user} = useStore()

      const [isNotificationShown, showNotification, hideNotification] = useBoolState()

      const getStorageKey = () => `notificationHidden_${notificationId}`

      useLazyEffect(() => {
        if (!isLocalStorageAllowed) {
          return
        }

        try {
          const storageText = localStorage.getItem(getStorageKey())

          if (storageText == null) {
            showNotification()
            return
          }

          const storageValue = JSON.parse(storageText)

          if (storageValue !== true) {
            showNotification()
          }
        } catch (e) {
          console.error(`Failed to get notification state from storage, id ${notificationId}`, e)
        }
      })

      if (!user || !isNotificationShown) {
        return null
      }

      const onClose = () => {
        hideNotification()

        if (isLocalStorageAllowed) {
          try {
            localStorage.setItem(getStorageKey(), JSON.stringify(true))
          } catch (e) {
            console.error('Failed to save notification closed state', getStorageKey(), e)
          }
        }
      }

      return (
        <div className={classes.wrapOuter}>
          <div className={classes.wrapInner}>
            <Alert
              variant="filled"
              severity={severity}
              className={classes.alert}
              classes={{icon: classes.alertIcon, message: classes.message}}
              onClose={onClose}
            >
              {children}
            </Alert>
          </div>
        </div>
      )
    })

const useStyles = makeStyles(
  (/*theme*/) => ({
    wrapOuter: {
      position: 'fixed',
      top: 0,
      // Center horizontally
      left: '50%',
      transform: 'translateX(-50%)',
      // On top of other warnings
      zIndex: 2500
    },
    wrapInner: {
      display: 'flex',
      justifyContent: 'center'
    },
    alert: {
      padding: '0 16px',
      fontSize: 20
    },
    alertIcon: {
      alignItems: 'center'
    },
    message: {
      whiteSpace: 'nowrap'
    }
  }))