import { MyProductModel } from '../server/mpsklad_core/Models/MyProductModel'
import { WbProductModel } from '../server/mpsklad_core/Models/WbProductModel'
import { OzonProductModel } from '../server/mpsklad_core/Models/OzonProductModel'
import { YandexMarketProductModel } from '../server/mpsklad_core/Models/YandexMarketProductModel'

export type MyProductModelColumnId =
  keyof Omit<MyProductModel, 'id' | 'state' | 'ozonProduct' | 'wbProduct' | 'yandexMarketProduct'>

/**
 * For the MyProducts table.
 */
export type MyOzonProductColumnId =
  `ozon${Capitalize<
    keyof Pick<OzonProductModel, 'article' | 'price' | 'basePrice' | 'balance' | 'isVisible' | 'isArchived' | 'syncEnabled'>>}`

/**
 * For the MyProducts table.
 */
export type MyWbProductColumnId =
  `wb${Capitalize<
    keyof Pick<WbProductModel, 'chrtId' | 'price' | 'basePrice' | 'balance' | 'syncEnabled'>>}`

/**
 * For the MyProducts table.
 */
export type MyYandexMarketProductColumnId =
  `yandexMarket${Capitalize<
    keyof Pick<YandexMarketProductModel, 'shopSku' | 'vendorCode' | 'price' | 'basePrice' | 'balance' | 'syncEnabled'>>}`

export type MyProductExtraColumnId =
  typeof selectColumnId
  | typeof syncErrorsColumnId
  | 'ConnectOzon'
  | 'ConnectWb'
  | 'ConnectYandexMarket'
  | 'SyncLogs'

/**
 * The value of column id for the selection column.
 * NOTE: Same values of different string enums have different types somehow
 */
export const selectColumnId = 'Select'

/**
 * The value of column id for the sync errors column.
 * NOTE: Same values of different string enums have different types somehow
 */
export const syncErrorsColumnId = 'SyncErrors'

export type MyProductOnlyColumnId =
  MyProductModelColumnId
  | MyProductExtraColumnId

export type MyProductColumnId =
  MyProductModelColumnId
  | MyProductExtraColumnId
  | MyOzonProductColumnId
  | MyWbProductColumnId
  | MyYandexMarketProductColumnId