import React from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { ReactiveTabs } from './ReactiveTabs'
import { MyProductsTable } from './MyProductsTable'
import { MyProductsFilter } from './MyProductsFilter'
import { ProductsTabTitle } from './ProductsTabTitle'
import { MyProductsTopPagination } from './MyProductsTopPagination'

import { useLazyEffect } from '../hooks/commonHooks'
import { useLogic, useStore } from '../hooks/storeHook'

import { MyProductState } from '../server/mpsklad_core/Models/MyProductState'

export const HomeScreenTable =
  observer(() => {
    const logic = useLogic()
    const store = useStore()
    const {productStore: {myProductsInfo}, homeNavRequired} = store

    useLazyEffect(logic.reloadMyProductsInfo)

    useLazyEffect(() =>
      reaction(() => store.getHomeNav()?.storeType, logic.reloadMyProductsInfo))

    useLazyEffect(() =>
      reaction(() => store.getHomeNav()?.accountId, logic.reloadMyProductsInfo))

    const onTabChange =
      (newTab: MyProductState) => {
        switch (newTab) {
          case MyProductState.Active:
          case MyProductState.New:
          case MyProductState.Error:
            store.setHomeTab(newTab)
            return

          default:
            throw new Error(`Unprocessed products tab: ${newTab}`)
        }
      }

    return (
      <>
        <div className="body_wrapper">
          <MyProductsFilter/>
        </div>

        <div className="body_wrapper__home">
          <ReactiveTabs
            tab={homeNavRequired.tab}
            titles={[
              <ProductsTabTitle title="Активные товары" count={myProductsInfo?.activeCount}/>,
              <ProductsTabTitle title="Не сопоставлено" count={myProductsInfo?.newCount}/>,
              <ProductsTabTitle title="Товары с ошибками" count={myProductsInfo?.errorCount}/>
            ]}
            appBarProps={{elevation: 1}}
            onTabChange={onTabChange}
            AfterTabsComponent={MyProductsTopPagination}
          >
            <MyProductsTable/>
          </ReactiveTabs>
        </div>
      </>
    )
  })