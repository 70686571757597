import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'

import { MsFormData } from './MoySkladDataForm'
import { MaximumProductStocksForm } from './MaximumProductStocksForm'
import { MoySkladYandexMarketPropertiesSelector } from './MoySkladYandexMarketPropertiesSelector'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useLogic } from '../hooks/storeHook'
import { usePaperStyles } from '../hooks/paperStylesHook'

import { exact } from '../common/tsUtils'
import { formatAccountTitle } from '../common/accountUtils'

import { YandexMarketAccountProps } from '../types/accountProps'
import { CommissionsRecordingType } from '../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditYandexMarketAccountModel } from '../server/mpsklad_core/Models/EditYandexMarketAccountModel'
import { YandexMarketModelType } from '../server/mpsklad_core/Entity/YandexMarket/Enums/YandexMarketModelType'

export const YandexMarketAccountForm =
  observer(
    ({account}: YandexMarketAccountProps) => {
      const {showSuccess, showError} = useMessages()

      const logic = useLogic()
      const api = useApi()

      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      // TODO: Rework this mess into observables or something
      // TODO: Maybe split requests for every field
      const [isFBS] = useState(account?.isFBS ?? true)
      const [isUpdatePrices, setUpdatePrices] = useState(account?.isUpdatePrices ?? false)
      const [isGeneratingPayments, setGeneratingPayments] = useState(account?.isGeneratingPayments ?? false)

      const [isUsingCommissions, setUsingCommissions] = useState(account?.isUsingCommissions ?? false)

      const [ordersCommissionsRecording, setOrdersCommissionsRecording] =
        useState(account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing)

      const [documentsCommissionsRecording, setDocumentsCommissionsRecording] =
        useState(account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing)

      const [campaignId, setCampaignId] = useState(account?.campaignId?.toString() ?? '')
      const [userCode, setUserCode] = useState('')
      const [name, setName] = useState(account?.name ?? '')

      const [usingUnifiedPrices, setUsingUnifiedPrices] = useState(account?.usingUnifiedPrices ?? false)

      const [msData, setMsData] = useState<MsFormData | null>(null)
      const [msSalesReturnStoreId, setMsSalesReturn] = useState<string | undefined>(account?.msSalesReturnStoreId)

      const [modelType, setModelType] = useState(account?.modelType ?? YandexMarketModelType.None)

      const classes = usePaperStyles()

      const toggleUsingUnifiedPrices = () => setUsingUnifiedPrices(!usingUnifiedPrices)

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (!userCode) {
            showError('Необходимо ввести код подтверждения.')
            return
          }

          setIsLoading()

          try {
            // TODO (Neli): Revise the file
            const formData =
              exact<Omit<EditYandexMarketAccountModel, 'id'>>({
                campaignId: parseInt(campaignId),
                userCode: parseInt(userCode),
                usingUnifiedPrices,
                isFBS,
                modelType,
                isUpdatePrices: isUpdatePrices,
                isGeneratingPayments,
                isUsingCommissions,
                ordersCommissionsRecording,
                documentsCommissionsRecording,
                name: name || undefined,
                msSalesReturnStoreId,
                msData: msData ?? undefined
              })

            if (account) {
              await logic.editYandexMarketAccount({id: account.id, ...formData})
            } else {
              await logic.createYandexMarketAccount({id: 0, ...formData})
            }

            showSuccess('Сохранено!')
          } catch (e) {
            console.error('Failed to save YandexMarket account', e)
          } finally {
            setNotLoading()
          }
        }

      return (
        <Box display="flex">
          <Paper className={clsx([classes.paper, classes.paperHalf, classes.paperLeft])}>
            <Box padding={2}>
              <Box marginBottom={3}>
                <Typography variant="h6">
                  {formatAccountTitle(account)}
                </Typography>
              </Box>

              <form noValidate autoComplete="off" onSubmit={onSubmit}>
                <Box marginTop={2}>

                  <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="campaignId"
                        label="Номер кампании"
                        placeholder="1337"
                        InputLabelProps={{shrink: true}}
                        value={campaignId}
                        onChange={e => setCampaignId(e.target.value)}
                      />

                      <FormHelperText>
                        Номер кампании можно узнать в личном кабинете продавца в разделе:
                      </FormHelperText>
                      <FormHelperText>
                        Настройки - Настройки API - Настройка запросов к Маркету.
                      </FormHelperText>
                    </Grid>

                    {
                      account &&
                      <Grid item xs={12}>
                        <Box>
                          <TextField
                            disabled
                            id="businessId"
                            label="Идентификатор бизнеса"
                            InputLabelProps={{shrink: true}}
                            value={account.businessId}
                            style={{minWidth: 200}}
                          />
                        </Box>
                      </Grid>
                    }
                  </Grid>
                </Box>

                <Box marginTop={2}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="userCode"
                        label="Код подтверждения"
                        placeholder="1337"
                        InputLabelProps={{shrink: true}}
                        value={userCode}
                        onChange={e => setUserCode(e.target.value)}
                      />

                      <FormHelperText>
                        Чтобы получить код, перейдите по данной <a
                        href="https://oauth.yandex.ru/authorize?response_type=code&client_id=bf8796a163424ce2b6ee8124a3a84a5b"
                        target="_blank" rel="noopener noreferrer">ссылке</a>.
                        Код активен 10 минут, после чего генерировать его придётся снова.
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Box>

                <Box marginTop={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Схема работы
                    </FormLabel>

                    <RadioGroup
                      name="ModelType"
                      value={JSON.stringify(modelType)}
                      onChange={e => setModelType(JSON.parse(e.target.value))}
                    >
                      <FormControlLabel
                        value={JSON.stringify(YandexMarketModelType.FBY)}
                        control={<Radio/>}
                        label="Продажа со склада Yandex.Market (FBY)"
                      />

                      <FormControlLabel
                        value={JSON.stringify(YandexMarketModelType.FBS)}
                        control={<Radio/>}
                        label="Продажа со своего склада (FBS)"
                      />

                      <FormControlLabel
                        value={JSON.stringify(YandexMarketModelType.Express)}
                        control={<Radio/>}
                        label="Продажа со своего склада с доставкой силами Yandex.Market (Express)"
                      />

                      <FormControlLabel
                        disabled
                        value={JSON.stringify(YandexMarketModelType.DBS)}
                        control={<Radio/>}
                        label="Продажа со своего склада и доставка силами продавца (DBS)"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                <Box marginTop={2}>
                  <FormControl>
                    <FormLabel component="legend">
                      Настройка работы с ценами
                    </FormLabel>

                    <FormControlLabel
                      label="Использовать общие цены"
                      control={<Switch checked={usingUnifiedPrices} onChange={toggleUsingUnifiedPrices}/>}
                    />

                    <FormHelperText>
                      Если ваша кампания использует разные цены в разных магазинах (стандартно для
                      давно зарегистрированных магазинов), то пропустите этот этап.
                    </FormHelperText>
                    <FormHelperText>
                      Если же вы используете общую цену для всех ваших магазинов (стандартно
                      для новых магазинов), то следует включить.
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Box marginTop={2}>
                  <TextField
                    id="name"
                    label="Название"
                    placeholder="YandexMarket 1337"
                    InputLabelProps={{shrink: true}}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </Box>

                {
                  account &&
                  <Box marginTop={2}>
                    <MaximumProductStocksForm
                      account={account}
                      onSubmit={api.userSync.setYandexMarketMaximumProductStocks}/>
                  </Box>
                }

                <Box marginTop={5}>
                  <Button variant="contained" type="submit" disabled={isLoading}>
                    {isLoading ? 'Сохраняем...' : 'Сохранить'}
                  </Button>
                </Box>
              </form>
            </Box>
          </Paper>

          <Paper className={clsx([classes.paper, classes.paperHalf, classes.paperRight])}>
            <MoySkladYandexMarketPropertiesSelector
              account={account}
              isLoading={isLoading}
              setMsData={setMsData}

              isUpdatePrices={isUpdatePrices}
              setUpdatePrices={setUpdatePrices}

              isGeneratingPayments={isGeneratingPayments}
              setGeneratingPayments={setGeneratingPayments}

              isUsingCommissions={isUsingCommissions}
              setUsingCommissions={setUsingCommissions}

              ordersCommissionsRecording={ordersCommissionsRecording}
              setOrdersCommissionsRecording={setOrdersCommissionsRecording}

              documentsCommissionsRecording={documentsCommissionsRecording}
              setDocumentsCommissionsRecording={setDocumentsCommissionsRecording}

              msSalesReturnStoreId={msSalesReturnStoreId}
              setMsSalesReturn={setMsSalesReturn}
            />
          </Paper>
        </Box>
      )
    })