import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormControlLabel } from '@material-ui/core'

import { PropFilter } from './PropFilter'
import { PropFilterItemBase } from './StringPropFilter'

import { emDash } from '../common/stringUtility'

export type StoreTableHasMsProductFilterBaseProps =
  Pick<PropFilterItemBase, 'value' | 'setValue'>

export const StoreTableHasMsProductFilterBase =
  observer(
    ({value, setValue}: StoreTableHasMsProductFilterBaseProps) =>
      <FormControlLabel
        labelPlacement="start"
        label="Сматчен с МоимСкладом"
        control={
          <Box marginLeft={1}>
            <PropFilter
              filter={{
                value,
                setValue,
                isBoolean: true,
                placeholder: emDash,
                onText: 'да',
                offText: 'нет'
              }}
            />
          </Box>}
      />)