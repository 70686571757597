import React, { useState } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box, Button, Paper, Typography } from '@material-ui/core'

import { MoySkladStatusMapForm } from './MoySkladStatusMapForm'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useStore } from '../hooks/storeHook'

import { MoySkladStatusMapsModel } from '../server/mpsklad_core/Models/MoySkladStatusMapsModel'

export const MoySkladStatusMapPanel =
  observer(
    () => {
      const {showError} = useMessages()

      const {syncStore} = useStore()

      const api = useApi()
      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const [statusMaps, setStatusMaps] = useState<MoySkladStatusMapsModel | null>(null)

      const onLoad = async () => {
        setIsLoading()

        try {
          const maps = await api.userSync.getMoySkladStatusMaps()
          setStatusMaps(makeAutoObservable(maps))
        } catch (e) {
          console.error('Failed to load MoySklad status maps', e)
          showError('Не удалось загрузить')
        } finally {
          setNotLoading()
        }
      }

      if (!syncStore.moySkladAccount) {
        return null
      }

      return (
        <Paper>
          <Box padding={2}>
            <Box marginBottom={3}>
              <Typography variant="h6">
                Сопоставление статусов заказа
              </Typography>
            </Box>

            {
              statusMaps === null
              ? isLoading
                ? 'Загружаем...'
                : <>
                  {
                    syncStore.moySkladAccount.hasStatusMaps &&
                    <Box marginBottom={2}>
                      Статусы сопоставлены
                    </Box>
                  }

                  <Button variant="contained" onClick={onLoad}>
                    {
                      syncStore.moySkladAccount.hasStatusMaps
                      ? 'Изменить'
                      : 'Сопоставить'
                    }
                  </Button>
                </>
              :
              <MoySkladStatusMapForm statusMaps={statusMaps} resetStatusMaps={() => setStatusMaps(null)}/>
            }
          </Box>
        </Paper>
      )
    })