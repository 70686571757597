import React from 'react'
import { FormHelperText } from '@material-ui/core'

export const AppErrorMessage =
  ({errorId}: {errorId: string | null}) =>
    <div style={{marginLeft: 20}}>
      <h1>
        Что-то пошло не так :(
      </h1>

      <p>
        Обновите страницу или обратитесь в поддержку.
      </p>

      <FormHelperText>
        Id ошибки: {errorId ?? 'unknown'}.
      </FormHelperText>
    </div>