import React from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { Add } from '@material-ui/icons'
import { Box, Button, Grid, IconButton, MenuItem, Select } from '@material-ui/core'

import { AccountStoreFormBaseProps, ImportWarehousesToMsProps, WarehouseModelBase2 } from './EditAccountStoreForm'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { required } from '../common/objectUtils'
import { fixMsAppScrollProps } from '../common/msAppUtils'

import { AddStoreModel } from '../server/mpsklad_core/Models/AddStoreModel'
import { StoreModelBase } from '../server/mpsklad_core/Models/StoreModelBase'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'

export type AddAccountStoreFormBaseProps<TWarehouse extends WarehouseModel> =
  AccountStoreFormBaseProps<TWarehouse>
  & {
  addStore: (store: AddStoreModel) => Promise<StoreModelBase>
}

export type AddAccountStoreFormProps<TWarehouse extends WarehouseModelBase2> =
  AddAccountStoreFormBaseProps<TWarehouse>
  & ImportWarehousesToMsProps<TWarehouse>
  & {
  warehouses: TWarehouse[]

  msStores: MoySkladStoreModel[]

  onAdd: (store: StoreModelBase) => Promise<void>
}

export const AddAccountStoreForm =
  observer(
    <TWarehouse extends WarehouseModelBase2>
    ({
       accountId, warehouses, msStores,
       addStore, whKeySelector,
       formatWarehouseName, formatWarehouseDisplayName,
       importWarehousesToMs, onAdd
     }: AddAccountStoreFormProps<TWarehouse>) => {
      const model = useLocalObservable<AddStoreModel>(() => ({
        accountId,
        warehouseId: undefined,
        warehouseName: '',
        msStoreId: ''
      }))

      const {showSuccess, showWarning} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()

      const selectedWarehouse =
        warehouses.find(_ => (!!model.warehouseId && whKeySelector(_) === model.warehouseId)
                             || (!!model.warehouseName && whKeySelector(_) === model.warehouseName))

      const setWarehouse = (whKey: string | number | undefined) => {
        if (!whKey) {
          model.warehouseId = undefined
          model.warehouseName = ''
          return
        }

        const newWarehouse = required(warehouses.find(_ => whKeySelector(_) === whKey))
        model.warehouseId = newWarehouse.id
        model.warehouseName = newWarehouse.name
      }

      const onAddClick = async () => {
        setSubmitting()

        try {
          const newModel = await addStore(model)
          await onAdd(newModel)

          // Reset
          model.warehouseId = undefined
          model.warehouseName = ''
          model.msStoreId = ''

          showSuccess('Добавлено!')
        } catch (e) {
          console.error('Failed to add a store', e)
        } finally {
          setSubmitted()
        }
      }

      const onImportClick = async () => {
        setSubmitting()

        try {
          if (selectedWarehouse) {
            const warehousesNames = [formatWarehouseName(selectedWarehouse)]
            await importWarehousesToMs({warehousesNames})
            showSuccess(`Склад создан в МоёмСкладе!`)
          } else {
            showWarning('Для начала выберите склад.')
          }
        } catch (e) {
          console.error('Failed to duplicate warehouse to MoySklad', e)
        } finally {
          setSubmitted()
        }
      }

      return (
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={4} style={{overflow: 'hidden'}}>
            <Select
              value={selectedWarehouse ? whKeySelector(selectedWarehouse) : ''}
              onChange={e => setWarehouse(e.target.value as string | number | undefined)}
              MenuProps={fixMsAppScrollProps}
            >
              <MenuItem>&mdash;</MenuItem>

              {
                warehouses.filter(_ => !_.isMatched)
                          .map(_ =>
                            <MenuItem key={whKeySelector(_)} value={whKeySelector(_)}>
                              {formatWarehouseDisplayName(_)}
                            </MenuItem>)
              }
            </Select>
          </Grid>

          <Grid item container xs={8}>
            <Grid item xs={4} style={{overflow: 'hidden'}}>
              <Select
                value={model.msStoreId ?? ''}
                onChange={e => model.msStoreId = e.target.value as string}
                MenuProps={fixMsAppScrollProps}
              >
                <MenuItem>&mdash;</MenuItem>

                {
                  msStores.filter(_ => !_.isMatched)
                          .map(_ => <MenuItem key={_.id} value={_.id}>{_.name}</MenuItem>)
                }
              </Select>
            </Grid>

            <Grid item xs={8}>
              <Box display="inline-flex" flexDirection="row" paddingLeft={2}>
                <IconButton title="Добавить" disabled={isSubmitting} onClick={onAddClick}>
                  <Add color="secondary"/>
                </IconButton>

                <Box display="flex" alignItems="center" marginLeft={8}>
                  <Button size="small" variant="contained" disabled={isSubmitting} onClick={onImportClick}>
                    Создать склад в МоёмСкладе
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )
    })