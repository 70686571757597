import React from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { Check, Delete } from '@material-ui/icons'
import { Box, Button, Grid, IconButton, MenuItem, Select } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { required } from '../common/objectUtils'
import { fixMsAppScrollProps } from '../common/msAppUtils'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { StoreModelBase } from '../server/mpsklad_core/Models/StoreModelBase'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { RemoveStoreModel } from '../server/mpsklad_core/Models/RemoveStoreModel'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'
import { CreateMsStoresModel } from '../server/mpsklad_core/Models/CreateMsStoresModel'

export type WarehouseModelBase2 =
  WarehouseModel2<WarehouseModel>

export type WarehouseModel2<TWarehouse extends WarehouseModel> =
  TWarehouse
  & {
  isMatched: boolean
}

export type AccountStoreFormBaseProps<TWarehouse extends WarehouseModel> = {
  accountId: number

  whKeySelector: (warehouse: TWarehouse) => number | string | undefined
}

export type ImportWarehousesToMsProps<TWarehouse extends WarehouseModel> = {
  formatWarehouseName: (warehouse: TWarehouse) => string

  formatWarehouseDisplayName: (warehouse: TWarehouse) => string

  importWarehousesToMs: (warehouse: CreateMsStoresModel) => Promise<void>
}

export type EditAccountStoreFormBaseProps<TWarehouse extends WarehouseModel> =
  AccountStoreFormBaseProps<TWarehouse>
  & {
  editStore: (store: EditStoreModel) => Promise<StoreModelBase>

  removeStore: (store: RemoveStoreModel) => Promise<void>
}

export type EditAccountStoreFormProps<TWarehouse extends WarehouseModelBase2> =
  EditAccountStoreFormBaseProps<TWarehouse>
  & ImportWarehousesToMsProps<TWarehouse>
  & {
  store: StoreModelBase

  warehouses: TWarehouse[]

  msStores: MoySkladStoreModel[]

  onEdit: (newStore: StoreModelBase) => Promise<void>

  onRemove: (store: StoreModelBase) => Promise<void>
}

export const EditAccountStoreForm =
  observer(
    <TWarehouse extends WarehouseModelBase2>
    ({
       accountId, store, warehouses, msStores,
       editStore, removeStore, whKeySelector,
       onEdit, onRemove, formatWarehouseName,
       formatWarehouseDisplayName, importWarehousesToMs
     }: EditAccountStoreFormProps<TWarehouse>) => {
      const model = useLocalObservable<EditStoreModel>(() => ({
        accountId,
        storeId: store.id,
        warehouseId: store.warehouseId,
        warehouseName: store.name,
        msStoreId: store.msStoreId
      }))

      const {showDialog} = useLogic()
      const {showSuccess, showWarning} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()

      const storeWarehouse =
        warehouses.find(_ => (!!store.warehouseId && whKeySelector(_) === store.warehouseId)
                             || (!!store.name && whKeySelector(_) === store.name))

      const selectedWarehouse =
        warehouses.find(_ => (!!model.warehouseId && whKeySelector(_) === model.warehouseId)
                             || (!!model.warehouseName && whKeySelector(_) === model.warehouseName))

      const setWarehouse = (whKey: string | number | undefined) => {
        if (!whKey) {
          model.warehouseId = undefined
          model.warehouseName = ''
          return
        }

        const newWarehouse = required(warehouses.find(_ => whKeySelector(_) === whKey))
        model.warehouseId = newWarehouse.id
        model.warehouseName = newWarehouse.name
      }

      const onSaveClick = async () => {
        setSubmitting()

        try {
          const newModel = await editStore(model)
          await onEdit(newModel)
          showSuccess('Сохранено!')
        } catch (e) {
          console.error('Failed to edit a store', e)
        } finally {
          setSubmitted()
        }
      }

      const onRemoveClick = async () => {
        if (!await showDialog(
          'Заказы для склада маркетплейса будут сброшены!', {
            title: 'Удалить сопоставление складов?',
            acceptButton: 'Удалить'
          })) {
          return
        }

        setSubmitting()

        try {
          await removeStore({accountId, storeId: store.id})
          await onRemove(store)
          showSuccess('Удалено!')
        } catch (e) {
          console.error('Failed to delete a store', e)
        } finally {
          setSubmitted()
        }
      }

      const onImportClick = async () => {
        setSubmitting()

        try {
          if (selectedWarehouse) {
            const warehousesNames = [formatWarehouseName(selectedWarehouse)]
            await importWarehousesToMs({warehousesNames})
            showSuccess(`Склад создан в МоёмСкладе!`)
          } else {
            showWarning('Для начала выберите склад.')
          }
        } catch (e) {
          console.error('Failed to duplicate warehouse to MoySklad', e)
        } finally {
          setSubmitted()
        }
      }

      return (
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={4} style={{overflow: 'hidden'}}>
            <Select
              value={selectedWarehouse ? whKeySelector(selectedWarehouse) : ''}
              onChange={e => setWarehouse(e.target.value as string | number | undefined)}
              MenuProps={fixMsAppScrollProps}
            >
              <MenuItem>&mdash;</MenuItem>

              {
                warehouses.filter(warehouse => !warehouse.isMatched || warehouse === storeWarehouse)
                          .map(_ =>
                            <MenuItem key={whKeySelector(_)} value={whKeySelector(_)}>
                              {formatWarehouseDisplayName(_)}
                            </MenuItem>)
              }
            </Select>
          </Grid>

          <Grid item container xs={8}>
            <Grid item xs={4} style={{overflow: 'hidden'}}>
              <Select
                value={model.msStoreId ?? ''}
                onChange={e => model.msStoreId = e.target.value as string}
                MenuProps={fixMsAppScrollProps}
              >
                <MenuItem>&mdash;</MenuItem>

                {
                  msStores.filter(_ => !_.isMatched || _.id === store.msStoreId)
                          .map(_ => <MenuItem key={_.id} value={_.id}>{_.name}</MenuItem>)
                }
              </Select>
            </Grid>

            <Grid item xs={8}>
              <Box display="inline-flex" flexDirection="row" paddingLeft={2}>
                <IconButton title="Сохранить" disabled={isSubmitting} onClick={onSaveClick}>
                  <Check color="secondary"/>
                </IconButton>

                <IconButton title="Удалить" disabled={isSubmitting} onClick={onRemoveClick}>
                  <Delete color="error"/>
                </IconButton>

                <Box display="flex" alignItems="center" marginLeft={2}>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={onImportClick}>
                    Создать склад в МоёмСкладе
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )
    })