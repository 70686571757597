import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { fromUnixTime } from 'date-fns'

import { Alert } from '@material-ui/lab'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

import { useMessages } from '../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { tryFormatDateTime } from '../common/dateTimeUtility'

import { FeedsFileModel } from '../server/mpsklad_core/Models/FeedsFileModel'

export type StoreFeedsTabProps = {
  accountId: number

  setFeedsGeneration: (accountId: number, isEnabled: boolean) => Promise<void>

  checkFeedsGeneration: (accountId: number) => Promise<boolean>

  getStoreFileUrl: (accountId: number) => string

  getFileDescription: (accountId: number) => Promise<FeedsFileModel>
}

export const StoreFeedsTab = observer(
  ({
     accountId,
     checkFeedsGeneration,
     setFeedsGeneration,
     getStoreFileUrl,
     getFileDescription
   }: StoreFeedsTabProps) => {
    const {showSuccess, showInfo} = useMessages()

    const [isUsingGeneration, setGenerationUsage] = useState(false)

    const [fileCreationDate, setFileCreationDate] = useState<Date>()
    const [fileName, setFileName] = useState('')

    const [isAwaiting, setAwaiting, setNotAwaiting] = useBoolState(false)

    async function onFeedsGenerationChange(event: React.ChangeEvent<HTMLInputElement>) {
      try {
        setAwaiting()

        let value = event.target.checked

        if (value) {
          showInfo('Включаем генерацию')
        }

        await setFeedsGeneration(accountId, value)

        setGenerationUsage(value)

        showSuccess('Обновлено')
        return
      } catch (e) {
        setGenerationUsage(!event.target.checked)
      } finally {
        setNotAwaiting()
      }
    }

    useLazyEffect(async () => {
      setAwaiting()

      setGenerationUsage(await checkFeedsGeneration(accountId))

      setNotAwaiting()
    }, [accountId])

    useEffect(() => {
      if (!isUsingGeneration) {
        return
      }

      const checkFileUpdate = async () => {
        const {name, dateUnix} = await getFileDescription(accountId)

        setFileName(name)
        setFileCreationDate(fromUnixTime(dateUnix))
      }

      const initialCheckerId = setTimeout(checkFileUpdate)
      const intervalCheckerId = setInterval(checkFileUpdate, 60000)

      return () => {
        clearTimeout(initialCheckerId)
        clearInterval(intervalCheckerId)
      }
    }, [accountId, getFileDescription, isUsingGeneration])

    return (
      <Box padding={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={6}>
            <Alert severity="warning">Тестовая возможность</Alert>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isAwaiting}
                  checked={isUsingGeneration}
                  onChange={onFeedsGenerationChange}
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
              label="Генерация фидов"
              labelPlacement="start"
            />
          </Grid>

          {
            isUsingGeneration &&
            <Grid item xs={6}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Файл</TableCell>
                      <TableCell>Дата обновления</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Link
                          download={fileName}
                          href={getStoreFileUrl(accountId)}
                        >
                          {fileName}
                        </Link>
                      </TableCell>

                      <TableCell>
                        {tryFormatDateTime(fileCreationDate)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          }

          <Grid item xs={8}>
            <FormHelperText>
              Файл генерируется раз в сутки в 00:00 по МСК.
            </FormHelperText>
          </Grid>
        </Grid>
      </Box>
    )
  })