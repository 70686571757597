import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Divider } from '@material-ui/core'

import { MyProductsColumnCheckbox } from './MyProductsColumnCheckbox'

import { useStore } from '../hooks/storeHook'

import { MyProductColumnGroupId, myProductColumnGroupNames } from '../types/myProductColumnGroups'

export const MyProductsColumnItem =
  observer(
    ({groupId}: {groupId: MyProductColumnGroupId}) => {
      const {myProductColumnsGrouped} = useStore()

      const columnGroup = myProductColumnsGrouped[groupId]

      return (
        <>
          <Box marginY={1}>
            <Box marginLeft={1} marginBottom={1}>
              {myProductColumnGroupNames[groupId]}
            </Box>

            {
              columnGroup.filter(column => column.id !== 'Select')
                         .map(column => <MyProductsColumnCheckbox key={column.id} column={column}/>)
            }
          </Box>

          <Divider/>
        </>
      )
    })