import React from 'react'

import { TableCell } from '@material-ui/core'

import { SyncLogsButton } from './SyncLogsButton'
import { CombinedOrderSyncLogsButton } from './CombinedOrderSyncLogsButton'

import { required } from '../../common/objectUtils'

import { MyRowProps } from '../../types/tableTypes'

import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'
import { MoySkladOrderModel } from '../../server/mpsklad_core/Models/Orders/MoySkladOrderModel'

export const MoySkladOrderSyncLogsCell =
  ({row}: MyRowProps<MoySkladOrderModel>) =>
    <TableCell className="table-border">
      <SyncLogsButton themed type={IntegrationType.MoySklad} orderId={row.id}/>

      {
        !!row.ozonOrderId &&
        <SyncLogsButton
          themed
          type={IntegrationType.Ozon}
          accountId={required(row.ozonOrderAccountId)}
          orderId={row.ozonOrderId}
        />
      }

      {
        !!row.wbOrderId &&
        <SyncLogsButton
          themed
          type={IntegrationType.Wildberries}
          accountId={required(row.wbOrderAccountId)}
          orderId={row.wbOrderId}
        />
      }

      {
        !!row.yandexMarketOrderId &&
        <SyncLogsButton
          themed
          type={IntegrationType.YandexMarket}
          accountId={required(row.yandexMarketOrderAccountId)}
          orderId={row.yandexMarketOrderId}
        />
      }

      <CombinedOrderSyncLogsButton msOrderId={row.id}/>
    </TableCell>