import React, { FunctionComponent, ReactNode } from 'react'

import clsx from 'clsx'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import { AppBarProps } from '@material-ui/core/AppBar/AppBar'

import { useStyles } from './AutoTabs'
import { tabA11yProps, TabPanel } from './TabPanel'

export type ReactiveTabsProps = {
  /**
   * If specified, this component will be controlled. Use `onTabChange` to update the `tab`.
   */
  tab: number

  titles: ReactNode[]

  secondary?: boolean

  appBarProps?: Omit<AppBarProps, 'position' | 'className'>

  onTabChange: (newTabIndex: number) => void

  children: ReactNode

  AfterTabsComponent?: FunctionComponent
}

/**
 * Renders the AppBar like AutoTabs, but has a single child in the TabPanel.
 * Must be controlled via `tab` prop.
 */
export const ReactiveTabs =
  ({tab, titles, secondary, appBarProps, onTabChange, children, AfterTabsComponent}: ReactiveTabsProps) => {
    const classes = useStyles()

    const onTabsChange =
      (event: unknown, newTabIndex: number) =>
        onTabChange(newTabIndex)

    // TODO: Altrenative indicator for active tab to fix multi-row bugs or is Tabs[scrollable] good enough?
    return <>
      <AppBar
        position="relative"
        className={clsx(classes.appBar, classes.appBarPrimary, secondary && classes.appBarSecondary)}
        {...appBarProps}
      >
        <Tabs variant="scrollable" value={tab} onChange={onTabsChange}>
          {
            titles.map(
              (title, index) =>
                <Tab key={index} label={title} {...tabA11yProps(index)}/>)
          }
        </Tabs>

        {
          AfterTabsComponent !== undefined &&
          <AfterTabsComponent/>
        }
      </AppBar>

      {
        <TabPanel value={0} index={0}>
          {children}
        </TabPanel>
      }
    </>
  }