import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button, Grid } from '@material-ui/core'

import { ManualLink } from './ManualLink'
import { ImpersonationBar } from './ImpersonationBar'
import { MoySkladStatusMapPanel } from './MoySkladStatusMapPanel'
import { MoySkladNamePhoneFormPanel } from './MoySkladNamePhoneFormPanel'

import { useStore } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'

import { required } from '../common/objectUtils'

export const MoySkladAppSettingsScreen =
  observer(
    () => {
      const user = required(useStore().user)
      const [hasPersonalData, setHasPersonalData] = useBoolState()

      return (
        <div className="body_wrapper_zero">
          <ImpersonationBar/>

          <Box padding={3}>
            <h1>
              Настройка приложения
            </h1>

            <Grid container spacing={3}>
              <Grid item lg={6} xl={4}>
                <Grid container direction="column" spacing={3}>
                  <Grid item lg={12}>
                    {
                      !hasPersonalData && !user.phoneNumber
                      ? <MoySkladNamePhoneFormPanel onFinish={setHasPersonalData}/>
                      : <MoySkladStatusMapPanel/>
                    }
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={3}>
                <Button variant="contained" color="secondary">
                  <ManualLink/>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      )
    })