import React from 'react'
import clsx from 'clsx'

import { Box, CircularProgress, IconButton } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import { createStyles, makeStyles } from '@material-ui/core/styles'

export type AutocompleteStubProps = {
  label?: string

  fullWidth?: boolean

  isLoading?: boolean

  onClick?: VoidFunction
}

export const AutocompleteStub =
  ({label, fullWidth, isLoading, onClick}: AutocompleteStubProps) => {
    const classes = useStyles()

    return (
      <div
        className={clsx(classes.wrap, onClick && classes.wrapClickable, fullWidth && classes.wrapFullWidth)}
        onClick={onClick}>
        <span>
          {label ?? ''}
        </span>

        {
          isLoading
          ?
          <Box minWidth={30} minHeight={30} display="inline-flex" justifyContent="center" alignItems="center">
            <CircularProgress size={18}/>
          </Box>
          :
          <IconButton size="small">
            <ArrowDropDown/>
          </IconButton>
        }
      </div>
    )
  }

const useStyles =
  makeStyles(() =>
    createStyles({
      wrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // TODO: Pass through via props?
        minWidth: 270,
        maxWidth: 300,
        padding: 4,
        paddingLeft: 10,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4
      },
      wrapClickable: {
        cursor: 'pointer'
      },
      wrapFullWidth: {
        width: '100%',
        maxWidth: 'none'
      }
    }))