import { TableColumn } from './tableTypes'
import { createColumns } from './columnGroupCreator'

import { FakeApiCell } from '../components/FakeApiCell'
import { UserSyncCell } from '../components/UserSyncCell'
import { UserImpersonateCell } from '../components/UserImpersonateCell'
import { TableDateCell, TableDateCellLastOrder } from '../components/tableCells/TableDateCell'

import { UserModel } from '../server/mpsklad_core/Models/UserModel'

export type UserColumnId = keyof UserModel | 'impersonate'

export type UserColumn = TableColumn<UserColumnId, UserModel>

export const userColumns: UserColumn[] =
  createColumns<UserColumnId, UserModel>({
    id: 'email',
    title: 'Email'
  }, {
    id: 'id',
    title: 'ID'
  }, {
    id: 'createdAt',
    title: 'Дата регистрации',
    sortable: true,
    CellComponent: TableDateCell
  }, {
    id: 'name',
    title: 'Имя'
  }, {
    id: 'phone',
    title: 'Телефон'
  }, {
    id: 'hasPaidMsApp',
    title: 'Платное приложение МС',
    sortable: true
  }, {
    id: 'lastOrderDateUnix',
    title: 'Последний заказ МС',
    sortable: true,
    CellComponent: TableDateCellLastOrder
  }, {
    id: 'ozonAccountCount',
    title: 'Аккаунты Ozon',
    sortable: true
  }, {
    id: 'wbAccountCount',
    title: 'Аккаунты Wildberries',
    sortable: true
  }, {
    id: 'yandexMarketAccountCount',
    title: 'Аккаунты Yandex Market',
    sortable: true
  }, {
    id: 'allowSync',
    title: 'Разрешить синхронизацию',
    sortable: true,
    CellComponent: UserSyncCell
  }, {
    id: 'isUsingFakeApi',
    title: 'Использовать кэш',
    sortable: true,
    CellComponent: FakeApiCell
  }, {
    id: 'msTariff',
    title: 'Тариф МоегоСклада',
    sortable: true
  }, {
    id: 'impersonate',
    title: 'Посмотреть',
    CellComponent: UserImpersonateCell
  })