import { useCallback, useState } from 'react'

import { isValidationErrorResponse, RequestValidationErrors } from '../common/requestValidationError'

export const useSubmitWithValidation =
  <TArgs extends unknown[], TResult>(
    submit: (...args: TArgs) => Promise<TResult>) => {
    const [validationErrors, setValidationErrors] = useState<RequestValidationErrors | null>(null)

    const submitWithValidation = useCallback(
      async (...args: TArgs): Promise<TResult> => {
        setValidationErrors(null)

        try {
          return await submit(...args)
        } catch (e) {
          if (isValidationErrorResponse(e)) {
            setValidationErrors(e.errors)
          }

          throw e
        }
      },
      [submit])

    return {
      submit: submitWithValidation,
      validationErrors
    } as const
  }