import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button } from '@material-ui/core'

import { LoginScreen } from './LoginScreen'

import { useLogic, useStore } from '../hooks/storeHook'

export const AccountScreen = observer(
  () => {
    const logic = useLogic()
    const {user} = useStore()

    if (!user) {
      return <LoginScreen/>
    }

    return (
      <Box padding={5}>
        <Box marginBottom={3}>
          Вы авторизованы: {[user.name, user.email].filter(_ => _).join(', ')}.
        </Box>

        <Box>
          <Button onClick={logic.logout} variant="contained" color="primary">
            Выйти
          </Button>
        </Box>
      </Box>
    )
  })