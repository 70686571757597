import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { AppAuthRoutePath } from '../common/appRoutes'

export type AppAuthRouteProps = {
  path: AppAuthRoutePath | AppAuthRoutePath[]

  component: RouteProps['component']

  exact?: boolean
}

export const AppAuthRoute =
  (props: AppAuthRouteProps) =>
    <Route {...props}/>