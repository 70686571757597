import React from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { AppRoute } from './AppRoute'
import { WbImportTabsScreen } from './WbImportScreen'
import { OzonImportTabsScreen } from './OzonImportScreen'
import { YandexMarketImportTabsScreen } from './YandexMarketImportScreen'
import { createAllowedTabs, RouteTabs } from './RouteTabs'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'

export const ImportRoute =
  observer(() => {
    const {allowedIntegrations} = useStore()

    const allowedTabs =
      createAllowedTabs(
        allowedIntegrations,
        [{
          title: 'Ozon',
          route: appRoutes.Import.ozon,
          type: StoreType.Ozon
        }, {
          title: 'Wildberries',
          route: appRoutes.Import.wildberries,
          type: StoreType.Wildberries
        }, {
          title: 'Yandex.Market',
          route: appRoutes.Import.yandexmarket,
          type: StoreType.YandexMarket
        }])

    if (!allowedTabs.length) {
      return <Box padding={3}>Аккаунты не подключены</Box>
    }

    return (
      <div className="body_wrapper_zero">
        <Box flex={1}>
          <RouteTabs routes={allowedTabs}>
            <Switch>
              <AppRoute path={appRoutes.Import.ozon} component={OzonImportTabsScreen}/>

              <AppRoute path={appRoutes.Import.wildberries} component={WbImportTabsScreen}/>

              <AppRoute path={appRoutes.Import.yandexmarket} component={YandexMarketImportTabsScreen}/>

              {/* NOTE: Fallback route, must remain the last one */}
              <Redirect from="*" to={allowedTabs[0].route}/>
            </Switch>
          </RouteTabs>
        </Box>
      </div>
    )
  })