import React from 'react'
import { observer } from 'mobx-react-lite'

import { fromUnixTime } from 'date-fns'

import { AppNotificationBar } from './AppNotificationBar'

import { useStore } from '../hooks/storeHook'

export const StoreMatchingWarningBar =
  observer(() => {
    const {user} = useStore()

    if (!user) {
      return null
    }

    try {
      // Don't show to new users
      if (fromUnixTime(user.createdAtUnix) >= new Date('2024-02-08T00:00:00.000Z')) {
        return null
      }
    } catch (e) {
      console.error(e, 'Failed to check user creation date')
    }

    return (
      <AppNotificationBar severity="warning" notificationId="storeMatchingWarning">
        Пожалуйста, проверьте и обновите настройки складов для всех маркетплейсов.
      </AppNotificationBar>
    )
  })