//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MyProductState {
  Active = 0,
  New = 1,
  Error = 2,
  FromMarketplace = 3
}
