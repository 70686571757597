import { action, computed, makeObservable, observable, reaction } from 'mobx'

import { tableRowsPerPageOptions } from '../hooks/tableHooks'

import { isLocalStorageAllowed } from '../common/commonUtils'

import { OrderInfo } from '../types/orderInfo'
import { TableOrder } from '../types/tableOrder'
import { TableColumn } from '../types/tableTypes'

export abstract class TableDataBase<TRow extends object, TColumnId> {
  protected readonly _name: string

  protected readonly _pageSizeStorageKey: string

  @observable
  protected _selectedRows = new Set<TRow>()

  @observable
  protected globalFilter: string | null

  @observable
  private _order: TableOrder<TColumnId, TRow> | null = null

  @observable
  pageSize = tableRowsPerPageOptions[2]

  @observable
  pageIndex = 0

  protected constructor(name: string, globalFilter: string | null = null) {
    makeObservable(this)

    this._name = name
    this._pageSizeStorageKey = `tablePageSize_${this._name}`

    this.globalFilter = globalFilter

    this.initPageSize()
  }

  abstract get data(): TRow[]

  protected abstract get filteredRowCount(): number

  protected abstract onOrderChange(): void

  @computed
  get pageCount(): number {
    return Math.ceil(this.filteredRowCount / this.pageSize)
  }

  @computed
  get order() {
    return this._order
  }

  setPageIndex =
    (pageIndex: number) =>
      this.pageIndex = pageIndex

  setPageSize =
    (pageSize: number) =>
      this.pageSize = pageSize

  setGlobalFilter =
    (value: string | null | undefined) =>
      this.globalFilter = value?.trim() || null

  getColumnOrder =
    (id: TColumnId): OrderInfo =>
      this._order?.column.id === id
      ? {
          isOrdered: true,
          orderDirection: this._order.isAscending ? 'asc' : 'desc'
        }
      : {isOrdered: false}

  @action
  setOrder =
    (column: TableColumn<TColumnId, TRow> | null, isAscending: boolean = true) => {
      this._order = column != null ? {column, isAscending} : null

      // Reaction doesn't work, probably because render triggers first
      this.onOrderChange()
    }

  toggleOrder =
    (column: TableColumn<TColumnId, TRow>) => {
      // Three-way toggle: descending, ascending, off
      if (this._order?.column.id === column.id) {
        if (this._order.isAscending) {
          this.setOrder(null)
        } else {
          this.setOrder(column, true)
        }
      } else {
        this.setOrder(column, false)
      }
    }

  @computed
  get selectedRows(): TRow[] {
    return this.data.filter(_ => this._selectedRows.has(_))
  }

  @computed
  get selectedRowCount(): number {
    return this.selectedRows.length
  }

  isRowSelected =
    (row: TRow) =>
      this._selectedRows.has(row)

  toggleRowSelection =
    (row: TRow) => {
      if (this.isRowSelected(row)) {
        this.deselectRow(row)
      } else {
        this.selectRow(row)
      }
    }

  selectRow =
    (row: TRow) =>
      this._selectedRows.add(row)

  deselectRow =
    (row: TRow) =>
      this._selectedRows.delete(row)

  toggleAllRowsSelection =
    () => this.setAllRowsSelection(this._selectedRows.size !== this.data.length)

  setAllRowsSelection =
    (selectAll: boolean) => {
      this._selectedRows = new Set(selectAll ? this.data : [])
    }

  private initPageSize =
    () => {
      if (!isLocalStorageAllowed) {
        return
      }

      try {
        const savedOptionString = localStorage.getItem(this._pageSizeStorageKey)

        if (savedOptionString) {
          const savedOption = JSON.parse(savedOptionString)

          if (tableRowsPerPageOptions.includes(savedOption)) {
            this.pageSize = savedOption
          }
        }
      } catch (e) {
        console.error('Failed to get saved pageSize', e)

        try {
          localStorage.removeItem(this._pageSizeStorageKey)
        } catch (e2) {
          console.error('Failed to clear pageSize', e2)
        }
      }

      reaction(
        () => this.pageSize,
        () => {
          try {
            localStorage.setItem(this._pageSizeStorageKey, JSON.stringify(this.pageSize))
          } catch (e) {
            console.error('Failed to save pageSize', e)
          }
        })
    }
}