import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountStoresForm } from './AccountStoresForm'

import { useApi } from '../hooks/storeHook'

import { WbAccountRequiredProps } from '../types/accountProps'
import { integrationNames } from '../types/integrationTypeName'

import { required } from '../common/objectUtils'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { WbStoreModel } from '../server/mpsklad_core/Models/WbStoreModel'
import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { WbWarehouseModel } from '../server/mpsklad_core/Models/WbWarehouseModel'

export const WbStoresForm =
  observer(({account}: WbAccountRequiredProps) => {
    const api = useApi()

    return <AccountStoresForm
      integrationName={integrationNames.Wildberries}
      storeType={StoreType.Wildberries}
      accountId={account.id}
      loadStores={api.userSync.getWbStores}
      loadWarehouses={api.userSync.getWbWarehouses}
      addStore={api.userSync.addWbStore}
      editStore={api.userSync.editWbStore}
      removeStore={api.userSync.removeWbStore}
      whKeySelector={account.isFBS ? warehouse => warehouse.id : warehouse => warehouse.name}
      isStoreUsingWarehouse={(wbStore, warehouse) => isWbStoreUsingWarehouse(account, wbStore, warehouse)}
      hint={account.isFBS ? undefined : 'На складах со звёздочкой * есть остатки'}
      formatWarehouseNameHint={warehouse => warehouse.hasStocks ? ' *' : ''}
    />
  })

// NOTE: This function is duplicated from backend, update simultaneously
const isWbStoreUsingWarehouse =
  (wbAccount: WbAccountModel, wbStore: WbStoreModel, warehouse: WbWarehouseModel) =>
    wbAccount.isFBS ? wbStore.warehouseId === required(warehouse.id)
                    : wbStore.name === required(warehouse.name)