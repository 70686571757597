import { EnumValue } from '../common/tsUtils'
import { CommissionsRecordingType } from '../server/mpsklad_core/Entity/Base/CommissionsRecordingType'

export const commissionsRecordingTypeNames: {
  [K in EnumValue<typeof CommissionsRecordingType>]: string
} = {
  [CommissionsRecordingType.Nothing]: 'Не учитывать',
  [CommissionsRecordingType.Commission]: 'Только комиссии',
  [CommissionsRecordingType.LogisticAndFees]: 'Логистику и сборы',
  [CommissionsRecordingType.CommissionLogisticAndFees]: 'Комиссии, логистику и сборы'
}