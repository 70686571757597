import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'

import { MsFormData } from './MoySkladDataForm'
import { WbAccountTokensForm } from './WbAccountTokensForm'
import { MaximumProductStocksForm } from './MaximumProductStocksForm'
import { MoySkladWbPropertiesSelector } from './MoySkladWbPropertiesSelector'

import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useLogic } from '../hooks/storeHook'
import { usePaperStyles } from '../hooks/paperStylesHook'

import { exact } from '../common/tsUtils'
import { formatAccountTitle } from '../common/accountUtils'

import { WbAccountProps } from '../types/accountProps'
import { EditWbAccountModel } from '../server/mpsklad_core/Models/EditWbAccountModel'
import { CommissionsRecordingType } from '../server/mpsklad_core/Entity/Base/CommissionsRecordingType'

export const WbAccountForm =
  observer(
    ({account}: WbAccountProps) => {
      const {showSuccess} = useMessages()

      const logic = useLogic()
      const api = useApi()

      // TODO: Rework this mess into observables or something
      // TODO: Maybe split requests for every field
      const [isFBS, setFBS] = useState(account?.isFBS ?? true)
      const [isUpdatePrices, setUpdatePrices] = useState(account?.isUpdatePrices ?? false)
      const [isGeneratingPayments, setGeneratingPayments] = useState(account?.isGeneratingPayments ?? false)
      const [isUsingCommissions, setUsingCommissions] = useState(account?.isUsingCommissions ?? false)

      const [ordersCommissionsRecording, setOrdersCommissionsRecording] =
        useState(account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing)

      const [documentsCommissionsRecording, setDocumentsCommissionsRecording] =
        useState(account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing)

      // TODO: Unify? Add a new field?
      const [newToken, setNewToken] = useState(account?.newToken ?? '')
      const [statTokenNew, setStatTokenNew] = useState(account?.statTokenNew ?? '')
      const [tokenCombined, setTokenCombined] = useState(account?.tokenCombined ?? '')

      const [name, setName] = useState(account?.name ?? '')
      const [msSalesReturnStoreId, setMsSalesReturn] = useState<string | undefined>(account?.msSalesReturnStoreId)

      const [isLoading, setIsLoading] = useState(false)

      const [msData, setMsData] = useState<MsFormData | null>(null)
      const classes = usePaperStyles()

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          setIsLoading(true)

          try {
            const formData = exact<Omit<EditWbAccountModel, 'id'>>({
              isFBS,
              isUpdatePrices,
              isGeneratingPayments,
              isUsingCommissions,
              ordersCommissionsRecording,
              documentsCommissionsRecording,
              newToken,
              statTokenNew,
              tokenCombined,
              name: name || undefined,
              msSalesReturnStoreId,
              msData: msData ?? undefined
            })

            if (account) {
              await logic.editWbAccount({id: account.id, ...formData})
            } else {
              await logic.createWbAccount({id: 0, ...formData})
            }

            showSuccess('Сохранено!')
          } catch (e) {
            console.error('Failed to save Wb account', e)
          } finally {
            setIsLoading(false)
          }
        }

      return (
        <Box display="flex">
          <Paper className={clsx([classes.paper, classes.paperHalf, classes.paperLeft])}>
            <Box padding={2}>
              <Typography variant="h6">
                {formatAccountTitle(account)}
              </Typography>

              <Box marginTop={2}>
                <form noValidate autoComplete="off" onSubmit={onSubmit}>
                  <Box marginTop={2}>
                    <TextField
                      id="name"
                      label="Название"
                      placeholder="Wildberries 42"
                      InputLabelProps={{shrink: true}}
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </Box>

                  <Box marginTop={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Схема работы
                      </FormLabel>

                      <RadioGroup
                        name="isFBS"
                        value={JSON.stringify(isFBS)}
                        onChange={e => setFBS(JSON.parse(e.target.value))}
                      >
                        <FormControlLabel
                          value={JSON.stringify(true)}
                          control={<Radio/>}
                          label="Продажа со своего склада"
                        />

                        <FormControlLabel
                          value={JSON.stringify(false)}
                          control={<Radio/>}
                          label="Продажа со склада Wildberries"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  {
                    account &&
                    <Box marginTop={2}>
                      <MaximumProductStocksForm
                        account={account}
                        onSubmit={api.userSync.setWbMaximumProductStocks}
                      />
                    </Box>
                  }

                  <Box marginTop={2}>
                    <WbAccountTokensForm
                      newToken={newToken}
                      statTokenNew={statTokenNew}
                      tokenCombined={tokenCombined}
                      tokenCombinedExpiresAtUnix={account?.tokenCombinedExpiresAtUnix}
                      setNewToken={setNewToken}
                      setStatTokenNew={setStatTokenNew}
                      setTokenCombined={setTokenCombined}
                    />
                  </Box>

                  <Box marginTop={5}>
                    <Button variant="contained" type="submit" disabled={isLoading}>
                      {isLoading ? 'Сохраняем...' : 'Сохранить'}
                    </Button>

                    {
                      isLoading &&
                      <Box component={FormHelperText} marginTop={2}>
                        Проверка API статистики может занять пару минут.
                      </Box>
                    }
                  </Box>
                </form>
              </Box>
            </Box>
          </Paper>

          <Paper className={clsx([classes.paper, classes.paperRight])}>
            <MoySkladWbPropertiesSelector
              account={account}
              isLoading={isLoading}
              setMsData={setMsData}

              isUpdatePrices={isUpdatePrices}
              setUpdatePrices={setUpdatePrices}

              isGeneratingPayments={isGeneratingPayments}
              setGeneratingPayments={setGeneratingPayments}

              isUsingCommissions={isUsingCommissions}
              setUsingCommissions={setUsingCommissions}

              ordersCommissionsRecording={ordersCommissionsRecording}
              setOrdersCommissionsRecording={setOrdersCommissionsRecording}

              documentsCommissionsRecording={documentsCommissionsRecording}
              setDocumentsCommissionsRecording={setDocumentsCommissionsRecording}

              msSalesReturnStoreId={msSalesReturnStoreId}
              setMsSalesReturn={setMsSalesReturn}
            />
          </Paper>
        </Box>
      )
    })