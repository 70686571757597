import { UserColumnId } from '../types/userColumns'

import { createServerTableData } from './serverTableData'

import { UserModel } from '../server/mpsklad_core/Models/UserModel'
import { UsersTableOrderColumn } from '../server/mpsklad_core/Models/UsersTableOrderColumn'
import { UsersTableFilterColumn } from '../server/mpsklad_core/Models/UsersTableFilterColumn'

export class AdminStore {
  readonly users = createServerTableData<UserModel, UserColumnId>('admin')(
    UsersTableFilterColumn, UsersTableOrderColumn)
}