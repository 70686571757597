import React, { HTMLAttributes } from 'react'

export type TabPanelProps =
  HTMLAttributes<HTMLDivElement> &
  {
    index: number

    value: number

    children: React.ReactNode
  }

export const TabPanel =
  ({index, value, children, ...passProps}: TabPanelProps) =>
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...passProps}
    >
      {value === index && children}
    </div>

export const tabA11yProps =
  (index: number) => ({
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  })