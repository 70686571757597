import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'
import { WbSyncSwitch } from './WbSyncSwitch'
import { OzonSyncSwitch } from './OzonSyncSwitch'

import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'
import { YandexMarketAccountModel } from '../server/mpsklad_core/Models/YandexMarketAccountModel'
import { YandexMarketSyncSwitch } from './YandexMarketSyncSwitch'

/**
 * NOTE: SyncAccountInfo types aren't checking mapped types properly
 */
export type HomeTab = {
  title: ReactNode
} & ({
  ozonAccount: OzonAccountModel
  wbAccount?: undefined
  yandexMarketAccount?: undefined
} | {
  wbAccount: WbAccountModel
  ozonAccount?: undefined
  yandexMarketAccount?: undefined
} | {
  yandexMarketAccount: YandexMarketAccountModel
  ozonAccount?: undefined
  wbAccount?: undefined
} | {
  ozonAccount?: undefined
  wbAccount?: undefined
  yandexMarketAccount?: undefined
})

export type HomeTabTitleProps = {
  tab: HomeTab
}

export const HomeTabTitle =
  observer(
    ({tab}: HomeTabTitleProps) => {
      return (
        <Box display="inline-flex" alignItems="center">
          {tab.title}

          {
            !!tab.ozonAccount &&
            <Box marginLeft={1} display="inline">
              <OzonSyncSwitch account={tab.ozonAccount}/>
            </Box>
          }

          {
            !!tab.wbAccount &&
            <Box marginLeft={1} display="inline">
              <WbSyncSwitch account={tab.wbAccount}/>
            </Box>
          }

          {
            !!tab.yandexMarketAccount &&
            <Box marginLeft={1} display="inline">
              <YandexMarketSyncSwitch account={tab.yandexMarketAccount}/>
            </Box>
          }
        </Box>
      )
    })