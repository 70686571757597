import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getUnixTime } from 'date-fns'

import { Button, FormHelperText, Grid } from '@material-ui/core'

import { ButtonLoader } from './ButtonLoader'
import { DateTimeRangePicker } from './DateTimeRangePicker'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { dateUtils } from '../common/dateTimeUtility'

import { LoadOrdersOptions } from '../server/mpsklad_core/Models/LoadOrdersOptions'

export type LoadOrdersFormProps = {
  accountId: number

  loadOrders: (options: LoadOrdersOptions) => Promise<number>
}

export const LoadOrdersForm =
  observer(
    ({accountId, loadOrders}: LoadOrdersFormProps) => {
      const {showSuccess, showWarning} = useMessages()

      const [startDate, setStartDate] = useState(dateUtils.startOfYesterday)
      const [endDate, setEndDate] = useState(dateUtils.now)

      const [isSubmitting, setSubmitting, setFinished] = useBoolState()

      const onDateChange =
        (newStartDate: Date, newEndDate: Date) => {
          setStartDate(newStartDate)
          setEndDate(newEndDate)
        }

      const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        try {
          setSubmitting()

          const orderCount = await loadOrders({
            accountId,
            startDateUnix: getUnixTime(startDate),
            endDateUnix: getUnixTime(endDate)
          })

          if (orderCount === 0) {
            showWarning('Нет заказов за выбранный период')
            return
          }

          showSuccess(`Загружено! Всего заказов за выбранный период: ${orderCount}`)
        } finally {
          setFinished()
        }
      }

      return (
        <form onSubmit={onSubmit}>
          <Grid container direction="column" spacing={2}>
            <DateTimeRangePicker
              containerProps={{xl: 8}}
              startDate={startDate}
              endDate={endDate}
              maxDate={dateUtils.now}
              onChange={onDateChange}
            />

            <Grid item>
              <FormHelperText>
                Загрузка может занять несколько минут для большого количества заказов.
              </FormHelperText>
            </Grid>

            <Grid item>
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                {
                  isSubmitting ? <ButtonLoader text="Загружаем"/>
                               : 'Загрузить'
                }
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    })