import { differenceInDays } from 'date-fns'

import { dateUtils, tryFromUnixTime } from './dateTimeUtility'

export const getWbTokenInfo =
  (tokenExpiresAtUnix: number | undefined) => {
    const tokenExpiresAt = tryFromUnixTime(tokenExpiresAtUnix)

    if (!tokenExpiresAt) {
      return undefined
    }

    const now = dateUtils.now
    const expiresInDays = differenceInDays(tokenExpiresAt, now)

    return {
      expiresAt: tokenExpiresAt,
      expiresInDays,
      expiresSoon: expiresInDays <= 7,
      expiresToday: expiresInDays <= 0,
      expired: tokenExpiresAt < now
    }
  }