import React from 'react'

import { AccountSyncSwitch } from './AccountSyncSwitch'

import { useLogic } from '../hooks/storeHook'

import { WbAccountRequiredProps } from '../types/accountProps'

export const WbSyncSwitch =
  ({account}: WbAccountRequiredProps) => {
    const {setWbAccountSync} = useLogic()

    return <AccountSyncSwitch account={account} setSync={setWbAccountSync}/>
  }