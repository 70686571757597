import React, { ReactNode } from 'react'

import { Table, TableBody, TableContainer } from '@material-ui/core'

export type SyncStatesTableProps = {
  children: ReactNode
}

export const SyncStatesTable =
  ({children}: SyncStatesTableProps) =>
    <TableContainer>
      <Table>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>