import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'

import { MoySkladAttributesForm } from './MoySkladAttributesForm'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useStore } from '../hooks/storeHook'

import { MoySkladAttributeModel } from '../server/mpsklad_core/Models/MoySkladAttributeModel'

export const MoySkladAttributesPanel =
  observer(
    () => {
      const {showError} = useMessages()

      const {syncStore} = useStore()

      const api = useApi()
      const [isLoading, setLoading, setLoaded] = useBoolState()

      const [msAttributes, setMsAttributes] = useState<MoySkladAttributeModel[] | null>(null)

      if (!syncStore.moySkladAccount) {
        return null
      }

      const onLoad = async () => {
        setLoading()

        try {
          setMsAttributes(await api.userSync.getMoySkladAttributes())
        } catch (e) {
          console.error('Failed to get MoySklad attrs', e)
          showError('Не удалось загрузить')
        } finally {
          setLoaded()
        }
      }

      return (
        <Paper>
          <Box padding={2}>
            <Box marginBottom={1}>
              <Typography variant="h6">
                Настройка дополнительных полей заказа
              </Typography>
            </Box>

            {
              msAttributes
              ? <MoySkladAttributesForm
                msAttributes={msAttributes}
                onCreate={(createdAttr: MoySkladAttributeModel) => setMsAttributes([...msAttributes, createdAttr])}
                onReset={() => setMsAttributes(null)}
              />
              : isLoading
                ? <CircularProgress size={25}/>
                : <Button type="button" variant="contained" onClick={onLoad}>Настроить</Button>
            }
          </Box>
        </Paper>
      )
    })