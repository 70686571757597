import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid } from '@material-ui/core'

import { WbSyncInfo } from './WbSyncInfo'
import { WbScreenTabs } from './WbScreenTabs'
import { WbAccountForm } from './WbAccountForm'
import { WbAccountInfo } from './WbAccountInfo'

import { WbAccountProps } from '../types/accountProps'

export const WbSyncTabsScreen = () =>
  <WbScreenTabs canAddAccount canRemoveAccount ScreenComponent={WbSyncScreen}/>

export const WbSyncScreen =
  observer(
    ({account}: WbAccountProps) =>
      <Box padding={3}>
        <Grid container direction="column" spacing={3}>
          <Grid item lg={10} xl={8}>
            <WbSyncInfo account={account}/>
          </Grid>

          <Grid item lg={10} xl={8}>
            <WbAccountForm account={account}/>
          </Grid>

          {
            account != null &&
            <WbAccountInfo account={account}/>
          }
        </Grid>
      </Box>
  )