import React from 'react'
import { observer } from 'mobx-react-lite'

import { OzonProductMultiPicker } from './OzonProductMultiPicker'
import { OzonProductSinglePicker } from './OzonProductSinglePicker'
import { ProductAnyPicker, ProductPickerProps } from '../ProductAnyPicker'

import { useStore } from '../../hooks/storeHook'

export const OzonProductPicker =
  observer(
    (props: ProductPickerProps) => {
      const {user} = useStore()

      if (!user) {
        throw new Error('Not authorized')
      }

      return (
        <ProductAnyPicker
          allowMultiple={user.allowMultiOzonProducts}
          SinglePicker={OzonProductSinglePicker}
          MultiPicker={OzonProductMultiPicker}
          {...props}
        />
      )
    })