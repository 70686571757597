//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { UsersTablePageModel } from '../Models/UsersTablePageModel';
import { UsersTablePageOptions } from '../Models/UsersTablePageOptions';
import { AdminUserSyncModel } from '../Models/AdminUserSyncModel';
import { AdminFakeApiFlagModel } from '../Models/AdminFakeApiFlagModel';

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class AdminController
{
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  
  private readonly axios: AxiosInstance
  
  impersonate(userId: string, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`Admin/Impersonate/${encodeUrlParameter(userId)}`, _config)
  }
  

  deImpersonate(_config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.post<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`Admin/DeImpersonate`, null, _config)
  }
  

  users(options: UsersTablePageOptions, _config?: AxiosRequestConfig) : Promise<UsersTablePageModel>
  {
    return this.axios.post<UsersTablePageModel>(`Admin/Users`, options, _config).then(getAxiosData)
  }
  

  setUserSync(model: AdminUserSyncModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Admin/SetUserSync`, model, _config).then(getAxiosData)
  }
  

  setFakeApiFlag(model: AdminFakeApiFlagModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Admin/SetFakeApiFlag`, model, _config).then(getAxiosData)
  }
  

  tariffs(_config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`Admin/Tariffs`, _config)
  }
  

  resetFakeApiCache(_config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`Admin/ResetFakeApiCache`, _config)
  }
  

  /** Gets the full relative URL for the action */
  impersonateUrl(userId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/Admin/Impersonate/${encodeUrlParameter(userId)}`
  }
  

  /** Gets the full relative URL for the action */
  tariffsUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/Admin/Tariffs`
  }
  

  /** Gets the full relative URL for the action */
  resetFakeApiCacheUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/Admin/ResetFakeApiCache`
  }
  

}
