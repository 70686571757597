import React, { ReactNode, useRef } from 'react'

import { ExpandMore } from '@material-ui/icons'
import { List, Popover } from '@material-ui/core'

import { useBoolState } from '../hooks/commonHooks'

import { exact } from '../common/tsUtils'
import { fixMsAppScrollProps } from '../common/msAppUtils'

export type DropdownButtonProps = {
  text: ReactNode

  loadingText?: ReactNode

  disabled?: boolean

  popupWidth?: number

  onOpen?: () => void | Promise<void>

  children: (itemProps: DropdownItemProps) => ReactNode
}

export type DropdownItemProps = {
  setClosed: VoidFunction

  setLoading: VoidFunction

  setFinished: VoidFunction
}

export const DropdownButton =
  ({text, loadingText, disabled, popupWidth, onOpen, children}: DropdownButtonProps) => {
    const [isOpen, setOpen, setClosed] = useBoolState()

    const [isLoading, setLoading, setFinished] = useBoolState()

    const buttonRef = useRef<HTMLButtonElement>(null)

    const itemProps = exact<DropdownItemProps>({
      setClosed,
      setLoading,
      setFinished
    })

    const onClick = async () => {
      if (isOpen) {
        setClosed()
        return
      }

      if (onOpen) {
        const onOpenResult = onOpen()

        if (onOpenResult instanceof Promise) {
          setLoading()
          await onOpenResult.finally(setFinished)
        }
      }

      setOpen()
    }

    return <>
      <button
        type="button"
        className="button_dropdown"
        disabled={disabled || isLoading}
        onClick={onClick}
        ref={buttonRef}
      >
        {isLoading ? (loadingText ?? text) : text}
        <ExpandMore/>
      </button>

      <Popover
        open={isOpen}
        onClose={setClosed}
        anchorEl={buttonRef?.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        {...fixMsAppScrollProps}
      >
        <List
          dense
          disablePadding
          style={{width: popupWidth, backgroundImage: 'linear-gradient(to bottom, #ffffff, #e8eef3)'}}
        >
          {children(itemProps)}
        </List>
      </Popover>
    </>
  }