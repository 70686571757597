import React, { useState } from 'react'
import { observable, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box, Button, CircularProgress, FormHelperText, Grid, Typography } from '@material-ui/core'

import { AddAccountStoreForm, AddAccountStoreFormBaseProps } from './AddAccountStoreForm'
import { EditAccountStoreForm, EditAccountStoreFormBaseProps, WarehouseModel2 } from './EditAccountStoreForm'

import { useApi } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { LoadState, useLoadState } from '../hooks/loadStateHook'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { StoreModelBase } from '../server/mpsklad_core/Models/StoreModelBase'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'
import { CreateMsStoresModel } from '../server/mpsklad_core/Models/CreateMsStoresModel'

type AccountStoresFormPropsBase<TWarehouse extends WarehouseModel> =
  EditAccountStoreFormBaseProps<TWarehouse>
  & AddAccountStoreFormBaseProps<TWarehouse>

export type AccountStoresFormProps<TWarehouse extends WarehouseModel> =
  AccountStoresFormPropsBase<TWarehouse>
  & {
  storeType: StoreType

  integrationName: string

  hint?: string

  loadWarehouses: (accountId: number) => Promise<TWarehouse[]>

  loadStores: (accountId: number) => Promise<StoreModelBase[]>

  isStoreUsingWarehouse: (store: StoreModelBase, warehouse: TWarehouse) => boolean

  formatWarehouseNameHint?: (warehouse: TWarehouse) => string
}

export const AccountStoresForm =
  observer(
    <TWarehouse extends WarehouseModel>
    ({
       integrationName, storeType, accountId, hint,
       loadWarehouses, loadStores,
       addStore, whKeySelector, isStoreUsingWarehouse, formatWarehouseNameHint,
       ...passProps
     }: AccountStoresFormProps<TWarehouse>) => {
      const api = useApi()
      const {loadState, setLoading, setSuccess, setError} = useLoadState()

      // TODO: Mixed states: don't mix react state with mobx, replace all with mobx
      const [stores, setStores] = useState<StoreModelBase[]>([])
      const [warehouses, setWarehouses] = useState<WarehouseModel2<TWarehouse>[]>([])
      const [msStores, setMsStores] = useState<MoySkladStoreModel[]>([])

      const {showSuccess} = useMessages()

      const importWarehousesToMs =
        async (warehouses: CreateMsStoresModel) => {
          await api.userSync.importWarehousesToMs(warehouses)
          await syncMsStores()
        }

      const formatWarehouseName =
        (warehouse: WarehouseModel2<TWarehouse>): string =>
          `${warehouse.id ? `${warehouse.id} ` : ''}${warehouse.name}`

      const formatWarehouseDisplayName =
        (warehouse: WarehouseModel2<TWarehouse>) =>
          `${formatWarehouseName(warehouse)}${formatWarehouseNameHint ? ` ${formatWarehouseNameHint(warehouse)}` : ''}`

      const makeWarehousesWithMatchFlags =
        // TODO: Mixed states: the parameters are required because of mixed react/mobx states
        (stores: StoreModelBase[], warehouses: TWarehouse[]): WarehouseModel2<TWarehouse>[] =>
          warehouses.map(warehouse => ({
            ...warehouse,
            isMatched: stores.some(store => isStoreUsingWarehouse(store, warehouse))
          }))

      const syncMsStores = async () => {
        const msStores = await api.userSync.getMoySkladStoresForAccount(storeType, accountId)
        setMsStores(observable(msStores))
      }

      const onLoad = async () => {
        setLoading()

        try {
          const stores = observable(await loadStores(accountId))
          setStores(stores)

          const warehouses = await loadWarehouses(accountId)

          // TODO: Mixed states: careful with these arguments
          const warehousesWithMatchFlags = makeWarehousesWithMatchFlags(stores, warehouses)
          setWarehouses(observable(warehousesWithMatchFlags))

          await syncMsStores()
          setSuccess()
        } catch (e) {
          console.error('Failed to load', e)
          setError()
        }
      }

      const onReload = async () => {
        try {
          // TODO: Mixed states: that's a mess of mixed states
          setWarehouses(observable(makeWarehousesWithMatchFlags(stores, toJS(warehouses))))

          // TODO: Update matching flags for MS stores client-side, like warehouses?
          await syncMsStores()
        } catch (e) {
          console.error('Failed to reload', e)
          setError()
        }
      }

      const onImportAllClick = async () => {
        setLoading()

        try {
          const warehousesNames = warehouses.map(warehouse => formatWarehouseName(warehouse))
          await importWarehousesToMs({warehousesNames})
          showSuccess(`Склады созданы в МоёмСкладе!`)
          setSuccess()
        } catch (e) {
          console.error('Failed to duplicate warehouses to MoySklad', e)
          setError()
        }
      }

      const onAddStore =
        async (store: StoreModelBase) => {
          stores.push(store)
          await onReload()
        }

      const onEditStore =
        async (store: StoreModelBase) => {
          const storeIndex = stores.findIndex(_ => _.id === store.id)
          stores[storeIndex] = store
          await onReload()
        }

      const onRemoveStore =
        async (store: StoreModelBase) => {
          const storeIndex = stores.findIndex(_ => _.id === store.id)
          stores.splice(storeIndex, 1)
          await onReload()
        }

      return (
        <Box>
          <Box marginBottom={1}>
            <Typography variant="h6">
              Настройка складов
            </Typography>
          </Box>

          {
            (loadState === LoadState.Initial || loadState === LoadState.Error) &&
            <Button type="button" variant="contained" onClick={onLoad}>Настроить</Button>
          }

          {
            loadState === LoadState.Loading &&
            <>
              <CircularProgress size={25}/>

              {
                storeType === StoreType.Wildberries &&
                <FormHelperText>
                  Загрузка складов FBW может занять пару минут (ограничение API WB).
                </FormHelperText>
              }
            </>
          }

          {
            loadState === LoadState.Success &&
            <>
              <Grid container direction="column" spacing={3}>
                <Grid item container xs={12} spacing={3}>
                  <Grid item xs={4}>
                    Склад {integrationName}
                  </Grid>

                  <Grid item xs={3}>
                    Склад МоегоСклада
                  </Grid>

                  <Grid item xs={5}>
                    <Button size="small" variant="contained" onClick={onImportAllClick}>
                      Создать все склады {integrationName} в МоёмСкладе
                    </Button>
                  </Grid>
                </Grid>

                {
                  !!hint &&
                  <Grid item xs={4}>
                    <FormHelperText>
                      {hint}
                    </FormHelperText>
                  </Grid>
                }

                {
                  stores.map(store =>
                    <EditAccountStoreForm
                      key={store.id}
                      accountId={accountId}
                      store={store}
                      warehouses={warehouses}
                      msStores={msStores}
                      whKeySelector={whKeySelector}
                      onEdit={onEditStore}
                      onRemove={onRemoveStore}
                      formatWarehouseName={formatWarehouseName}
                      formatWarehouseDisplayName={formatWarehouseDisplayName}
                      importWarehousesToMs={importWarehousesToMs}
                      {...passProps}/>)
                }

                <AddAccountStoreForm
                  accountId={accountId}
                  warehouses={warehouses}
                  msStores={msStores}
                  addStore={addStore}
                  whKeySelector={whKeySelector}
                  formatWarehouseName={formatWarehouseName}
                  formatWarehouseDisplayName={formatWarehouseDisplayName}
                  importWarehousesToMs={importWarehousesToMs}
                  onAdd={onAddStore}
                />
              </Grid>
            </>
          }
        </Box>
      )
    })