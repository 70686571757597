import React from 'react'

import { TableCell } from '@material-ui/core'


import { MyRowProps } from '../../types/tableTypes'
import { myOrderStatusNames } from '../../common/orderStatuses'
import { MoySkladOrderModel } from '../../server/mpsklad_core/Models/Orders/MoySkladOrderModel'

export const MoySkladOrderStatusCell =
  ({row}: MyRowProps<MoySkladOrderModel>) =>
    <TableCell className="table-border">
      {row.status != null ? myOrderStatusNames[row.status] : `Неизвестно (${row.statusId})`}
    </TableCell>