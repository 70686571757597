export enum AmountInsertStyle {
  /** The amount string will be inserted at the beginning of the result string. */
  Beginning = 0,

  /** The amount string will be inserted at the end of the result string. */
  End = 1,

  /** The amount string will `not` be inserted in the result string. */
  None = 2
}

/**
 * Returns correct plural form for the specified `amount`.
 * @param amount
 * @param single - The single form.
 * @param plural1 - The first plural form, to go with 2, 3, 4.
 * @param plural2 - The second plural form, to go with 5, 6, 7.
 * @param amountInsertStyle - Determines whether and how to insert the string representation of the `amount`
 * into the result string.
 */
export const pluralize = (amount: number, single: string, plural1: string, plural2: string,
                          amountInsertStyle: AmountInsertStyle = AmountInsertStyle.Beginning) => {
  const pluralString = amount % 10 === 1 && amount % 100 !== 11
                       ? single
                       : amount % 10 >= 2 && amount % 10 <= 4 && (amount % 100 < 10 || amount % 100 >= 20)
                         ? plural1
                         : plural2

  switch (amountInsertStyle) {
    case AmountInsertStyle.Beginning:
      return `${amount} ${pluralString}`

    case AmountInsertStyle.End:
      return `${pluralString} ${amount}`

    case AmountInsertStyle.None:
      return pluralString

    default:
      throw new Error(`Unprocessed value of AmountInsertStyle: ${amountInsertStyle}`)
  }
}

/**
 * Shorter dash, about half the width of an {@link emDash}, still longer than a hyphen.
 * See {@link https://en.wikipedia.org/wiki/Dash#En_dash}
 */
export const enDash = '–'

/**
 * Long dash, longer than both hyphen and {@link enDash}.
 * See {@link https://en.wikipedia.org/wiki/Dash#Em_dash}.
 */
export const emDash = '—'