import React from 'react'
import { observer } from 'mobx-react-lite'

import { MenuItem, Select, TableCell, TableRow } from '@material-ui/core'

import { fixMsAppScrollProps } from '../common/msAppUtils'
import { myOrderStatusNames } from '../common/orderStatuses'

import { MoySkladStatusMapModel } from '../server/mpsklad_core/Models/MoySkladStatusMapModel'
import { MoySkladApiOrderStatus } from '../server/mpsklad_core/Models/ApiModels/MoySkladApiOrderStatus'

type MoySkladStatusMapRowProps = {
  map: MoySkladStatusMapModel

  statuses: MoySkladApiOrderStatus[]

  onChange: (map: MoySkladStatusMapModel, statusId: string) => void
}

export const MoySkladStatusMapRow =
  observer(
    ({map, statuses, onChange}: MoySkladStatusMapRowProps) =>
      <TableRow>
        <TableCell>
          {myOrderStatusNames[map.myOrderStatus]}
        </TableCell>

        <TableCell>
          <Select
            value={map.moySkladId ?? ''}
            onChange={e => onChange(map, e.target.value as string)}
            MenuProps={fixMsAppScrollProps}
          >
            <MenuItem value={''}>
              -
            </MenuItem>

            {
              statuses.map(
                status =>
                  <MenuItem key={status.id} value={status.id}>
                    {status.name}
                  </MenuItem>
              )
            }
          </Select>
        </TableCell>
      </TableRow>
  )