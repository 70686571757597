let isJivoInitialized = false

export const initJivo = () => {
  if (isJivoInitialized) {
    return
  }

  const scriptElement = document.createElement('script', {})
  scriptElement.src = '//code.jivo.ru/widget/gFOmwiJ5ba'
  scriptElement.async = true

  document.head?.appendChild(scriptElement)
  isJivoInitialized = true
}