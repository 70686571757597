import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountToggleRow } from './AccountToggleRow'

import { StoreAccountToggleRowProps } from '../types/accountProps'

export const StoreReserveMsOrdersRow =
  observer(
    ({account, onSubmit}: StoreAccountToggleRowProps) =>
      <AccountToggleRow
        title="Резерв товаров в заказах МоегоСклада"
        helperText="При создании заказа в МоёмСкладе, его позиции будут зарезервированы."
        account={account}
        onSubmit={onSubmit}
        getAccountToggle={() => account.reserveMsOrdersEnabled}
        setAccountToggle={isEnabled => account.reserveMsOrdersEnabled = isEnabled}
        getSuccessMessage={isEnabled => `Резерв ${isEnabled ? 'включён' : 'отключен'}`}
      />)