import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { ImportScreen } from './ImportScreen'
import { WbScreenTabs } from './WbScreenTabs'
import { WbProductImportTable } from './WbProductImportTable'

import { useApi, useStore } from '../hooks/storeHook'

import { WbProductsTableData } from '../store/productStore'

import { WbProductColumnId } from '../types/wbColumns'
import { WbAccountRequiredProps } from '../types/accountProps'
import { WbProductModel } from '../server/mpsklad_core/Models/WbProductModel'

export const WbImportTabsScreen = () =>
  <WbScreenTabs ScreenComponent={WbImportScreen}/>

export const WbImportScreen =
  observer(
    ({account}: WbAccountRequiredProps) => {
      const api = useApi()

      const {productStore: {createWbProductsTable}} = useStore()

      const products = useMemo(
        () => createWbProductsTable(account.id),
        [createWbProductsTable, account])

      return (
        <ImportScreen<WbProductsTableData, WbProductModel, WbProductColumnId>
          account={account}
          type="Wildberries"
          importProductsFunc={api.import.importProductsWb}
          importStocksFunc={api.import.importStocksWb}
          products={products}
          TableComponent={WbProductImportTable}
        />
      )
    })