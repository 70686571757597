import React from 'react'
import clsx from 'clsx'

import { TableCell, TableHead, TableRow } from '@material-ui/core'

import { useTableStyles } from '../hooks/tableStylesHook'

export const DiffsTableHead = () => {
  const classes = useTableStyles()

  return (
    <TableHead>
      <TableRow>
        {
          headCellNames.map(
            cell =>
              <TableCell key={cell} align="center" className={clsx(classes.cellRightLine, classes.cellTopLine)}>
                {cell}
              </TableCell>)
        }
      </TableRow>
    </TableHead>
  )
}

const headCellNames = [
  'Дата',
  'Время',
  'Название',
  'Артикул',
  'Штрихкод',
  'Начальный остаток',
  'Тип операции',
  'Источник',
  'Заказ МоегоСклада',
  // TODO: Split via account tabs?
  'Заказ маркетплейса',
  'Аккаунт',
  'Количество',
  'Конечный остаток'
]