import React from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'

import { appAuthRoutes } from '../common/appRoutes'

import { AppAuthRoute } from './AppAuthRoute'

import { LoginScreen } from './LoginScreen'
import { RegisterScreen } from './RegisterScreen'
import { PasswordResetScreen } from './PasswordResetScreen'
import { MoySkladAppAuthScreen } from './MoySkladAppAuthScreen'

export const AppAuthRouter = () =>
  <div className="App">
    <BrowserRouter>
      <Switch>
        <AppAuthRoute path={appAuthRoutes.Login} component={LoginScreen}/>

        <AppAuthRoute path={appAuthRoutes.Register} component={RegisterScreen}/>

        <AppAuthRoute path={appAuthRoutes.PasswordReset} component={PasswordResetScreen}/>

        <AppAuthRoute path={appAuthRoutes.MoySkladApp.template} component={MoySkladAppAuthScreen}/>

        <AppAuthRoute path={appAuthRoutes.MoySkladAppOld} component={MoySkladAppAuthScreen}/>

        {/* NOTE: Fallback route, must remain the last one */}
        <Redirect from="*" to={appAuthRoutes.Login}/>
      </Switch>
    </BrowserRouter>
  </div>