import React from 'react'

import { TableCell } from '@material-ui/core'

import { MyRowProps } from '../../types/tableTypes'
import { MoySkladOrderModel } from '../../server/mpsklad_core/Models/Orders/MoySkladOrderModel'

export const MoySkladOzonIdCell =
  ({row}: MyRowProps<MoySkladOrderModel>) =>
    <TableCell className="table-border">
      {row.ozonOrderExternalId ?? null}
      <br/>
      {row.ozonOrderId ? `(${row.ozonOrderId})` : null}
    </TableCell>