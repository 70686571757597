import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Paper } from '@material-ui/core'

import { MyServerTable } from './MyServerTable'

import { useStore } from '../hooks/storeHook'

import { userColumns } from '../types/userColumns'

export const AdminUsersScreen =
  observer(
    () => {
      const {user, adminStore: {users}} = useStore()

      if (!user?.isSuperAdmin) {
        return null
      }

      return (
        <div className="body_wrapper_zero">
          <div className="body_wrapper">
            <Box display="flex" alignItems="center" marginBottom={2}>
              <h1>
                Пользователи
              </h1>
            </Box>
          </div>

          <div className="body_wrapper">
            <Box display="flex" alignItems="center">
              <input
                className="main_input"
                type="text"
                placeholder="Поиск"
                onChange={e => users.setGlobalFilter(e.target.value)}
              />
            </Box>
          </div>

          <div className="body_wrapper__two">
            <Box display="flex">
              <Paper>
                <MyServerTable
                  table={users}
                  columns={userColumns}
                  labelNoRows="Пользователей нет"
                  labelNotFound="Пользователи не найдены"
                  labelRowsPerPage="Пользователей на странице:"
                />
              </Paper>
            </Box>
          </div>
        </div>
      )
    })