import { useContextRequired } from './commonHooks'

import { AppContext } from '../store/appContext'

export const useAppContext = () => useContextRequired(AppContext, 'AppContext')

export const useStore = () => useAppContext().store

export const useLogic = () => useAppContext().logic

export const useApi = () => useAppContext().logic.api