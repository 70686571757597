import { useMemo } from 'react'
import { useSnackbar } from 'notistack'

export const useMessages =
  () => {
    const snackbar = useSnackbar()

    return useMemo(
      () => ({
        ...snackbar,

        showInfo:
          (message: string) =>
            snackbar.enqueueSnackbar(message, {variant: 'info', autoHideDuration: 5000}),

        showSuccess:
          (message: string) =>
            snackbar.enqueueSnackbar(message, {variant: 'success', autoHideDuration: 5000}),

        showWarning:
          (message: string) =>
            snackbar.enqueueSnackbar(message, {variant: 'warning', autoHideDuration: 5000}),

        showError:
          (message: string) =>
            snackbar.enqueueSnackbar(message, {variant: 'error', autoHideDuration: 10000})
      }),
      [snackbar])
  }