import React, { FormEvent } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'

import { ImportProductsProps } from './ImportScreen'

import { useImportEvents } from '../hooks/importHooks'
import { usePaperStyles } from '../hooks/paperStylesHook'

import { IServerTableData } from '../types/tableTypes'
import { StoreAccountBaseProps } from '../types/accountProps'
import { StoreProductModel } from '../server/mpsklad_core/Models/StoreProductModel'
import { ImportStocksOptionsModel } from '../server/mpsklad_core/Models/ImportModels/ImportStocksModels/ImportStocksOptionsModel'

export type ImportStocksFormProps<
  TProducts extends IServerTableData<TRow, TColumnId>,
  TRow extends StoreProductModel,
  TColumnId> =
  StoreAccountBaseProps
  & ImportProductsProps<TProducts, TRow, TColumnId>
  & {
  importStocksFunc: (accountId: number, productOptionsModel: ImportStocksOptionsModel) => Promise<void>
}

export const ImportStocksForm =
  observer(
    <TProducts extends IServerTableData<TRow, TColumnId>, TRow extends StoreProductModel, TColumnId>
    ({account, importStocksFunc, products}: ImportStocksFormProps<TProducts, TRow, TColumnId>) => {
      const classes = useStyles()
      const paperClasses = usePaperStyles()

      const {onStockImport, isImportDisabled} = useImportEvents(account, products)

      const stockImportOptions =
        useLocalObservable<ImportStocksOptionsModel>(
          () => ({
            sumStocks: false,
            accountForReserved: false,
            somethingAboutMatchedWarehouses: false,
            productIds: []
          }) satisfies ImportStocksOptionsModel)

      const onImportStocks = onStockImport(importStocksFunc, stockImportOptions)

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()
          await onImportStocks()
        }

      return (
        <Paper className={paperClasses.paper}>
          <Box padding={2}>
            <form onSubmit={onSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="h6">
                    Импорт остатков в МойСклад
                  </Typography>
                </Grid>

                <Grid item container direction="row">
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Что делать с остатками?
                      </FormLabel>

                      <RadioGroup
                        name="sumStocks"
                        value={JSON.stringify(stockImportOptions.sumStocks)}
                        onChange={e =>
                          stockImportOptions.sumStocks = (e.target.value as string) === JSON.stringify(true)}
                        className={classes.radioGroup}>
                        <FormControlLabel
                          value={JSON.stringify(false)}
                          control={<Radio/>}
                          label="Перезаписать остатки МоегоСклада на остатки маркетплейса"
                        />

                        <FormControlLabel
                          value={JSON.stringify(true)}
                          control={<Radio/>}
                          label="Прибавить остатки маректплейса к остаткам МоегоСклада"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Button variant="contained" type="submit" disabled={isImportDisabled}>
                    {isImportDisabled ? 'Импортируем...' : 'Импортировать остатки'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      )
    })

const useStyles = makeStyles(
  theme => ({
    radioGroup: {
      marginTop: theme.spacing(1)
    }
  }))