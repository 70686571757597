import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { Box, Button, FormHelperText } from '@material-ui/core'

import { BoxLoader } from './BoxLoader'
import { WbLabelsPrintForm } from './WbLabelsPrintForm'

import { useApi } from '../hooks/storeHook'
import { useLazyEffect } from '../hooks/commonHooks'

import { appRoutes } from '../common/appRoutes'

import { WbAccountIdProps } from '../types/accountProps'
import { WbSupplyModel } from '../server/mpsklad_core/Models/WbSupplyModel'

export const WbLabelsTab =
  observer(
    ({wbAccountId}: WbAccountIdProps) => {
      const api = useApi()

      const [supplies, setSupplies] = useState(null as WbSupplyModel[] | null)

      const loadSupplies =
        async () => setSupplies(await api.order.getActiveWbSupplies(wbAccountId))

      const loadDeliveredSupplies =
        async () => {
          setSupplies(null)
          setSupplies(await api.order.getClosedWbSupplies(wbAccountId))
        }

      const reloadSupplies = async () => {
        setSupplies(null)
        await loadSupplies()
      }

      useLazyEffect(loadSupplies, [wbAccountId])

      if (supplies == null) {
        return <BoxLoader/>
      }

      return <>
        <Box padding={2}>
          <Button variant="contained" onClick={loadDeliveredSupplies}>
            Загрузить закрытые поставки
          </Button>

          <FormHelperText>
            100 последних по дате создания
          </FormHelperText>
        </Box>

        {
          supplies.length > 0
          ? <WbLabelsPrintForm wbAccountId={wbAccountId} wbSupplies={supplies} onPrinted={reloadSupplies}/>
          :
          <Box padding={3}>
            Нет поставок.
            <br/>
            Для печати этикеток необходимо
            добавить <Link to={appRoutes.Orders.wildberries}>заказы</Link> в поставку, либо загрузить закрытые поставки.
          </Box>
        }
      </>
    })