import { createColumns } from './columnGroupCreator'
import { PrintFilesColumnId } from './wbFilesColumns'

import { YandexMarketFileCell } from '../components/YandexMarketFileCell'
import { TableDateCell } from '../components/tableCells/TableDateCell'

import { formatShipmentDatesRange } from '../common/dateTimeUtility'

import { PrintFileModel } from '../server/mpsklad_core/Models/PrintFileModel'

export const yandexMarketFilesColumns =
  createColumns<PrintFilesColumnId, PrintFileModel>({
    id: 'createdAt',
    title: 'Дата создания',
    sortable: true,
    CellComponent: TableDateCell
  }, {
    id: 'date',
    title: 'Дата заказа',
    sortable: true,
    accessor: formatShipmentDatesRange
  }, {
    id: 'options',
    title: 'Настройки',
    accessor: _ => [
      `Статусы: ${_.statuses}`,
      `Этикеток: ${_.labelCount}`,
      `Заказов: ${_.orderCount}`,
      `Товаров: ${_.productCount}`
    ]
  }, {
    id: 'link',
    title: 'Ссылка на файл',
    CellComponent: YandexMarketFileCell
  })