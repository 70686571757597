import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core'

import { SyncStartTableRow } from './SyncStartTableRow'
import { StoreAutoShipmentRow } from './StoreAutoShipmentRow'
import { YandexMarketSyncStates } from './YandexMarketSyncStates'
import { YandexMarketSyncSwitch } from './YandexMarketSyncSwitch'
import { StoreReserveMsOrdersRow } from './StoreReserveMsOrdersRow'
import { StoreAutoConfirmationRow } from './StoreAutoConfirmationRow'
import { YandexMarketLoadOrdersForm } from './YandexMarketLoadOrdersForm'

import { useApi, useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'
import { emDash } from '../common/stringUtility'

import { YandexMarketAccountProps } from '../types/accountProps'

export const YandexMarketSyncInfo =
  observer(
    ({account}: YandexMarketAccountProps) => {
      const {user} = useStore()

      const {
        userSync: {
          setYandexMarketSyncStartDate,
          setYandexMarketAutoConfirmation,
          setYandexMarketAutoShipment,
          setYandexMarketReserveMsOrders
        }
      } = useApi()

      return (
        <Paper>
          {
            // TODO: Split?
            account !== null
            ?
            <TableContainer component={'div'}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Синхронизировать
                    </TableCell>

                    <TableCell>
                      <YandexMarketSyncSwitch account={account}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      Последняя синхронизация
                    </TableCell>

                    <TableCell>
                      <YandexMarketSyncStates account={account}/>
                    </TableCell>
                  </TableRow>

                  <SyncStartTableRow account={account} onSubmit={setYandexMarketSyncStartDate}/>

                  <StoreAutoConfirmationRow account={account} onSubmit={setYandexMarketAutoConfirmation}/>

                  <StoreAutoShipmentRow account={account} onSubmit={setYandexMarketAutoShipment}/>

                  <StoreReserveMsOrdersRow account={account} onSubmit={setYandexMarketReserveMsOrders}/>

                  {
                    account.syncEnabled && !account.isFBS &&
                    <TableRow>
                      <TableCell>
                        Загрузка заказов
                      </TableCell>

                      <TableCell>
                        <YandexMarketLoadOrdersForm yandexMarketAccountId={account.id}/>
                      </TableCell>
                    </TableRow>
                  }

                  {
                    (user?.isSuperAdmin || user?.isImpersonating) &&
                    <TableRow>
                      <TableCell>
                        API токен
                      </TableCell>

                      <TableCell>
                        <TextField disabled fullWidth id="ymToken" value={account.token ?? emDash}/>
                      </TableCell>
                    </TableRow>
                  }

                  <TableRow>
                    <TableCell>
                      <Link to={appRoutes.SyncLogs.route({integration: 'YandexMarket', accountId: account.id})}>
                        Логи синхронизации
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            :
            <Box padding={3}>
              Подключите аккаунт Yandex.Market для синхронизации товаров
            </Box>
          }
        </Paper>
      )
    })