import React from 'react'

import { Badge } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

export type ProductsTabTitleProps = {
  title: string

  count: number | undefined
}

export const ProductsTabTitle =
  ({title, count}: ProductsTabTitleProps) => {
    const classes = useStyles()

    return (
      <Badge showZero max={1000000} overlap="rectangular" classes={{badge: classes.badge}} badgeContent={count}>
        {title}
      </Badge>
    )
  }

const useStyles =
  makeStyles((/*theme*/) =>
    createStyles({
      badge: {
        // Fix overflow for large numbers
        transform: 'scale(1) translate(12px, -50%)'
      }
    }))