import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { ImportScreen } from './ImportScreen'
import { YandexMarketScreenTabs } from './YandexMarketScreenTabs'
import { YandexMarketProductImportTable } from './YandexMarketProductImportTable'

import { useApi, useStore } from '../hooks/storeHook'

import { YandexMarketAccountRequiredProps } from '../types/accountProps'
import { YandexMarketProductColumnId } from '../types/yandexMarketColumns'

import { YandexMarketProductsTableData } from '../store/productStore'
import { YandexMarketProductModel } from '../server/mpsklad_core/Models/YandexMarketProductModel'

export const YandexMarketImportTabsScreen = () =>
  <YandexMarketScreenTabs ScreenComponent={YandexMarketImportScreen}/>

export const YandexMarketImportScreen =
  observer(
    ({account}: YandexMarketAccountRequiredProps) => {
      const api = useApi()

      const {productStore: {createYandexMarketProductsTable}} = useStore()

      const products = useMemo(
        () => createYandexMarketProductsTable(account.id),
        [createYandexMarketProductsTable, account])

      return (
        <ImportScreen<YandexMarketProductsTableData, YandexMarketProductModel, YandexMarketProductColumnId>
          account={account}
          type="YandexMarket"
          importProductsFunc={api.import.importProductsYandexMarket}
          importStocksFunc={api.import.importStocksYandexMarket}
          products={products}
          TableComponent={YandexMarketProductImportTable}
        />
      )
    })