import { ErrorInfo } from 'react'

import { ApiLogic } from './apiLogic'

export class ErrorApiLogic {
  private readonly apiLogic: ApiLogic

  constructor() {
    this.apiLogic = new ApiLogic(() => {})
  }

  logRenderError =
    async (errorId: string, error: Error, errorInfo: ErrorInfo) => {
      try {
        // TODO: MS app authentication is done inside logic, which is nested deeper in the component tree
        await this.apiLogic.log.logRenderError({
          errorId,
          name: error.name,
          message: error.message,
          stack: error.stack,
          componentStack: errorInfo.componentStack,
          url: window.location.href
        })

        console.log(`Render error sent to backend #{${errorId}}`)
      } catch (e) {
        console.log(`Failed to send render error to backend #{${errorId}}`, e)
      }
    }
}