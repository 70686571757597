import React, { FormEvent, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { LockOutlined } from '@material-ui/icons'
import { Avatar, Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core'

import { PhoneNumberInput } from './PhoneNumberInput'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'
import { useAuthFormStyles } from '../hooks/authFormStylesHook'

import { __PRODUCTION__ } from '../common/devUtils'
import { appAuthRoutes } from '../common/appRoutes'

export const RegisterScreen = observer(
  () => {
    const formClasses = useAuthFormStyles()

    const logic = useLogic()
    const {hasAuth} = useStore()

    const {showSuccess, showError} = useMessages()

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmed, setPasswordConfirmed] = useState(true)
    const [acceptTermsOfService, setAcceptTermsOfService] = useState(false)

    const [isLoading, setLoading, setFinished] = useBoolState()

    if (hasAuth) {
      return null
    }

    const onSubmit = async (e: FormEvent) => {
      e.preventDefault()

      if (!acceptTermsOfService) {
        showError('Необходимо принять оферту.')
        return
      }

      setLoading()

      try {
        await logic.register({email, password, name, phone})

        // NOTE: Don't update state on success - this component will be unmounted
        showSuccess('Вы зарегистрированы!')
      } catch (e) {
        setFinished()
        showError('Не удалось зарегистрироваться')
        return
      }

      if (__PRODUCTION__) {
        ym(69917182, 'reachGoal', 'successRegistration')
      }
    }

    return (
      <Container component="main" maxWidth="xs">
        <div className={formClasses.innerContainer}>
          <Avatar className={formClasses.avatar}>
            <LockOutlined/>
          </Avatar>

          <Typography component="h1" variant="h5">
            Регистрация
          </Typography>

          <form className={formClasses.form} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  variant="outlined"
                  id="firstName"
                  name="firstName"
                  label="Имя"
                  autoComplete="fname"
                  onChange={e => setName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="phone"
                  name="phone"
                  label="Телефон"
                  autoComplete="tel"
                  InputLabelProps={{shrink: true}}
                  InputProps={{
                    inputComponent: PhoneNumberInput,
                    inputProps: {onAccept: setPhone}
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={e => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="password"
                  name="password"
                  type="password"
                  label="Пароль"
                  autoComplete="current-password"
                  onChange={e => setPassword(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  name="password-confirm"
                  type="password"
                  label="Подтверждение пароля"
                  error={!passwordConfirmed}
                  helperText={!passwordConfirmed && 'Пароли должны совпадать'}
                  onChange={e => setPasswordConfirmed(e.target.value === password)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  label={
                    <span>
                      Регистрируясь, я соглашаюсь с <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://mp360.ru/img/rules.pdf"
                      className={formClasses.formLink}>офертой.</a>
                    </span>}
                  control={
                    <Checkbox
                      size="small"
                      checked={acceptTermsOfService}
                      onChange={({target: {checked}}) => setAcceptTermsOfService(checked)}
                    />}
                />
              </Grid>
            </Grid>

            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              className={formClasses.submit}
              disabled={isLoading}
            >
              {isLoading ? '...' : 'Зарегистрироваться'}
            </Button>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <NavLink exact to={appAuthRoutes.Login} className={formClasses.formLink}>
                  Вход
                </NavLink>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    )
  })