import React from 'react'

import { TableCell } from '@material-ui/core'

import { MyRowProps } from '../../types/tableTypes'
import { MoySkladOrderModel } from '../../server/mpsklad_core/Models/Orders/MoySkladOrderModel'

export const MoySkladYandexMarketIdCell =
  ({row}: MyRowProps<MoySkladOrderModel>) =>
    <TableCell className="table-border">
      {row.yandexMarketOrderExternalId ?? null}
      <br/>
      {row.yandexMarketOrderId ? `(${row.yandexMarketOrderId})` : null}
    </TableCell>