import React from 'react'

import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

export const AppNotHealthy =
  () =>
    <Box height={'100vh'} display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'}>
      <Alert severity="error">
        Сервер недоступен.
        <br/>
        Обновите страницу через пару минут.
      </Alert>
    </Box>