import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { Box, FormHelperText, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'

import { WbSyncStates } from './WbSyncStates'
import { WbSyncSwitch } from './WbSyncSwitch'
import { WbLoadOrdersForm } from './WbLoadOrdersForm'
import { SyncStartTableRow } from './SyncStartTableRow'
import { WbToggleApiProducts } from './WbToggleApiProducts'
import { WbUploadProductsForm } from './WbUploadProductsForm'
import { StoreAutoShipmentRow } from './StoreAutoShipmentRow'
import { StoreReserveMsOrdersRow } from './StoreReserveMsOrdersRow'
import { StoreAutoConfirmationRow } from './StoreAutoConfirmationRow'

import { useApi } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'
import { WbAccountProps } from '../types/accountProps'
import { StoreRealizationReportsSyncRow } from './StoreRealizationReportsSyncRow'

export const WbSyncInfo =
  observer(
    ({account}: WbAccountProps) => {
      const {
        userSync: {
          setWbSyncStartDate,
          setWbAutoConfirmation,
          setWbAutoShipment,
          setWbReserveMsOrders,
          setWbRealizationReportsSync
        }
      } = useApi()

      return (
        <Paper>
          {
            // TODO: Split?
            account !== null
            ?
            <TableContainer component={'div'}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Синхронизировать
                    </TableCell>

                    <TableCell>
                      <WbSyncSwitch account={account}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      Последняя синхронизация
                    </TableCell>

                    <TableCell>
                      <WbSyncStates account={account}/>
                    </TableCell>
                  </TableRow>

                  <SyncStartTableRow account={account} onSubmit={setWbSyncStartDate}/>

                  <StoreAutoConfirmationRow account={account} onSubmit={setWbAutoConfirmation}/>

                  <StoreAutoShipmentRow account={account} onSubmit={setWbAutoShipment}/>

                  <StoreReserveMsOrdersRow account={account} onSubmit={setWbReserveMsOrders}/>

                  <StoreRealizationReportsSyncRow account={account} onSubmit={setWbRealizationReportsSync}/>

                  {
                    account.syncEnabled && !account.isFBS &&
                    <TableRow>
                      <TableCell>
                        Загрузка заказов
                      </TableCell>

                      <TableCell>
                        <WbLoadOrdersForm wbAccountId={account.id}/>
                      </TableCell>
                    </TableRow>
                  }

                  {
                    <TableRow>
                      <TableCell>
                        Загрузка товаров

                        <FormHelperText>
                          Ручная загрузка товаров из файла отчёта №8.
                        </FormHelperText>
                      </TableCell>

                      <TableCell>
                        <WbUploadProductsForm wbAccountId={account.id}/>
                      </TableCell>
                    </TableRow>
                  }

                  <TableRow>
                    <TableCell>
                      Получение товаров из API

                      <FormHelperText>
                        Отключите, чтобы загружать товары только вручную.
                      </FormHelperText>
                    </TableCell>

                    <TableCell>
                      <WbToggleApiProducts account={account}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Link to={appRoutes.SyncLogs.route({integration: 'Wildberries', accountId: account.id})}>
                        Логи синхронизации
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            :
            <Box padding={3}>
              Подключите аккаунт Wildberries для синхронизации товаров
            </Box>
          }
        </Paper>
      )
    })