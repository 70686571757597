import React, { useEffect } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import {
  MoySkladSearchableEntityInput,
  MsEntityDataOptional,
  MsEntityDataRequired
} from './MoySkladSearchableEntityInput'

import { useApi } from '../hooks/storeHook'

import { EditStoreAccountMsDataModel } from '../server/mpsklad_core/Models/EditStoreAccountMsDataModel'

export type MsFormData = EditStoreAccountMsDataModel

export type StoreAccountMsData = {
  organization: MsEntityDataRequired
  counterparty: MsEntityDataRequired

  contract: MsEntityDataOptional
  salesChannel: MsEntityDataOptional
  project: MsEntityDataOptional

  readonly formData: MsFormData
}

export type MoySkladDataFormProps = {
  msData: StoreAccountMsData

  onChange: (msData: MsFormData) => void
}

export const MoySkladDataForm =
  observer(
    ({msData, onChange}: MoySkladDataFormProps) => {
      const api = useApi()

      useEffect(() =>
          reaction(
            () => msData.formData,
            () => onChange(msData.formData)),
        [msData, onChange])

      return <>
        <Box marginBottom={3}>
          <MoySkladSearchableEntityInput
            isRequired
            id="msOrganization"
            data={msData.organization}
            searchEntities={api.userSync.getMoySkladOrganizations}
            label="Организация продавца"
            hint="Введите наименование или код для поиска."
          />
        </Box>

        <Box marginBottom={3}>
          <MoySkladSearchableEntityInput
            isRequired
            id="msCounterparty"
            data={msData.counterparty}
            searchEntities={api.userSync.getMoySkladCounterparties}
            label="Контрагент покупателя"
            hint={
              <>
                Введите наименование или код для поиска.
                <br/>
                Возможен выбор контрагента только с типом "Юридическое лицо".
              </>
            }
          />
        </Box>

        <Box marginBottom={3}>
          <MoySkladSearchableEntityInput
            id="msContract"
            data={msData.contract}
            searchEntities={api.userSync.getMoySkladContracts}
            label="Договор"
            hint={
              <>
                Введите номер договора или комментарий для поиска.
                <br/>
                Возможен выбор только договора комиссии.
              </>
            }
          />
        </Box>

        <Box marginBottom={3}>
          <MoySkladSearchableEntityInput
            id="msSalesChannel"
            data={msData.salesChannel}
            searchEntities={api.userSync.getMoySkladSalesChannels}
            label="Канал продаж"
            hint="Введите наименование или описание для поиска."
          />
        </Box>

        <Box marginBottom={3}>
          <MoySkladSearchableEntityInput
            id="msProject"
            data={msData.project}
            searchEntities={api.userSync.getMoySkladProjects}
            label="Проект"
            hint="Введите название проекта."
          />
        </Box>
      </>
    })