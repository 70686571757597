import React from 'react'
import { observer } from 'mobx-react-lite'

import { ListItem, ListItemText } from '@material-ui/core'

import { DropdownItemProps } from './DropdownButton'

import { useLogic } from '../hooks/storeHook'

import { StoreAccountRequiredProps } from '../types/accountProps'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'
import { useMessages } from '../hooks/snackbarHooks'

export type UnmatchProductsButtonProps<TAccount extends StoreAccountModelBase> =
  StoreAccountRequiredProps<TAccount> &
  DropdownItemProps & {
  makeAccountName: (account: TAccount) => string

  onSubmit: (accountId: number) => Promise<void>
}

export const UnmatchProductsButton =
  observer(
    <TAccount extends StoreAccountModelBase>
    ({
       account, makeAccountName, onSubmit,
       setClosed, setLoading, setFinished
     }: UnmatchProductsButtonProps<TAccount>) => {
      const logic = useLogic()

      const {showSuccess} = useMessages()

      const accountName = makeAccountName(account)

      const onClick = async () => {
        setClosed()

        if (!await logic.showDialog(`Будет сброшен матчинг для всех товаров на аккаунте ${accountName}!`)) {
          return
        }

        setLoading()

        try {
          await onSubmit(account.id)
          showSuccess('Матчинг товаров сброшен')
        } finally {
          setFinished()
        }
      }

      return (
        <ListItem button onClick={onClick}>
          <ListItemText primary={`Сбросить ${accountName}`}/>
        </ListItem>
      )
    })