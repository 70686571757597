import orderBy from 'lodash/orderBy'
import { action, comparer, computed, makeObservable, observable, reaction } from 'mobx'

import { TableDataBase } from './tableDataBase'

import { valuesOf } from '../common/tsUtils'
import { required } from '../common/objectUtils'

import { TablePropFilter } from '../types/tableFilter'
import { ITableData } from '../types/tableTypes'
import { CellAccessorFunc, toFuncAccessor } from '../types/cellAccessor'

export class MemoryTableData<TRow extends object, TColumnId>
  extends TableDataBase<TRow, TColumnId>
  implements ITableData<TRow, TColumnId> {
  readonly isLoading = false

  readonly wasLoaded = true

  @observable
  private _data: TRow[]

  @observable
  private propFilters: TablePropFilter<TRow, TColumnId>[] = []

  @observable.ref
  private orderAccessor: CellAccessorFunc<TRow> | null

  constructor(name: string, data: TRow[] = []) {
    super(name)

    makeObservable(this)

    this._data = data
    this.orderAccessor = null

    reaction(
      () => [this.data.length, this.globalFilter, this.pageSize],
      () => this.pageIndex = 0,
      {equals: comparer.shallow})

    reaction(
      () => this.propFilters,
      () => this.pageIndex = 0,
      {equals: comparer.structural})
  }

  @computed
  get data() {
    return this._data
  }

  set data(value: TRow[]) {
    this._data = value
    this.setAllRowsSelection(false)
  }

  @computed
  get totalRowCount(): number {
    return this.data.length
  }

  @computed
  private get filteredRows(): TRow[] {
    let rows = this.data

    for (const {accessor, filter} of this.propFilters) {
      const filterLower = filter.toLowerCase()

      rows = rows.filter(
        row => {
          const prop = accessor(row)

          return prop !== null && prop !== undefined
                 && JSON.stringify(prop).toLowerCase().includes(filterLower)
        })
    }

    if (this.globalFilter) {
      const globalFilterLower = this.globalFilter.toLowerCase()

      rows = rows.filter(
        row => valuesOf(row).some(
          prop => prop !== null && prop !== undefined
                  && JSON.stringify(prop).toLowerCase().includes(globalFilterLower)
        ))
    }

    return rows
  }

  @computed
  get filteredRowCount(): number {
    return this.filteredRows.length
  }

  @computed
  private get orderedFilteredRows(): TRow[] {
    if (this.order === null) {
      return this.filteredRows
    }

    const orderAccessor = required(this.orderAccessor)

    // Order only by non-null/undefined values
    const sortableRows = this.filteredRows.filter(_ => orderAccessor(_) != null)
    const nonSortableRows = this.filteredRows.filter(_ => orderAccessor(_) == null)

    const sortedRows = orderBy(sortableRows, orderAccessor, this.order.isAscending ? 'asc' : 'desc')

    // Consider null values to be less than all other values
    return this.order.isAscending ? [...nonSortableRows, ...sortedRows]
                                  : [...sortedRows, ...nonSortableRows]
  }

  @computed
  get pageRows(): TRow[] {
    const startIndex = this.pageIndex * this.pageSize

    return this.orderedFilteredRows.slice(startIndex, startIndex + this.pageSize)
  }

  setPropFilter =
    (key: string & TColumnId & keyof TRow, filter: string | undefined) =>
      this.setFuncPropFilter(key, toFuncAccessor(key), filter)

  @action
  private setFuncPropFilter =
    (id: TColumnId, accessor: CellAccessorFunc<TRow>, filter: string | undefined) => {
      this.propFilters = this.propFilters.filter(_ => _.id !== id)

      if (filter) {
        this.propFilters.push({id, filter, accessor})
      }
    }

  resetPropFilters = () =>
    this.propFilters.length = 0

  protected onOrderChange = () => {
    if (this.order === null) {
      this.orderAccessor = null
      return
    }

    const {column: {accessor}} = this.order

    this.orderAccessor = (row: TRow) => {
      const data = accessor(row)
      return data instanceof Array ? data[0] : data
    }
  }
}