import React from 'react'

import question from '../assets/question.svg'

import { Link } from '@material-ui/core'

export const ManualLink = () =>
  <Link
    className="nav"
    href="https://help.mp360.ru/"
    target="_blank"
    rel="noreferrer"
    style={{display: 'flex', alignItems: 'center'}}
  >
    <img src={question} alt=""/>
    <p style={{color: '#fff'}}>Инструкция</p>
  </Link>