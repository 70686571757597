import React from 'react'
import { observer } from 'mobx-react-lite'

import { OpenInNewOutlined } from '@material-ui/icons'
import { Box, FormHelperText, Grid, Link, TextField } from '@material-ui/core'

import { AutoTabs } from './AutoTabs'
import { WbTokenExpirationInfo } from './WbTokenExpirationInfo'

import { SetStateFunc } from '../common/tsUtils'

export type WbAccountTokensFormProps = {
  newToken: string
  statTokenNew: string
  tokenCombined: string
  tokenCombinedExpiresAtUnix: number | undefined
  setNewToken: SetStateFunc<string>
  setStatTokenNew: SetStateFunc<string>
  setTokenCombined: SetStateFunc<string>
}

export const WbAccountTokensForm =
  observer(
    ({
       newToken, statTokenNew, tokenCombined, tokenCombinedExpiresAtUnix,
       setNewToken, setStatTokenNew, setTokenCombined
     }: WbAccountTokensFormProps) =>
      <AutoTabs titles={['Новая система', 'Старая система']}>
        <Box marginTop={2}>
          <TextField
            required
            fullWidth
            id="tokenCombined"
            label="API токен"
            placeholder="eyEgbGciWijsUzIeqiiSMNR4cSI7IopZVHJ5.enR..."
            InputLabelProps={{shrink: true}}
            value={tokenCombined}
            onChange={e => setTokenCombined(e.target.value)}
          />

          <WbTokenExpirationInfo tokenExpiresAtUnix={tokenCombinedExpiresAtUnix}/>

          <FormHelperText>
            Создать токен можно в личном кабинете
            Wildberries: <Link href="https://seller.wildberries.ru/supplier-settings/access-to-api" target="_blank">
            Профиль - Настройки - Доступ к API <OpenInNewOutlined fontSize="inherit"/></Link>.
            <br/>
            Необходимо выбрать категории: Контент, Маркетплейс, Статистика, Цены и скидки.
          </FormHelperText>
        </Box>

        <Box>
          <Box marginTop={2}>
            <TextField
              required
              fullWidth
              id="newToken"
              label="API ключ"
              placeholder="eyEgbGciWijsUzIeqiiSMNR4cSI7IopZVHJ5.enR..."
              InputLabelProps={{shrink: true}}
              value={newToken}
              onChange={e => setNewToken(e.target.value)}
            />
          </Box>

          <Box marginTop={2}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="statTokenNew"
                  label="API ключ статистики"
                  placeholder="e3cSI8IoqJhYiakjQWZX<QiIs2NleyEgbGcmpZVHJ3.e123..."
                  InputLabelProps={{shrink: true}}
                  value={statTokenNew}
                  onChange={e => setStatTokenNew(e.target.value)}
                />

                <FormHelperText>
                  Сгенерировать ключ можно в разделе Настройки - Доступ к новому API.
                  Выберите тип ключа Статистика.
                </FormHelperText>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </AutoTabs>)