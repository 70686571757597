import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, TextField } from '@material-ui/core'

import { ReactiveTabs } from './ReactiveTabs'
import { MyServerTable } from './MyServerTable'
import { YandexMarketScreenTabs } from './YandexMarketScreenTabs'
import { PullOrdersWithErrorsButton } from './PullOrdersWithErrorsButton'

import { useLogic, useStore } from '../hooks/storeHook'

import { yandexMarketOrderColumns } from '../types/yandexMarketOrderColumns'
import { YandexMarketAccountRequiredProps } from '../types/accountProps'

export const YandexMarketOrdersTabsScreen = () =>
  <YandexMarketScreenTabs ScreenComponent={YandexMarketOrdersScreen}/>

export const YandexMarketOrdersScreen =
  observer(
    ({account}: YandexMarketAccountRequiredProps) => {
      const {api, yandexMarketOrderLoaders} = useLogic()
      const {orderStore: {createYandexMarketOrdersTable}} = useStore()

      const yandexMarketOrders = useMemo(
        () => createYandexMarketOrdersTable(account.id),
        [createYandexMarketOrdersTable, account])

      useEffect(() => yandexMarketOrderLoaders.addHandler(yandexMarketOrders.load),
        [yandexMarketOrderLoaders, yandexMarketOrders])

      const isErrorsTab = yandexMarketOrders.findPropFilterValue('hasError') === JSON.stringify(true)

      const onTabChange =
        (newTabIndex: number) =>
          yandexMarketOrders.reload(() =>
            yandexMarketOrders.setPropFilter('hasError', JSON.stringify(newTabIndex === 1)))

      return (
        <ReactiveTabs titles={['Активные', 'С ошибками']} tab={isErrorsTab ? 1 : 0} onTabChange={onTabChange}>
          <Box margin={2} display="flex" justifyContent="space-between">
            <Box>
              <TextField
                id="search-yandexMarket-orders"
                size="small"
                variant="outlined"
                label="Поиск"
                helperText="Id / Id в Yandex.Market / Ваш SKU товара"
                onChange={e => yandexMarketOrders.setGlobalFilter(e.target.value)}
                style={{minWidth: 400, maxWidth: 400}}
              />
            </Box>

            {
              isErrorsTab &&
              <PullOrdersWithErrorsButton
                ordersTable={yandexMarketOrders}
                pullOrders={() => api.order.pullYmOrdersWithErrors(account.id)}
              />
            }
          </Box>

          <MyServerTable
            table={yandexMarketOrders}
            columns={yandexMarketOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />
        </ReactiveTabs>
      )
    })