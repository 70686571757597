import React from 'react'
import { observer } from 'mobx-react-lite'

import { AppRouter } from './AppRouter'
import { MoySkladAppSettingsScreen } from './MoySkladAppSettingsScreen'
import { MoySkladAppSuspendedScreen } from './MoySkladAppSuspendedScreen'

import { useStore } from '../hooks/storeHook'
import { useLazyEffect } from '../hooks/commonHooks'

import { startMoySkladAppHeightInterval } from '../common/msAppUtils'

import { MoySkladAppState } from '../server/mpsklad_core/Models/MoySkladAppState'

export const MoySkladAppRoute =
  observer(() => {
    const {user} = useStore()

    useLazyEffect(startMoySkladAppHeightInterval)

    if (user?.moySkladAppState == null) {
      // TODO: Error?
      return null
    }

    // No routing - the screen is decided only by the app state
    switch (user.moySkladAppState) {
      case MoySkladAppState.Active:
        return <AppRouter/>

      case MoySkladAppState.SettingsRequired:
        return <MoySkladAppSettingsScreen/>

      case MoySkladAppState.Suspended:
        return <MoySkladAppSuspendedScreen/>

      default:
        return null
    }
  })