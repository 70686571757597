import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountStoresForm } from './AccountStoresForm'

import { useApi } from '../hooks/storeHook'

import { integrationNames } from '../types/integrationTypeName'
import { OzonAccountRequiredProps } from '../types/accountProps'

import { required } from '../common/objectUtils'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { OzonStoreModel } from '../server/mpsklad_core/Models/OzonStoreModel'
import { OzonWarehouseModel } from '../server/mpsklad_core/Models/OzonWarehouseModel'

export const OzonStoresForm =
  observer(({account}: OzonAccountRequiredProps) => {
    const api = useApi()

    return <AccountStoresForm
      integrationName={integrationNames.Ozon}
      storeType={StoreType.Ozon}
      accountId={account.id}
      loadStores={api.userSync.getOzonStores}
      loadWarehouses={api.userSync.getOzonWarehouses}
      addStore={api.userSync.addOzonStore}
      editStore={api.userSync.editOzonStore}
      removeStore={api.userSync.removeOzonStore}
      whKeySelector={_ => _.id}
      isStoreUsingWarehouse={isOzonStoreUsingWarehouse}
    />
  })

// NOTE: This function is duplicated from backend, update simultaneously
const isOzonStoreUsingWarehouse =
  (ozonStore: OzonStoreModel, warehouse: OzonWarehouseModel) =>
    ozonStore.warehouseId === required(warehouse.id)