import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core'

import { SyncStartTableRow } from './SyncStartTableRow'
import { MoySkladSyncStates } from './MoySkladSyncStates'
import { MoySkladSyncSwitch } from './MoySkladSyncSwitch'
import { WbMultiProductSwitch } from './WbMultiProductSwitch'
import { OzonMultiProductSwitch } from './OzonMultiProductSwitch'

import { useApi, useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'
import { MoySkladUseNumerationRow } from './MoySkladUseNumerationRow'
import { YandexMarketMultiProductSwitch } from './YandexMarketMultiProductSwitch'

export const MoySkladSyncInfo =
  observer(
    () => {
      const {syncStore: {moySkladAccount}, user} = useStore()
      const {userSync: {setMoySkladSyncStartDate}} = useApi()

      return (
        <Paper>
          {
            moySkladAccount != null
            ?
            <TableContainer component={'div'}>
              <Table>
                <TableBody>

                  <TableRow>
                    <TableCell>
                      Синхронизировать
                    </TableCell>

                    <TableCell>
                      <MoySkladSyncSwitch/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      Последняя синхронизация
                    </TableCell>

                    <TableCell>
                      <MoySkladSyncStates account={moySkladAccount}/>
                    </TableCell>
                  </TableRow>

                  <SyncStartTableRow account={moySkladAccount} onSubmit={setMoySkladSyncStartDate}/>

                  <MoySkladUseNumerationRow/>

                  <TableRow>
                    <TableCell>
                      Разрешить привязку нескольких товаров Ozon
                    </TableCell>

                    <TableCell>
                      <OzonMultiProductSwitch/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      Разрешить привязку нескольких товаров Wildberries
                    </TableCell>

                    <TableCell>
                      <WbMultiProductSwitch/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      Разрешить привязку нескольких товаров Yandex.Market
                    </TableCell>

                    <TableCell>
                      <YandexMarketMultiProductSwitch/>
                    </TableCell>
                  </TableRow>

                  {
                    (user?.isSuperAdmin || user?.isImpersonating) &&
                    <TableRow>
                      <TableCell>
                        API токен
                      </TableCell>

                      <TableCell>
                        <TextField disabled fullWidth id="msToken" value={moySkladAccount.accessToken}/>
                      </TableCell>
                    </TableRow>
                  }

                  <TableRow>
                    <TableCell>
                      <Link to={appRoutes.SyncLogs.route({integration: 'MoySklad', accountId: moySkladAccount.id})}>
                        Логи синхронизации
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            :
            <Box padding={3}>
              Подключите аккаунт МоегоСклада для синхронизации товаров.
            </Box>
          }
        </Paper>
      )
    })