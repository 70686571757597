import React from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { AppRoute } from './AppRoute'
import { StatusScreen } from './StatusScreen'
import { WbSyncTabsScreen } from './WbSyncScreen'
import { OzonSyncTabsScreen } from './OzonSyncScreen'
import { MoySkladSyncScreen } from './MoySkladSyncScreen'
import { createAllowedTabs, RouteTabs } from './RouteTabs'
import { YandexMarketSyncTabsScreen } from './YandexMarketSyncScreen'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'

export const SyncRoute =
  observer(() => {
    const {allowedIntegrations} = useStore()

    const allowedTabs = [
      ...createAllowedTabs(
        allowedIntegrations, [{
          type: StoreType.Ozon,
          title: 'Ozon',
          route: appRoutes.Sync.ozon
        }, {
          type: StoreType.Wildberries,
          title: 'Wildberries',
          route: appRoutes.Sync.wildberries
        }, {
          type: StoreType.YandexMarket,
          title: 'Yandex.Market',
          route: appRoutes.Sync.yandexmarket
        }]), {
        title: 'МойСклад',
        route: appRoutes.Sync.moySklad
      }, {
        title: 'Статусы',
        route: appRoutes.Sync.status
      }]

    if (!allowedTabs.length) {
      return <Box padding={3}>Аккаунты не подключены</Box>
    }

    return (
      <div className="body_wrapper_zero">
        <Box flex={1}>
          <RouteTabs routes={allowedTabs}>
            <Switch>
              {
                allowedIntegrations[StoreType.Ozon] &&
                <AppRoute path={appRoutes.Sync.ozon} component={OzonSyncTabsScreen}/>
              }

              {
                allowedIntegrations[StoreType.Wildberries] &&
                <AppRoute path={appRoutes.Sync.wildberries} component={WbSyncTabsScreen}/>
              }

              {
                allowedIntegrations[StoreType.YandexMarket] &&
                <AppRoute path={appRoutes.Sync.yandexmarket} component={YandexMarketSyncTabsScreen}/>
              }

              <AppRoute path={appRoutes.Sync.moySklad} component={MoySkladSyncScreen}/>

              <AppRoute path={appRoutes.Sync.status} component={StatusScreen}/>

              {/* NOTE: Fallback route, must remain the last one */}
              <Redirect from="*" to={allowedTabs[0].route}/>
            </Switch>
          </RouteTabs>
        </Box>
      </div>
    )
  })