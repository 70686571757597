import React from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { AppRoute } from './AppRoute'
import { WbPrintTabsScreen } from './WbPrintScreen'
import { OzonPrintTabsScreen } from './OzonPrintScreen'
import { createAllowedTabs, RouteTabs } from './RouteTabs'
import { YandexMarketPrintTabsScreen } from './YandexMarketPrintTabsScreen'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'

export const PrintRoute =
  observer(() => {
    const {allowedIntegrations} = useStore()

    const allowedTabs =
      createAllowedTabs(
        allowedIntegrations,
        [{
          type: StoreType.Ozon,
          title: 'Ozon',
          route: appRoutes.Print.ozon
        }, {
          type: StoreType.Wildberries,
          title: 'Wildberries',
          route: appRoutes.Print.wildberries
        }, {
          type: StoreType.YandexMarket,
          title: 'Yandex.Market',
          route: appRoutes.Print.yandexmarket
        }])

    if (!allowedTabs.length) {
      return <Box padding={3}>Аккаунты не подключены</Box>
    }

    return (
      <div className="body_wrapper_zero">
        <Box flex={1}>
          <RouteTabs routes={allowedTabs}>
            <Switch>
              {
                allowedIntegrations[StoreType.Ozon] &&
                <AppRoute path={appRoutes.Print.ozon} component={OzonPrintTabsScreen}/>
              }

              {
                allowedIntegrations[StoreType.Wildberries] &&
                <AppRoute path={appRoutes.Print.wildberries} component={WbPrintTabsScreen}/>
              }

              {
                allowedIntegrations[StoreType.YandexMarket] &&
                <AppRoute path={appRoutes.Print.yandexmarket} component={YandexMarketPrintTabsScreen}/>
              }

              {/* NOTE: Fallback route, must remain the last one */}
              <Redirect from="*" to={allowedTabs[0].route}/>
            </Switch>
          </RouteTabs>
        </Box>
      </div>
    )
  })