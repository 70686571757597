import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'

import { OzonSyncStates } from './OzonSyncStates'
import { OzonSyncSwitch } from './OzonSyncSwitch'
import { SyncStartTableRow } from './SyncStartTableRow'
import { OzonLoadOrdersForm } from './OzonLoadOrdersForm'
import { StoreAutoShipmentRow } from './StoreAutoShipmentRow'
import { StoreReserveMsOrdersRow } from './StoreReserveMsOrdersRow'
import { StoreAutoConfirmationRow } from './StoreAutoConfirmationRow'

import { useApi } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

import { OzonAccountProps } from '../types/accountProps'
import { StoreRealizationReportsSyncRow } from './StoreRealizationReportsSyncRow'

export const OzonSyncInfo =
  observer(
    ({account}: OzonAccountProps) => {
      const {
        userSync: {
          setOzonSyncStartDate,
          setOzonAutoConfirmation,
          setOzonAutoShipment,
          setOzonReserveMsOrders,
          setOzonRealizationReportsSync
        }
      } = useApi()

      return (
        <Paper>
          {
            // TODO: Split?
            account !== null
            ?
            <TableContainer component={'div'}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Синхронизировать
                    </TableCell>

                    <TableCell>
                      <OzonSyncSwitch account={account}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      Последняя синхронизация
                    </TableCell>

                    <TableCell>
                      <OzonSyncStates account={account}/>
                    </TableCell>
                  </TableRow>

                  <SyncStartTableRow account={account} onSubmit={setOzonSyncStartDate}/>

                  <StoreAutoConfirmationRow account={account} onSubmit={setOzonAutoConfirmation}/>

                  <StoreAutoShipmentRow account={account} onSubmit={setOzonAutoShipment}/>

                  <StoreReserveMsOrdersRow account={account} onSubmit={setOzonReserveMsOrders}/>

                  <StoreRealizationReportsSyncRow account={account} onSubmit={setOzonRealizationReportsSync}/>

                  {
                    account.syncEnabled && !account.isFBS &&
                    <TableRow>
                      <TableCell>
                        Загрузка заказов
                      </TableCell>

                      <TableCell>
                        <OzonLoadOrdersForm ozonAccountId={account.id}/>
                      </TableCell>
                    </TableRow>
                  }

                  <TableRow>
                    <TableCell>
                      <Link to={appRoutes.SyncLogs.route({integration: 'Ozon', accountId: account.id})}>
                        Логи синхронизации
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            :
            <Box padding={3}>
              Подключите аккаунт Ozon для синхронизации товаров
            </Box>
          }
        </Paper>
      )
    })