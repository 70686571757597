import React from 'react'
import { observer } from 'mobx-react-lite'

import { ProductSyncSwitch } from '../ProductSyncSwitch'

import { useLogic } from '../../hooks/storeHook'

import { YandexMarketProductModel } from '../../server/mpsklad_core/Models/YandexMarketProductModel'

export type ProductYandexMarketSyncSwitchProps = {
  msProductId: number

  yandexMarketProduct: YandexMarketProductModel
}

export const ProductYandexMarketSyncSwitch =
  observer(
    ({msProductId, yandexMarketProduct}: ProductYandexMarketSyncSwitchProps) => {
      const {setYandexMarketProductSync} = useLogic()

      return (
        <ProductSyncSwitch
          syncEnabled={yandexMarketProduct.syncEnabled}
          onChange={syncEnabled => setYandexMarketProductSync(msProductId, yandexMarketProduct.id, syncEnabled)}
        />
      )
    })
