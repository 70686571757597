import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountSyncSwitch } from './AccountSyncSwitch'

import { useLogic, useStore } from '../hooks/storeHook'

export const MoySkladSyncSwitch =
  observer(() => {
    const {syncStore: {moySkladAccount}, hasMoySkladApp} = useStore()
    const {setMoySkladAccountSync} = useLogic()

    const setSync =
      (syncEnabled: boolean, _: unknown) =>
        setMoySkladAccountSync(syncEnabled)

     // For users with an MS app: disallow disabling, allow enabling
    return moySkladAccount != null
           ? <AccountSyncSwitch
             account={moySkladAccount}
             setSync={setSync}
             disabled={hasMoySkladApp && moySkladAccount.syncEnabled}/>
           : null
  })