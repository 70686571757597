import Axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'

export type AxiosRequestMethod<TData, TResponse> =
  (data: TData, _config?: AxiosRequestConfig) => Promise<TResponse>

export type CancellableAxiosRequestMethod<TData, TResponse> =
  (data: TData, _config?: Omit<AxiosRequestConfig, 'cancelToken'>) => Promise<TResponse>

/**
 * Wraps the given `requestMethod` so that only a single instance of the request can be executing at any time.
 * Preemptive - when a new request is started, the ongoing request is cancelled (if there is one).
 * @param requestMethod
 */
export const createPreemptiveRequest =
  <TData, TResponse>(requestMethod: AxiosRequestMethod<TData, TResponse>): CancellableAxiosRequestMethod<TData, TResponse> => {
    let previousCancelToken: CancelTokenSource | null = null

    return async (data: TData, _config?: Omit<AxiosRequestConfig, 'cancelToken'>): Promise<TResponse> => {
      if (previousCancelToken !== null) {
        // TODO: Wait for the cancellation to throw?
        previousCancelToken.cancel('Cancelled by the next request')
        previousCancelToken = null
      }

      let currentCancelToken: CancelTokenSource | null = null

      try {
        currentCancelToken = Axios.CancelToken.source()
        previousCancelToken = currentCancelToken

        return await requestMethod(data, {..._config, cancelToken: currentCancelToken.token})
      } finally {
        // Cleanup only if it wasn't cancelled
        if (previousCancelToken === currentCancelToken) {
          previousCancelToken = null
        }
      }
    }
  }