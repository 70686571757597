import React, { useRef, useState } from 'react'
import { differenceInMinutes, differenceInSeconds } from 'date-fns'

import { useLazyEffect } from '../hooks/commonHooks'

import { formatDuration } from '../common/dateTimeUtility'

export type SyncStateTextProps = {
  syncDate: Date
}

export const SyncStateText =
  ({syncDate}: SyncStateTextProps) => {
    const reRenderTimeout = useRef<number>()
    const [now, setNow] = useState(new Date())

    const diffInSeconds = differenceInSeconds(now, syncDate)

    useLazyEffect(() => {
      if (diffInSeconds <= 60) {
        reRenderTimeout.current = window.setTimeout(() => setNow(new Date()), 10 * 1000)
      } else if (differenceInMinutes(now, syncDate) <= 60) {
        reRenderTimeout.current = window.setTimeout(() => setNow(new Date()), 60 * 1000)
      }

      return () => window.clearTimeout(reRenderTimeout.current)
    }, [now])

    const syncDateText = formatSyncDate(syncDate)

    return <span>{syncDateText}</span>
  }

export const formatSyncDate =
  (syncDate: Date) => {
    const now = new Date()
    const diffInSeconds = differenceInSeconds(now, syncDate)

    return diffInSeconds < 10 ? 'Только что'
                              : formatDuration(syncDate, now, {addSuffix: true})
  }