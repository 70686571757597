import React from 'react'
import { observer } from 'mobx-react-lite'

import { Checkbox, TableCell } from '@material-ui/core'

import { MyRowWithTableProps } from '../../types/tableTypes'
import { MyProductOnlyColumnId } from '../../types/myProductColumnIds'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'

export const MyProductSelectCell =
  observer(
    ({row, table: {isRowSelected, toggleRowSelection}}: MyRowWithTableProps<MyProductOnlyColumnId, MyProductModel>) =>
      <TableCell
        className="table-border"
        padding="checkbox"
        onClick={() => toggleRowSelection(row)}
        style={{cursor: 'pointer'}}
      >
        <Checkbox
          checked={isRowSelected(row)}
          inputProps={{'aria-labelledby': makeMyProductRowId(row)}}
          style={{color: '#3987cf'}}
        />
      </TableCell>
  )

export const makeMyProductRowId =
  (product: MyProductModel) => `my-product-${product.id}`