import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

import { NoteAddOutlined } from '@material-ui/icons'
import { Box, CircularProgress, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core'

import { fixMsAppScrollProps } from '../common/msAppUtils'

import { MoySkladAttributeModel } from '../server/mpsklad_core/Models/MoySkladAttributeModel'

export type MoySkladAttributeSelectProps = {
  title: ReactNode

  value: string | undefined

  setValue: (value: string | undefined) => void

  options: MoySkladAttributeModel[]

  isCreating: boolean

  onCreate: () => Promise<unknown>
}

export const MoySkladAttributeSelect =
  observer(
    ({title, value, setValue, options, isCreating, onCreate}: MoySkladAttributeSelectProps) => {
      return (
        <Box marginBottom={4}>
          <Box marginBottom={1}>
            <InputLabel>
              {title}
            </InputLabel>
          </Box>

          <Select
            value={value}
            onChange={e => setValue(e.target.value as string)}
            style={{minWidth: 200}}
            MenuProps={fixMsAppScrollProps}
          >
            <MenuItem>&mdash;</MenuItem>

            {
              options.map(_ => <MenuItem key={_.id} value={_.id}>{_.name}</MenuItem>)
            }
          </Select>

          <IconButton
            title="Создать новое поле"
            disabled={isCreating}
            onClick={onCreate}
            style={{marginLeft: 5}}
          >
            {
              isCreating ? <CircularProgress size={20}/> : <NoteAddOutlined/>
            }
          </IconButton>
        </Box>
      )
    })