import { useLocation, useParams } from 'react-router-dom'

export type StringRouteParams<TParams> = {
  [K in keyof TParams]: string
}

export type RouteParamsParser<TParams> =
  (stringRouteParams: StringRouteParams<TParams>) => TParams

export const useRouteParams =
  <TParams extends object>() =>
    useParams<StringRouteParams<TParams>>()

export const useQuery =
  () => new URLSearchParams(useLocation().search)