import { useLogic } from './storeHook'
import { useBoolState } from './commonHooks'
import { useMessages } from './snackbarHooks'

import { ITableData } from '../types/tableTypes'
import { StoreProductModel } from '../server/mpsklad_core/Models/StoreProductModel'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'
import { ImportProductsOptionsModel } from '../server/mpsklad_core/Models/ImportModels/ImportProductsModels/ImportProductsOptionsModel'
import { ImportStocksOptionsModel } from '../server/mpsklad_core/Models/ImportModels/ImportStocksModels/ImportStocksOptionsModel'

export type ProductImportAction =
  (accountId: number, productOptionsModel: ImportProductsOptionsModel) => Promise<void>

export type StockImportAction =
  (accountId: number, productOptionsModel: ImportStocksOptionsModel) => Promise<void>

export const useImportEvents =
  <TRow extends StoreProductModel, TColumnId>
  (account: StoreAccountModelBase, products: ITableData<TRow, TColumnId>) => {
    const logic = useLogic()

    const {showSuccess} = useMessages()

    const [isImportDisabled, disableImport, enableImport] = useBoolState()

    const onProductImport =
      (importAction: ProductImportAction,
       productImportOptions: ImportProductsOptionsModel) =>
        async () => {
          if (!await logic.showDialog(
            makeImportProductsConfirmationText(products.selectedRowCount > 0, productImportOptions.addOptions.images,
              productImportOptions.addOptions.prices))) {
            return
          }

          try {
            disableImport()

            productImportOptions.productIds = products.selectedRows.map(r => r.id)
            await importAction(account.id, productImportOptions)

            // TODO: Inform users of progress and when it finishes
            showSuccess('Импорт в процессе...')
          } catch (e) {
            console.error('Failed to import products for account', e)
          } finally {
            enableImport()
          }
        }

    const onStockImport =
      (importAction: StockImportAction,
       stockImportOptions: ImportStocksOptionsModel) =>
        async () => {
          if (!await logic.showDialog(makeImportStocksConfirmationText(products.selectedRowCount > 0))) {
            return
          }

          try {
            disableImport()

            stockImportOptions.productIds = products.selectedRows.map(r => r.id)
            await importAction(account.id, stockImportOptions)

            // TODO: Inform users of progress and when it finishes
            showSuccess('Импорт в процессе...')
          } catch (e) {
            console.error('Failed to import stocks for account', e)
          } finally {
            enableImport()
          }
        }

    return {
      onProductImport,
      onStockImport,
      isImportDisabled
    }
  }

export const makeImportProductsConfirmationText =
  (hasSelectedRows: boolean, isImportingImages: boolean, isImportingPrices: boolean) => {
    let finalText = `Все${hasSelectedRows ? ' выбранные' : ''} несматченные товары будут импортированы в МойСклад!
Внимание! Новые модификации ранее сматченных товаров нужно матчить до импорта, иначе они будут созданы как новые товары в МоёмСкладе!`

    if (isImportingImages) {
      finalText = finalText + '\n\n' + makeImportImagesConfirmationText(hasSelectedRows)
    }

    if (isImportingPrices) {
      finalText = finalText + '\n\n' + makeImportPricesConfirmationText(hasSelectedRows)
    }

    return finalText
  }

export const makeImportImagesConfirmationText =
  (hasSelectedRows: boolean) =>
    `При общем весе картинок, превышающем ваш тариф МоегоСклада, операция не удастся!
Картинки будут загружены в МойСклад для всех ${hasSelectedRows ? 'выбранных' : 'несматченных'} товаров!`

export const makeImportPricesConfirmationText =
  (hasSelectedRows: boolean) =>
    `Требуется наличие сгенерированных типов цен в МоёмСкладе.
Цены для маркетплейса будут перезаписаны у всех ${hasSelectedRows ? 'выбранных' : 'несматченных'} товаров!`

export const makeImportStocksConfirmationText =
  (hasSelectedRows: boolean) =>
    `Внимание! Остатки в МоёмСкладе будут перезаписаны на текущие остатки маркетплейса и только для${
      hasSelectedRows ? ' выбранных' : ''} сматченных товаров.`