import React from 'react'
import { observer } from 'mobx-react-lite'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import {
  ExternalProductPicker,
  ExternalProductPickerBaseProps,
  ExternalProductPickerProps
} from './Tables/ExternalProductPicker'

import { ExternalProductLabel } from './Tables/ExternalProductLabel'

import { ExternalProductOption } from '../server/mpsklad_core/Models/ExternalProductOption'

export type ProductMultiPickerBaseProps =
  Omit<ExternalProductPickerBaseProps, 'onChange'>
  & {
  products: ExternalProductOption[]

  onChange: (product: ExternalProductOption[]) => void | Promise<void>
}

export type ProductMultiPickerProps =
  ProductMultiPickerBaseProps
  & Pick<ExternalProductPickerProps, 'renderInput'>
  & {
  loadProductOptions:
    (msProductId: number | undefined, searchTerm: string | undefined) => Promise<ExternalProductOption[]>
}

export const ProductMultiPicker =
  observer(
    ({products, loadProductOptions, onChange, ...passProps}: ProductMultiPickerProps) => {
      const classes = useStyles()

      const onAdd =
        async (addedProduct: ExternalProductOption | undefined) => {
          if (addedProduct && products.every(_ => _.id !== addedProduct.id)) {
            await onChange([...products, addedProduct])
          }
        }

      const onRemove =
        (removedProduct: ExternalProductOption) =>
          onChange(products.filter(_ => _ !== removedProduct))

      // NOTE: Box is slower that div with styles, useStyles is about the same as div
      return (
        <>
          {
            products.map(product =>
              <ExternalProductLabel key={product.id} label={product.label} onRemove={() => onRemove(product)}/>)
          }

          <div className={classes.pickerWrap}>
            <ExternalProductPicker
              text=""
              externalId={undefined}
              loadOptions={searchTerm => loadProductOptions(undefined, searchTerm)}
              onChange={onAdd}
              {...passProps}
            />
          </div>
        </>
      )
    })

const useStyles =
  makeStyles(theme =>
    createStyles({
      pickerWrap: {
        marginTop: theme.spacing(1)
      }
    }))