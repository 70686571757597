//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { RenderErrorModel } from '../Models/RenderErrorModel';

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class LogController
{
  
  private readonly axios: AxiosInstance
  
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  logRenderError(model: RenderErrorModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Log/LogRenderError`, model, _config).then(getAxiosData)
  }
  

}
