import { action, computed, makeObservable, observable } from 'mobx'

import { PasswordResetState } from '../types/passwordResetState'

export class PasswordResetStore {
  @observable
  private _state = PasswordResetState.RequestEmail

  @observable
  private _email: string | null = null

  @observable
  private _resetCode: string | null = null

  constructor() {
    makeObservable(this)
  }

  @computed
  get state() {
    return this._state
  }

  @computed
  get email() {
    return this._email
  }

  @computed
  get resetCode() {
    return this._resetCode
  }

  @action
  setEmail =
    (email: string) => {
      if (this._state !== PasswordResetState.RequestEmail) {
        throw new Error(`Bad PasswordResetState: ${this._state}`)
      }

      this._email = email
      this._state = PasswordResetState.RequestCode
    }

  @action
  setResetCode =
    (resetCode: string) => {
      if (this._state !== PasswordResetState.RequestCode) {
        throw new Error(`Bad PasswordResetState: ${this._state}`)
      }

      this._resetCode = resetCode
      this._state = PasswordResetState.RequestNewPassword
    }

  @action
  setFinished = () => {
    if (this._state !== PasswordResetState.RequestNewPassword) {
      throw new Error(`Bad PasswordResetState: ${this._state}`)
    }

    return this._state = PasswordResetState.Finished
  }
}