import { MemoryTableData } from './memoryTableData'

import { PrintFilesColumnId } from '../types/wbFilesColumns'
import { PrintFileModel } from '../server/mpsklad_core/Models/PrintFileModel'

export class LabelStore {
  readonly wbFiles = new MemoryTableData<PrintFileModel, PrintFilesColumnId>('wbFiles')

  readonly ozonFiles = new MemoryTableData<PrintFileModel, PrintFilesColumnId>('ozonFiles')

  readonly yandexMarketFiles = new MemoryTableData<PrintFileModel, PrintFilesColumnId>('yandexMarketFiles')
}