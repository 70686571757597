import React from 'react'

import { ImpersonationBar } from './ImpersonationBar'

export const MoySkladAppSuspendedScreen = () =>
  <div className="body_wrapper_zero">
    <ImpersonationBar/>

    <div className="body_wrapper">
      Приложение и/или ваш тариф не оплачены в МоёмСкладе.
    </div>
  </div>