import { GroupTableColumn, TableColumn } from './tableTypes'
import { CellKeyOrAccessor, toFuncAccessor } from './cellAccessor'

import { isNotNull, OmitStrict } from '../common/tsUtils'

export type MyColumnParam<TColumnId, TRow extends object> =
  OmitStrict<TableColumn<TColumnId, TRow>, 'accessor'>
  & {
  /**
   * NOTE: Required for sortable columns with non-key ids
   */
  accessor?: CellKeyOrAccessor<TRow>
}

export type GroupedMyColumnsParam<TGroupColumnId extends string, TRow extends object> =
  Array<MyColumnParam<TGroupColumnId, TRow> | null>

export const createColumns =
  <TColumnId extends string, TRow extends object>
  (...columns: Array<MyColumnParam<TColumnId, TRow> | null>): Array<TableColumn<TColumnId, TRow>> =>
    columns.filter(isNotNull)
           .map(createColumn)

export const createColumnGroup =
  <TColumnId extends string, TRow extends object>() =>
    <TGroup>(group: TGroup, columns: GroupedMyColumnsParam<TColumnId, TRow>)
      : Array<GroupTableColumn<TGroup, TColumnId, TRow>> =>
      columns.filter(isNotNull)
             .map(column => ({group, ...createColumn(column)}))

export const createColumn =
  <TColumnId extends string, TRow extends object>
  ({accessor, ...column}: MyColumnParam<TColumnId, TRow>): TableColumn<TColumnId, TRow> =>
    ({
      ...column,
      accessor: toFuncAccessor(accessor ?? (column.id as string as (string & keyof TRow)))
    })