import React from 'react'

import { Error } from '@material-ui/icons'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { useSyncError } from '../../hooks/syncErrorHook'

import { IntegrationTypeName } from '../../types/integrationTypeName'

export const SyncErrorIcon =
  ({type, errorId}: {
    type: IntegrationTypeName
    errorId: string | undefined
  }) => {
    const {onErrorClick, SyncErrorDialog} = useSyncError(type, errorId)

    const classes = useStyles()

    if (!errorId) {
      return null
    }

    return <>
      <div
        className={classes.iconWrap}
        title="Показать ошибку"
        onClick={onErrorClick}
      >
        <Error color="error"/>
      </div>

      <SyncErrorDialog/>
    </>
  }

const useStyles =
  makeStyles(theme =>
    createStyles({
      iconWrap: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'inline-flex',
        cursor: 'pointer'
      }
    }))