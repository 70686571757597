import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid } from '@material-ui/core'

import { OzonSyncInfo } from './OzonSyncInfo'
import { OzonScreenTabs } from './OzonScreenTabs'
import { OzonAccountForm } from './OzonAccountForm'
import { OzonAccountInfo } from './OzonAccountInfo'

import { OzonAccountProps } from '../types/accountProps'

export const OzonSyncTabsScreen = () =>
  <OzonScreenTabs canAddAccount canRemoveAccount ScreenComponent={OzonSyncScreen}/>

export const OzonSyncScreen =
  observer(
    ({account}: OzonAccountProps) =>
      <Box padding={3}>
        <Grid container direction="column" spacing={3}>
          <Grid item lg={10} xl={8}>
            <OzonSyncInfo account={account}/>
          </Grid>

          <Grid item lg={10} xl={8}>
            <OzonAccountForm account={account}/>
          </Grid>

          {
            account !== null &&
            <OzonAccountInfo account={account}/>
          }
        </Grid>
      </Box>
  )