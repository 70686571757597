import { Constrained, EnumKey, EnumValue } from '../common/tsUtils'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'
import { AccountModelBase } from '../server/mpsklad_core/Models/AccountModelBase'
import { MoySkladAccountModel } from '../server/mpsklad_core/Models/MoySkladAccountModel'
import { YandexMarketAccountModel } from '../server/mpsklad_core/Models/YandexMarketAccountModel'

export type IntegrationTypeName = EnumKey<typeof IntegrationType>

export type IntegrationAccountTypeMap =
  Constrained<{ [I in IntegrationTypeName]: AccountModelBase }, {
    Ozon: OzonAccountModel,
    Wildberries: WbAccountModel,
    MoySklad: MoySkladAccountModel,
    YandexMarket: YandexMarketAccountModel
  }>

export type SyncAccountInfo<I extends IntegrationTypeName> = {
  type: I

  account: IntegrationAccountTypeMap[I]

  displayName: string
}

export const integrationNames: {
  [integration in IntegrationTypeName]: string
} = {
  Ozon: 'Ozon',
  Wildberries: 'Wildberries',
  MoySklad: 'МойСклад',
  YandexMarket: 'Yandex.Market'
}

export const integrationTypeNames: {
  [integrationType in IntegrationType]: string
} = {
  [IntegrationType.Ozon]: integrationNames.Ozon,
  [IntegrationType.Wildberries]: integrationNames.Wildberries,
  [IntegrationType.MoySklad]: integrationNames.MoySklad,
  [IntegrationType.YandexMarket]: integrationNames.YandexMarket
}

export const integrationTypes: {
  [integration in IntegrationTypeName]: IntegrationType
} = {
  Ozon: IntegrationType.Ozon,
  Wildberries: IntegrationType.Wildberries,
  MoySklad: IntegrationType.MoySklad,
  YandexMarket: IntegrationType.YandexMarket
}

export type AllowedIntegrationsMap = {
  [storeType in EnumValue<typeof StoreType>]: boolean
}