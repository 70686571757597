import { makeStyles } from '@material-ui/core/styles'

export const useAuthFormStyles =
  makeStyles(
    theme => ({
      innerContainer: {
        // Padding instead of margin fixes unnecessary scrollbar on 768p
        paddingTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
      },
      form: {
        // Fix IE 11 issue
        width: '100%',
        marginTop: theme.spacing(1)
      },
      submit: {
        margin: theme.spacing(3, 0, 2)
      },
      formLink: {
        color: theme.palette.primary.main,
        textDecorationSkipInk: 'none'
      }
    }))