import React, { Dispatch } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button, FormControlLabel, FormHelperText, Switch, Typography } from '@material-ui/core'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { LoadState, useLoadState } from '../hooks/loadStateHook'

export type StoreAccountPriceUpdateFormProps = {
  isUpdatePrices: boolean

  setUpdatePrices: Dispatch<boolean>

  onCheckPrices: () => Promise<boolean>

  onEnablePriceSync: () => Promise<void>

  onForceUpdatePrices: () => Promise<void>

  description: string
}

export const StoreAccountPriceUpdateForm =
  observer(
    ({
       isUpdatePrices,
       setUpdatePrices,
       onCheckPrices,
       onEnablePriceSync,
       onForceUpdatePrices,
       description
     }: StoreAccountPriceUpdateFormProps) => {
      const {showSuccess} = useMessages()

      const {
        loadState: checkState,
        setLoading: setChecking,
        setSuccess: setChecked,
        setError: setCheckError
      } = useLoadState()

      const [hasPrices, setHasPrices, setNoPrices] = useBoolState()

      const [isEnableLoading, setEnableLoading, setEnableLoaded] = useBoolState()

      const [isForceUpdateLoading, setForceUpdateLoading, setForceUpdateLoaded] = useBoolState()

      const isLoading = isEnableLoading || isForceUpdateLoading

      const onCheckPricesClick = async () => {
        try {
          setChecking()

          if (await onCheckPrices()) {
            setHasPrices()
          } else {
            setNoPrices()
          }

          setChecked()
        } catch {
          setCheckError()
        }
      }

      const onEnablePriceSyncClick = async () => {
        try {
          setEnableLoading()

          await onEnablePriceSync()

          setHasPrices()
          showSuccess('Активировано')
        } catch {
          setNoPrices()
        } finally {
          setEnableLoaded()
        }
      }

      const onForceUpdateClick = async () => {
        try {
          setForceUpdateLoading()

          await onForceUpdatePrices()

          showSuccess('Обновление в процессе')
        } finally {
          setForceUpdateLoaded()
        }
      }

      return (
        <>
          <Box marginBottom={1}>
            <Typography variant="h6">Настройка цен</Typography>
          </Box>

          {
            checkState === LoadState.Success
            ? hasPrices
              ? <>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isLoading}
                      checked={isUpdatePrices}
                      onChange={e => setUpdatePrices(e.target.checked)}/>
                  }
                  label="Автоматическое обновление цен"
                />

                <FormHelperText>Обновление происходит в 10:00 по МСК</FormHelperText>

                <Box marginTop={2}>
                  <Button
                    onClick={onForceUpdateClick}
                    variant="contained"
                    disabled={isLoading}
                  >
                    Обновить цены
                  </Button>
                </Box>

                <Box marginTop={1} style={{whiteSpace: 'pre-line'}}>
                  <FormHelperText>{description}</FormHelperText>
                </Box>
              </>
              :
              <Box marginBottom={2}>
                <Button
                  onClick={onEnablePriceSyncClick}
                  variant="contained"
                  disabled={isLoading}
                >
                  Добавить типы цен в МС
                </Button>
              </Box>
            :
            <Button
              variant="contained"
              disabled={checkState === LoadState.Loading}
              onClick={onCheckPricesClick}
            >
              Настроить
            </Button>
          }
        </>
      )
    })