import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountToggleRow } from './AccountToggleRow'

import { StoreAccountToggleRowProps } from '../types/accountProps'

export const StoreAutoConfirmationRow =
  observer(
    ({account, onSubmit}: StoreAccountToggleRowProps) =>
      <AccountToggleRow
        title="Автоматическая сборка заказов"
        helperText='Перевод заказов из статуса "Новый" в статус "Подтверждён".'
        account={account}
        onSubmit={onSubmit}
        getAccountToggle={() => account.autoConfirmationEnabled}
        setAccountToggle={isEnabled => account.autoConfirmationEnabled = isEnabled}
        getSuccessMessage={isEnabled => `Автосборка ${isEnabled ? 'включена' : 'отключена'}`}
      />)