import React from 'react'
import { observer } from 'mobx-react-lite'

import { Grid, GridProps } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

export type DateRangePickerProps = {
  containerProps?: Omit<GridProps, 'item' | 'container'>

  startDate: Date

  endDate: Date

  maxDate?: Date

  onChange: (startDate: Date, endDate: Date) => void
}

export const DateTimeRangePicker =
  observer(
    ({
       containerProps,
       startDate, endDate, maxDate, onChange
     }: DateRangePickerProps) => {
      const onStartChange = (date: MaterialUiPickersDate) => {
        const newStartDate = date ?? startDate
        const newEndDate = newStartDate > endDate ? newStartDate : endDate

        onChange(newStartDate, newEndDate)
      }

      const onEndChange = (date: MaterialUiPickersDate) => {
        const newEndDate = date ?? endDate
        const newStartDate = newEndDate < startDate ? newEndDate : startDate

        onChange(newStartDate, newEndDate)
      }

      return (
        <Grid item container direction="row" justifyContent="space-between" {...containerProps}>
          <Grid item xs={5}>
            <DateTimePicker
              autoOk
              ampm={false}
              variant="inline"
              format="dd.MM.yyyy, HH:mm"
              label="От"
              maxDate={maxDate}
              value={startDate}
              onChange={onStartChange}
            />
          </Grid>

          <Grid item xs={5}>
            <DateTimePicker
              autoOk
              ampm={false}
              variant="inline"
              format="dd.MM.yyyy, HH:mm"
              label="До"
              maxDate={maxDate}
              value={endDate}
              onChange={onEndChange}
            />
          </Grid>
        </Grid>
      )
    })