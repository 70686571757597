import { computed, makeObservable, observable } from 'mobx'

import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'
import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'
import { MoySkladAccountModel } from '../server/mpsklad_core/Models/MoySkladAccountModel'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'
import { YandexMarketAccountModel } from '../server/mpsklad_core/Models/YandexMarketAccountModel'

export class SyncStore {
  @observable
  ozonAccounts: OzonAccountModel[]

  @observable
  wbAccounts: WbAccountModel[]

  @observable
  yandexMarketAccounts: YandexMarketAccountModel[]

  @observable
  moySkladAccount: MoySkladAccountModel | null

  constructor() {
    makeObservable(this)

    this.ozonAccounts = []
    this.wbAccounts = []
    this.yandexMarketAccounts = []
    this.moySkladAccount = null
  }

  @computed
  get allowCombinedTab() {
    // Allow combined only when there is at most one account of each type and at least two different account types
    return this.ozonAccounts.length <= 1
           && this.wbAccounts.length <= 1
           && this.yandexMarketAccounts.length <= 1
           && (this.ozonAccounts.length + this.wbAccounts.length + this.yandexMarketAccounts.length >= 2)
  }

  getStoreAccounts(storeType: Exclude<NavStoreType, NavStoreType.Combined>): StoreAccountModelBase[] {
    switch (storeType) {
      case NavStoreType.Ozon:
        return this.ozonAccounts

      case NavStoreType.Wildberries:
        return this.wbAccounts

      case NavStoreType.YandexMarket:
        return this.yandexMarketAccounts

      default:
        throw new Error(`Unprocessed store type: ${storeType}`)
    }
  }
}