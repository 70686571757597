import React, { FormEvent } from 'react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'

import { MoySkladStatusMapRow } from './MoySkladStatusMapRow'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { required } from '../common/objectUtils'
import { msAppUrls } from '../common/urlUtility'

import { MoySkladStatusMapsModel } from '../server/mpsklad_core/Models/MoySkladStatusMapsModel'
import { MoySkladStatusMapModel } from '../server/mpsklad_core/Models/MoySkladStatusMapModel'

export type MoySkladStatusMapFormProps = {
  statusMaps: MoySkladStatusMapsModel

  resetStatusMaps: () => void
}

export const MoySkladStatusMapForm =
  observer(
    ({statusMaps, resetStatusMaps}: MoySkladStatusMapFormProps) => {
      const {showSuccess} = useMessages()

      const logic = useLogic()

      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const onChange =
        (map: MoySkladStatusMapModel, statusId: string) => {
          const selectedStatus = statusMaps.statuses.find(_ => _.id === statusId)

          runInAction(() => {
            map.moySkladId = selectedStatus?.id
            map.moySkladName = selectedStatus?.name
          })
        }

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()
          setIsLoading()

          try {
            await logic.setMoySkladStatusMaps(required(statusMaps).maps)
            showSuccess('Сохранено!')
            resetStatusMaps()
          } finally {
            setNotLoading()
          }
        }

      return (
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <Table>
            <TableContainer>
              <TableHead>
                <TableRow>
                  <TableCell>
                    MPsklad
                  </TableCell>

                  <TableCell>
                    МойСклад
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  statusMaps.maps.map(
                    map =>
                      <MoySkladStatusMapRow
                        key={map.myOrderStatus}
                        map={map}
                        statuses={statusMaps.statuses}
                        onChange={onChange}
                      />
                  )
                }
              </TableBody>
            </TableContainer>
          </Table>

          {
            statusMaps.statuses.length === 0 &&
            <Typography variant="body1" color="error">
              Не найдены статусы для Заказа покупателя в МоёмСкладе.
              Создайте статусы на странице <a
              href={msAppUrls.orders}
              target="_blank"
              rel="noreferrer">Продажи - Заказы покупателей</a>: Статус - Настроить.
            </Typography>
          }

          <Box marginTop={3}>
            <Button
              variant="contained"
              type="button"
              onClick={resetStatusMaps}
              disabled={isLoading}
              style={{marginRight: 10}}
            >
              Отменить
            </Button>

            <Button variant="contained" color="secondary" type="submit" disabled={isLoading}>
              {isLoading ? 'Сохраняем...' : 'Сохранить'}
            </Button>
          </Box>
        </form>
      )
    })