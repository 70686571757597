//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum IntegrationType {
  Ozon = 1,
  Wildberries = 2,
  MoySklad = 3,
  YandexMarket = 4
}
