import { useLogic } from '../hooks/storeHook'
import { AccountSyncSwitch } from './AccountSyncSwitch'
import React from 'react'
import { YandexMarketAccountRequiredProps } from '../types/accountProps'

export const YandexMarketSyncSwitch =
  ({account}: YandexMarketAccountRequiredProps) => {
    const {setYandexMarketAccountSync} = useLogic()

    return <AccountSyncSwitch account={account} setSync={setYandexMarketAccountSync}/>
  }
