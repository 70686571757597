import { createColumns } from './columnGroupCreator'
import { TableColumn } from './tableTypes'

import { TableDateCell } from '../components/tableCells/TableDateCell'
import { MoySkladIdCell } from '../components/tableCells/MoySkladIdCell'
import { YandexMarketOrderSyncLogsCell } from '../components/tableCells/StoreOrderSyncLogsCell'
import { YandexMarketOrderSyncErrorCell } from '../components/tableCells/YandexMarketOrderSyncErrorCell'

import { formatPrice } from '../common/numberUtils'

import { YandexMarketOrderModel } from '../server/mpsklad_core/Models/Orders/YandexMarketOrderModel'

export type YandexMarketOrderColumnId =
  keyof YandexMarketOrderModel
  | 'moySkladId'
  | 'logs'

export type YandexMarketOrderColumn = TableColumn<YandexMarketOrderColumnId, YandexMarketOrderModel>

export const yandexMarketOrderColumns: YandexMarketOrderColumn[] =
  createColumns<YandexMarketOrderColumnId, YandexMarketOrderModel>({
    id: 'id',
    title: 'Id'
  }, {
    id: 'createdAt',
    title: 'Дата создания',
    CellComponent: TableDateCell
  }, {
    id: 'yandexMarketOrderId',
    title: 'Id в Yandex.Market'
  }, {
    id: 'items',
    title: 'Товары',
    accessor: _ => _.items.map(p => `${p.shopSku} - ${p.offerName} (${p.count})`)
  }, {
    id: 'price',
    title: 'Цена продажи, ₽',
    accessor: _ => formatPrice(_.price)
  }, {
    id: 'status',
    title: 'Статус'
  }, {
    id: 'moySkladId',
    title: 'GUID в МоёмСкладе',
    CellComponent: MoySkladIdCell
  }, {
    id: 'syncErrorId',
    title: 'Ошибки',
    CellComponent: YandexMarketOrderSyncErrorCell
  }, {
    id: 'logs',
    title: 'Логи',
    CellComponent: YandexMarketOrderSyncLogsCell
  })