import React from 'react'

import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography
} from '@material-ui/core'

import { fixMsAppScrollProps } from '../common/msAppUtils'
import { SetStateFunc, valuesOfNumericEnum } from '../common/tsUtils'

import { commissionsRecordingTypeNames } from '../types/commissionsRecordingTypeNames'
import { CommissionsRecordingType } from '../server/mpsklad_core/Entity/Base/CommissionsRecordingType'

export type AccountCommissionsProps = {
  isUsingCommissions: boolean

  setUsingCommissions: SetStateFunc<boolean>

  ordersCommissionsRecording: CommissionsRecordingType

  setOrdersCommissionsRecording: SetStateFunc<CommissionsRecordingType>

  documentsCommissionsRecording: CommissionsRecordingType

  setDocumentsCommissionsRecording: SetStateFunc<CommissionsRecordingType>
}

export const AccountCommissions =
  ({
     isUsingCommissions,
     setUsingCommissions,
     ordersCommissionsRecording,
     setOrdersCommissionsRecording,
     documentsCommissionsRecording,
     setDocumentsCommissionsRecording
   }: AccountCommissionsProps) => {
    const toggleUsingCommissions = () => setUsingCommissions(!isUsingCommissions)

    return <>
      <Box marginTop={3}>
        <Typography variant="h6">Настройка комиссий</Typography>
      </Box>

      <FormControlLabel
        label="Автоматический учёт комиссии"
        control={<Switch checked={isUsingCommissions} onChange={toggleUsingCommissions}/>}
      />

      <Box marginBottom={2}>
        <FormHelperText>
          Обновляется вместе с заказами
        </FormHelperText>
      </Box>

      {
        isUsingCommissions &&
        <Box marginBottom={3}>
          <Grid container spacing={6}>
            <Grid item>
              <Box marginBottom={1}>
                <InputLabel>Учитывать в заказах</InputLabel>
              </Box>

              <Select
                value={ordersCommissionsRecording}
                onChange={({target: {value}}) => setOrdersCommissionsRecording(value as CommissionsRecordingType)}
                MenuProps={fixMsAppScrollProps}
              >
                {
                  [CommissionsRecordingType.Nothing, CommissionsRecordingType.Commission]
                    .map(value =>
                      <MenuItem key={value} value={value}>
                        {commissionsRecordingTypeNames[value]}
                      </MenuItem>
                    )
                }
              </Select>
            </Grid>

            <Grid item>
              <Box marginBottom={1}>
                <InputLabel>Учитывать в документах</InputLabel>
              </Box>

              <Select
                value={documentsCommissionsRecording}
                onChange={({target: {value}}) => setDocumentsCommissionsRecording(value as CommissionsRecordingType)}
                MenuProps={fixMsAppScrollProps}
              >
                {
                  valuesOfNumericEnum(CommissionsRecordingType)
                    .map(value =>
                      <MenuItem key={value} value={value}>
                        {commissionsRecordingTypeNames[value]}
                      </MenuItem>
                    )
                }
              </Select>
            </Grid>
          </Grid>
        </Box>
      }
    </>
  }