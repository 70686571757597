import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'

import { MsFormData } from './MoySkladDataForm'
import { MaximumProductStocksForm } from './MaximumProductStocksForm'
import { MoySkladOzonPropertiesSelector } from './MoySkladOzonPropertiesSelector'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useLogic } from '../hooks/storeHook'
import { usePaperStyles } from '../hooks/paperStylesHook'

import { exact } from '../common/tsUtils'
import { formatAccountTitle } from '../common/accountUtils'

import { OzonAccountProps } from '../types/accountProps'
import { EditOzonAccountModel } from '../server/mpsklad_core/Models/EditOzonAccountModel'
import { CommissionsRecordingType } from '../server/mpsklad_core/Entity/Base/CommissionsRecordingType'

export const OzonAccountForm =
  observer(
    ({account}: OzonAccountProps) => {
      const {showSuccess} = useMessages()

      const logic = useLogic()
      const api = useApi()

      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      // TODO: Rework this mess into observables or something
      // TODO: Maybe split requests for every field
      const [isFBS, setFBS] = useState(account?.isFBS ?? true)
      const [isUpdatePrices, setUpdatePrices] = useState(account?.isUpdatePrices ?? false)
      const [isGeneratingPayments, setGeneratingPayments] = useState(account?.isGeneratingPayments ?? false)

      const [isUsingCommissions, setUsingCommissions] = useState(account?.isUsingCommissions ?? false)

      const [ordersCommissionsRecording, setOrdersCommissionsRecording] =
        useState(account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing)

      const [documentsCommissionsRecording, setDocumentsCommissionsRecording] =
        useState(account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing)

      const [apiKey, setApiKey] = useState(account?.apiKey ?? '')
      const [clientId, setClientId] = useState(account?.clientId?.toString() ?? '')
      const [name, setName] = useState(account?.name ?? '')

      const [msData, setMsData] = useState<MsFormData | null>(null)
      const [msSalesReturnStoreId, setMsSalesReturn] = useState<string | undefined>(account?.msSalesReturnStoreId)

      const classes = usePaperStyles()

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          setIsLoading()

          try {
            const formData =
              exact<Omit<EditOzonAccountModel, 'id'>>({
                isFBS,
                isUpdatePrices: isUpdatePrices,
                isGeneratingPayments,
                isUsingCommissions,
                ordersCommissionsRecording,
                documentsCommissionsRecording,
                apiKey,
                clientId: parseInt(clientId),
                name: name || undefined,
                msSalesReturnStoreId,
                msData: msData ?? undefined
              })

            if (account) {
              await logic.editOzonAccount({id: account.id, ...formData})
            } else {
              await logic.createOzonAccount({id: 0, ...formData})
            }

            showSuccess('Сохранено!')
          } catch (e) {
            console.error('Failed to save Ozon account', e)
          } finally {
            setNotLoading()
          }
        }

      return (
        <Box display="flex">
          <Paper className={clsx([classes.paper, classes.paperHalf, classes.paperLeft])}>
            <Box padding={2}>
              <Box marginBottom={3}>
                <Typography variant="h6">
                  {formatAccountTitle(account)}
                </Typography>
              </Box>

              <form noValidate autoComplete="off" onSubmit={onSubmit}>
                <Box marginTop={2}>
                  <TextField
                    id="clientId"
                    label="Id клиента"
                    placeholder="1337"
                    InputLabelProps={{shrink: true}}
                    value={clientId}
                    onChange={e => setClientId(e.target.value)}
                  />
                </Box>

                <Box marginTop={2}>
                  <TextField
                    fullWidth
                    id="apiKey"
                    label="API ключ"
                    placeholder="be0813e9-8383-4c51-9ebf-decafbad013f"
                    InputLabelProps={{shrink: true}}
                    value={apiKey}
                    onChange={e => setApiKey(e.target.value)}
                  />
                </Box>

                <Box marginTop={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Схема работы
                    </FormLabel>

                    <RadioGroup
                      name="isFBS"
                      value={JSON.stringify(isFBS)}
                      onChange={e => setFBS(JSON.parse(e.target.value))}
                    >
                      <FormControlLabel
                        value={JSON.stringify(true)}
                        control={<Radio/>}
                        label="Продажа со своего склада (FBS)"
                      />

                      <FormControlLabel
                        value={JSON.stringify(false)}
                        control={<Radio/>}
                        label="Продажа со склада Ozon (FBO)"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                <Box marginTop={2}>
                  <TextField
                    id="name"
                    label="Название"
                    placeholder="Ozon 1337"
                    InputLabelProps={{shrink: true}}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </Box>

                {
                  account &&
                  <Box marginTop={2}>
                    <MaximumProductStocksForm account={account} onSubmit={api.userSync.setOzonMaximumProductStocks}/>
                  </Box>
                }

                <Box marginTop={5}>
                  <Button variant="contained" type="submit" disabled={isLoading}>
                    {isLoading ? 'Сохраняем...' : 'Сохранить'}
                  </Button>
                </Box>
              </form>
            </Box>
          </Paper>

          <Paper className={clsx([classes.paper, classes.paperRight])}>
            <MoySkladOzonPropertiesSelector
              account={account}
              isLoading={isLoading}
              setMsData={setMsData}

              isUpdatePrices={isUpdatePrices}
              setUpdatePrices={setUpdatePrices}

              isGeneratingPayments={isGeneratingPayments}
              setGeneratingPayments={setGeneratingPayments}

              isUsingCommissions={isUsingCommissions}
              setUsingCommissions={setUsingCommissions}

              ordersCommissionsRecording={ordersCommissionsRecording}
              setOrdersCommissionsRecording={setOrdersCommissionsRecording}

              documentsCommissionsRecording={documentsCommissionsRecording}
              setDocumentsCommissionsRecording={setDocumentsCommissionsRecording}

              msSalesReturnStoreId={msSalesReturnStoreId}
              setMsSalesReturn={setMsSalesReturn}
            />
          </Paper>
        </Box>
      )
    })