import React from 'react'

import { OzonProductsTable, OzonProductsTableProps } from './OzonProductsTable'

import { ozonProductImportColumns } from '../types/ozonColumns'

export type OzonProductImportTableProps =
  Pick<OzonProductsTableProps, 'products'>

export const OzonProductImportTable =
  ({products}: OzonProductImportTableProps) =>
    <OzonProductsTable products={products} columns={ozonProductImportColumns}/>