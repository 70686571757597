import React from 'react'
import { observer } from 'mobx-react-lite'

import { renderOzonInput } from './OzonProductSinglePicker'
import { ProductMultiPicker, ProductMultiPickerBaseProps } from '../ProductMultiPicker'

import { useLogic } from '../../hooks/storeHook'

export const OzonProductMultiPicker =
  observer(
    (props: ProductMultiPickerBaseProps) => {
      const {loadOzonProductOptions} = useLogic()

      return <ProductMultiPicker renderInput={renderOzonInput} loadProductOptions={loadOzonProductOptions} {...props}/>
    })