//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MyProductsTableOrderColumn {
  name = 0,
  createdAt = 1,
  ConnectOzon = 2,
  ConnectWb = 3,
  ConnectYandexMarket = 4,
  moySkladId = 5,
  type = 6,
  code = 7,
  article = 8,
  price = 9,
  basePrice = 10,
  ozonMsMinimalPrice = 11,
  ozonMsPrice = 12,
  ozonMsBasePrice = 13,
  wbMsPrice = 14,
  wbMsBasePrice = 15,
  yandexMarketMsPrice = 16,
  yandexMarketMsBasePrice = 17,
  ozonSyncEnabled = 18,
  ozonArticle = 19,
  ozonIsVisible = 20,
  wbSyncEnabled = 21,
  wbChrtId = 22,
  yandexMarketSyncEnabled = 23,
  yandexMarketShopSku = 24
}
