import React from 'react'
import { observer } from 'mobx-react-lite'

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

import { TableDateCell } from './tableCells/TableDateCell'

import { formatPrice } from '../common/numberUtils'
import { formatDateOnly } from '../common/dateTimeUtility'

import { OzonPrintOrderModel } from '../server/mpsklad_core/Models/OzonPrintOrderModel'

export const OzonPreviewPrintTable =
  observer(
    ({orders}: {orders: OzonPrintOrderModel[] | null}) => {
      if (orders === null) {
        return <Box padding={2}>
          <CircularProgress size={20}/>
        </Box>
      }

      if (!orders.length) {
        return <Box padding={2}>Нет заказов за выбранный период</Box>
      }

      return (
        <>
          <Box padding={2}>
            Выбрано заказов: {orders.length}
          </Box>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Дата создания
                  </TableCell>

                  <TableCell>
                    Плановая дата отгрузки
                  </TableCell>

                  <TableCell>
                    Id в Ozon
                  </TableCell>

                  <TableCell>
                    Номер отправления
                  </TableCell>

                  <TableCell>
                    Цена продажи, ₽
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  orders.map(order =>
                    <TableRow key={order.ozonId} hover tabIndex={-1}>
                      <TableDateCell row={order}/>
                      <TableCell>
                        {
                          order.shipmentDate
                          ? formatDateOnly(new Date(order.shipmentDate))
                          : <span>&mdash;</span>
                        }
                      </TableCell>

                      <TableCell>
                        {order.ozonId}
                      </TableCell>

                      <TableCell>
                        {order.postingNumber}
                      </TableCell>

                      <TableCell>
                        {formatPrice(order.price)}
                      </TableCell>
                    </TableRow>)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </>)
    })