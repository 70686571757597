import React from 'react'

import { PropFilterItemBase } from './StringPropFilter'

export type BooleanPropFilterItem = PropFilterItemBase & {
  isBoolean: true

  onText?: string

  offText?: string

  allowMixed?: boolean

  mixedText?: string
}

export const BooleanPropFilter =
  ({placeholder, value, onText, offText, allowMixed, mixedText, setValue}: BooleanPropFilterItem) =>
    <select
      value={value ?? resetBooleanValue}
      onChange={({target: {value}}) => setValue(value === resetBooleanValue ? undefined : value)}
    >
      <option value={resetBooleanValue}>{placeholder}</option>

      <option value={JSON.stringify(true)}>{onText ?? 'Включена'}</option>

      <option value={JSON.stringify(false)}>{offText ?? 'Отключена'}</option>

      {
        allowMixed &&
        <option value={JSON.stringify(null)}>{mixedText ?? 'Вкл + Откл'}</option>
      }
    </select>

/**
 * NOTE: This exists because undefined doesn't work (obviously)
 */
const resetBooleanValue = 'none'