//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { FeedsFileModel } from '../Models/FeedsFileModel';

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class FeedController
{
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  
  private readonly axios: AxiosInstance
  
  ozonFile(accountId: number, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.FileStreamResult*/>(`Feed/OzonFile/${encodeUrlParameter(accountId)}`, _config)
  }
  

  ozonFileDescription(accountId: number, _config?: AxiosRequestConfig) : Promise<FeedsFileModel>
  {
    return this.axios.get<FeedsFileModel>(`Feed/OzonFileDescription/${encodeUrlParameter(accountId)}`, _config).then(getAxiosData)
  }
  

  /** Gets the full relative URL for the action */
  ozonFileUrl(accountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/Feed/OzonFile/${encodeUrlParameter(accountId)}`
  }
  

  /** Gets the full relative URL for the action */
  ozonFileDescriptionUrl(accountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/Feed/OzonFileDescription/${encodeUrlParameter(accountId)}`
  }
  

}
