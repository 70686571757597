import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button, Paper, Typography } from '@material-ui/core'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'

export const MoySkladWebhookPanel =
  observer(
    () => {
      const {showSuccess, showError} = useMessages()

      const {syncStore} = useStore()

      const logic = useLogic()
      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const onLoad = async () => {
        setIsLoading()

        try {
          await logic.verifyMoySkladWebhooks()
          showSuccess('Вебхуки проверены успешно!')
        } catch (e) {
          console.error('Failed to verify MoySklad webhooks', e)
          showError('Не удалось проверить')
        } finally {
          setNotLoading()
        }
      }

      if (!syncStore.moySkladAccount) {
        return null
      }

      return (
        <Paper>
          <Box padding={2}>
            <Box marginBottom={3}>
              <Typography variant="h6">
                Отслеживание статуса заказа
              </Typography>
            </Box>

            <Button variant="contained" onClick={onLoad}>
              {
                isLoading ? 'Проверяем...' : 'Проверить статус вебхука'
              }
            </Button>
          </Box>
        </Paper>
      )
    })