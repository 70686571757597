import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { WbProductsTable } from './WbProductsTable'

import { useLogic, useStore } from '../hooks/storeHook'

import { wbProductColumns } from '../types/wbColumns'
import { WbAccountRequiredProps } from '../types/accountProps'

export const WbAccountInfo =
  observer(
    ({account}: WbAccountRequiredProps) => {
      const {productStore: {createWbProductsTable}} = useStore()
      const logic = useLogic()

      const wbProducts = useMemo(
        () => createWbProductsTable(account.id),
        [createWbProductsTable, account])

      useEffect(() => logic.wbProductReloaders.addHandler(wbProducts.reload), [logic.wbProductReloaders, wbProducts])

      return <WbProductsTable products={wbProducts} columns={wbProductColumns}/>
    })