import React, { ChangeEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { CircularProgress, TableCell } from '@material-ui/core'

import { IOSSwitch } from './IOSSwitch'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'

import { MyRowProps } from '../types/tableTypes'
import { UserModel } from '../server/mpsklad_core/Models/UserModel'

export const UserSyncCell =
  observer(
    ({row}: MyRowProps<UserModel>) => {
      const {setUserSync} = useLogic()

      const {showError} = useMessages()

      const [isLoading, setIsLoading] = useState(false)

      const onChange =
        async (e: ChangeEvent<HTMLInputElement>) => {
          const allowSync = e.target.checked

          setIsLoading(true)

          try {
            await setUserSync(row.id, allowSync)
          } catch (e) {
            console.log('Failed to toggle user sync', e)
            showError('Ошибка при сохранении')
          } finally {
            setIsLoading(false)
          }
        }

      return (
        <TableCell className="table-border">
          {
            isLoading
            ? <CircularProgress size={31}/>
            : <IOSSwitch checked={row.allowSync} onChange={onChange}/>
          }
        </TableCell>
      )
    })