import React from 'react'

import { TableCell, TableRow } from '@material-ui/core'

import { SyncStateCell } from './SyncStateCell'

import { IntegrationTypeName } from '../types/integrationTypeName'
import { SyncStateModel } from '../server/mpsklad_core/Models/SyncStateModel'

export type SyncStateRowProps = {
  title: string

  type: IntegrationTypeName

  state: SyncStateModel | undefined
}

export const SyncStateRow =
  ({title, type, state}: SyncStateRowProps) =>
    <TableRow>
      <TableCell>
        {title}
      </TableCell>

      <SyncStateCell type={type} state={state}/>
    </TableRow>