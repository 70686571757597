import React from 'react'
import { observer } from 'mobx-react-lite'

import { ProductSyncSwitch } from '../ProductSyncSwitch'

import { useLogic } from '../../hooks/storeHook'

import { WbProductModel } from '../../server/mpsklad_core/Models/WbProductModel'

export type ProductWbSyncSwitchProps = {
  msProductId: number

  wbProduct: WbProductModel
}

export const ProductWbSyncSwitch =
  observer(
    ({msProductId, wbProduct}: ProductWbSyncSwitchProps) => {
      const {setWbProductSync} = useLogic()

      return (
        <ProductSyncSwitch
          syncEnabled={wbProduct.syncEnabled}
          onChange={syncEnabled => setWbProductSync(msProductId, wbProduct.id, syncEnabled)}
        />
      )
    })