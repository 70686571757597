import React, { ChangeEvent, useRef } from 'react'
import { observer } from 'mobx-react-lite'

import uniqueId from 'lodash/uniqueId'
import { CircularProgress, Switch } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { WbAccountRequiredProps } from '../types/accountProps'

export const WbToggleApiProducts =
  observer(
    ({account}: WbAccountRequiredProps) => {
      // UI is inverted
      const isChecked = !account.disableApiProducts

      const logic = useLogic()
      const {showSuccess} = useMessages()
      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()

      const switchName = useRef(uniqueId('WbToggleApiProducts_')).current

      const onChange =
        async (e: ChangeEvent<HTMLInputElement>) => {
          setSubmitting()

          // UI is inverted
          const newIsDisabled = !e.target.checked

          try {
            await logic.setDisableWbApiProducts(account.id, newIsDisabled)

            showSuccess(newIsDisabled ? 'Отключено' : 'Включено')
          } finally {
            setSubmitted()
          }
        }

      return isSubmitting
             ? <CircularProgress size={19}/>
             : <Switch
               size="small"
               color="primary"
               name={switchName}
               title={isChecked ? 'Отключить' : 'Включить'}
               disabled={isSubmitting}
               checked={isChecked}
               onChange={onChange}
             />
    })