import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { ImportScreen } from './ImportScreen'
import { OzonScreenTabs } from './OzonScreenTabs'
import { OzonProductImportTable } from './OzonProductImportTable'

import { useApi, useStore } from '../hooks/storeHook'

import { OzonProductsTableData } from '../store/productStore'

import { OzonProductColumnId } from '../types/ozonColumns'
import { OzonAccountRequiredProps } from '../types/accountProps'
import { OzonProductModel } from '../server/mpsklad_core/Models/OzonProductModel'

export const OzonImportTabsScreen = () =>
  <OzonScreenTabs ScreenComponent={OzonImportScreen}/>

export const OzonImportScreen =
  observer(
    ({account}: OzonAccountRequiredProps) => {
      const api = useApi()

      const {productStore: {createOzonProductsTable}} = useStore()

      const products = useMemo(
        () => createOzonProductsTable(account.id),
        [createOzonProductsTable, account])

      return (
        <ImportScreen<OzonProductsTableData, OzonProductModel, OzonProductColumnId>
          account={account}
          type="Ozon"
          importProductsFunc={api.import.importProductsOzon}
          importStocksFunc={api.import.importStocksOzon}
          products={products}
          TableComponent={OzonProductImportTable}
        />
      )
    })