import { useState } from 'react'

export enum LoadState {
  Initial,
  Loading,
  Success,
  Error
}

export const useLoadState =
  (initialState = LoadState.Initial) => {
    const [loadState, setLoadState] = useState(initialState)

    return {
      loadState,
      setInitial: () => setLoadState(LoadState.Initial),
      setLoading: () => setLoadState(LoadState.Loading),
      setSuccess: () => setLoadState(LoadState.Success),
      setError: () => setLoadState(LoadState.Error)
    } as const
  }