let lastAppHeight = -1
let hasAppHeightInterval = false

export const sendMoySkladAppHeight =
  () => {
    const appHeight = document.body.scrollHeight

    if (appHeight === lastAppHeight) {
      return
    }

    // NOTE: Can't add an offset - height is 100% CSS, so it keeps reducing at each tick
    window.parent?.postMessage({height: appHeight}, '*')
    lastAppHeight = appHeight
  }

/**
 * Sends MS app height to the parent frame immediately and starts an interval to do it periodically.
 * Idempotent.
 * Returns a cleanup function.
 */
export const startMoySkladAppHeightInterval =
  (): VoidFunction => {
    sendMoySkladAppHeight()

    if (hasAppHeightInterval) {
      console.trace('Deduped MS app height interval')
      return () => {}
    }

    const appHeightIntervalId = window.setInterval(sendMoySkladAppHeight, 300)
    hasAppHeightInterval = true

    return () => {
      clearInterval(appHeightIntervalId)
      hasAppHeightInterval = false
    }
  }

/**
 * Fixes scroll jumps within MS app iframe when certain buttons are pressed.
 * Use on Menu, Popover, Select (via MenuProps), TablePagination (via MenuProps inside SelectProps).
 * TODO: Refactor Selects into a custom component
 */
export const fixMsAppScrollProps = {
  autoFocus: false,
  disableAutoFocus: true
}