import React, { FormEvent, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { LockOutlined } from '@material-ui/icons'
import { Avatar, Button, Container, Grid, TextField, Typography } from '@material-ui/core'

import { appAuthRoutes } from '../common/appRoutes'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'
import { useAuthFormStyles } from '../hooks/authFormStylesHook'

export const LoginScreen =
  observer(() => {
    const formClasses = useAuthFormStyles()

    const logic = useLogic()
    const {hasAuth} = useStore()

    const {showSuccess, showError} = useMessages()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setLoading, setFinished] = useBoolState()

    if (hasAuth) {
      return null
    }

    const onSubmit = async (e: FormEvent) => {
      e.preventDefault()

      setLoading()

      try {
        await logic.login(email, password)
        showSuccess('Вход выполнен!')
      } catch (e) {
        // NOTE: Don't update state on success - this component will be unmounted
        setFinished()
        showError('Не удалось войти')
      }
    }

    return (
      <Container component="main" maxWidth="xs">
        <div className={formClasses.innerContainer}>
          <Avatar className={formClasses.avatar}>
            <LockOutlined/>
          </Avatar>

          <Typography component="h1" variant="h5">
            Вход
          </Typography>

          <form className={formClasses.form} onSubmit={onSubmit}>
            <TextField
              required
              autoFocus
              fullWidth
              variant="outlined"
              margin="normal"
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              onChange={e => setEmail(e.target.value)}
            />

            <TextField
              required
              fullWidth
              variant="outlined"
              margin="normal"
              id="password"
              name="password"
              label="Пароль"
              type="password"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
            />

            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              className={formClasses.submit}
              disabled={isLoading}
            >
              {isLoading ? '...' : 'Войти'}
            </Button>

            <Grid container justifyContent="space-between">
              <Grid item>
                <NavLink exact to={appAuthRoutes.Register} className={formClasses.formLink}>
                  Регистрация
                </NavLink>
              </Grid>

              <Grid item>
                <NavLink exact to={appAuthRoutes.PasswordReset} className={formClasses.formLink}>
                  Забыли пароль?
                </NavLink>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    )
  })