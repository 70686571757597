import React from 'react'
import { observer } from 'mobx-react-lite'

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'

import { SyncStateRow } from './SyncStateRow'
import { SyncStatesTable } from './SyncStatesTable'

import { usePaperStyles } from '../hooks/paperStylesHook'

import { IntegrationTypeName } from '../types/integrationTypeName'
import { SyncStatesModel } from '../server/mpsklad_core/Models/SyncStatesModel'

export type ImportStatusTableProps = {
  type: Exclude<IntegrationTypeName, 'MoySklad'>

  states: SyncStatesModel
}

export const ImportStatusTable =
  observer(
    ({type, states}: ImportStatusTableProps) => {
      const classes = usePaperStyles()

      return (
        <Paper className={classes.paper}>
          <TableContainer component={'div'}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Статус последнего импорта
                  </TableCell>

                  <TableCell>
                    <SyncStatesTable>
                      <SyncStateRow
                        title={'Импорт товаров'}
                        type={type}
                        state={states.importProductsState}
                      />

                      <SyncStateRow
                        title={'Импорт остатков'}
                        type={type}
                        state={states.importStocksState}
                      />
                    </SyncStatesTable>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )
    })