import { cellStyleBreakNormal } from './myStorageColumns'
import { MyProductColumnGroupId } from './myProductColumnGroups'
import { MyProductColumnId, MyYandexMarketProductColumnId, selectColumnId } from './myProductColumnIds'
import { createColumnGroup, createColumns, GroupedMyColumnsParam, MyColumnParam } from './columnGroupCreator'

import { ProductImageCell2 } from '../components/ProductImageCell'
import { TableSelectCell } from '../components/tableCells/TableSelectCell'
import { YandexMarketProductSyncLogsCell } from '../components/tableCells/StoreProductSyncLogsCell'
import { MyProductYandexMarketSyncCell } from '../components/tableCells/MyProductYandexMarketSyncCell'

import { formatBalance, formatPriceOrEmpty } from '../common/numberUtils'

import { MyProductModel } from '../server/mpsklad_core/Models/MyProductModel'
import { YandexMarketProductModel } from '../server/mpsklad_core/Models/YandexMarketProductModel'

export const myYandexMarketColumns =
  createColumnGroup<MyProductColumnId, MyProductModel>()(
    MyProductColumnGroupId.YandexMarket, [{
      title: 'Синх. Yandex.Market',
      id: 'yandexMarketSyncEnabled',
      sortable: true,
      CellComponent: MyProductYandexMarketSyncCell
    }, {
      title: 'Ваш Sku',
      id: 'yandexMarketShopSku',
      sortable: true,
      accessor: _ => _.yandexMarketProducts.map(p => p.shopSku)
    }, {
      title: 'Артикул от производителя',
      id: 'yandexMarketVendorCode',
      accessor: _ => _.yandexMarketProducts.map(p => p.vendorCode)
    }, {

      title: 'Цена',
      id: 'yandexMarketPrice',
      accessor: _ => _.yandexMarketProducts.map(p => formatPriceOrEmpty(p.price))
    }, {
      title: 'Цена без скидки',
      id: 'yandexMarketBasePrice',
      accessor: _ => _.yandexMarketProducts.map(p => formatPriceOrEmpty(p.basePrice))
    }, {
      title: 'Остаток',
      id: 'yandexMarketBalance',
      accessor: _ => _.yandexMarketProducts.map(p => formatBalance(p.balance))
    }] satisfies GroupedMyColumnsParam<MyYandexMarketProductColumnId, MyProductModel>) // Ensure filtering of ids

export type YandexMarketProductColumnId =
  keyof Pick<YandexMarketProductModel, 'imageUrl' | 'barcodes' | 'name' | 'price' | 'basePrice' | 'balance'
    | 'hasMsProduct' | 'shopSku' | 'vendorCode'>
  | 'logs' | typeof selectColumnId

const yandexMarketCommonProductColumns = [{
  id: 'imageUrl',
  title: 'Фото',
  CellComponent: ProductImageCell2
}, {
  id: 'shopSku',
  title: 'Ваш Sku'
}, {
  id: 'vendorCode',
  title: 'Артикул от производителя'
}, {
  id: 'barcodes',
  title: 'Штрихкоды',
  cellStyle: cellStyleBreakNormal
}, {
  id: 'name',
  title: 'Наименование'
}, {
  id: 'price',
  title: 'Цена',
  accessor: _ => formatPriceOrEmpty(_.price)
}, {
  id: 'basePrice',
  title: 'Цена без скидки',
  accessor: _ => formatPriceOrEmpty(_.basePrice)
}, {
  id: 'balance',
  title: 'Остаток',
  accessor: _ => formatBalance(_.balance)
}, {
  id: 'hasMsProduct',
  title: 'Сматчен с МоимСкладом'
}]  satisfies Array<MyColumnParam<YandexMarketProductColumnId, YandexMarketProductModel>>

export const yandexMarketProductColumns =
  createColumns<YandexMarketProductColumnId, YandexMarketProductModel>(
    ...yandexMarketCommonProductColumns, {
      id: 'logs',
      title: 'Логи',
      CellComponent: YandexMarketProductSyncLogsCell
    })

export const yandexMarketProductImportColumns =
  createColumns<YandexMarketProductColumnId, YandexMarketProductModel>({
    id: selectColumnId,
    title: 'Выбрать',
    CellComponent: TableSelectCell
  }, ...yandexMarketCommonProductColumns)