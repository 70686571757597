import React from 'react'
import { observer } from 'mobx-react-lite'

import { SyncStateRow } from './SyncStateRow'
import { SyncStatesProps } from './PullProductsStateRow'

export const PushStocksStateRow =
  observer(
    ({type, syncStates}: SyncStatesProps) =>
      <SyncStateRow title="Отправка остатков" type={type} state={syncStates.pushStocksState}/>
  )