import React from 'react'

import { Box, CircularProgress, Dialog, Table, TableBody, TableContainer } from '@material-ui/core'

import { SyncLogRow } from './SyncLogRow'
import { SyncLogsTableHead } from './SyncLogsTableHead'

import { SyncErrorHookData } from '../hooks/syncErrorHook'

import { required } from '../common/objectUtils'
import { SyncLogModel } from '../server/mpsklad_core/Models/SyncLogModel'

export type SyncLogsDialogProps =
  Pick<SyncErrorHookData, 'isLoading'> & {
  logs: SyncLogModel[] | null

  reset: VoidFunction
}

export const SyncLogsDialog =
  ({isLoading, logs, reset}: SyncLogsDialogProps) => {
    if (!isLoading && !logs) {
      return null
    }

    return (
      <Dialog open onClose={reset} maxWidth={false}>
        {
          isLoading
          ?
          <Box padding={3}>
            <CircularProgress size={32}/>
          </Box>
          :
          required(logs).length
          ?
          <TableContainer>
            <Table>
              <SyncLogsTableHead/>

              <TableBody>
                {
                  required(logs).map(log => <SyncLogRow key={log.id} log={log}/>)
                }
              </TableBody>
            </Table>
          </TableContainer>
          :
          <Box padding={3}>
            Нет логов.
          </Box>
        }
      </Dialog>
    )
  }