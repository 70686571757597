import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, FormHelperText } from '@material-ui/core'

import { MoySkladAttributeSelect } from './MoySkladAttributeSelect'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'

import { MoySkladOrderFeeType } from '../server/mpsklad_core/Models/MoySkladOrderFeeType'
import { MoySkladAttributeModel } from '../server/mpsklad_core/Models/MoySkladAttributeModel'

export type MoySkladAttributesFormProps = {
  msAttributes: MoySkladAttributeModel[]

  onCreate: (createdAttr: MoySkladAttributeModel) => void

  onReset: VoidFunction
}

export const MoySkladAttributesForm =
  observer(
    ({msAttributes, onCreate, onReset}: MoySkladAttributesFormProps) => {
      const {showSuccess, showError} = useMessages()

      const {syncStore: {moySkladAccount}} = useStore()

      const logic = useLogic()

      /**
       * Ensures that the given id exists in MS.
       * @param id
       */
      const validateMsAttributeId =
        (id: string | undefined): string | undefined =>
          msAttributes.some(_ => _.id === id) ? id : undefined

      const [commissionId, setCommissionId] = useState(validateMsAttributeId(moySkladAccount?.commissionFeeAttributeId))

      const [magistralId, setMagistralId] = useState(validateMsAttributeId(moySkladAccount?.magistralFeeAttributeId))
      const [lastMileId, setLastMileId] = useState(validateMsAttributeId(moySkladAccount?.lastMileFeeAttributeId))
      const [restId, setRestId] = useState(validateMsAttributeId(moySkladAccount?.logisticsRestFeeAttributeId))

      const [isLoading, setLoading, setLoaded] = useBoolState()
      const [isCreating, setCreating, setCreated] = useBoolState()

      const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setLoading()

        try {
          await logic.setMsOrderAttributes({
            commissionFeeAttributeId: commissionId,
            magistralFeeAttributeId: magistralId,
            lastMileFeeAttributeId: lastMileId,
            logisticsRestFeeAttributeId: restId
          })

          showSuccess('Поля сохранены!')
          onReset()
        } catch (e) {
          console.error('Failed to set MoySklad attrs', e)
        } finally {
          setLoaded()
        }
      }

      const onCreateAttr =
        (feeType: MoySkladOrderFeeType) =>
          async () => {
            setCreating()

            try {
              const createdAttr = await logic.api.userSync.createMoySkladOrderFeeAttribute(feeType)

              onCreate(createdAttr)

              switch (feeType) {
                case MoySkladOrderFeeType.Commission:
                  setCommissionId(createdAttr.id)
                  break

                case MoySkladOrderFeeType.Magistral:
                  setMagistralId(createdAttr.id)
                  break

                case MoySkladOrderFeeType.LastMile:
                  setLastMileId(createdAttr.id)
                  break

                case MoySkladOrderFeeType.Assembly:
                  setRestId(createdAttr.id)
                  break
              }
            } catch (e) {
              console.error('Failed to created MS attr', e)
              showError('Не удалось создать поле')
            } finally {
              setCreated()
            }
          }

      return (
        <form onSubmit={onSubmit}>
          <MoySkladAttributeSelect
            title="Комиссия"
            value={commissionId}
            setValue={setCommissionId}
            options={msAttributes}
            isCreating={isCreating}
            onCreate={onCreateAttr(MoySkladOrderFeeType.Commission)}
          />

          <MoySkladAttributeSelect
            title="Магистраль"
            value={magistralId}
            setValue={setMagistralId}
            options={msAttributes}
            isCreating={isCreating}
            onCreate={onCreateAttr(MoySkladOrderFeeType.Magistral)}
          />

          <MoySkladAttributeSelect
            title="Последняя миля"
            value={lastMileId}
            setValue={setLastMileId}
            options={msAttributes}
            isCreating={isCreating}
            onCreate={onCreateAttr(MoySkladOrderFeeType.LastMile)}
          />

          <MoySkladAttributeSelect
            title="Сборка"
            value={restId}
            setValue={setRestId}
            options={msAttributes}
            isCreating={isCreating}
            onCreate={onCreateAttr(MoySkladOrderFeeType.Assembly)}
          />

          <FormHelperText style={{marginBottom: 15}}>
            Можно выбрать только поля с типом "число дробное (double)"
          </FormHelperText>

          <Button
            type="button"
            variant="contained"
            onClick={onReset}
            disabled={isLoading}
            style={{marginRight: 10}}
          >
            Отменить
          </Button>

          <Button type="submit" variant="contained" color="secondary" disabled={isLoading}>
            Сохранить
          </Button>
        </form>
      )
    })