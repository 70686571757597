//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WbProductProp {
  ChrtId = 1,
  SupplierArticle = 2,
  Barcode = 3,
  Name = 4
}
