import React from 'react'

import { observer } from 'mobx-react-lite'
import {
  Box,
  FormControlLabel,
  Switch
} from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'

import { StoreAccountBaseProps } from '../types/accountProps'
import { SetAccountToggleModel } from '../server/mpsklad_core/Models/SetAccountToggleModel'

export type AutoReturnEnabledSwitchProps =
  StoreAccountBaseProps
  & {
  onSubmit: (model: SetAccountToggleModel) => Promise<void>
}

export const AutoReturnEnabledSwitch =
  observer(
    ({account, onSubmit}: AutoReturnEnabledSwitchProps) => {
      const logic = useLogic()

      const {showSuccess} = useMessages()

      const onChange =
        async () => {
          const newValue = !account.isAutoReturnEnabled

          await onSubmit({
            accountId: account.id,
            isEnabled: newValue
          })

          await logic.loadUserAccounts()

          showSuccess(`Создание документа ${newValue ? 'включено' : 'отключено'}`)
        }

      return (
        <Box>
          <FormControlLabel
            label="Создание документа о возврате"
            control={
              <Switch
                color="secondary"
                checked={account.isAutoReturnEnabled}
                onChange={onChange}
              />
            }
          />
        </Box>
      )
    })