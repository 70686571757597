import { useMemo } from 'react'
import sum from 'lodash/sum'

import { cellStyleBreakNormal } from './myStorageColumns'
import { createColumns, MyColumnParam } from './columnGroupCreator'

import { MoySkladProductSyncLogsCell } from '../components/tableCells/MoySkladProductSyncLogsCell'

import { msProductTypeNames } from '../store/logic/productUtils'

import { formatBalance, formatPriceOrEmpty } from '../common/numberUtils'

import { useStore } from '../hooks/storeHook'

import { TableColumn } from './tableTypes'
import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { MoySkladProductModel } from '../server/mpsklad_core/Models/MoySkladProductModel'

export type MoySkladProductColumnId =
  keyof Pick<MoySkladProductModel, 'moySkladId' | 'type' | 'code' | 'article' | 'name' | 'barcodes'
    | 'price' | 'basePrice' | 'ozonMinimalPrice' | 'ozonPrice' | 'ozonBasePrice' | 'wbPrice' | 'wbBasePrice'
    | 'yandexMarketPrice' | 'yandexMarketBasePrice' | 'balance'>
  | 'logs'
  | 'balanceTotal'

export type MoySkladProductColumn =
  TableColumn<MoySkladProductColumnId, MoySkladProductModel>

export const useMoySkladProductColumns =
  (): MoySkladProductColumn[] => {
    const {allowedIntegrations} = useStore()

    return useMemo(
      (): MoySkladProductColumn[] => {
        const columnParams: MyColumnParam<MoySkladProductColumnId, MoySkladProductModel>[] = [{
          id: 'moySkladId',
          title: 'GUID'
        }, {
          id: 'type',
          title: 'Тип',
          accessor: _ => msProductTypeNames[_.type]
        }, {
          id: 'code',
          title: 'Код'
        }, {
          id: 'article',
          title: 'Артикул'
        }, {
          id: 'name',
          title: 'Наименование'
        }, {
          id: 'barcodes',
          title: 'Штрихкоды',
          cellStyle: cellStyleBreakNormal
        }, {
          id: 'price',
          title: 'Цена',
          accessor: _ => formatPriceOrEmpty(_.price)
        }, {
          id: 'basePrice',
          title: 'Цена без скидки',
          accessor: _ => formatPriceOrEmpty(_.basePrice)
        }]

        if (allowedIntegrations[StoreType.Ozon]) {
          columnParams.push({
            id: 'ozonMinimalPrice',
            title: 'Цена Ozon минимальная',
            accessor: _ => formatPriceOrEmpty(_.ozonMinimalPrice)
          })

          columnParams.push({
            id: 'ozonPrice',
            title: 'Цена Ozon',
            accessor: _ => formatPriceOrEmpty(_.ozonPrice)
          })

          columnParams.push({
            id: 'ozonBasePrice',
            title: 'Цена Ozon без скидки',
            accessor: _ => formatPriceOrEmpty(_.ozonBasePrice)
          })
        }

        if (allowedIntegrations[StoreType.Wildberries]) {
          columnParams.push({
            id: 'wbPrice',
            title: 'Цена WB',
            accessor: _ => formatPriceOrEmpty(_.wbPrice)
          })

          columnParams.push({
            id: 'wbBasePrice',
            title: 'Цена WB без скидки',
            accessor: _ => formatPriceOrEmpty(_.wbBasePrice)
          })
        }

        if (allowedIntegrations[StoreType.YandexMarket]) {
          columnParams.push({
            id: 'yandexMarketPrice',
            title: 'Цена Yandex.Market',
            accessor: _ => formatPriceOrEmpty(_.yandexMarketPrice)
          })

          columnParams.push({
            id: 'yandexMarketBasePrice',
            title: 'Цена Yandex.Market без скидки',
            accessor: _ => formatPriceOrEmpty(_.yandexMarketBasePrice)
          })
        }

        columnParams.push({
          id: 'balance',
          title: 'Остатки на сматченных складах',
          accessor: row => row.balance.map(_ => `${_.storeName}: ${formatBalance(_.balance)}`)
        })

        columnParams.push({
          id: 'balanceTotal',
          title: 'Общий остаток',
          titleHint: 'Для сматченных складов',
          accessor: row => formatBalance(sum(row.balance.map(_ => _.balance)))
        })

        columnParams.push({
          id: 'logs',
          title: 'Логи',
          CellComponent: MoySkladProductSyncLogsCell
        })

        return createColumns(...columnParams)
      },
      [allowedIntegrations])
  }