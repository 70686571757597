import React from 'react'

import { Box, FormLabel } from '@material-ui/core'

import { RequestValidationErrors } from '../common/requestValidationError'

export type FormValidationErrorsProps = {
  validationErrors: RequestValidationErrors | null
}

export const FormValidationErrors =
  ({validationErrors}: FormValidationErrorsProps) =>
    validationErrors
    ? <>
      {
        Object.values(validationErrors).flatMap(
          (error, index) =>
            <Box key={index}>
              <FormLabel error>
                {error}
              </FormLabel>
            </Box>)
      }
    </>
    : null