import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { AutoTabs } from './AutoTabs'
import { WbFilesTab } from './WbFilesTab'
import { WbLabelsTab } from './WbLabelsTab'
import { WbScreenTabs } from './WbScreenTabs'

import { WbAccountRequiredProps } from '../types/accountProps'

export const WbPrintTabsScreen = () =>
  <WbScreenTabs ScreenComponent={WbPrintScreen}/>

// TODO: Generalize with OzonPrintScreen?
export const WbPrintScreen =
  observer(
    ({account}: WbAccountRequiredProps) => {
      const titles = ['Этикетки', 'Акты', 'Файлы']

      return (
        account.isFBS ?
        <AutoTabs secondary titles={titles}>
            <WbLabelsTab wbAccountId={account.id}/>
            
            <Box padding={2}>Coming soon™</Box>

            <WbFilesTab wbAccountId={account.id}/>
        </AutoTabs>
        :
        <Box padding={3}>
          Печать этикеток недоступна при схеме работы FBW.
        </Box>
      )
    })