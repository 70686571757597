import React from 'react'

import { ListItem, ListItemText } from '@material-ui/core'

import { DropdownItemProps } from './DropdownButton'

import { useMessages } from '../hooks/snackbarHooks'

export type ProductsSyncButtonsProps = DropdownItemProps
  & {
  messages: SyncMessages

  toggleSync: (syncEnabled: boolean) => Promise<void>
}

export type SyncMessages = {
  on: string
  off: string

  onSuccess: string
  offSuccess: string

  onError: string
  offError: string
}

export const ProductsSyncButtons =
  ({
     messages, toggleSync,
     setClosed, setLoading, setFinished
   }: ProductsSyncButtonsProps) => {
    const {showSuccess, showError} = useMessages()

    const onButtonClick =
      async (syncEnabled: boolean) => {
        setClosed()
        setLoading()

        try {
          await toggleSync(syncEnabled)
          showSuccess(syncEnabled ? messages.onSuccess : messages.offSuccess)
        } catch (e) {
          console.log('Failed to sync products', e)
          showError(syncEnabled ? messages.onError : messages.offError)
        } finally {
          setFinished()
        }
      }

    return <>
      <ListItem button onClick={() => onButtonClick(true)}>
        <ListItemText primary={messages.on}/>
      </ListItem>

      <ListItem button onClick={() => onButtonClick(false)}>
        <ListItemText primary={messages.off}/>
      </ListItem>
    </>
  }