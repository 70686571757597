import React from 'react'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { NoteAdd, People } from '@material-ui/icons'
import { Avatar, Box } from '@material-ui/core'

import bag from '../assets/bag.svg'
import box from '../assets/box.svg'
import chart from '../assets/chart.svg'
import cloud from '../assets/cloud.svg'
import logo from '../assets/MPsklad.svg'
import printer from '../assets/printer.svg'

import { ManualLink } from './ManualLink'
import { UserSyncWarning } from './UserSyncWarning'
import { ImpersonationBar } from './ImpersonationBar'
import { StoreMatchingWarningBar } from './StoreMatchingWarningBar'
import { MoySkladAppTrialNotificationBar } from './MoySkladAppTrialNotificationBar'
import { WbApiTokensExpirationNotificationBars } from './WbApiTokensExpirationNotificationBars'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

import { MoySkladAppState } from '../server/mpsklad_core/Models/MoySkladAppState'

export const AppNavigation =
  observer(() => {
    const {user, hasMoySkladApp} = useStore()

    if (!user) {
      return null
    }

    switch (user.moySkladAppState) {
      case null:
      case MoySkladAppState.Active:
        break

      case MoySkladAppState.Suspended:
      case MoySkladAppState.SettingsRequired:
        return null
    }

    return (
      <div className="header">
        <ImpersonationBar/>

        <UserSyncWarning/>

        <MoySkladAppTrialNotificationBar/>

        <WbApiTokensExpirationNotificationBars/>

        <StoreMatchingWarningBar/>

        <div className="nav-right-outer">
          <img src={logo} alt="logo"/>

          <Box className="nav-right-inner">
            <NavLink exact to={appRoutes.Home} className="nav" activeClassName="active">
              <img src={box} alt=""/>
              <p>Товары</p>
            </NavLink>

            <NavLink to={appRoutes.Diffs} className="nav" activeClassName="active">
              <img src={bag} alt=""/>
              <p>Обороты</p>
            </NavLink>

            <NavLink to={appRoutes.Orders.base} className="nav" activeClassName="active">
              <img src={chart} alt=""/>
              <p>Заказы</p>
            </NavLink>

            <NavLink to={appRoutes.Sync.base} className="nav" activeClassName="active">
              <img src={cloud} alt=""/>
              <p>{hasMoySkladApp ? 'Аккаунты' : 'Синхронизация'}</p>
            </NavLink>

            <NavLink to={appRoutes.Print.base} className="nav" activeClassName="active">
              <img src={printer} alt=""/>
              <p>Печать</p>
            </NavLink>

            <NavLink to={appRoutes.Import.base} className="nav" activeClassName="active">
              <NoteAdd/>
              <p>Импорт</p>
            </NavLink>

            {
              user.isSuperAdmin &&
              <NavLink to={appRoutes.AdminUsers} className="nav" activeClassName="active">
                <People style={{marginRight: 10}}/>
                <p>Пользователи</p>
              </NavLink>
            }
          </Box>
        </div>

        <div className="nav-left">
          <ManualLink/>

          {
            !hasMoySkladApp &&
            <NavLink
              to={appRoutes.Account}
              className="nav"
              activeClassName="active"
              style={{display: 'flex', alignItems: 'center'}}
            >
              <Box style={{marginRight: '10px', textAlign: 'right'}}>
                <p>Администратор</p>
                <p>{user.email}</p>
              </Box>

              <Avatar style={{background: '#025383', margin: 0}}/>
            </NavLink>
          }
        </div>
      </div>
    )
  })