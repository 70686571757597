import React from 'react'
import { observer } from 'mobx-react-lite'

import { TablePagination2 } from './TablePagination'

import { useStore } from '../hooks/storeHook'

export const MyProductsTopPagination =
  observer(() => {
    const {productStore: {myProducts}} = useStore()

    return (
      <div
        style={{
          maxWidth: 'calc(100vw - 40px)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        }}
      >
        <TablePagination2
          table={myProducts}
          labelRowsPerPage="Товаров на странице:"
          style={{position: 'absolute', top: 0, right: 0}}
        />
      </div>
    )
  })