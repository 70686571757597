import React from 'react'

import { LoadOrdersForm } from './LoadOrdersForm'

import { useApi } from '../hooks/storeHook'
import { OzonAccountIdProps } from '../types/accountProps'

export const OzonLoadOrdersForm =
  ({ozonAccountId}: OzonAccountIdProps) => {
    const api = useApi()

    return <LoadOrdersForm accountId={ozonAccountId} loadOrders={api.userSync.loadOzonOrders}/>
  }