import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { useStyles } from './SyncStateCell'
import { SyncEnabledSwitch } from './SyncEnabledSwitch'

import { IntegrationTypeName, SyncAccountInfo } from '../types/integrationTypeName'

export type SyncEnabledCellProps<I extends IntegrationTypeName> = {
  info: SyncAccountInfo<I>
}

export const SyncEnabledCell =
  observer(
    <I extends IntegrationTypeName>
    ({info: {type, account}}: SyncEnabledCellProps<I>) => {
      const classes = useStyles()

      return (
        <TableCell className={account.syncEnabled ? classes.successCell : classes.disabledCell}>
          <SyncEnabledSwitch type={type} account={account}/>
        </TableCell>
      )
    })