import { required } from './objectUtils'

export type RouteWithParams<TParams extends Record<string, unknown>> = {
  template: string,
  route: (routeArgs: TParams) => string
}

export type RouteWithChildren<TBase extends string, TChildren extends string[]> = {
  base: TBase
} & {
  [TChild in TChildren[number]]: ChildRoute<TBase, TChild>
}

export type ChildRoute<TBase extends string, TChild extends string> =
  `${TBase}/${TChild}`

export type RoutesPath<TRoutes> = RoutePath<TRoutes[keyof TRoutes]>

export type RoutePath<TRoute> =
  TRoute extends string
  ? TRoute
  : TRoute extends {template: string}
    ? TRoute['template']
    : TRoute extends RouteWithChildren<infer TBase, infer TChildren>
      ? TBase | ChildRoute<TBase, TChildren[number]>
      : unknown

export const createRoute =
  <TParams extends Record<string, unknown>>() =>
    <TParamName extends (keyof TParams)[]>
    (templateFragments: TemplateStringsArray, ...routeParamNames: TParamName): RouteWithParams<TParams> => {
      const templateFragmentsFiltered = templateFragments.filter(s => !!s)

      if (routeParamNames.length === 0) {
        throw new Error('No route params')
      }

      if (routeParamNames.length !== templateFragmentsFiltered.length) {
        throw new Error(`Lengths of route params and template fragments do not match`)
      }

      if (!templateFragmentsFiltered[0].startsWith('/')) {
        throw new Error(`The route template must start with a slash: ${templateFragmentsFiltered[0]}`)
      }

      for (const templateFragment of templateFragmentsFiltered) {
        if (!/^[\w/]+$/.test(templateFragment)) {
          throw new Error(`Bad route template fragment: ${templateFragment}`)
        }
      }

      return {
        template: templateFragmentsFiltered.map((fragment, index) => `${fragment}:${String(routeParamNames[index])}`)
                                           .join(''),
        route:
          routeParams => {
            const routeEntries = Object.entries(routeParams)

            if (routeEntries.length !== templateFragmentsFiltered.length) {
              throw new Error('Lengths of route params and template fragments do not match')
            }

            return templateFragmentsFiltered.map(
              (fragment, index) => {
                const routeParamName = routeParamNames[index]
                const routeEntry = required(routeEntries.find(_ => _[0] === routeParamName))

                return fragment + String(routeEntry[1])
              }).join('')
          }
      }
    }

//  Spread params are required for proper type inference
export const createRouteWithChildren =
  <TBase extends string>(base: TBase) =>
    <TChildren extends string[]>
    (...subRoutes: TChildren)
      : RouteWithChildren<TBase, TChildren> =>
      subRoutes.reduce(
        (result, subRoute) => ({
          ...result,
          [subRoute]: `${base}/${subRoute}`
        }),
        {base}) as RouteWithChildren<TBase, TChildren>