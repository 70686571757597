import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Check } from '@material-ui/icons'
import { Box, FormControlLabel, Grid, IconButton, Switch, TextField } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'

import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'
import { SetMaximumProductStocksModel } from '../server/mpsklad_core/Entity/SetMaximumProductStocksModel'

export interface MaximumProductStocksFormProps {
  account: StoreAccountModelBase

  onSubmit: (model: SetMaximumProductStocksModel) => Promise<void>
}

export const MaximumProductStocksForm =
  observer(
    ({account, onSubmit}: MaximumProductStocksFormProps) => {
      const logic = useLogic()

      const {showSuccess} = useMessages()

      const [maximumProductStocks, setMaximumProductStocks] = useState(account.maximumProductStocks)

      const isMaximumEnabled = maximumProductStocks != null

      useEffect(
        () => setMaximumProductStocks(account.maximumProductStocks),
        [account.maximumProductStocks])

      // TODO: Loading indication
      const onSave =
        async (newMaximumProductStocks: number | undefined) => {
          setMaximumProductStocks(newMaximumProductStocks)

          await onSubmit({
            storeAccountId: account.id,
            maximumProductStocks: newMaximumProductStocks
          })

          await logic.loadUserAccounts()

          showSuccess(`Ограничение ${newMaximumProductStocks ? 'включено' : 'отключено'}`)
        }

      const onChangeIsMaximumEnabled =
        async () => {
          if (isMaximumEnabled) {
            // Save disabled state immediately
            await onSave(undefined)
          } else {
            // Save enabled state only when the save button is pressed
            setMaximumProductStocks(123)
          }
        }

      return (
        <Box>
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <FormControlLabel
                label="Антипарсинг"
                control={
                  <Switch
                    color="secondary"
                    checked={isMaximumEnabled}
                    onChange={onChangeIsMaximumEnabled}
                  />
                }
              />
            </Grid>

            {
              isMaximumEnabled &&
              <>
                <Grid item>
                  <Grid item>
                    <TextField
                      type="number"
                      id="maximumProductStocks"
                      label="Максимальный остаток"
                      placeholder="123"
                      InputLabelProps={{shrink: true}}
                      value={maximumProductStocks}
                      onChange={e => setMaximumProductStocks(parseInt(e.target.value))}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <IconButton title="Сохранить" onClick={() => onSave(maximumProductStocks)}>
                    <Check color="primary"/>
                  </IconButton>
                </Grid>
              </>
            }
          </Grid>
        </Box>
      )
    })