import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell, TableRow } from '@material-ui/core'

import { required } from '../common/objectUtils'

import { MyTableRowProps } from '../types/tableTypes'
import { CellAccessorFunc } from '../types/cellAccessor'

export const MyTableRow =
  observer(
    <TColumnId extends string, TRow extends object>
    ({table, visibleColumns, row}: MyTableRowProps<TColumnId, TRow>) =>
      <TableRow
        hover
        className="table-border"
        tabIndex={-1}
        selected={table.isRowSelected(row)}
      >
        {
          visibleColumns.map(
            ({id, accessor, cellStyle, CellComponent}) =>
              CellComponent
              ? <CellComponent key={id} row={row} table={table}/>
              :
              <TableCell key={id} className="table-border" style={cellStyle?.(row)}>
                {renderCellAccessor(row, accessor)}
              </TableCell>)
        }
      </TableRow>
  )

const renderCellAccessor =
  <TRow extends object>(row: TRow, accessor: CellAccessorFunc<TRow>): ReactNode =>
    renderCellValue(required(accessor)(row))

export const renderCellValue =
  (value: unknown): ReactNode => {
    if (value instanceof Array) {
      // Render each item on a separate line
      return <>
        {
          value.map((item, index) => <div key={index}>{renderCellValueItem(item)}</div>)
        }
      </>
    }

    return renderCellValueItem(value)
  }

const renderCellValueItem =
  (value: unknown): ReactNode => {
    if (typeof value === 'boolean') {
      return value ? 'Да' : 'Нет'
    }

    return value as ReactNode
  }