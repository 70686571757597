import { createColumns } from './columnGroupCreator'

import { WbFileCell } from '../components/WbFileCell'
import { TableDateCell } from '../components/tableCells/TableDateCell'

import { formatShipmentDatesRange } from '../common/dateTimeUtility'

import { PrintFileModel } from '../server/mpsklad_core/Models/PrintFileModel'
import { WbPrintFileModel } from '../server/mpsklad_core/Models/WbPrintFileModel'

export type PrintFilesColumnId =
  keyof Pick<WbPrintFileModel, 'createdAt' | 'wbSupplyId'>
  | 'date'
  | 'options'
  | 'link'

export const wbFilesColumns =
  createColumns<PrintFilesColumnId, PrintFileModel>({
    id: 'createdAt',
    title: 'Дата создания',
    sortable: true,
    CellComponent: TableDateCell
  }, {
    id: 'date',
    title: 'Дата заказа',
    sortable: true,
    accessor: formatShipmentDatesRange
  }, {
    id: 'options',
    title: 'Настройки',
    accessor: _ => [
      `Статусы: ${_.statuses}`,
      `Этикеток: ${_.labelCount}`,
      `Заказов: ${_.orderCount}`,
      `Товаров: ${_.productCount}`
    ]
  }, {
    id: 'wbSupplyId',
    title: 'ID поставки',
    sortable: true
  }, {
    id: 'link',
    title: 'Ссылка на файл',
    CellComponent: WbFileCell
  })