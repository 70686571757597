import { onCLS, onFCP, onFID, onLCP, onTTFB, ReportHandler } from 'web-vitals'

export const reportWebVitals =
  () => {
    onCLS(onWebVitalsReport)
    onFID(onWebVitalsReport)
    onFCP(onWebVitalsReport)
    onLCP(onWebVitalsReport)
    onTTFB(onWebVitalsReport)
  }

// TODO: Send these metrics to backend?
const onWebVitalsReport: ReportHandler =
    metrics => console.log('web vitals', metrics)