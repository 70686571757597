import React from 'react'
import { observer } from 'mobx-react-lite'

import { ProductAnyPicker, ProductPickerProps } from '../ProductAnyPicker'

import { useStore } from '../../hooks/storeHook'
import { YandexMarketProductSinglePicker } from './YandexMarketProductSinglePicker'
import { YandexMarketProductMultiPicker } from './YandexMarketProductMultiPicker'

export const YandexMarketProductPicker =
  observer(
    (props: ProductPickerProps) => {
      const {user} = useStore()

      if (!user) {
        throw new Error('Not authorized')
      }

      return (
        <ProductAnyPicker
          allowMultiple={user.allowMultiYandexMarketProducts}
          SinglePicker={YandexMarketProductSinglePicker}
          MultiPicker={YandexMarketProductMultiPicker}
          {...props}
        />
      )
    })