import { Context, DependencyList, useCallback, useContext, useEffect, useRef, useState } from 'react'

/**
 * Use this constant as default context value along with the {@link useContextRequired} hook
 * to check that context is provided at runtime.
 */
export const getContextNotProvidedError =
  <TContext>() => 'Context was not provided!' as unknown as TContext

/**
 * Use this hook along with the {@link getContextNotProvidedError} to check that context is provided at runtime.
 */
export const useContextRequired = <T>(context: Context<T>, contextName: string): T => {
  const providedContext = useContext<T>(context)

  if (providedContext === getContextNotProvidedError<T>()) {
    throw new Error(`The ${contextName} was not provided!`)
  }

  return providedContext
}

/**
 * Kinda like didMount, but not really.
 * Default `deps` are empty.
 * Use carefully with deps, rules of hooks are not enforced.
 * Don't depend on `func` for loader hook - will cause infinite loading.
 */
export const useLazyEffect =
  (func: () => VoidFunction | unknown, deps: DependencyList = []) =>
    useEffect(() => {
        const result = func()

        if (typeof result === 'function') {
          return result as VoidFunction
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      deps)

/**
 * Kinda like willUnmount, but not really.
 * Default `deps` are empty.
 */
export const useLazyUnEffect =
  (func: () => unknown, deps: DependencyList = []) =>
    useEffect(() =>
        () => {
          func()
        },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      deps)

export const useBoolState = (initialValue = false) => {
  const [value, setValue] = useState(initialValue)

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])

  return [value, setTrue, setFalse] as const
}

export const useToggleState = (initialValue = false) => {
  const [value, setValue] = useState(initialValue)

  const toggleValue = useCallback(() => setValue(!value), [value])

  return [value, toggleValue] as const
}

export const usePrevious = <T>(value: T) => {
  const valueRef = useRef<T>(value)

  useEffect(() => {
      valueRef.current = value
    },
    [value, valueRef])

  return valueRef.current
}

export const useIsMounted = () => {
  // Can't use state, because it's updated after unmount
  // It also needs to be a box with the value inside
  const isMounted = useRef(true)

  useLazyUnEffect(() => isMounted.current = false)

  return isMounted
}