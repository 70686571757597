import React from 'react'

import { Link, TableCell } from '@material-ui/core'

import { formatShipmentDatesRange } from '../common/dateTimeUtility'

import { MyRowProps } from '../types/tableTypes'
import { PrintFileModel } from '../server/mpsklad_core/Models/PrintFileModel'

export type PrintFileCellProps =
  MyRowProps<PrintFileModel> & {
  makeUrl: (fileId: string) => string
}

export const PrintFileCell =
  ({row, makeUrl}: PrintFileCellProps) =>
    <TableCell className="table-border">
      <Link href={makeUrl(row.id)} target="_blank" underline="always">
        Этикетки от {formatShipmentDatesRange(row)}
      </Link>
    </TableCell>