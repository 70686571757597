import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountStoresForm } from './AccountStoresForm'

import { useApi } from '../hooks/storeHook'

import { integrationNames } from '../types/integrationTypeName'
import { YandexMarketAccountRequiredProps } from '../types/accountProps'

import { required } from '../common/objectUtils'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'
import { YandexMarketStoreModel } from '../server/mpsklad_core/Models/YandexMarketStoreModel'
import { YandexMarketWarehouseModel } from '../server/mpsklad_core/Models/YandexMarketWarehouseModel'

export const YandexMarketStoresForm =
  observer(({account}: YandexMarketAccountRequiredProps) => {
    const api = useApi()

    return <AccountStoresForm
      integrationName={integrationNames.YandexMarket}
      storeType={StoreType.YandexMarket}
      accountId={account.id}
      loadStores={api.userSync.getYandexMarketStores}
      loadWarehouses={api.userSync.getYandexMarketWarehouses}
      addStore={api.userSync.addYandexMarketStore}
      editStore={api.userSync.editYandexMarketStore}
      removeStore={api.userSync.removeYandexMarketStore}
      whKeySelector={_ => _.id}
      isStoreUsingWarehouse={isYmStoreUsingWarehouse}
      formatWarehouseNameHint={warehouse => warehouse.warehouseGroupName ? ` | ${warehouse.warehouseGroupName}` : ''}
    />
  })

// NOTE: This function is duplicated from backend, update simultaneously
const isYmStoreUsingWarehouse =
  (ymStore: YandexMarketStoreModel, warehouse: YandexMarketWarehouseModel) =>
    ymStore.warehouseId === required(warehouse.id)