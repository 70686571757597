import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

import { SyncStateCell } from './SyncStateCell'
import { SyncEnabledCell } from './SyncEnabledCell'

import { useStore } from '../hooks/storeHook'

import { makeOzonAccountName, makeWbAccountName, makeYandexMarketAccountName } from '../common/accountUtils'

import { IntegrationTypeName, SyncAccountInfo } from '../types/integrationTypeName'

import { StoreType } from '../server/mpsklad_core/Entity/StoreType'

export const StatusSyncTable =
  observer(() => {
    const {
      syncStore: {ozonAccounts, wbAccounts, yandexMarketAccounts, moySkladAccount},
      allowedIntegrations
    } = useStore()

    const syncAccountsInfo: SyncAccountInfo<IntegrationTypeName>[] = []

    if (moySkladAccount) {
      syncAccountsInfo.push({
        type: 'MoySklad',
        account: moySkladAccount,
        displayName: 'МойСклад'
      })
    }

    if (allowedIntegrations[StoreType.Ozon]) {
      syncAccountsInfo.push(
        ...ozonAccounts.map(_ => ({
          type: 'Ozon' as const,
          account: _,
          displayName: makeOzonAccountName(_)
        })))
    }

    if (allowedIntegrations[StoreType.Wildberries]) {
      syncAccountsInfo.push(
        ...wbAccounts.map(_ => ({
          type: 'Wildberries' as const,
          account: _,
          displayName: makeWbAccountName(_)
        })))
    }

    if (allowedIntegrations[StoreType.YandexMarket]) {
      syncAccountsInfo.push(
        ...yandexMarketAccounts.map(_ => ({
          type: 'YandexMarket' as const,
          account: _,
          displayName: makeYandexMarketAccountName(_)
        })))
    }

    if (!syncAccountsInfo.length) {
      return <Box padding={3}>Аккаунты не подключены</Box>
    }

    const showStocks = moySkladAccount != null
                       || ozonAccounts.some(_ => _.isFBS)
                       || wbAccounts.some(_ => _.isFBS)
                       || yandexMarketAccounts.some(_ => _.isFBS)

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {/* spaaaaaace */}
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <TableCell key={makeKey(info)}>
                      {info.displayName}
                    </TableCell>)
              }
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>
                Синхронизация
              </TableCell>

              {
                syncAccountsInfo.map(
                  info => <SyncEnabledCell key={makeKey(info)} info={info}/>)
              }
            </TableRow>

            <TableRow>
              <TableCell>
                Получение товаров
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <SyncStateCell
                      key={makeKey(info)}
                      type={info.type}
                      state={info.account.syncStates.pullProductsState}
                    />)
              }
            </TableRow>

            {
              showStocks &&
              <TableRow>
                <TableCell>
                  Получение остатков
                </TableCell>

                {
                  syncAccountsInfo.map(
                    info =>
                      <SyncStateCell
                        key={makeKey(info)}
                        type={info.type}
                        state={info.account.syncStates.pullStocksState}
                      />)
                }
              </TableRow>
            }

            {
              showStocks &&
              <TableRow>
                <TableCell>
                  Отправка остатков
                </TableCell>

                {
                  syncAccountsInfo.map(
                    info =>
                      <SyncStateCell
                        key={makeKey(info)}
                        type={info.type}
                        state={info.account.syncStates.pushStocksState}
                      />)
                }
              </TableRow>
            }

            <TableRow>
              <TableCell>
                Получение заказов
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <SyncStateCell
                      key={makeKey(info)}
                      type={info.type}
                      state={info.account.syncStates.pullOrdersState}
                    />)
              }
            </TableRow>

            <TableRow>
              <TableCell>
                Отправка заказов
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <SyncStateCell
                      key={makeKey(info)}
                      type={info.type}
                      state={info.account.syncStates.pushOrdersState}
                    />)
              }
            </TableRow>

            <TableRow>
              <TableCell>
                Отправка отчётов о реализации
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <SyncStateCell
                      key={makeKey(info)}
                      type={info.type}
                      state={info.account.syncStates.pushRealizationReportsState}
                    />)
              }
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  })

const makeKey =
  ({type, account}: SyncAccountInfo<IntegrationTypeName>) =>
    `${type}_${account.id}`