import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Paper, TextField, Typography } from '@material-ui/core'

import { MyServerTable } from './MyServerTable'

import { useStore } from '../hooks/storeHook'

import { useMoySkladProductColumns } from '../types/moySkladColumns'

export const MoySkladProductsTable =
  observer(() => {
    const {syncStore: {moySkladAccount}, productStore: {moySkladProducts}} = useStore()
    const msProductColumns = useMoySkladProductColumns()

    if (moySkladAccount == null) {
      return null
    }

    return (
      <Paper>
        <Box marginY={2} marginLeft={2} display="inline-flex">
          <Typography variant="h6">
            Товары
          </Typography>

          <Box marginLeft={25}>
            <TextField
              id="search-ms"
              size="small"
              variant="outlined"
              label="Поиск"
              helperText="Id / GUID / Код / Артикул / Наименование / Штрихкод"
              onChange={e => moySkladProducts.setGlobalFilter(e.target.value)}
            />
          </Box>
        </Box>

        <MyServerTable
          tableProps={{size: 'small'}}
          table={moySkladProducts}
          columns={msProductColumns}
          labelNoRows="Нет товаров"
          labelNotFound="Товары не найдены"
          labelRowsPerPage="Товаров на странице:"
        />
      </Paper>
    )
  })