import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountToggleRow } from './AccountToggleRow'

import { StoreAccountToggleRowProps } from '../types/accountProps'

export const StoreRealizationReportsSyncRow =
  observer(
    ({account, onSubmit}: StoreAccountToggleRowProps) =>
      <AccountToggleRow
        title="Синхронизация отчётов о реализации"
        helperText="Создание отчёта комиссионера в МоёмСкладе. В него будут добавляться только товары, для которых включена синхронизация."
        account={account}
        onSubmit={onSubmit}
        getAccountToggle={() => account.isSyncingRealizationReports}
        setAccountToggle={isEnabled => account.isSyncingRealizationReports = isEnabled}
        getSuccessMessage={isEnabled => `Отчёты ${isEnabled ? 'включены' : 'отключены'}`}
      />)