import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { AppRoutePath } from '../common/appRoutes'

export type AppRouteProps = {
  path: AppRoutePath | AppRoutePath[]

  component: RouteProps['component']

  exact?: boolean
}

export const AppRoute =
  (props: AppRouteProps) =>
    <Route {...props}/>