import React from 'react'

// @ts-expect-error no types
import { IMaskInput } from 'react-imask'

import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase'

export const PhoneNumberInput =
  ({value, onAccept, inputRef, ...passProps}: InputBaseComponentProps) =>
    <IMaskInput
      unmask
      lazy={false}
      mask="+{7} (000) 000-00-00"
      inputRef={
        (ref: unknown) => inputRef(
          typeof ref === 'object' && !!ref && 'inputElement' in ref
          ? ref.inputElement
          : null)}
      // NOTE: DO NOT USE onChange TO HANDLE CHANGES! USE onAccept INSTEAD
      onAccept={(unmaskedValue: string) => onAccept(unmaskedValue)}
      {...passProps}
    />