import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { Box } from '@material-ui/core'

import { EditProductsButton } from './EditProductsButton'
import { ExportProductsButton } from './ExportProductsButton'
import { MyProductsPropFilter } from './MyProductsPropFilter'
import { MyProductsColumnsMenu } from './MyProductsColumnsMenu'
import { MatchProductsDropdownButton } from './MatchProductsDropdownButton'

import { useStore } from '../hooks/storeHook'
import { useBoolState, useLazyUnEffect } from '../hooks/commonHooks'

export const MyProductsFilter =
  observer(() => {
    const {
      myProductColumns, hiddenMyProductColumnIds,
      productStore: {myProducts: {selectedRowCount, setGlobalFilter}}
    } = useStore()

    const [isFilterOpen, setFilterOpen, setFilterClosed] = useBoolState()

    const [columnSettingsAnchor, setColumnSettingsAnchor] = useState<HTMLButtonElement | null>(null)

    useLazyUnEffect(() => setGlobalFilter(null))

    const hasHiddenColumns = myProductColumns.some(_ => hiddenMyProductColumnIds.has(_.id))

    return <>
      <Box display="flex" alignItems="center">
        <button
          onClick={isFilterOpen ? setFilterClosed : setFilterOpen}
          className={clsx('btn', isFilterOpen && 'btn_isactive')}
          style={{width: '80px'}}
        >
          Фильтр
        </button>

        <button
          onClick={e => setColumnSettingsAnchor(e.currentTarget)}
          className={clsx(
            'btn',
            columnSettingsAnchor && 'btn_isactive',
            hasHiddenColumns && 'btn_isapplied'
          )}
          style={{width: '80px'}}
        >
          Столбцы
        </button>

        <input
          className="main_input"
          type="text"
          placeholder="Название, код или артикул..."
          onChange={e => setGlobalFilter(e.target.value)}
        />

        {
          selectedRowCount > 0 &&
          <>
            <button
              disabled
              type="button"
              className="button_counter"
            >
              Выбрано: {selectedRowCount}
            </button>

            <EditProductsButton/>
          </>
        }

        <ExportProductsButton/>

        <MatchProductsDropdownButton/>
      </Box>

      {
        isFilterOpen &&
        <MyProductsPropFilter/>
      }

      <MyProductsColumnsMenu anchorEl={columnSettingsAnchor} onClose={() => setColumnSettingsAnchor(null)}/>
    </>
  })