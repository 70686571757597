import React from 'react'
import { observer } from 'mobx-react-lite'

import { StoreTableHasMsProductFilterBase } from './StoreTableHasMsProductFilterBase'

import { ServerTableData } from '../store/serverTableData'

import { StoreProductsTableFilterColumn } from '../server/mpsklad_core/Models/StoreProductsTableFilterColumn'

export type StoreTableHasMsProductFilterProps<TRow extends object, TColumn> = {
  products: ServerTableData<TRow, TColumn, typeof StoreProductsTableFilterColumn, never>
}

export const StoreTableHasMsProductFilter =
  observer(
    <TRow extends object, TColumn>
    ({products}: StoreTableHasMsProductFilterProps<TRow, TColumn>) =>
      <StoreTableHasMsProductFilterBase
        value={products.findPropFilterValue('hasMsProduct')}
        setValue={_ => products.setPropFilter('hasMsProduct', _)}
      />)