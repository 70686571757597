import { MyOrderStatus } from '../server/mpsklad_core/Entity/MyOrderStatus'

// TODO: Generate these mappings in C#
export const myOrderStatusNames: {
  [S in MyOrderStatus]: string
} = {
  [MyOrderStatus.New]: 'Новый',
  [MyOrderStatus.Confirmed]: 'Подтверждён / На сборке',
  [MyOrderStatus.Delivering]: 'Отгружен / Доставляется',
  [MyOrderStatus.Delivered]: 'Доставлен',
  [MyOrderStatus.SellerCancelled]: 'Отменён продавцом',
  [MyOrderStatus.BuyerCancelled]: 'Отменён покупателем',
  [MyOrderStatus.Returned]: 'Возвращён',
  [MyOrderStatus.ReturnDenied]: 'Не принят в СЦ'
}