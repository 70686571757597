import React, { ChangeEvent, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

import { CircularProgress, FormHelperText, Switch, TableCell, TableRow } from '@material-ui/core'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { AccountModelBase } from '../server/mpsklad_core/Models/AccountModelBase'
import { SetAccountToggleModel } from '../server/mpsklad_core/Models/SetAccountToggleModel'

export type AccountToggleRowProps<TAccount extends AccountModelBase> = {
  title: ReactNode

  helperText: ReactNode

  account: TAccount

  onConfirm?: (isEnabled: boolean) => Promise<boolean>

  onSubmit: (model: SetAccountToggleModel) => Promise<void>

  getAccountToggle: () => boolean

  setAccountToggle: (isEnabled: boolean) => void

  getSuccessMessage?: (isEnabled: boolean) => string
}

export const AccountToggleRow =
  observer(
    <TAccount extends AccountModelBase>
    ({
       title, helperText,
       account, getAccountToggle, setAccountToggle, getSuccessMessage,
       onConfirm, onSubmit
     }: AccountToggleRowProps<TAccount>) => {
      const {showSuccess, showError} = useMessages()

      const [isLoading, setLoading, setFinished] = useBoolState()

      const onChange =
        async (e: ChangeEvent<HTMLInputElement>) => {
          const isEnabled = e.target.checked

          setLoading()

          try {
            if (onConfirm && !await onConfirm(isEnabled)) {
              return
            }

            await onSubmit({accountId: account.id, isEnabled})
            setAccountToggle(isEnabled)

            showSuccess(getSuccessMessage?.(isEnabled) ?? (isEnabled ? 'Включёно' : 'Отключено'))
          } catch (e) {
            console.log('Failed to toggle an account flag', e)
            showError('Ошибка при сохранении')
          } finally {
            setFinished()
          }
        }

      return (
        <TableRow>
          <TableCell>
            {title}

            <FormHelperText>
              {helperText}
            </FormHelperText>
          </TableCell>

          <TableCell>
            {
              isLoading
              ? <CircularProgress size={19}/>
              : <Switch
                size="small"
                color="primary"
                checked={getAccountToggle()}
                onChange={onChange}
              />
            }
          </TableCell>
        </TableRow>
      )
    })