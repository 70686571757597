import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Paper, Table, TableBody, TableContainer } from '@material-ui/core'

import { ScreenLoader } from './ScreenLoader'
import { DiffsTableRow } from './DiffsTableRow'
import { DiffsTableHead } from './DiffsTableHead'
import { TablePagination } from './TablePagination'

import { useLogic, useStore } from '../hooks/storeHook'
import { useTablePagination } from '../hooks/tableHooks'
import { usePaperStyles } from '../hooks/paperStylesHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

export const ProductDiffsScreen =
  observer(
    () => {
      const paperClasses = usePaperStyles()

      const {productStore: {productDiffs}, syncStore: {moySkladAccount}} = useStore()

      const {loadProductDiffs} = useLogic()

      const [isLoading, , setLoaded] = useBoolState(true)

      useLazyEffect(() => loadProductDiffs().finally(setLoaded))

      const pagination = useTablePagination()

      if (moySkladAccount == null) {
        return <Box padding={3}>Добавьте аккаунт МоегоСклада для работы с оборотами.</Box>
      }

      if (isLoading) {
        return <ScreenLoader/>
      }

      if (!productDiffs.length) {
        return (
          <Box flex={1} padding={3}>
            Оборотов ещё нет.
          </Box>
        )
      }

      // TODO: Change to MyTable, requires backend updates
      return (
        <div className="body_wrapper_zero">
          <Box display="flex" alignItems="center" marginLeft={2}>
            <h1>
              Обороты
            </h1>
          </Box>

          <Box flex={1} padding={3}>
            <Paper className={paperClasses.paper}>
              <TableContainer>
                <Table size="small">
                  <DiffsTableHead/>

                  <TableBody>
                    {
                      pagination.slicePage(productDiffs)
                                .map(diff => <DiffsTableRow key={diff.id} diff={diff}/>)
                    }
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination count={productDiffs.length} pagination={pagination}/>
            </Paper>
          </Box>
        </div>
      )
    })