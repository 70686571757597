import React from 'react'
import { observer } from 'mobx-react-lite'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'

import clsx from 'clsx'

import { AppRoute } from './AppRoute'
import { AppNavigation } from './AppNavigation'

import { SyncRoute } from './SyncRoute'
import { PrintRoute } from './PrintRoute'
import { OrdersRoute } from './OrdersRoute'
import { ImportRoute } from './ImportRoute'

import { HomeScreen } from './HomeScreen'
import { AccountScreen } from './AccountScreen'
import { SyncLogsScreen } from './SyncLogsScreen'
import { AdminUsersScreen } from './AdminUsersScreen'
import { ProductDiffsScreen } from './ProductDiffsScreen'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

export const AppRouter =
  observer(() => {
    const {hasMoySkladApp} = useStore()

    return (
      <div className={clsx('App', {'ms-app': hasMoySkladApp})}>
        <BrowserRouter>
          <AppNavigation/>

          <Switch>
            <AppRoute exact path={appRoutes.Home} component={HomeScreen}/>

            <AppRoute path={appRoutes.Sync.base} component={SyncRoute}/>

            <AppRoute path={appRoutes.Print.base} component={PrintRoute}/>

            <AppRoute path={appRoutes.Diffs} component={ProductDiffsScreen}/>

            <AppRoute path={appRoutes.Orders.base} component={OrdersRoute}/>

            <AppRoute path={appRoutes.Import.base} component={ImportRoute}/>

            <AppRoute path={appRoutes.Account} component={AccountScreen}/>

            <AppRoute path={appRoutes.AdminUsers} component={AdminUsersScreen}/>

            <AppRoute path={appRoutes.SyncLogs.template} component={SyncLogsScreen}/>

            <Redirect from={appRoutes.MoySkladApp.template} to={appRoutes.Home}/>

            <Redirect from={appRoutes.MoySkladAppOld} to={appRoutes.Home}/>

            <Redirect from={appRoutes.StatusOld} to={appRoutes.Sync.status}/>

            {/* NOTE: Fallback route, must remain the last one */}
            <Redirect from="*" to={appRoutes.Home}/>
          </Switch>
        </BrowserRouter>
      </div>
    )
  })