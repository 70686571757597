import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Button, FormHelperText, Grid, makeStyles } from '@material-ui/core'

import { formatDateOnly } from '../common/dateTimeUtility'
import { AmountInsertStyle, pluralize } from '../common/stringUtility'

import { OrdersAtDateCount } from '../types/ordersAtDateCount'

/**
 * @property {OrdersAtDateCount[]} daysCounts dates must be unique
 */
export interface LabelsPostDateFiltersProps {
  overallCount: number,

  daysCounts: Array<OrdersAtDateCount>,

  onFilter(date: Date | null): void;
}

export const minShipmentDate = new Date('2000')

export const maxShipmentDate = new Date('3000')

/**
 * List of buttons for filtering post's labels
 */
export const LabelsPostDateFilters =
  observer(
    ({overallCount, daysCounts, onFilter}: LabelsPostDateFiltersProps) => {
      const styles = useStyles()

      const [isFiltersActive, setFiltersActive] = useState<Boolean[]>([])

      const [laterCount, setLaterCount] = useState(0)

      function pluralizeShipments(count: number): string {
        return pluralize(count, 'отправление', 'отправления', 'отправлений', AmountInsertStyle.None)
      }

      useEffect(() => {
        const blankArray = new Array(daysCounts.length + 2).fill(false)
        blankArray[0] = true
        setFiltersActive(blankArray)
      }, [daysCounts])

      useEffect(() => {
        setLaterCount(overallCount - daysCounts.reduce(
          (day1, day2) => (
            {date: day1.date, count: day1.count + day2.count}
          ), ({date: maxShipmentDate, count: 0})
        ).count)
      }, [daysCounts, overallCount])

      /**
       * Changing filter button class to active
       */
      function onFilterSelect(filterIndex: number): void {
        setFiltersActive(
          isFiltersActive.map((_, i) => i === filterIndex)
        )
      }

      function onClickFilter(date: Date | null, indexToSelect: number): void {
        if (!isFiltersActive[indexToSelect]) {
          onFilter(date)
          onFilterSelect(indexToSelect)
        }
      }

      return (
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={'auto'}>
            <Button
              variant="outlined"
              color={isFiltersActive[0] ? 'secondary' : 'primary'}
              className={styles.filter_Button}
              onClick={() => onClickFilter(null, 0)}
            >
              <Box>
                <FormHelperText>
                  <b>Все отправления</b>
                </FormHelperText>
                <FormHelperText>
                  <b>{overallCount}</b> {pluralizeShipments(overallCount)}
                </FormHelperText>
              </Box>
            </Button>
          </Grid>

          {
            daysCounts.map(({date, count}, i) =>
              <Grid key={date.valueOf()} item xs={'auto'}>
                <Button
                  variant={`outlined`}
                  color={isFiltersActive[i + 1] ? 'secondary' : 'primary'}
                  className={styles.filter_Button}
                  onClick={() => onClickFilter(date, i + 1)}
                >
                  <Box>
                    <FormHelperText>
                      <b>{formatDateOnly(date)}</b>
                    </FormHelperText>
                    <FormHelperText>
                      <b>{count}</b> {pluralizeShipments(count)}
                    </FormHelperText>
                  </Box>
                </Button>
              </Grid>)
          }

          {
            daysCounts.length > 0 && laterCount > 0 &&
            <Grid item xs={'auto'}>
              <Button
                variant="outlined"
                color={isFiltersActive[isFiltersActive.length - 1] ? 'secondary' : 'primary'}
                className={styles.filter_Button}
                onClick={() => onClickFilter(maxShipmentDate, isFiltersActive.length - 1)}
              >
                <Box>
                  <FormHelperText>
                    <b>Позднее</b>
                  </FormHelperText>
                  <FormHelperText>
                    <b>{laterCount}</b> {pluralizeShipments(laterCount)}
                  </FormHelperText>
                </Box>
              </Button>
            </Grid>
          }
        </Grid>
      )
    })

export const useStyles = makeStyles(
  (/*theme*/) => ({
    filter_Button: {
      textTransform: 'none'
    }
  }))