import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { SyncErrorIcon } from './SyncErrorIcon'

import { integrationNames, IntegrationTypeName } from '../../types/integrationTypeName'

export type SingleProductSyncErrorProps = {
  type: IntegrationTypeName

  product: {syncErrorId?: string} | undefined
}

export const SingleProductSyncError =
  observer(
    ({type, product}: SingleProductSyncErrorProps) => {
      if (!product?.syncErrorId) {
        return null
      }

      return (
        <Box display="flex" flexDirection="row" alignItems="center">
          <span>{integrationNames[type]}:</span>

          <SyncErrorIcon type={type} errorId={product.syncErrorId}/>
        </Box>
      )
    })