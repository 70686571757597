import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useTableStyles =
  makeStyles(theme =>
    createStyles({
      root: {
        width: '100%'
      },
      tableWrap: {
        // For the loader
        position: 'relative'
      },
      loader: {
        width: '100%',
        position: 'absolute',
        zIndex: 1
      },
      table: {
        minWidth: 750,
        maxWidth: '100%',
        // TODO: Cell borders cause unnecessary scroll
        width: 'calc(100% - 1px)',
        overflow: 'auto'
      },
      grayCell: {
        backgroundColor: '#ececec'
      },
      pinkCell: {
        backgroundColor: '#f4cccd'
      },
      sticky: {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: '#fff'
      },
      button: {
        margin: 15
      },
      emptyTable: {
        padding: 20
      },
      cellTopLine: {
        borderTop: '1px solid lightgray'
      },
      cellRightLine: {
        borderRight: '1px solid lightgray'
      },
      mediumCell: {
        padding: theme.spacing(2)
      },
      header: {
        paddingBottom: theme.spacing(3)
      },
      balanceInputDisabled: {
        fontSize: 15,
        minWidth: 50,
        display: 'inline-block',
        cursor: 'pointer',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
      },
      balanceInputRoot: {
        width: 50
      },
      balanceInput: {
        textAlign: 'center'
      },
      diff: {
        fontSize: '0.9em',
        position: 'relative',
        top: -5,
        right: -3
      },
      diffCell: {
        //@ts-expect-error - no types for important
        fontWeight: '700 !important'
      },
      positiveDiff: {
        color: '#8ac72b'
      },
      negativeDiff: {
        color: '#e22724'
      },
      warnCell: {
        background: '#FFEDB0'
      },
      zeroCell: {
        background: '#FCD6D8'
      }
    }))