import React, { ChangeEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { FormControlLabel, Switch } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'

import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { SetAccountToggleModel } from '../server/mpsklad_core/Models/SetAccountToggleModel'

export type ConvertNonRubleOrdersSwitchProps = {
  account: WbAccountModel

  onSubmit: (model: SetAccountToggleModel) => Promise<void>
}

export const ConvertNonRubleOrdersSwitch =
  observer(
    ({account, onSubmit}: ConvertNonRubleOrdersSwitchProps) => {
      const logic = useLogic()

      const {showSuccess} = useMessages()

      const [convertNonRubleOrders, setConvertNonRubleOrders] = useState(account.convertNonRubleOrders)

      const onChange =
        async (e: ChangeEvent<HTMLInputElement>) => {
          const checked = e.target.checked
          setConvertNonRubleOrders(checked)

          try {
            await onSubmit({
              accountId: account.id,
              isEnabled: checked
            })

            await logic.loadUserAccounts()
            showSuccess(`Конвертация ${checked ? 'включена' : 'отключена'}`)
          } catch {
            setConvertNonRubleOrders(!checked)
          }
        }

      return (
        <FormControlLabel
          label="Конвертировать нерублёвые заказы в рубли"
          control={
            <Switch color="secondary" checked={convertNonRubleOrders} onChange={onChange}/>
          }
        />
      )
    })