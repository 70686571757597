import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { FormValidationErrors } from '../FormValidationErrors'
import { OzonProductPicker } from '../Tables/OzonProductPicker'

import { useLogic } from '../../hooks/storeHook'
import { useSubmitWithValidation } from '../../hooks/submitWithValidationHook'

import { makeOzonProductLabel } from '../../store/logic/productUtils'

import { MyRowProps } from '../../types/tableTypes'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'
import { ExternalProductOption } from '../../server/mpsklad_core/Models/ExternalProductOption'

export const MyProductOzonCell =
  observer(
    ({row}: MyRowProps<MyProductModel>) => {
      const {setOzonProducts} = useLogic()

      const {
        submit,
        validationErrors
      } = useSubmitWithValidation(
        (ozonProducts: ExternalProductOption[]) =>
          setOzonProducts(row.id, ozonProducts.map(_ => _.id)))

      const ozonProducts = row.ozonProducts.map(
        _ => ({
          id: _.id,
          label: makeOzonProductLabel(_)
        }))

      return (
        <TableCell className="table-border" style={{padding: 8}}>
          <OzonProductPicker
            size="small"
            msProductId={row.id}
            products={ozonProducts}
            onChange={submit}
          />

          <FormValidationErrors validationErrors={validationErrors}/>
        </TableCell>
      )
    })