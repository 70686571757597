import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { FormControlLabel, Switch } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'

import { StoreAccountBaseProps } from '../types/accountProps'
import { SetAccountToggleModel } from '../server/mpsklad_core/Models/SetAccountToggleModel'

export type VatEnabledSwitchProps =
  StoreAccountBaseProps
  & {
  onSubmit: (model: SetAccountToggleModel) => Promise<void>
}

export const VatEnabledSwitch =
  observer(
    ({account, onSubmit}: VatEnabledSwitchProps) => {
      const logic = useLogic()

      const {showSuccess} = useMessages()

      const [isVatEnabled, setVatEnabled] = useState(account?.isVatEnabled ?? false)

      // TODO: Loading indication
      const onChange =
        async () => {
          const newIsVatEnabled = !isVatEnabled

          setVatEnabled(newIsVatEnabled)

          await onSubmit({
            accountId: account.id,
            isEnabled: newIsVatEnabled
          })

          await logic.loadUserAccounts()

          showSuccess(`Учёт НДС ${newIsVatEnabled ? 'включён' : 'отключен'}`)
        }

      return (
        <FormControlLabel
          label="Включить учёт НДС"
          control={
            <Switch color="secondary" checked={isVatEnabled} onChange={onChange}/>
          }
        />
      )
    })