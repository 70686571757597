import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { AutoTabs } from './AutoTabs'
import { YandexMarketFilesTab } from './YandexMarketFilesTab'
import { YandexMarketLabelsTab } from './YandexMarketLabelsTab'
import { YandexMarketScreenTabs } from './YandexMarketScreenTabs'

import { YandexMarketAccountRequiredProps } from '../types/accountProps'

export const YandexMarketPrintTabsScreen = () =>
  <YandexMarketScreenTabs ScreenComponent={YandexMarketPrintScreen}/>

// TODO: Generalize with WbPrintScreen & OzonPrintScreen?
export const YandexMarketPrintScreen =
  observer(
    ({account}: YandexMarketAccountRequiredProps) =>
      account.isFBS
      ?
      <AutoTabs secondary titles={['Этикетки', 'Акты', 'Файлы']}>
        <YandexMarketLabelsTab yandexMarketAccountId={account.id}/>

        <Box padding={2}>Coming soon™</Box>

        <YandexMarketFilesTab yandexMarketAccountId={account.id}/>
      </AutoTabs>
      :
      <Box padding={3}>
        Печать этикеток доступна только при схеме работы FBS.
      </Box>
  )