import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { AutoTabs } from './AutoTabs'
import { OzonFeedsTab } from './OzonFeedsTab'
import { OzonFilesTab } from './OzonFilesTab'
import { OzonLabelsTab } from './OzonLabelsTab'
import { OzonScreenTabs } from './OzonScreenTabs'

import { OzonAccountRequiredProps } from '../types/accountProps'

export const OzonPrintTabsScreen = () =>
  <OzonScreenTabs ScreenComponent={OzonPrintScreen}/>

// TODO: Generalize with WbPrintScreen?
export const OzonPrintScreen =
  observer(
    ({account}: OzonAccountRequiredProps) => {
      const titles = ['Фиды']

      if (account.isFBS) {
        titles.unshift('Этикетки', 'Акты', 'Файлы')
      }

      return (
        <AutoTabs titles={titles}>
          {
            account.isFBS &&
            <OzonLabelsTab ozonAccountId={account.id}/>
          }

          {
            account.isFBS &&
            <Box padding={2}>Coming soon™</Box>
          }

          {
            account.isFBS &&
            <OzonFilesTab ozonAccountId={account.id}/>
          }

          <OzonFeedsTab ozonAccountId={account.id}/>
        </AutoTabs>
      )
    })