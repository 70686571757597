import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, TextField } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useAuthFormStyles } from '../hooks/authFormStylesHook'

export const PasswordResetEmailForm =
  observer(() => {
    const classes = useAuthFormStyles()

    const logic = useLogic()

    const {showSuccess} = useMessages()

    const [email, setEmail] = useState('')
    const [isLoading, setLoading, setFinished] = useBoolState()

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()

        setLoading()

        try {
          await logic.sendPasswordResetCodeEmail(email)
          showSuccess('Код восстановления отправлен на почту')
        } catch (e) {
          // NOTE: Don't update state on success - this component will be unmounted
          setFinished()
        }
      }

    return (
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          required
          autoFocus
          fullWidth
          variant="outlined"
          margin="normal"
          id="email"
          name="email"
          label="Email"
          autoComplete="email"
          onChange={e => setEmail(e.target.value)}
        />

        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isLoading}
        >
          {isLoading ? '...' : 'Получить код'}
        </Button>
      </form>
    )
  })