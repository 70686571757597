import React from 'react'
import { observer } from 'mobx-react-lite'

import { OzonProductsTableProps } from './OzonProductsTable'
import { StoreTableHasMsProductFilterBase } from './StoreTableHasMsProductFilterBase'

export type OzonTableHasMsProductFilterProps =
  Pick<OzonProductsTableProps, 'products'>

export const OzonTableHasMsProductFilter =
  observer(
    ({products}: OzonTableHasMsProductFilterProps) =>
      <StoreTableHasMsProductFilterBase
        value={products.findPropFilterValue('hasMsProduct')}
        setValue={_ => products.setPropFilter('hasMsProduct', _)}
      />)