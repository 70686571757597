import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { YandexMarketProductsTable } from './YandexMarketProductsTable'

import { useStore } from '../hooks/storeHook'

import { yandexMarketProductColumns } from '../types/yandexMarketColumns'
import { YandexMarketAccountRequiredProps } from '../types/accountProps'

export const YandexMarketAccountInfo =
  observer(
    ({account}: YandexMarketAccountRequiredProps) => {
      const {productStore: {createYandexMarketProductsTable}} = useStore()

      const yandexMarketProducts = useMemo(
        () => createYandexMarketProductsTable(account.id),
        [createYandexMarketProductsTable, account]
      )

      return <YandexMarketProductsTable products={yandexMarketProducts} columns={yandexMarketProductColumns}/>
    })