import React from 'react'
import { observer } from 'mobx-react-lite'

import { SyncStateRow } from './SyncStateRow'
import { SyncStatesProps } from './PullProductsStateRow'

export const PushRealizationReportsStateRow =
  observer(
    ({type, syncStates}: SyncStatesProps) =>
      <SyncStateRow title="Отправка отчётов о реализации" type={type} state={syncStates.pushRealizationReportsState}/>
  )