import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

export class RuDateFnsUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, 'LLLL yyyy', {locale: this.locale})
  }

  getDatePickerHeaderText(date: Date) {
    return format(date, 'dd MMMM', {locale: this.locale})
  }
}