import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid } from '@material-ui/core'

import { YandexMarketSyncInfo } from './YandexMarketSyncInfo'
import { YandexMarketScreenTabs } from './YandexMarketScreenTabs'
import { YandexMarketAccountForm } from './YandexMarketAccountForm'
import { YandexMarketAccountInfo } from './YandexMarketAccountInfo'

import { YandexMarketAccountProps } from '../types/accountProps'

export const YandexMarketSyncTabsScreen = () =>
  <YandexMarketScreenTabs canAddAccount canRemoveAccount ScreenComponent={YandexMarketSyncScreen}/>

export const YandexMarketSyncScreen =
  observer(
    ({account}: YandexMarketAccountProps) =>
      <Box padding={3}>
        <Grid container direction="column" spacing={3}>
          <Grid item lg={10} xl={8}>
            <YandexMarketSyncInfo account={account}/>
          </Grid>

          <Grid item lg={10} xl={8}>
            <YandexMarketAccountForm account={account}/>
          </Grid>

          {
            account !== null &&
            <YandexMarketAccountInfo account={account}/>
          }
        </Grid>
      </Box>
  )