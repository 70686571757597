import { makeStyles } from '@material-ui/core/styles'

export const usePaperStyles =
  makeStyles(theme => ({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    paperHalf: {
      width: '50%'
    },
    paperLeft: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    paperRight: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  }))