import { SyncLogsScreenParams } from '../components/SyncLogsScreen'

import { exactKeyOf } from './tsUtils'
import { createRoute, createRouteWithChildren, RoutesPath } from './routeUtils'

import { MoySkladAppScreenParams } from '../types/moySkladAppTypes'

const moySkladAppRouteBase = '/moySkladApp'

const moySkladAppRoute = {
  template: `${moySkladAppRouteBase}/:${exactKeyOf<MoySkladAppScreenParams>()('appType')}`
} as const

export const appRoutes = {
  Home: '/',
  Diffs: '/diffs',
  // TODO: Children with parameters - integration, accountId. Use the new route creator.
  //  How would the base work?
  Orders: createRouteWithChildren('/orders')('ozon', 'wildberries', 'yandexmarket', 'moySklad'),
  Sync: createRouteWithChildren('/sync')('ozon', 'wildberries', 'yandexmarket', 'moySklad', 'status'),
  Print: createRouteWithChildren('/print')('ozon', 'wildberries', 'yandexmarket'),
  Import: createRouteWithChildren('/import')('ozon', 'wildberries', 'yandexmarket'),
  Account: '/account',
  AdminUsers: '/admin/users',
  // TODO: Replace camelCase with kebab-case for better readability?
  //  MS app is hardcoded in the descriptor
  //  Add redirects for backward compatibility
  SyncLogs: createRoute<SyncLogsScreenParams>()`/syncLogs/${'integration'}/${'accountId'}`,
  MoySkladApp: moySkladAppRoute,
  MoySkladAppOld: moySkladAppRouteBase,
  StatusOld: '/status'
} as const

export const appAuthRoutes = {
  Login: '/login',
  Register: '/register',
  PasswordReset: '/password-reset',
  MoySkladApp: moySkladAppRoute,
  MoySkladAppOld: moySkladAppRouteBase
} as const

export const isMoySkladAppRoute = () =>
  window.location.pathname.toLowerCase().startsWith(moySkladAppRouteBase.toLowerCase())

export type AppRoutePath = RoutesPath<typeof appRoutes>

export type AppAuthRoutePath = RoutesPath<typeof appAuthRoutes>