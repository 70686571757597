import React, { FormEvent } from 'react'
import { autorun, comparer, reaction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { getUnixTime } from 'date-fns'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Paper
} from '@material-ui/core'

import { DateTimeRangePicker } from './DateTimeRangePicker'
import { PrintMsBarcodesCheckbox } from './PrintMsBarcodesCheckbox'
import { YandexMarketPreviewPrintTable } from './YandexMarketPreviewPrintTable'
import { LabelsPostDateFilters, maxShipmentDate, minShipmentDate } from './LabelsPostDateFilters'
import { filterPreviewByShipmentDate, getOrderShipmentDateCounts, LabelsTabStateBase } from './OzonLabelsTab'

import { useApi } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { exact } from '../common/tsUtils'
import { wait } from '../common/commonUtils'
import { dateUtils } from '../common/dateTimeUtility'

import { OrdersAtDateCount } from '../types/ordersAtDateCount'
import { YandexMarketAccountIdProps } from '../types/accountProps'
import { ResponseCancelledError } from '../types/responseCancelledError'

import { YandexMarketPrintOptions } from '../server/mpsklad_core/Models/YandexMarketPrintOptions'
import { YandexMarketPrintOrderModel } from '../server/mpsklad_core/Models/YandexMarketPrintOrderModel'

export type YandexMarketLabelsTabState =
  LabelsTabStateBase & {
  readonly printRequestData: Readonly<YandexMarketPrintOptions>
  readonly previewRequestData: Readonly<YandexMarketPrintOptions>
}

// TODO: Generalize with Ozon instead of copy-pasting?
export const YandexMarketLabelsTab =
  observer(
    ({yandexMarketAccountId}: YandexMarketAccountIdProps) => {
      const api = useApi()

      const {showSuccess} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()

      const options =
        useLocalObservable(() =>
          exact<YandexMarketLabelsTabState>({
            // NOTE: Effects are required for initial values that depend on props or state
            startDate: dateUtils.startOfYesterday,
            endDate: dateUtils.endOfToday,
            maxDate: dateUtils.endOfToday,
            // TODO: Not only this is not controlled, its computation is non-trivial...
            pickedShipmentDate: null,
            shipmentDateMin: minShipmentDate,
            shipmentDateMax: maxShipmentDate,
            useAutoReset: false,
            hasPurchaseList: true,
            hasMsBarcodes: false,
            get printRequestData() {
              return exact<YandexMarketPrintOptions>({
                accountId: yandexMarketAccountId,
                startDateUnix: getUnixTime(this.startDate),
                endDateUnix: getUnixTime(this.endDate),
                shipmentDateMin: this.shipmentDateMin.toISOString(),
                shipmentDateMax: this.shipmentDateMax.toISOString(),
                useAutoReset: this.useAutoReset,
                hasPurchaseList: this.hasPurchaseList,
                hasMsBarcodes: this.hasMsBarcodes
              })
            },
            get previewRequestData(): Readonly<YandexMarketPrintOptions> {
              return exact<YandexMarketPrintOptions>({
                ...this.printRequestData,
                // TODO: Make these filters server-side for absolute consistency
                // TODO: Use startOfDay, endOfDay in Unix-time to preserve client-side timezone on the backend
                // These filters are applied client-side for the preview, thus we request with default values
                shipmentDateMin: minShipmentDate.toISOString(),
                shipmentDateMax: maxShipmentDate.toISOString()
              })
            }
          }))

      const localState =
        useLocalObservable(() => ({
          preview: null as YandexMarketPrintOrderModel[] | null,
          previewWithShipmentFilters: null as YandexMarketPrintOrderModel[] | null,
          get shipmentDateCounts(): OrdersAtDateCount[] {
            return getOrderShipmentDateCounts(this.preview)
          }
        }))

      useLazyEffect(() =>
        autorun(() => {
          const {previewWithShipmentFilters, shipmentDateMin, shipmentDateMax} =
            filterPreviewByShipmentDate(options.pickedShipmentDate, localState.preview, localState.shipmentDateCounts)

          localState.previewWithShipmentFilters = previewWithShipmentFilters
          options.shipmentDateMin = shipmentDateMin
          options.shipmentDateMax = shipmentDateMax
        }))

      // Fetch preview orders from backend when options change
      useLazyEffect(() =>
        reaction(
          () => options.previewRequestData,
          async () => {
            try {
              localState.preview = null
              localState.preview = await api.previewYandexMarketLabelsPreemptive(options.previewRequestData)

              // Reset filter by shipment date when other options change
              // TODO: This is required because LabelsPostDateFilters is not controlled, make it
              options.pickedShipmentDate = null
            } catch (e) {
              if (e instanceof ResponseCancelledError) {
                // Ignore
                return
              } else {
                throw e
              }
            }
          }, {
            fireImmediately: true,
            equals: comparer.shallow
          }))

      const onDateChange =
        (startDate: Date, endDate: Date) => {
          options.startDate = startDate
          options.endDate = endDate
        }

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (isSubmitting) {
            return
          }

          setSubmitting()

          try {
            const {fileUrl, errorCount} = await api.label.printYandexMarket(options.printRequestData)

            if (errorCount === 0) {
              showSuccess('Создано!')
            } else {
              showSuccess(`Создано! Отправлений с ошибками: ${errorCount}.`)
            }

            await wait(500)
            window.location.assign(fileUrl)
          } finally {
            setSubmitted()
          }
        }

      const onPostDateChange =
        (newDate: Date | null) =>
          options.pickedShipmentDate = newDate

      return (
        <Box padding={2}>
          <form onSubmit={onSubmit}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={4}>
                <FormHelperText>
                  Возможна печать до 300 заказов в одном файле.
                </FormHelperText>
              </Grid>

              <Grid item xs={4}>
                Дата поступления заказа
              </Grid>

              <DateTimeRangePicker
                containerProps={{xs: 3}}
                startDate={options.startDate}
                endDate={options.endDate}
                maxDate={options.maxDate}
                onChange={onDateChange}
              />

              <Grid item xs={4}>
                Дата для поставки в Yandex.Market
              </Grid>

              <LabelsPostDateFilters
                overallCount={localState.preview?.length ?? 0}
                daysCounts={localState.shipmentDateCounts}
                onFilter={onPostDateChange}
              />

              <Grid item container xs={6}>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Что печатать
                    </FormLabel>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="feedCheckbox"
                            checked
                            disabled
                          />
                        }
                        label="Лента"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="purchaseListCheckbox"
                            checked={options.hasPurchaseList}
                            onChange={e => options.hasPurchaseList = e.target.checked}
                          />
                        }
                        label="Список покупок"
                      />

                      <PrintMsBarcodesCheckbox
                        checked={options.hasMsBarcodes}
                        onChange={checked => options.hasMsBarcodes = checked}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="autoResetCheckbox"
                          checked={options.useAutoReset}
                          onChange={e => options.useAutoReset = e.target.checked}
                        />
                      }
                      label="Использовать автоматическое обнуление"
                    />

                    <FormHelperText>
                      Распечатанные заказы будут вычеркнуты, и в следующий раз будут взяты только новые заказы.
                    </FormHelperText>
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Button color="secondary" variant="contained" type="submit" disabled={isSubmitting}>
                  {
                    isSubmitting
                    ? 'Создаём...'
                    : 'Создать этикетки'
                  }
                </Button>
              </Grid>

              <Grid item xl={6}>
                <Box marginBottom={2}>
                  Предпросмотр
                </Box>

                <Paper>
                  <YandexMarketPreviewPrintTable orders={localState.previewWithShipmentFilters}/>
                </Paper>
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    })