import { RefObject } from 'react'
import { comparer, reaction, runInAction, when } from 'mobx'
import { SnackbarProvider } from 'notistack'
import { AxiosRequestConfig } from 'axios'
import debounce from 'lodash/debounce'

import { ApiLogic } from './apiLogic'
import { HandlersContainer } from './handlersContainer'

import { Store } from '../store'

import { appRoutes } from '../../common/appRoutes'
import { hasEnumFlag } from '../../common/tsUtils'
import { required } from '../../common/objectUtils'
import { isLocalStorageAllowed } from '../../common/commonUtils'

import { MyProductColumnId } from '../../types/myProductColumnIds'

import { NavStoreType } from '../../server/mpsklad_core/Models/NavStoreType'
import { MoySkladAppType } from '../../server/mpsklad_common/MoySkladAppType'
import { MsProductProp } from '../../server/mpsklad_core/Models/MsProductProp'
import { WbProductProp } from '../../server/mpsklad_core/Models/WbProductProp'
import { UserAuthModel } from '../../server/mpsklad_core/Models/UserAuthModel'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'
import { OzonProductProp } from '../../server/mpsklad_core/Models/OzonProductProp'
import { MoySkladAppState } from '../../server/mpsklad_core/Models/MoySkladAppState'
import { TablePageOptions } from '../../server/mpsklad_core/Models/TablePageOptions'
import { StoreProductModel } from '../../server/mpsklad_core/Models/StoreProductModel'
import { EditWbAccountModel } from '../../server/mpsklad_core/Models/EditWbAccountModel'
import { RegisterModel } from '../../server/mpsklad_core/Models/ApiModels/RegisterModel'
import { EditOzonAccountModel } from '../../server/mpsklad_core/Models/EditOzonAccountModel'
import { MoySkladStatusMapModel } from '../../server/mpsklad_core/Models/MoySkladStatusMapModel'
import { YandexMarketProductProp } from '../../server/mpsklad_core/Models/YandexMarketProductProp'
import { EditMoySkladAccountModel } from '../../server/mpsklad_core/Models/EditMoySkladAccountModel'
import { SetStoreProductsSyncModel } from '../../server/mpsklad_core/Models/SetStoreProductsSyncModel'
import { MoySkladSetAttributesModel } from '../../server/mpsklad_core/Models/MoySkladSetAttributesModel'
import { MyProductsTableOrderColumn } from '../../server/mpsklad_core/Models/MyProductsTableOrderColumn'
import { MyProductsTableFilterColumn } from '../../server/mpsklad_core/Models/MyProductsTableFilterColumn'
import { EditYandexMarketAccountModel } from '../../server/mpsklad_core/Models/EditYandexMarketAccountModel'
import { StoreAccountModelBase } from '../../server/mpsklad_core/Models/StoreAccountModelBase'

export class Logic {
  private readonly hiddenMyProductColumnIdsKey = 'hiddenMyProductColumnIds'

  private readonly store: Store

  private readonly showUiError: (uiMessage: string) => void

  readonly api: ApiLogic

  readonly wbProductReloaders = new HandlersContainer()

  readonly ozonOrderLoaders = new HandlersContainer()

  readonly wbOrderLoaders = new HandlersContainer()

  readonly yandexMarketOrderLoaders = new HandlersContainer()

  readonly msOrderLoaders = new HandlersContainer()

  constructor(store: Store, snackbarRef: RefObject<SnackbarProvider>) {
    this.showUiError = (uiMessage: string) =>
      snackbarRef.current?.enqueueSnackbar(uiMessage, {variant: 'error', autoHideDuration: 7000})

    this.api = new ApiLogic(this.showUiError)

    this.store = store

    this.store.productStore.init(
      this.myProductsPageLoader,
      this.api.product.getOzonPage, this.api.product.getWbPage, this.api.product.getYandexMarketPage,
      this.api.product.getMoySkladPage)

    this.store.orderStore.init(this.api.order.getOzonPage, this.api.order.getWbPage, this.api.order.getYandexMarketPage,
      this.api.order.getMoySkladPage)

    this.store.adminStore.users.init(this.api.admin.users)

    this.loadMyProductColumnIds()
    this.trackMyProductColumnIds()

    this.trackAuthToken()
    this.trackAccounts()
  }

  healthCheck =
    (): Promise<boolean> =>
      this.api.healthCheck().catch(_ => false)

  loadUserAccounts =
    async () => {
      const {ozonAccounts, wbAccounts, yandexMarketAccounts, moySkladAccount} = await this.api.userSync.getSync()

      runInAction(() => {
        this.store.syncStore.ozonAccounts = ozonAccounts
        this.store.syncStore.wbAccounts = wbAccounts
        this.store.syncStore.yandexMarketAccounts = yandexMarketAccounts
        this.store.syncStore.moySkladAccount = moySkladAccount ?? null
      })
    }

  setOzonAccountSync =
    async (syncEnabled: boolean, ozonAccountId: number) => {
      await this.api.userSync.setOzonSync({isEnabled: syncEnabled, accountId: ozonAccountId})

      const account = required(this.store.syncStore.ozonAccounts.find(_ => _.id === ozonAccountId))
      account.syncEnabled = syncEnabled

      if (syncEnabled) {
        setTimeout(this.loadUserAccounts)
      }
    }

  setOzonMultiProducts =
    async (allow: boolean) => {
      await this.api.userSync.setOzonMultiProducts(allow)

      const user = required(this.store.user)
      user.allowMultiOzonProducts = allow
    }

  setWbMultiProducts =
    async (allow: boolean) => {
      await this.api.userSync.setWbMultiProducts(allow)

      const user = required(this.store.user)
      user.allowMultiWbProducts = allow
    }

  setWbAccountSync =
    async (syncEnabled: boolean, wbAccountId: number) => {
      await this.api.userSync.setWbSync({isEnabled: syncEnabled, accountId: wbAccountId})

      const account = required(this.store.syncStore.wbAccounts.find(_ => _.id === wbAccountId))
      account.syncEnabled = syncEnabled

      if (syncEnabled) {
        setTimeout(this.loadUserAccounts)
      }
    }

  setYandexMarketAccountSync =
    async (syncEnabled: boolean, yandexMarketAccountId: number) => {
      await this.api.userSync.setYandexMarketSync({isEnabled: syncEnabled, accountId: yandexMarketAccountId})

      const account = required(this.store.syncStore.yandexMarketAccounts.find(_ => _.id === yandexMarketAccountId))
      account.syncEnabled = syncEnabled

      if (syncEnabled) {
        setTimeout(this.loadUserAccounts)
      }
    }

  setYandexMarketMultiProducts =
    async (allow: boolean) => {
      await this.api.userSync.setYandexMarketMultiProducts(allow)

      const user = required(this.store.user)
      user.allowMultiYandexMarketProducts = allow
    }

  setMoySkladAccountSync =
    async (syncEnabled: boolean) => {
      await this.api.userSync.setMoySkladSync(syncEnabled)

      const account = required(this.store.syncStore.moySkladAccount)
      account.syncEnabled = syncEnabled

      if (syncEnabled) {
        setTimeout(this.loadUserAccounts)
      }
    }

  setMsOrderAttributes =
    async (model: MoySkladSetAttributesModel) => {
      await this.api.userSync.setMoySkladAttributes(model)

      setTimeout(this.loadUserAccounts)
    }

  setOzonProductSync =
    async (msProductId: number, ozonProductId: number, syncEnabled: boolean) => {
      const product = await this.api.product.setOzonSync({
        msProductId,
        storeProductId: ozonProductId,
        syncEnabled,
        ...this.store.homeNavRequired
      })

      this.store.productStore.replaceProduct(product)
      setTimeout(this.loadMyProductsInfo)
    }

  setWbProductSync =
    async (msProductId: number, wbProductId: number, syncEnabled: boolean) => {
      const product = await this.api.product.setWbSync({
        msProductId,
        storeProductId: wbProductId,
        syncEnabled,
        ...this.store.homeNavRequired
      })

      this.store.productStore.replaceProduct(product)
      setTimeout(this.loadMyProductsInfo)
    }

  setYandexMarketProductSync =
    async (msProductId: number, yandexMarketProductId: number, syncEnabled: boolean) => {
      const product = await this.api.product.setYandexMarketSync({
        msProductId,
        storeProductId: yandexMarketProductId,
        syncEnabled,
        ...this.store.homeNavRequired
      })

      this.store.productStore.replaceProduct(product)
      setTimeout(this.loadMyProductsInfo)
    }

  syncUser =
    async () => {
      const response = await this.api.syncCurrentUser()

      await this.loadUserAccounts()

      return response
    }

  loadMyProductsInfo =
    async () => {
      this.store.productStore.myProductsInfo = await this.api.product.getMyInfo(this.store.homeNavRequired)
    }

  private loadMyProductsInfoDebounced =
    debounce(this.loadMyProductsInfo, 50)

  reloadMyProductsInfo =
    () => {
      this.store.productStore.myProductsInfo = null
      this.loadMyProductsInfoDebounced()
    }

  private myProductsPageLoader =
    (options: TablePageOptions<typeof MyProductsTableFilterColumn, typeof MyProductsTableOrderColumn>,
     config: AxiosRequestConfig | undefined) => {
      const {tab, ...homeNavOptions} = this.store.homeNavRequired

      return this.api.product.getMyPage({
        ...options,
        ...homeNavOptions,
        productsStateTab: tab
      }, config)
    }

  refreshMyProductsTab =
    () => this.store.productStore.myProducts.load()

  loadProductDiffs =
    async () => {
      if (this.store.syncStore.moySkladAccount != null) {
        this.store.productStore.productDiffs = await this.api.product.getDiffs()
      }
    }

  loadOzonProductOptions =
    (msProductId: number | undefined, searchTerm: string | undefined) =>
      hasEnumFlag(this.store.homeNavRequired.storeType, NavStoreType.Ozon)
      ? this.api.product.getOzonProductOptions({
        externalAccountId: this.store.homeNavRequired.accountId,
        msProductId,
        searchTerm
      })
      : Promise.resolve([])

  loadWbProductOptions =
    (msProductId: number | undefined, searchTerm: string | undefined) =>
      hasEnumFlag(this.store.homeNavRequired.storeType, NavStoreType.Wildberries)
      ? this.api.product.getWbProductOptions({
        externalAccountId: this.store.homeNavRequired.accountId,
        msProductId,
        searchTerm
      })
      : Promise.resolve([])

  loadYandexMarketProductOptions =
    (msProductId: number | undefined, searchTerm: string | undefined) =>
      hasEnumFlag(this.store.homeNavRequired.storeType, NavStoreType.YandexMarket)
      ? this.api.product.getYandexMarketProductOptions({
        externalAccountId: this.store.homeNavRequired.accountId,
        msProductId,
        searchTerm
      })
      : Promise.resolve([])

  checkAuth =
    async () => {
      // Split the variable for proper IDE analysis
      let user: UserAuthModel

      try {
        user = await this.api.auth.check()
      } catch (e) {
        console.error('Auth check failed', e)
        this.store.user = null
        return
      }

      if (user.isImpersonating && user.moySkladAppState != null && user.moySkladAppState !== MoySkladAppState.Active) {
        this.showUiError(`Неактивное приложение МоегоСклада: ${MoySkladAppState[user.moySkladAppState]}`)
        user.moySkladAppState = MoySkladAppState.Active
      }

      this.store.user = user
      await this.loadUserAccounts()
    }

  private checkMoySkladAppState =
    async () => {
      if (this.store.user?.moySkladAppState === MoySkladAppState.SettingsRequired) {
        this.store.user.moySkladAppState = await this.api.auth.checkMoySkladAppState()
      }
    }

  private ensureNoAuth = () => {
    if (this.store.hasAuth) {
      throw new Error('Already has auth!')
    }
  }

  login =
    async (email: string, password: string) => {
      this.ensureNoAuth()

      await this.api.auth.login({email, password})
      await this.checkAuth()
    }

  loginMoySkladApp =
    async (appType: MoySkladAppType, contextKey: string) => {
      this.ensureNoAuth()

      this.store.authToken = await this.api.auth.loginMoySkladApp({appType, contextKey})
      await this.checkAuth()
    }

  register =
    async (model: RegisterModel) => {
      this.ensureNoAuth()

      await this.api.auth.register(model)
      await this.checkAuth()
    }

  logout =
    async () => {
      await this.api.auth.logOut()
      this.store.authToken = null

      // Reload is simpler than resetting the store
      window.location.assign(appRoutes.Home)
    }

  sendPasswordResetCodeEmail =
    async (email: string) => {
      this.ensureNoAuth()

      await this.api.auth.requestPasswordReset({email})

      this.store.passwordResetStore.setEmail(email)
    }

  verifyResetCode =
    async (resetCode: string) => {
      this.ensureNoAuth()

      const {passwordResetStore} = this.store

      await this.api.auth.verifyResetCode({
        email: required(passwordResetStore.email),
        resetCode
      })

      passwordResetStore.setResetCode(resetCode)
    }

  resetPassword =
    async (newPassword: string) => {
      this.ensureNoAuth()

      const {passwordResetStore} = this.store

      await this.api.auth.resetPassword({
        email: required(passwordResetStore.email),
        resetCode: required(passwordResetStore.resetCode),
        newPassword
      })

      passwordResetStore.setFinished()
      await this.checkAuth()
    }

  setOzonProducts =
    async (msProductId: number, ozonProductIds: number[]) => {
      const product = this.store.productStore.findProduct(msProductId)

      product.ozonProducts =
        await this.api.product.setOzonProducts({
          msProductId,
          externalProductIds: ozonProductIds,
          externalAccountId: this.store.homeNavRequired.accountId
        })

      setTimeout(this.loadMyProductsInfo)
    }

  setWbProducts =
    async (msProductId: number, wbProductIds: number[]) => {
      const product = this.store.productStore.findProduct(msProductId)

      product.wbProducts =
        await this.api.product.setWbProducts({
          msProductId,
          externalProductIds: wbProductIds,
          externalAccountId: this.store.homeNavRequired.accountId
        })

      setTimeout(this.loadMyProductsInfo)
    }

  setYandexMarketProducts =
    async (msProductId: number, yandexMarketProductIds: number[]) => {
      const product = this.store.productStore.findProduct(msProductId)

      product.yandexMarketProducts =
        await this.api.product.setYandexMarketProducts({
          msProductId,
          externalProductIds: yandexMarketProductIds,
          externalAccountId: this.store.homeNavRequired.accountId
        })

      setTimeout(this.loadMyProductsInfo)
    }

  ozonSyncSelectedProducts =
    (syncEnabled: boolean) =>
      this.syncSelectedProducts(syncEnabled, NavStoreType.Ozon,
        this.store.syncStore.ozonAccounts, _ => _.ozonProducts, this.api.product.setOzonSyncMultiple)

  wbSyncSelectedProducts =
    (syncEnabled: boolean) =>
      this.syncSelectedProducts(syncEnabled, NavStoreType.Wildberries,
        this.store.syncStore.wbAccounts, _ => _.wbProducts, this.api.product.setWbSyncMultiple)

  yandexMarketSyncSelectedProducts =
    (syncEnabled: boolean) =>
      this.syncSelectedProducts(syncEnabled, NavStoreType.YandexMarket,
        this.store.syncStore.yandexMarketAccounts, _ => _.yandexMarketProducts,
        this.api.product.setYandexMarketSyncMultiple)

  private syncSelectedProducts =
    async (syncEnabled: boolean,
           storeAccountType: Exclude<NavStoreType, NavStoreType.Combined>,
           storeAccounts: StoreAccountModelBase[],
           mapStoreProducts: (myProduct: MyProductModel) => StoreProductModel[],
           invokeApi: (model: SetStoreProductsSyncModel) => Promise<void>): Promise<void> => {
      let storeAccountId: number
      const {productStore, homeNavRequired: homeNav} = this.store

      switch (homeNav.storeType) {
        case storeAccountType:
          storeAccountId = required(homeNav.accountId)
          break

        case NavStoreType.Combined:
          storeAccountId = required(storeAccounts[0].id)
          break

        default:
          throw new Error(`Bad store type: ${(homeNav.storeType)}`)
      }

      await invokeApi({
        syncEnabled,
        accountId: storeAccountId,
        productIds: productStore.myProducts.selectedRows.flatMap(mapStoreProducts).map(_ => _.id)
      })

      setTimeout(this.loadMyProductsInfo)
      setTimeout(this.refreshMyProductsTab)
    }

  createOzonAccount =
    async (model: EditOzonAccountModel) => {
      const createdAccount = await this.api.userSync.createOzonAccount(model)
      this.store.syncStore.ozonAccounts.push(createdAccount)
    }

  editOzonAccount =
    async (model: EditOzonAccountModel) => {
      await this.api.userSync.editOzonAccount(model)
      await this.loadUserAccounts()
    }

  deleteOzonAccount =
    async (accountId: number) => {
      await this.api.userSync.deleteOzonAccount(accountId)
      await this.loadUserAccounts()
    }

  createWbAccount =
    async (model: EditWbAccountModel) => {
      const createdAccount = await this.api.userSync.createWbAccount(model)
      this.store.syncStore.wbAccounts.push(createdAccount)
    }

  editWbAccount =
    async (model: EditWbAccountModel) => {
      await this.api.userSync.editWbAccount(model)
      await this.loadUserAccounts()
    }

  deleteWbAccount =
    async (accountId: number) => {
      await this.api.userSync.deleteWbAccount(accountId)
      await this.loadUserAccounts()
    }

  createYandexMarketAccount =
    async (model: EditYandexMarketAccountModel) => {
      const createdAccount = await this.api.userSync.createYandexMarketAccount(model)
      this.store.syncStore.yandexMarketAccounts.push(createdAccount)
    }

  editYandexMarketAccount =
    async (model: EditYandexMarketAccountModel) => {
      await this.api.userSync.editYandexMarketAccount(model)
      await this.loadUserAccounts()
    }

  deleteYandexMarketAccount =
    async (accountId: number) => {
      await this.api.userSync.deleteYandexMarketAccount(accountId)
      await this.loadUserAccounts()
    }

  editMoySkladAccount =
    async (model: EditMoySkladAccountModel) => {
      this.store.syncStore.moySkladAccount = await this.api.userSync.editMoySkladAccount(model)
    }

  // TODO: UI Logic?
  confirmSyncEnable =
    () => this.showDialog('Внимание, после включения синхронизации остатки на маркетплейсах будут перезаписаны!')

  confirmSyncDisable =
    () => this.showDialog('Синхронизация будет отключена. Заказы и остатки перестанут обновляться.')

  showDialog =
    (text: string,
     options?: {
       acceptButton?: string
       declineButton?: string
       title?: string
     }): Promise<boolean> => {
      if (this.store.dialog !== null) {
        throw new Error('Another dialog is already shown')
      }

      return new Promise(resolve =>
        this.store.dialog = {
          text,
          title: options?.title ?? 'Продолжить?',
          acceptButton: options?.acceptButton ?? 'Продолжить',
          declineButton: options?.declineButton ?? 'Отмена',
          onClose: resolve
        })
    }

  acceptDialog = () => {
    required(this.store.dialog).onClose(true)
    this.store.dialog = null
  }

  declineDialog = () => {
    required(this.store.dialog).onClose(false)
    this.store.dialog = null
  }

  toggleMyProductsColumn =
    (columnId: MyProductColumnId) => {
      if (this.store.hiddenMyProductColumnIds.has(columnId)) {
        this.store.hiddenMyProductColumnIds.delete(columnId)
      } else {
        this.store.hiddenMyProductColumnIds.add(columnId)
      }
    }

  matchOzonToMoySkladProducts =
    async (ozonProp: OzonProductProp, moySkladProp: MsProductProp, accountIdFrom: number,
           accountIdTo: number): Promise<number> =>
      this.matchProducts(
        () => this.api.userSync.matchOzonToMoySkladProducts(
          {fromProp: ozonProp, toProp: moySkladProp, accountIdFrom, accountIdTo}))

  matchWbToMoySkladProducts =
    async (wbProp: WbProductProp, moySkladProp: MsProductProp, accountIdFrom: number,
           accountIdTo: number): Promise<number> =>
      this.matchProducts(() => this.api.userSync.matchWbToMoySkladProducts(
        {fromProp: wbProp, toProp: moySkladProp, accountIdFrom, accountIdTo}))

  matchYandexMarketToMoySkladProducts =
    async (yandexMarketProp: YandexMarketProductProp, moySkladProp: MsProductProp, accountIdFrom: number,
           accountIdTo: number): Promise<number> =>
      this.matchProducts(
        () => this.api.userSync.matchYandexMarketToMoySkladProducts(
          {fromProp: yandexMarketProp, toProp: moySkladProp, accountIdFrom, accountIdTo}))

  private matchProducts =
    async (invokeApi: () => Promise<number>): Promise<number> => {
      const productCount = await invokeApi()

      setTimeout(this.loadMyProductsInfo)
      setTimeout(this.refreshMyProductsTab)

      return productCount
    }

  unmatchOzonProducts =
    async (ozonAccountId: number) => {
      await this.api.userSync.unmatchOzonProducts(ozonAccountId)

      await this.loadMyProductsInfo()
      await this.refreshMyProductsTab()
    }

  unmatchWbProducts =
    async (wbAccountId: number) => {
      await this.api.userSync.unmatchWbProducts(wbAccountId)

      await this.loadMyProductsInfo()
      await this.refreshMyProductsTab()
    }

  unmatchYandexMarketProducts =
    async (yandexMarketAccountId: number) => {
      await this.api.userSync.unmatchYandexMarketProducts(yandexMarketAccountId)

      await this.loadMyProductsInfo()
      await this.refreshMyProductsTab()
    }

  setMoySkladStatusMaps =
    async (model: MoySkladStatusMapModel[]) => {
      await this.api.userSync.setMoySkladStatusMaps(model)
      setTimeout(this.checkMoySkladAppState)
    }

  verifyMoySkladWebhooks =
    async () => {
      await this.api.userSync.verifyMoySkladWebhooks()
    }

  setUserSync =
    async (userId: string, allowSync: boolean) => {
      const user = required(this.store.adminStore.users.data.find(_ => _.id === userId))
      await this.api.admin.setUserSync({userId, allowSync})
      user.allowSync = allowSync
    }

  setFakeApiFlag =
    async (userId: string, useFakeApi: boolean) => {
      const user = required(this.store.adminStore.users.data.find(_ => _.id === userId))
      await this.api.admin.setFakeApiFlag({userId, useFakeApi})
      user.isUsingFakeApi = useFakeApi
    }

  private loadMyProductColumnIds =
    () => {
      if (!isLocalStorageAllowed) {
        return
      }

      try {
        const hiddenColumnIdsJson = localStorage.getItem(this.hiddenMyProductColumnIdsKey)

        if (!hiddenColumnIdsJson) {
          return
        }

        const hiddenColumnIds = new Set<MyProductColumnId>(JSON.parse(hiddenColumnIdsJson))

        if (hiddenColumnIds.size > 0) {
          this.store.hiddenMyProductColumnIds = hiddenColumnIds
        }
      } catch (e) {
        console.error('Failed to load hiddenMyProductColumnIds', e)

        try {
          localStorage.removeItem(this.hiddenMyProductColumnIdsKey)
        } catch (e2) {
          console.error('Failed to clear hiddenMyProductColumnIds', e2)
        }
      }
    }

  private trackMyProductColumnIds =
    () => {
      if (!isLocalStorageAllowed) {
        return
      }

      return reaction(
        () => Array.from(this.store.hiddenMyProductColumnIds),
        hiddenColumnIds => {
          try {
            localStorage.setItem(this.hiddenMyProductColumnIdsKey, JSON.stringify(hiddenColumnIds))
          } catch (e) {
            console.error('Failed to save hiddenMyProductColumnIds', e)
          }
        },
        {equals: comparer.shallow})
    }

  private trackAuthToken =
    () => reaction(
      () => this.store.authToken,
      authToken => this.api.headerAuthToken = authToken)

  private trackAccounts =
    () => {
      const {syncStore} = this.store

      when(() => syncStore.ozonAccounts.length > 0 || syncStore.wbAccounts.length > 0
                 || syncStore.yandexMarketAccounts.length > 0,
        this.setHomeNavInitial)

      reaction(
        () => syncStore.allowCombinedTab,
        allowCombinedTab => {
          if (!syncStore.ozonAccounts.length && !syncStore.wbAccounts.length
              && !syncStore.yandexMarketAccounts.length) {
            return
          }

          const homeNav = this.store.getHomeNav()

          if (!homeNav) {
            return
          }

          switch (homeNav.storeType) {
            case NavStoreType.Combined: {
              if (!allowCombinedTab) {
                this.setHomeNavFirstAccount()
              }

              return
            }

            case NavStoreType.Ozon: {
              if (homeNav.accountId && syncStore.ozonAccounts.some(_ => _.id === homeNav.accountId)) {
                // Valid
                return
              }

              this.setHomeNavFirstAccount()
              return
            }

            case NavStoreType.Wildberries: {
              if (homeNav.accountId && syncStore.wbAccounts.some(_ => _.id === homeNav.accountId)) {
                // Valid
                return
              }

              this.setHomeNavFirstAccount()
              return
            }

            case NavStoreType.YandexMarket: {
              if (homeNav.accountId && syncStore.yandexMarketAccounts.some(
                account => account.id === homeNav.accountId)) {
                // Valid
                return
              }

              this.setHomeNavFirstAccount()
              return
            }

            default:
              throw new Error(`Unprocessed StoreType: ${homeNav.storeType}`)
          }
        })
    }

  setHomeNavInitial = () => {
    const {syncStore, setHomeNavCombined} = this.store

    if (syncStore.allowCombinedTab) {
      setHomeNavCombined()
    } else {
      this.setHomeNavFirstAccount()
    }
  }

  private setHomeNavFirstAccount = () => {
    const {syncStore, setHomeNav} = this.store

    if (syncStore.ozonAccounts.length > 0) {
      setHomeNav(NavStoreType.Ozon, syncStore.ozonAccounts[0].id)
    } else if (syncStore.wbAccounts.length > 0) {
      setHomeNav(NavStoreType.Wildberries, syncStore.wbAccounts[0].id)
    } else if (syncStore.yandexMarketAccounts.length > 0) {
      setHomeNav(NavStoreType.YandexMarket, syncStore.yandexMarketAccounts[0].id)
    }
  }

  pullOzonOrder =
    async (ozonAccountId: number, ozonOrderId: number) => {
      await this.api.order.pullOzon(ozonOrderId)
      await this.ozonOrderLoaders.trigger()
    }

  pullWbOrder =
    async (wbAccountId: number, wbOrderId: number) => {
      await this.api.order.pullWb(wbOrderId)
      await this.wbOrderLoaders.trigger()
    }

  pullYandexMarketOrder =
    async (yandexMarketAccountId: number, yandexMarketOrderId: number) => {
      await this.api.order.pullYandexMarket(yandexMarketOrderId)
      await this.yandexMarketOrderLoaders.trigger()
    }

  pullMoySkladOrder =
    async (msOrderId: number) => {
      await this.api.order.pullMoySklad(msOrderId)
      await this.msOrderLoaders.trigger()
    }

  loadOzonFiles =
    async (ozonAccountId: number): Promise<void> => {
      this.store.labelStore.ozonFiles.data = await this.api.label.ozonFiles(ozonAccountId)
    }

  loadWbFiles =
    async (wbAccountId: number): Promise<void> => {
      this.store.labelStore.wbFiles.data = await this.api.label.wbFiles(wbAccountId)
    }

  loadYandexMarketFiles =
    async (yandexMarketAccountId: number): Promise<void> => {
      this.store.labelStore.yandexMarketFiles.data = await this.api.label.yandexMarketFiles(yandexMarketAccountId)
    }

  uploadWbProducts =
    async (wbAccountId: number, productsFile: File) => {
      try {
        return await this.api.product.uploadWbProducts(wbAccountId, productsFile)
      } finally {
        // Always refresh statuses
        await this.loadUserAccounts()
        await this.wbProductReloaders.trigger()
      }
    }

  setDisableWbApiProducts =
    async (wbAccountId: number, isDisabled: boolean) => {
      const wbAccountIndex = this.store.syncStore.wbAccounts.findIndex(_ => _.id === wbAccountId)

      if (wbAccountIndex < 0) {
        throw new Error('WB account not found')
      }

      this.store.syncStore.wbAccounts[wbAccountIndex] =
        await this.api.userSync.setWbDisableApiProducts(wbAccountId, isDisabled)
    }
}