import React from 'react'

import { AccountSyncSwitch } from './AccountSyncSwitch'

import { useLogic } from '../hooks/storeHook'

import { OzonAccountRequiredProps } from '../types/accountProps'

export const OzonSyncSwitch =
  ({account}: OzonAccountRequiredProps) => {
    const {setOzonAccountSync} = useLogic()

    return <AccountSyncSwitch account={account} setSync={setOzonAccountSync}/>
  }