import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { WbProductPicker } from '../Tables/WbProductPicker'
import { FormValidationErrors } from '../FormValidationErrors'

import { useLogic } from '../../hooks/storeHook'
import { useSubmitWithValidation } from '../../hooks/submitWithValidationHook'

import { makeWbProductLabel } from '../../store/logic/productUtils'

import { MyRowProps } from '../../types/tableTypes'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'
import { ExternalProductOption } from '../../server/mpsklad_core/Models/ExternalProductOption'

export const MyProductWbCell =
  observer(
    ({row}: MyRowProps<MyProductModel>) => {
      const {setWbProducts} = useLogic()

      const {
        submit,
        validationErrors
      } = useSubmitWithValidation(
        (wbProducts: ExternalProductOption[]) => setWbProducts(row.id, wbProducts.map(_ => _.id)))

      const wbProducts = row.wbProducts.map(
        _ => ({
          id: _.id,
          label: makeWbProductLabel(_)
        }))

      return (
        <TableCell className="table-border" style={{padding: 8}}>
          <WbProductPicker
            size="small"
            msProductId={row.id}
            products={wbProducts}
            onChange={submit}
          />

          <FormValidationErrors validationErrors={validationErrors}/>
        </TableCell>
      )
    })