import { ChangeEvent, useCallback, useState } from 'react'

export type TablePaginationHelpers = {
  page: number

  rowsPerPage: number

  slicePage: <T>(data: T[]) => T[]

  handleChangePage: (_: unknown, newPage: number) => void

  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void
}

export const tableRowsPerPageOptions = [10, 50, 100, 500, 1000]

export const useTablePagination = (): TablePaginationHelpers => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(tableRowsPerPageOptions[2])

  const handleChangePage = useCallback(
    (_: unknown, newPage: number) => setPage(newPage),
    [setPage])

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value))
      setPage(0)
    },
    [setPage, setRowsPerPage])

  const slicePageData = useCallback(
    <T>(data: T[]) => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage])

  return {page, rowsPerPage, slicePage: slicePageData, handleChangePage, handleChangeRowsPerPage}
}