import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import debounce from 'lodash/debounce'
import clsx from 'clsx'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import { Autocomplete } from '@material-ui/lab'
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete'
import { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete'

import { AutocompleteStub } from './AutocompleteStub2'
import { AutocompleteLoader } from './AutocompleteLoader'

import { useBoolState, useLazyEffect } from '../../hooks/commonHooks'

import { ExternalProductOption } from '../../server/mpsklad_core/Models/ExternalProductOption'

export type ProductSinglePickerBaseProps =
  ExternalProductPickerBaseProps
  & Pick<ExternalProductPickerProps, 'externalId' | 'text'>
  & {
  msProductId: number | undefined
}

export type ExternalProductPickerBaseProps = {
  fullWidth?: boolean

  size?: 'small' | 'medium'

  placeholder?: string

  onChange: (option: ExternalProductOption | undefined) => void | Promise<void>
}

export type ExternalProductPickerProps =
  ExternalProductPickerBaseProps
  & {
  text: string | undefined

  externalId: number | undefined

  loadOptions: (searchTerm: string | undefined) => Promise<ExternalProductOption[]>

  /**
   * NOTE: Must have autofocus set to true
   */
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode
}

export const ExternalProductPicker = observer(
  ({
     externalId, text,
     placeholder, onChange,
     loadOptions, renderInput,
     ...passProps
   }: ExternalProductPickerProps) => {
    const classes = useStyles()

    const [isOpen, setOpened, setClosed] = useBoolState()
    const [isLoading, setLoading, setLoaded] = useBoolState()
    const [isChanging, setChanging, setChanged] = useBoolState()

    const [newLabel, setNewLabel] = useState<string>()

    const [searchTerm, setSearchTerm] = useState<string>()
    const [loadedOptions, setLoadedOptions] = useState<ExternalProductOption[] | null>(null)

    const setSearchTermDebounced = useMemo(() => debounce(setSearchTerm, 800), [setSearchTerm])

    useLazyEffect(async () => {
      if (!isOpen) {
        setLoaded()
        return
      }

      setLoading()
      setLoadedOptions([])

      try {
        setLoadedOptions(await loadOptions(searchTerm))
      } finally {
        setLoaded()
      }
    }, [searchTerm])

    let renderedOptions: ExternalProductOption[]
    let selectedOption: ExternalProductOption | null

    if (externalId != null) {
      // Loaded options might not contain the selected option because of filtering or truncating
      const selectedOptionFallback = {id: externalId, label: text ?? `#${externalId}`}

      if (loadedOptions != null) {
        selectedOption = loadedOptions.find(_ => _.id === externalId) ?? selectedOptionFallback
        renderedOptions = loadedOptions
      } else {
        selectedOption = selectedOptionFallback
        renderedOptions = [selectedOption]
      }
    } else {
      selectedOption = null
      renderedOptions = loadedOptions ?? []
    }

    const stubLabel = newLabel ?? selectedOption?.label ?? placeholder

    const onOpen = async () => {
      setLoading()

      setLoadedOptions([])

      try {
        setLoadedOptions(await loadOptions(searchTerm))
      } finally {
        setLoaded()
      }

      setOpened()
    }

    if (!isOpen || isChanging) {
      return (
        <AutocompleteStub
          label={stubLabel}
          isLoading={isLoading || isChanging}
          fullWidth={passProps.fullWidth}
          onClick={!isOpen && !isLoading && !isChanging ? onOpen : undefined}
        />
      )
    }

    const onInputChange =
      (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'input') {
          setLoading()
          setLoadedOptions([])
          setSearchTermDebounced(value)
        }
      }

    const onSelectionChange =
      async (e: React.ChangeEvent<{}>, newValue: ExternalProductOption | null) => {
        if (newValue && newValue.id <= 0) {
          // Prevent selection of hints
          setTimeout(setOpened)
          return
        }

        const hasNewValue = newValue != null
        const onChangeResult = onChange(newValue ?? undefined)

        if (!(onChangeResult instanceof Promise)) {
          if (hasNewValue) {
            setClosed()
          }

          return
        }

        try {
          setNewLabel(newValue?.label ?? '')

          if (hasNewValue) {
            setChanging()
          } else {
            setLoading()
          }

          await onChangeResult

          if (hasNewValue) {
            setClosed()
          }
        } finally {
          if (hasNewValue) {
            setChanged()
          } else {
            setLoaded()
          }

          setNewLabel(undefined)
        }
      }

    const onClose = () => {
      setClosed()
      setSearchTerm(undefined)
    }

    return (
      <Autocomplete
        value={selectedOption}
        options={isLoading ? [/* force show the loader */] : renderedOptions}
        getOptionLabel={getOptionLabel}
        clearText="Разматчить"
        loadingText={<AutocompleteLoader/>}
        noOptionsText="Нет доступных товаров"
        open={isOpen}
        loading={isLoading}
        onOpen={onOpen}
        onClose={onClose}
        onChange={onSelectionChange}
        onInputChange={onInputChange}
        renderInput={renderInput}
        className={clsx(classes.autoComplete, passProps.size === 'small' && classes.autoCompleteSmall)}
        classes={
          passProps.size === 'small'
          ? {
              popper: classes.popperSmall,
              input: classes.inputSmall,
              option: classes.textSmall,
              listbox: classes.listboxSmall
            }
          : undefined
        }
        {...passProps}
      />
    )
  })

const getOptionLabel = ({label}: ExternalProductOption) => label

const useStyles =
  makeStyles(() =>
    createStyles({
      autoComplete: {
        minWidth: 300
      },
      autoCompleteSmall: {
        minWidth: 270,
        maxWidth: 300,
        '& .MuiFormLabel-root': {
          fontSize: 12
        }
      },
      textSmall: {
        fontSize: 12
      },
      inputSmall: {
        fontSize: 12,
        overflow: 'hidden',
        '&:focus': {
          overflow: 'auto'
        }
      },
      listboxSmall: {
        maxHeight: 400
      },
      popperSmall: {
        width: '500px !important'
      }
    }))