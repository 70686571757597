import { createServerTableData, ServerTableData, TablePageLoader, TablePageLoader2 } from './serverTableData'

import { required } from '../common/objectUtils'

import { WbOrderColumnId } from '../types/wbOrderColumns'
import { OzonOrderColumnId } from '../types/ozonOrderColumns'
import { MoySkladOrderColumnId } from '../types/moySkladOrderColumns'

import { WbOrderModel } from '../server/mpsklad_core/Models/Orders/WbOrderModel'
import { OzonOrderModel } from '../server/mpsklad_core/Models/Orders/OzonOrderModel'
import { MoySkladOrderModel } from '../server/mpsklad_core/Models/Orders/MoySkladOrderModel'
import { OrdersTablePageOptions } from '../server/mpsklad_core/Models/OrdersTablePageOptions'
import { OrdersTableFilterColumn } from '../server/mpsklad_core/Models/OrdersTableFilterColumn'
import { MoySkladOrdersTablePageOptions } from '../server/mpsklad_core/Models/MoySkladOrdersTablePageOptions'
import { YandexMarketOrderModel } from '../server/mpsklad_core/Models/Orders/YandexMarketOrderModel'
import { YandexMarketOrderColumnId } from '../types/yandexMarketOrderColumns'

export type StoreOrdersLoader<TOrder extends object> =
  (accountId: number) => TablePageLoader<TOrder, typeof OrdersTableFilterColumn, never>

export type OzonOrdersTableData =
  ServerTableData<OzonOrderModel, OzonOrderColumnId, typeof OrdersTableFilterColumn, never>

export type WbOrdersTableData =
  ServerTableData<WbOrderModel, WbOrderColumnId, typeof OrdersTableFilterColumn, never>

export type YandexMarketOrdersTableData =
  ServerTableData<YandexMarketOrderModel, YandexMarketOrderColumnId, typeof OrdersTableFilterColumn, never>

export type MoySkladOrdersTableData =
  ServerTableData<MoySkladOrderModel, MoySkladOrderColumnId, typeof OrdersTableFilterColumn, never>

export class OrderStore {
  readonly moySkladOrders: MoySkladOrdersTableData =
    createServerTableData<MoySkladOrderModel, MoySkladOrderColumnId>('moySkladOrders')(OrdersTableFilterColumn)

  private ozonOrdersPageLoader: StoreOrdersLoader<OzonOrderModel> | null = null

  private wbOrdersPageLoader: StoreOrdersLoader<WbOrderModel> | null = null

  private yandexMarketOrdersPageLoader: StoreOrdersLoader<YandexMarketOrderModel> | null = null

  init =
    (ozonOrdersPageLoader: TablePageLoader2<OzonOrderModel, OrdersTablePageOptions>,
     wbOrdersPageLoader: TablePageLoader2<WbOrderModel, OrdersTablePageOptions>,
     yandexMarketOrdersPageLoader: TablePageLoader2<YandexMarketOrderModel, OrdersTablePageOptions>,
     msOrdersPageLoader: TablePageLoader2<MoySkladOrderModel, MoySkladOrdersTablePageOptions>) => {
      this.ozonOrdersPageLoader = accountId =>
        (options, config) => ozonOrdersPageLoader({accountId, ...options}, config)

      this.wbOrdersPageLoader = accountId =>
        (options, config) => wbOrdersPageLoader({accountId, ...options}, config)

      this.yandexMarketOrdersPageLoader = accountId =>
        (options, config) => yandexMarketOrdersPageLoader({accountId, ...options}, config)

      this.moySkladOrders.init(msOrdersPageLoader)
    }

  createOzonOrdersTable =
    (ozonAccountId: number): OzonOrdersTableData =>
      createServerTableData<OzonOrderModel, OzonOrderColumnId>('ozonOrders')(OrdersTableFilterColumn)
        .init(required(this.ozonOrdersPageLoader)(ozonAccountId))
        .setPropFilter('hasError', JSON.stringify(false))

  createWbOrdersTable =
    (wbAccountId: number): WbOrdersTableData =>
      createServerTableData<WbOrderModel, WbOrderColumnId>('wbOrders')(OrdersTableFilterColumn)
        .init(required(this.wbOrdersPageLoader)(wbAccountId))
        .setPropFilter('hasError', JSON.stringify(false))

  createYandexMarketOrdersTable =
    (yandexMarketAccountId: number): YandexMarketOrdersTableData =>
      createServerTableData<YandexMarketOrderModel, YandexMarketOrderColumnId>('yandexMarketOrders')(
        OrdersTableFilterColumn)
        .init(required(this.yandexMarketOrdersPageLoader)(yandexMarketAccountId))
        .setPropFilter('hasError', JSON.stringify(false))
}