import React from 'react'

import { Alert } from '@material-ui/lab'
import { FormHelperText } from '@material-ui/core'

import { pluralize } from '../common/stringUtility'
import { getWbTokenInfo } from '../common/wbTokenUtils'
import { formatDateTime } from '../common/dateTimeUtility'

export type WbTokenExpirationInfoProps = {
  tokenExpiresAtUnix: number | undefined
}

export const WbTokenExpirationInfo =
  ({tokenExpiresAtUnix}: WbTokenExpirationInfoProps) => {
    const tokenInfo = getWbTokenInfo(tokenExpiresAtUnix)

    if (!tokenInfo) {
      return null
    }

    const {expiresAt, expiresInDays, expiresSoon, expiresToday, expired} = tokenInfo

    return <>
      <FormHelperText>
        Время жизни токена: до {formatDateTime(expiresAt, false)}.
      </FormHelperText>

      {
        expired
        ? <Alert severity="error">
          Время жизни токена истекло.
          <br/>
          Для продолжения работы выпустите новый токен в личном кабинете Wildberries.
        </Alert>
        : expiresSoon
          ? <Alert severity="warning">
            Время жизни токена истекает {
            expiresToday ? 'менее, чем через день'
                         : `через ${pluralize(expiresInDays, 'день', 'дня', 'дней')}`}.
            <br/>
            Для бесперебойной работы замените токен на новый.
          </Alert>
          : null
      }
    </>
  }