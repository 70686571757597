import React from 'react'
import { observer } from 'mobx-react-lite'

import { MyTable } from './MyTable'

import { useLazyEffect } from '../hooks/commonHooks'

import { MyServerTableProps } from '../types/tableTypes'

export const MyServerTable =
  observer(
    <TColumnId extends string, TRow extends {id: React.Attributes['key']}>
    (props: MyServerTableProps<TColumnId, TRow>) => {
      useLazyEffect(props.table.mount)

      return <MyTable {...props}/>
    })