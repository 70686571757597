import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

import { __DEV__, getApiBaseUrl } from '../../common/devUtils'
import { isUiErrorResponse, isValidationErrorResponse } from '../../common/requestValidationError'
import { CancellableAxiosRequestMethod, createPreemptiveRequest } from '../../common/requestUtils'

import { ResponseCancelledError } from '../../types/responseCancelledError'

import { LogController } from '../../server/mpsklad_core/Controllers/LogController'
import { AuthController } from '../../server/mpsklad_core/Controllers/AuthController'
import { FeedController } from '../../server/mpsklad_core/Controllers/FeedController'
import { AdminController } from '../../server/mpsklad_core/Controllers/AdminController'
import { LabelController } from '../../server/mpsklad_core/Controllers/LabelController'
import { OrderController } from '../../server/mpsklad_core/Controllers/OrderController'
import { ImportController } from '../../server/mpsklad_core/Controllers/ImportController'
import { ProductController } from '../../server/mpsklad_core/Controllers/ProductController'
import { UserSyncController } from '../../server/mpsklad_core/Controllers/UserSyncController'
import { PriceSyncController } from '../../server/mpsklad_core/Controllers/PriceSyncController'

import { WbPrintOptions } from '../../server/mpsklad_core/Models/WbPrintOptions'
import { OzonPrintOptions } from '../../server/mpsklad_core/Models/OzonPrintOptions'
import { WbPrintOrderModel } from '../../server/mpsklad_core/Models/WbPrintOrderModel'
import { OzonPrintOrderModel } from '../../server/mpsklad_core/Models/OzonPrintOrderModel'
import { YandexMarketPrintOptions } from '../../server/mpsklad_core/Models/YandexMarketPrintOptions'
import { YandexMarketPrintOrderModel } from '../../server/mpsklad_core/Models/YandexMarketPrintOrderModel'

export class ApiLogic {
  private readonly axios: AxiosInstance

  private readonly showUiError: (uiMessage: string) => void

  headerAuthToken: string | null

  readonly admin: AdminController

  readonly auth: AuthController

  readonly order: OrderController

  readonly product: ProductController

  readonly userSync: UserSyncController

  readonly label: LabelController

  readonly feeds: FeedController

  readonly prices: PriceSyncController

  readonly log: LogController

  readonly import: ImportController

  readonly previewOzonLabelsPreemptive: CancellableAxiosRequestMethod<OzonPrintOptions,
    OzonPrintOrderModel[]>

  readonly previewWbLabelsPreemptive: CancellableAxiosRequestMethod<WbPrintOptions,
    WbPrintOrderModel[]>

  readonly previewYandexMarketLabelsPreemptive: CancellableAxiosRequestMethod<YandexMarketPrintOptions,
    YandexMarketPrintOrderModel[]>

  constructor(showUiError: (uiMessage: string) => void) {
    this.axios = Axios.create({
      baseURL: getApiBaseUrl(),
      timeout: (__DEV__ ? 300 : 180) * 1000,
      withCredentials: true
    })

    this.axios.interceptors.request.use(
      this.interceptRequest,
      this.interceptRequestError
    )

    this.axios.interceptors.response.use(
      undefined,
      this.interceptResponseError
    )

    this.showUiError = showUiError

    this.headerAuthToken = null

    this.admin = new AdminController(this.axios)
    this.auth = new AuthController(this.axios)
    this.order = new OrderController(this.axios)
    this.product = new ProductController(this.axios)
    this.userSync = new UserSyncController(this.axios)
    this.label = new LabelController(this.axios)
    this.feeds = new FeedController(this.axios)
    this.prices = new PriceSyncController(this.axios)
    this.log = new LogController(this.axios)
    this.import = new ImportController(this.axios)

    this.previewOzonLabelsPreemptive =
      createPreemptiveRequest(this.label.previewOzon)

    this.previewWbLabelsPreemptive =
      createPreemptiveRequest(this.label.previewWbSupply)

    this.previewYandexMarketLabelsPreemptive = createPreemptiveRequest(this.label.previewYandexMarket)
  }

  private interceptRequest = (
    requestConfig: AxiosRequestConfig
  ): AxiosRequestConfig => {
    if (this.headerAuthToken !== null) {
      requestConfig.headers['Authorization'] = `Bearer ${this.headerAuthToken}`
    }

    return requestConfig
  }

  private interceptRequestError = (error?: Partial<AxiosError>) => {
    if (Axios.isCancel(error)) {
      console.log(
        `[REQUEST CANCEL] ${error?.config?.method} ${error?.config?.url}`,
        error?.config?.data ?? ''
      )
      throw new ResponseCancelledError()
    }

    console.error(
      `[REQUEST ERROR] ${error?.config?.method} ${error?.config?.url} =>`,
      error?.config?.data ?? '',
      error
    )
    this.showUiError('Что-то пошло не так')
    throw error
  }

  private interceptResponseError =
    (error?: Partial<AxiosError>) => {
      if (error === undefined) {
        console.error('[RESPONSE ERROR] no error argument')
        this.showUiError('Что-то пошло не так')
        throw error
      }

      if (Axios.isCancel(error)) {
        console.log(`[RESPONSE CANCEL] ${error?.config?.method} ${error?.config?.url}`)
        throw new ResponseCancelledError()
      }

      const requestInfo = `[RESPONSE ERROR] ${error?.config?.method} ${error?.config?.url} =>`

      if (error?.response) {
        const {status, data} = error.response

        if (isValidationErrorResponse(data)) {
          this.showUiError('Ошибка валидации')
          throw data
        }

        if (isUiErrorResponse(data)) {
          this.showUiError(data.uiMessage)
        } else if (error?.config?.url?.toLowerCase?.() !== 'auth/check') {
          this.showUiError('Что-то пошло не так')
        }

        console.error(requestInfo, status, JSON.stringify(data, null, 2))
      } else {
        console.error(`${requestInfo} no response`, error)
        this.showUiError('Что-то пошло не так')
      }

      throw error
    }

  healthCheck = (): Promise<boolean> =>
    // NOTE: This URL is hardcoded
    this.axios.get<string>('health').then((_) => _.data === 'Healthy')

  syncCurrentUser = () =>
    this.userSync.syncCurrentUser({
      timeout: 10 * 60 * 1000 /* 10 minutes in ms */
    })
}