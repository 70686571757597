//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum NavStoreType {
  Ozon = 1,
  Wildberries = 2,
  YandexMarket = 4,
  Combined = 7
}
