import React from 'react'
import { observer } from 'mobx-react-lite'

import { SyncStatesTable } from './SyncStatesTable'
import { PushStocksStateRow } from './PushStocksStateRow'
import { PullOrdersStateRow } from './PullOrdersStateRow'
import { PushOrdersStateRow } from './PushOrdersStateRow'
import { PullProductsStateRow } from './PullProductsStateRow'

import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'
import { AccountModelBase } from '../server/mpsklad_core/Models/AccountModelBase'
import { PushRealizationReportsStateRow } from './PushRealizationReportsStateRow'

export type AccountSyncStatesProps = {
  account: AccountModelBase & Partial<Pick<OzonAccountModel, 'isFBS'>>
}

export const OzonSyncStates =
  observer(
    ({account: {syncStates, isFBS}}: AccountSyncStatesProps) =>
      <SyncStatesTable>
        <PullProductsStateRow type="Ozon" syncStates={syncStates}/>

        {
          isFBS &&
          <PushStocksStateRow type="Ozon" syncStates={syncStates}/>
        }

        <PullOrdersStateRow type="Ozon" syncStates={syncStates}/>

        <PushOrdersStateRow type="Ozon" syncStates={syncStates}/>

        <PushRealizationReportsStateRow type="Ozon" syncStates={syncStates}/>
      </SyncStatesTable>
  )