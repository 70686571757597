import React from 'react'

import { TableCell, TableHead, TableRow } from '@material-ui/core'

export const SyncLogsTableHead =
  () =>
    <TableHead>
      <TableRow>
        <TableCell>
          Дата
        </TableCell>

        <TableCell>
          Тип
        </TableCell>

        <TableCell>
          Сообщение
        </TableCell>

        <TableCell>
          Id
        </TableCell>
      </TableRow>
    </TableHead>