import React from 'react'

import { Info } from '@material-ui/icons'
import { useTheme } from '@material-ui/core/styles'

import { SyncLogsButtonBase } from './SyncLogsButtonBase'

import { useApi } from '../../hooks/storeHook'

import { GetProductSyncLogsCombinedModel } from '../../server/mpsklad_core/Models/GetProductSyncLogsCombinedModel'

export const CombinedProductSyncLogsButton =
  (props: GetProductSyncLogsCombinedModel) => {
    const {palette} = useTheme()

    const api = useApi()

    return (
      <SyncLogsButtonBase
        title="Все товары"
        icon={<Info htmlColor={palette.mpsklad.background}/>}
        loadLogs={() => api.userSync.productSyncLogsCombined(props)}
      />)
  }