import React from 'react'

import { TableCell } from '@material-ui/core'

import { SyncLogsButton } from './SyncLogsButton'

import { MyRowProps } from '../../types/tableTypes'
import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'
import { MoySkladProductModel } from '../../server/mpsklad_core/Models/MoySkladProductModel'

export const MoySkladProductSyncLogsCell =
  ({row}: MyRowProps<MoySkladProductModel>) =>
    <TableCell>
      <SyncLogsButton themed type={IntegrationType.MoySklad} productId={row.id}/>
    </TableCell>