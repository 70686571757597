import React from 'react'

import { Info } from '@material-ui/icons'
import { useTheme } from '@material-ui/core/styles'

import { SyncLogsButtonBase } from './SyncLogsButtonBase'

import { useApi } from '../../hooks/storeHook'

export type CombinedOrderSyncLogsButtonProps = {
  msOrderId: number
}

export const CombinedOrderSyncLogsButton =
  ({msOrderId}: CombinedOrderSyncLogsButtonProps) => {
    const {palette} = useTheme()

    const api = useApi()

    const loadLogs =
      () => api.userSync.orderSyncLogsCombined(msOrderId)

    return (
      <SyncLogsButtonBase
        title="Все логи заказа"
        icon={<Info htmlColor={palette.mpsklad.background}/>}
        loadLogs={loadLogs}
      />)
  }