import React from 'react'
import { observer } from 'mobx-react-lite'

import {
  Box,
  FormControlLabel, FormHelperText,
  Switch
} from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'

import { StoreAccountBaseProps } from '../types/accountProps'
import { SetAccountToggleModel } from '../server/mpsklad_core/Models/SetAccountToggleModel'

export type RemovingDocumentsOnCancelledOrderSwitchProps =
  StoreAccountBaseProps
  & {
  onSubmit: (model: SetAccountToggleModel) => Promise<void>
}

export const RemovingDocumentsOnCancelledOrderSwitch =
  observer(
    ({account, onSubmit}: RemovingDocumentsOnCancelledOrderSwitchProps) => {
      const logic = useLogic()

      const {showSuccess} = useMessages()

      const onChange =
        async () => {
          const newValue = !account.autoRemoveCancelledMsOrderDocs

          await onSubmit({
            accountId: account.id,
            isEnabled: newValue
          })

          await logic.loadUserAccounts()

          showSuccess(`Удаление документов ${newValue ? 'включено' : 'отключено'}`)
        }

      return (
        <Box>
          <FormControlLabel
            label="Удалять документы при статусе Wildberries Отменён"
            control={
              <Switch
                color="secondary"
                checked={account.autoRemoveCancelledMsOrderDocs}
                onChange={onChange}
              />
            }
          />

          <FormHelperText>
            В заказе МоегоСклада будут удалены Отгрузка и Входящий платёж, если они были привязаны.
          </FormHelperText>
        </Box>
      )
    })