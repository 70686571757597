import { AxiosResponse } from 'axios'

export const encodeUrlParameter =
  (parameter: string | number | boolean | null | undefined): string =>
    parameter === null || parameter === undefined
    ? ''
    : encodeURIComponent(parameter)

export const getAxiosData =
  <T>(response: AxiosResponse<T>) =>
    response.data

const getMsAppOrigin = () => {
  try {
    return new URL(document.location.ancestorOrigins?.[0] ?? document.referrer).origin
  } catch (e) {
    console.log('Failed to get parent frame URL', e)
    return 'https://online.moysklad.ru'
  }
}

const msAppUrlRoot = `${getMsAppOrigin()}/app`

export const msAppUrls = {
  root: msAppUrlRoot,
  payments: `${msAppUrlRoot}#payments`,
  orders: `${msAppUrlRoot}#customerorder`
}