import React from 'react'
import { observer } from 'mobx-react-lite'

import { YandexMarketSyncScreen } from './YandexMarketSyncScreen'
import { StoreScreenTabs, StoreScreenTabsBaseProps } from './StoreScreenTabs'

import { useLogic, useStore } from '../hooks/storeHook'

import { makeYandexMarketAccountName } from '../common/accountUtils'

import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { YandexMarketAccountModel } from '../server/mpsklad_core/Models/YandexMarketAccountModel'

export type YandexMarketScreenTabsProps = StoreScreenTabsBaseProps<YandexMarketAccountModel>

export const YandexMarketScreenTabs =
  observer(
    ({canAddAccount, ...props}: YandexMarketScreenTabsProps) => {
      const {canAddYandexMarketAccount, syncStore: {yandexMarketAccounts}} = useStore()
      const logic = useLogic()

      return <StoreScreenTabs
        storeType={IntegrationType.YandexMarket}
        storeAccounts={yandexMarketAccounts}
        canAddAccount={canAddAccount && canAddYandexMarketAccount}
        onRemoveAccount={logic.deleteYandexMarketAccount}
        makeAccountName={makeYandexMarketAccountName}
        AddAccountComponent={YandexMarketSyncScreen}
        {...props}
      />
    })