import React from 'react'
import { observer } from 'mobx-react-lite'

import { SyncStatesTable } from './SyncStatesTable'
import { AccountSyncStatesProps } from './OzonSyncStates'
import { PushStocksStateRow } from './PushStocksStateRow'
import { PullOrdersStateRow } from './PullOrdersStateRow'
import { PushOrdersStateRow } from './PushOrdersStateRow'
import { PullProductsStateRow } from './PullProductsStateRow'
import { PushRealizationReportsStateRow } from './PushRealizationReportsStateRow'

export const WbSyncStates =
  observer(
    ({account: {syncStates, isFBS}}: AccountSyncStatesProps) =>
      <SyncStatesTable>
        <PullProductsStateRow type="Wildberries" syncStates={syncStates}/>

        {
          isFBS &&
          <PushStocksStateRow type="Wildberries" syncStates={syncStates}/>
        }

        <PullOrdersStateRow type="Wildberries" syncStates={syncStates}/>

        <PushOrdersStateRow type="Wildberries" syncStates={syncStates}/>

        <PushRealizationReportsStateRow type="Wildberries" syncStates={syncStates}/>
      </SyncStatesTable>
  )