import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { ProductYandexMarketSyncSwitch } from './ProductYandexMarketSyncSwitch'

import { MyRowProps } from '../../types/tableTypes'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'

export const MyProductYandexMarketSyncCell =
  observer(
    ({row}: MyRowProps<MyProductModel>) =>
      <TableCell className="table-border" align="center" style={{padding: '8px'}}>
        {
          row.yandexMarketProducts.map(yandexMarketProduct =>
            <div key={yandexMarketProduct.id}>
              <ProductYandexMarketSyncSwitch msProductId={row.id} yandexMarketProduct={yandexMarketProduct}/>
            </div>
          )
        }
      </TableCell>
  )
