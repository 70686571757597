import React from 'react'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper } from '@material-ui/core'

import { useStore } from '../hooks/storeHook'

import { MyServerTable } from './MyServerTable'
import { MyProductsTableHead } from './Tables/MyProductsTableHead'

export const MyProductsTable =
  observer(() => {
    const classes = useStyles()

    const {visibleMyProductColumns, productStore: {myProducts}} = useStore()

    return (
      <Box display="flex">
        <Paper className={classes.paper}>
          <MyServerTable
            hideTopPagination
            classes={classes}
            table={myProducts}
            columns={visibleMyProductColumns}
            HeadComponent={MyProductsTableHead}
            labelNoRows="Товаров нет"
            labelNotFound="Товары не найдены"
            labelRowsPerPage="Товаров на странице:"
          />
        </Paper>
      </Box>
    )
  })

const useStyles =
  makeStyles(
    theme => ({
      paper: {
        minWidth: 1280
      },
      tableContainer: {
        // HACK: Fix one-pixel scroll
        overflowX: 'hidden'
      },
      table: {
        minWidth: 1580,
        fontSize: 12,
        color: '#5f5f5f'
      }
    }))