import React from 'react'

import { YandexMarketProductsTable, YandexMarketProductsTableProps } from './YandexMarketProductsTable'

import { yandexMarketProductImportColumns } from '../types/yandexMarketColumns'

export type YandexMarketProductImportTableProps =
  Pick<YandexMarketProductsTableProps, 'products'>

export const YandexMarketProductImportTable =
  ({products}: YandexMarketProductImportTableProps) =>
    <YandexMarketProductsTable products={products} columns={yandexMarketProductImportColumns}/>