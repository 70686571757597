import { createContext } from 'react'

import { Store } from './store'
import { Logic } from './logic/logic'

import { getContextNotProvidedError } from '../hooks/commonHooks'

export interface AppContext {
  readonly store: Store

  readonly logic: Logic
}

// ESLint can't distinguish between type and variable declarations
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppContext =
  createContext<AppContext>(getContextNotProvidedError())