import React, { ChangeEvent, useState } from 'react'

import { CircularProgress } from '@material-ui/core'

import { IOSSwitch } from './IOSSwitch'

import { useMessages } from '../hooks/snackbarHooks'

export type ProductSyncSwitchProps = {
  syncEnabled: boolean

  onChange: (syncEnabled: boolean) => Promise<void>
}

export const ProductSyncSwitch =
  ({syncEnabled, onChange}: ProductSyncSwitchProps) => {
    const {showError} = useMessages()

    const [isLoading, setIsLoading] = useState(false)

    const onSyncChange =
      async (e: ChangeEvent<HTMLInputElement>) => {
        const syncEnabled = e.target.checked

        setIsLoading(true)

        try {
          await onChange(syncEnabled)
        } catch (e) {
          console.log('Failed to toggle product sync', e)
          showError('Ошибка при сохранении')
        } finally {
          setIsLoading(false)
        }
      }

    return isLoading
           ? <CircularProgress size={31}/>
           : <IOSSwitch checked={syncEnabled} onChange={onSyncChange}/>
  }