import React from 'react'
import { observer } from 'mobx-react-lite'

import { StoreFeedsTab } from './StoreFeedsTab'

import { useApi } from '../hooks/storeHook'

import { OzonAccountIdProps } from '../types/accountProps'

export const OzonFeedsTab = observer(
  ({ozonAccountId}: OzonAccountIdProps) => {
    const api = useApi()

    return <StoreFeedsTab
      accountId={ozonAccountId}
      setFeedsGeneration={api.userSync.setOzonFeedsGeneration}
      checkFeedsGeneration={api.userSync.checkFeedsUsageOzon}
      getStoreFileUrl={api.feeds.ozonFileUrl}
      getFileDescription={api.feeds.ozonFileDescription}
    />
  })