import React from 'react'
import { observer } from 'mobx-react-lite'

import { renderYandexMarketInput } from './YandexMarketProductSinglePicker'
import { ProductMultiPicker, ProductMultiPickerBaseProps } from '../ProductMultiPicker'

import { useLogic } from '../../hooks/storeHook'

export const YandexMarketProductMultiPicker =
  observer(
    (props: ProductMultiPickerBaseProps) => {
      const {loadYandexMarketProductOptions} = useLogic()

      return <ProductMultiPicker renderInput={renderYandexMarketInput}
                                 loadProductOptions={loadYandexMarketProductOptions} {...props}/>
    })