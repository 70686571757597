import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { CircularProgress, ListItem, ListItemText } from '@material-ui/core'

import { DropdownButton, DropdownItemProps } from './DropdownButton'

import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useLogic } from '../hooks/storeHook'

import { WbOrdersTableData } from '../store/orderStore'

import { WbAccountRequiredProps } from '../types/accountProps'
import { WbSupplyModel } from '../server/mpsklad_core/Models/WbSupplyModel'

export type WbOrdersSupplyDropdownProps =
  WbAccountRequiredProps & {
  wbOrders: WbOrdersTableData
}

export const WbOrdersSupplyDropdown =
  observer(
    ({account, wbOrders}: WbOrdersSupplyDropdownProps) => {
      const api = useApi()
      const {wbOrderLoaders} = useLogic()
      const {showWarning} = useMessages()

      const [supplies, setSupplies] = useState<WbSupplyModel[]>([])

      const onDropdownOpen = async () => {
        setSupplies(await api.order.getActiveWbSupplies(account.id))
      }

      const onSetSupply =
        async (supply: WbSupplyModel, {setLoading, setFinished, setClosed}: DropdownItemProps) => {
          setClosed()
          setLoading()

          await api.order.setWbOrdersSupply({
            wbAccountId: account.id,
            wbOrderIds: wbOrders.selectedRows.map(_ => _.id),
            wbSupplyId: supply.id
          }).finally(setFinished)

          setTimeout(wbOrderLoaders.trigger)
        }

      const onCreateSupply =
        async ({setLoading, setFinished, setClosed}: DropdownItemProps) => {
          const supplyName = prompt('Наименование новой поставки')

          if (!supplyName) {
            showWarning('Наименование поставки обязательно')
            return
          }

          setClosed()
          setLoading()

          await api.order.createWbSupplyWithOrders({
            wbAccountId: account.id,
            wbOrderIds: wbOrders.selectedRows.map(_ => _.id),
            name: supplyName
          }).finally(setFinished)

          setTimeout(wbOrderLoaders.trigger)
        }

      return (
        <DropdownButton
          disabled={wbOrders.selectedRowCount === 0}
          text={
            wbOrders.selectedRowCount > 0
            ? `Добавить к поставке (${wbOrders.selectedRowCount})`
            : 'Выберите заказы'
          }
          loadingText={<CircularProgress size={16}/>}
          onOpen={onDropdownOpen}>
          {
            itemProps => <>
              {
                supplies.map(supply =>
                  <ListItem key={supply.id} button onClick={() => onSetSupply(supply, itemProps)}>
                    <ListItemText primary={formatWbSupplyDisplayName(supply)}/>
                  </ListItem>
                )
              }

              <ListItem button onClick={() => onCreateSupply(itemProps)}>
                <ListItemText primary="Создать новую поставку"/>
              </ListItem>
            </>
          }
        </DropdownButton>
      )
    })

export const formatWbSupplyDisplayName =
  (supply: WbSupplyModel) => {
    let result: string

    if (supply.name) {
      result = `${supply.name} (${supply.id})`
    } else {
      result = supply.id
    }

    if (supply.isLargeCargo) {
      result += ' (сКГТ)'
    }

    return result
  }