import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, Grid, TextField } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useAuthFormStyles } from '../hooks/authFormStylesHook'

export const PasswordResetNewPasswordForm =
  observer(() => {
    const classes = useAuthFormStyles()

    const logic = useLogic()

    const {showSuccess} = useMessages()

    const [newPassword, setNewPassword] = useState('')
    const [isPasswordConfirmationMatches, setPasswordConfirmationMatches] = useState(true)
    const [isLoading, setLoading, setFinished] = useBoolState()

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()

        setLoading()

        try {
          await logic.resetPassword(newPassword)
          showSuccess('Пароль успешно изменён!')
        } catch (e) {
          // NOTE: Don't update state on success - this component will be unmounted
          setFinished()
        }
      }

    return (
      <form className={classes.form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              autoFocus
              fullWidth
              variant="outlined"
              id="newPassword"
              name="newPassword"
              type="password"
              label="Новый пароль"
              autoComplete="new-password"
              onChange={e => setNewPassword(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="passwordConfirmation"
              type="password"
              label="Подтверждение пароля"
              error={!isPasswordConfirmationMatches}
              helperText={!isPasswordConfirmationMatches && 'Пароли должны совпадать'}
              onChange={e => setPasswordConfirmationMatches(e.target.value === newPassword)}
            />
          </Grid>

          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            {isLoading ? '...' : 'Изменить пароль'}
          </Button>
        </Grid>
      </form>
    )
  })