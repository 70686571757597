import React from 'react'

import { WbProductsTable, WbProductsTableProps } from './WbProductsTable'

import { wbProductImportColumns } from '../types/wbColumns'

export type WbProductImportTableProps =
  Pick<WbProductsTableProps, 'products'>

export const WbProductImportTable =
  ({products}: WbProductImportTableProps) =>
    // NOTE: Reloading WB products is not needed - they don't change in this table. Unless we add progress tracking for import.
    <WbProductsTable products={products} columns={wbProductImportColumns}/>