import React from 'react'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'

import { LockOutlined } from '@material-ui/icons'
import { Avatar, Container, Grid, Typography } from '@material-ui/core'

import { PasswordResetForm } from './PasswordResetForm'

import { useAuthFormStyles } from '../hooks/authFormStylesHook'

import { appAuthRoutes } from '../common/appRoutes'

export const PasswordResetScreen =
  observer(() => {
    const formClasses = useAuthFormStyles()

    return (
      <Container component="main" maxWidth="xs">
        <div className={formClasses.innerContainer}>
          <Avatar className={formClasses.avatar}>
            <LockOutlined/>
          </Avatar>

          <Typography component="h1" variant="h5">
            Восстановление пароля
          </Typography>

          <PasswordResetForm/>
        </div>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <NavLink exact to={appAuthRoutes.Login} className={formClasses.formLink}>
              Вход
            </NavLink>
          </Grid>
        </Grid>
      </Container>
    )
  })