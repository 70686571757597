import React from 'react'
import { observer } from 'mobx-react-lite'

import { WbProductMultiPicker } from './WbProductMultiPicker'
import { WbProductSinglePicker } from './WbProductSinglePicker'
import { ProductAnyPicker, ProductPickerProps } from '../ProductAnyPicker'

import { useStore } from '../../hooks/storeHook'

export const WbProductPicker =
  observer(
    (props: ProductPickerProps) => {
      const {user} = useStore()

      if (!user) {
        throw new Error('Not authorized')
      }

      return (
        <ProductAnyPicker
          allowMultiple={user.allowMultiWbProducts}
          SinglePicker={WbProductSinglePicker}
          MultiPicker={WbProductMultiPicker}
          {...props}
        />
      )
    })