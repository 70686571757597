import React from 'react'

export type PropFilterItemBase = {
  placeholder: string

  value: string | undefined

  setValue: (filterValue: string | undefined) => void
}

export type StringPropFilterItem =
  PropFilterItemBase & {
  isBoolean?: false
}

export const StringPropFilter =
  ({placeholder, value, setValue}: StringPropFilterItem) =>
    <input type="text" placeholder={placeholder} value={value ?? ''} onChange={e => setValue(e.target.value)}/>