//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MoySkladOrderFeeType {
  Commission = 0,
  Magistral = 1,
  LastMile = 2,
  Assembly = 3
}
