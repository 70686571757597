import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { WbSyncSwitch } from './WbSyncSwitch'
import { OzonSyncSwitch } from './OzonSyncSwitch'
import { MoySkladSyncSwitch } from './MoySkladSyncSwitch'

import { IntegrationAccountTypeMap, IntegrationTypeName, SyncAccountInfo } from '../types/integrationTypeName'
import { YandexMarketSyncSwitch } from './YandexMarketSyncSwitch'

export type SyncEnabledSwitchProps<I extends IntegrationTypeName> =
  Pick<SyncAccountInfo<I>, 'type' | 'account'>

export const SyncEnabledSwitch =
  observer(
    <I extends IntegrationTypeName>
    ({type, account}: SyncEnabledSwitchProps<I>) => {
      // TS can't infer the proper FC type from mapping
      const SyncSwitch = syncSwitchComponents[type] as FC<{account: IntegrationAccountTypeMap[I]}>

      return <SyncSwitch account={account}/>
    })

const syncSwitchComponents: {
  [I in IntegrationTypeName]: FC<{account: IntegrationAccountTypeMap[I]}>
} = {
  Ozon: OzonSyncSwitch,
  Wildberries: WbSyncSwitch,
  YandexMarket: YandexMarketSyncSwitch,
  MoySklad: MoySkladSyncSwitch
}