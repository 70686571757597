import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Switch, SwitchProps } from '@material-ui/core'

export type IOSSwitchProps =
  Omit<SwitchProps, 'classes' | 'disableRipple' | 'focusVisibleClassName'>

export const IOSSwitch =
  (props: IOSSwitchProps) => {
    const classes = useStyles()

    return (
      <Switch
        disableRipple
        focusVisibleClassName={classes.focusVisible}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    )
  }

const useStyles = makeStyles(
  theme => ({
    root: {
      width: 40,
      height: 20,
      padding: 0,
      margin: theme.spacing(1)
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(19px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#3987CF',
          opacity: 1,
          border: 'none'
        }
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: 'none'
      }
    },
    thumb: {
      width: 18,
      height: 18
    },
    track: {
      borderRadius: 20 / 2,
      backgroundColor: '#D7E7F5',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    },
    checked: {},
    focusVisible: {}
  }))