import React, { FormEvent, useEffect, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { autorun } from 'mobx'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core'

import { WbPreviewPrintTable } from './WbPreviewPrintTable'
import { PrintMsBarcodesCheckbox } from './PrintMsBarcodesCheckbox'
import { formatWbSupplyDisplayName } from './WbOrdersSupplyDropdown'

import { useApi } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { MemoryTableData } from '../store/memoryTableData'

import { exact } from '../common/tsUtils'
import { wait } from '../common/commonUtils'
import { fixMsAppScrollProps } from '../common/msAppUtils'
import { isUiException } from '../common/requestValidationError'

import { WbAccountIdProps } from '../types/accountProps'
import { WbPrintOrderColumnId } from '../types/wbColumns'
import { ResponseCancelledError } from '../types/responseCancelledError'

import { WbSupplyModel } from '../server/mpsklad_core/Models/WbSupplyModel'
import { WbPrintOptions } from '../server/mpsklad_core/Models/WbPrintOptions'
import { WbPrintOrderModel } from '../server/mpsklad_core/Models/WbPrintOrderModel'

export type WbLabelsPrintFormProps = {
  wbSupplies: WbSupplyModel[]

  onPrinted: VoidFunction
} & WbAccountIdProps

export const WbLabelsPrintForm =
  observer(
    ({wbAccountId, wbSupplies, onPrinted}: WbLabelsPrintFormProps) => {
      const api = useApi()

      const {showSuccess} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()
      const [submitError, setSubmitError] = useState(null as string | null)

      const options =
        useLocalObservable(() => ({
          // NOTE: Effects are required for initial values that depend on props or state
          wbSupplyId: wbSupplies[0].id,
          hasPurchaseList: true,
          hasMsBarcodes: false,
          hasArticleCounts: false,
          hasWbBarcode: false,
          hasWbOrderId: false,
          doubleUpLabels: false,

          get requestData(): Readonly<WbPrintOptions> {
            return exact<WbPrintOptions>({
              accountId: wbAccountId,
              wbSupplyId: this.wbSupplyId,
              hasPurchaseList: this.hasPurchaseList,
              hasMsBarcodes: this.hasMsBarcodes,
              hasArticleCounts: this.hasArticleCounts,
              hasWbBarcode: this.hasWbBarcode,
              hasWbOrderId: this.hasWbOrderId,
              doubleUpLabels: this.doubleUpLabels
            })
          }
        }))

      const [wbPrintOrders] = useState(
        () => new MemoryTableData<WbPrintOrderModel, WbPrintOrderColumnId>('wbPrintOrders'))

      useEffect(() => {options.wbSupplyId = wbSupplies[0].id}, [options, wbSupplies])

      useLazyEffect(() =>
        autorun(async () => {
          wbPrintOrders.data = []

          try {
            wbPrintOrders.data = await api.previewWbLabelsPreemptive(options.requestData)
          } catch (e) {
            if (e instanceof ResponseCancelledError) {
              // Ignore
              return
            } else {
              throw e
            }
          }
        }))

      const onWbSupplyIdChange =
        (e: React.ChangeEvent<{value: unknown}>) =>
          options.wbSupplyId = e.target.value as string

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (isSubmitting) {
            return
          }

          setSubmitting()
          setSubmitError(null)

          try {
            const fileUrl = await api.label.printWbSupply(options.requestData)

            showSuccess('Создано!')

            await wait(500)
            window.location.assign(fileUrl)

            onPrinted()
          } catch (e) {
            if (isUiException(e)) {
              setSubmitError(e.response.data.uiMessage)
            } else {
              throw e
            }
          } finally {
            setSubmitted()
          }
        }

      return (
        <Box padding={2} paddingTop={0}>
          <form onSubmit={onSubmit}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={6}>
                <FormHelperText>
                  Печать этикеток возможна только для заказов, добавленных в поставку.
                  <br/>
                  Возможна печать до 300 заказов в одном файле.
                </FormHelperText>
              </Grid>

              <Grid item container xs={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Поставка
                  </FormLabel>

                  <Select
                    value={options.wbSupplyId}
                    onChange={onWbSupplyIdChange}
                    MenuProps={fixMsAppScrollProps}
                  >
                    {
                      wbSupplies.map(wbSupply =>
                        <MenuItem key={wbSupply.id} value={wbSupply.id}>
                          {formatWbSupplyDisplayName(wbSupply)}
                        </MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item container xs={6}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Что печатать
                    </FormLabel>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="feedCheckbox"
                            checked
                            disabled
                          />
                        }
                        label="Лента"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="purchaseListCheckbox"
                            checked={options.hasPurchaseList}
                            onChange={e => options.hasPurchaseList = e.target.checked}
                          />
                        }
                        label="Список покупок"
                      />

                      <PrintMsBarcodesCheckbox
                        checked={options.hasMsBarcodes}
                        onChange={checked => options.hasMsBarcodes = checked}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="hasWbBarcodeCheckbox"
                            checked={options.hasWbBarcode}
                            onChange={e => options.hasWbBarcode = e.target.checked}
                          />
                        }
                        label="Штрихкод товара в Wildberries"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container xs={6}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Опции печати
                    </FormLabel>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="doubleUpLabelsCheckbox"
                            checked={options.doubleUpLabels}
                            onChange={e => options.doubleUpLabels = e.target.checked}
                          />
                        }
                        label="Задваивать каждую этикетку"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="hasArticleCountsCheckbox"
                            checked={options.hasArticleCounts}
                            onChange={e => options.hasArticleCounts = e.target.checked}
                          />
                        }
                        label="Добавить список артикулов"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="hasWbOrderIdCheckbox"
                            checked={options.hasWbOrderId}
                            onChange={e => options.hasWbOrderId = e.target.checked}
                          />
                        }
                        label="Добавить идентификатор сборочного задания в Маркетплейсе"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked
                          disabled
                          color="primary"
                          name="closeSupplyCheckbox"
                        />
                      }
                      label="Закрыть поставку после печати"
                    />

                    <FormHelperText>
                      Печать этикетки для поставки возможна только при закрытии поставки.
                      <br/>
                      При успешном создании этикеток поставка будет закрыта (передана в доставку).
                    </FormHelperText>
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item>
                <Button color="secondary" variant="contained" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Создаём...' : 'Создать этикетки'}
                </Button>
              </Grid>

              {
                !!submitError &&
                <Grid item>
                  <FormLabel error>
                    {submitError}
                  </FormLabel>
                </Grid>
              }

              <Grid item xl={6}>
                <Box marginBottom={2}>
                  Предпросмотр
                </Box>

                <Paper>
                  <WbPreviewPrintTable orders={wbPrintOrders}/>
                </Paper>
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    })