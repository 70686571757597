import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import './index.css'

import { App } from './components/App'
import { AppErrorBoundary } from './components/AppErrorBoundary'

import { reportWebVitals } from './reportWebVitals'

import { ErrorApiLogic } from './store/logic/errorApiLogic'

import { __PRODUCTION__ } from './common/devUtils'
import { initYandexMetrika } from './common/yandexMetrika'

// NOTE: Strict mode double-invokes effects, which can be painful for debugging
ReactDOM.render(
  <StrictMode>
    <AppErrorBoundary createErrorApiLogic={() => new ErrorApiLogic()}>
      <App/>
    </AppErrorBoundary>
  </StrictMode>,
  document.getElementById('root'))

if (__PRODUCTION__) {
  initYandexMetrika()
}

reportWebVitals()