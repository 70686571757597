import React from 'react'

import { StringPropFilter, StringPropFilterItem } from './StringPropFilter'
import { BooleanPropFilter, BooleanPropFilterItem } from './BooleanPropFilter'

export type PropFilterItem = StringPropFilterItem | BooleanPropFilterItem

export type PropFilterProps = {
  filter: PropFilterItem
}

export const PropFilter =
  ({filter}: PropFilterProps) =>
    filter.isBoolean ? <BooleanPropFilter {...filter}/>
                     : <StringPropFilter {...filter}/>