import React from 'react'

import { TableCell } from '@material-ui/core'

import { SyncLogsButton } from './SyncLogsButton'
import { CombinedOrderSyncLogsButton } from './CombinedOrderSyncLogsButton'

import { MyRowProps } from '../../types/tableTypes'

import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'
import { WbOrderModel } from '../../server/mpsklad_core/Models/Orders/WbOrderModel'
import { OzonOrderModel } from '../../server/mpsklad_core/Models/Orders/OzonOrderModel'
import { StoreOrderModelBase } from '../../server/mpsklad_core/Models/Orders/StoreOrderModelBase'
import { YandexMarketOrderModel } from '../../server/mpsklad_core/Models/Orders/YandexMarketOrderModel'

export type StoreOrderSyncLogsCellProps =
  MyRowProps<StoreOrderModelBase>
  & {
  type: Exclude<IntegrationType, IntegrationType.MoySklad>
}

export const StoreOrderSyncLogsCell =
  ({type, row}: StoreOrderSyncLogsCellProps) =>
    <TableCell className="table-border">
      <SyncLogsButton themed type={type} accountId={row.accountId} orderId={row.id}/>

      {
        !!row.moySkladOrderId &&
        <>
          <SyncLogsButton themed type={IntegrationType.MoySklad} orderId={row.moySkladOrderId}/>

          <CombinedOrderSyncLogsButton msOrderId={row.moySkladOrderId}/>
        </>
      }
    </TableCell>

export const OzonOrderSyncLogsCell =
  ({row}: MyRowProps<OzonOrderModel>) =>
    <StoreOrderSyncLogsCell type={IntegrationType.Ozon} row={row}/>

export const WbOrderSyncLogsCell =
  ({row}: MyRowProps<WbOrderModel>) =>
    <StoreOrderSyncLogsCell type={IntegrationType.Wildberries} row={row}/>

export const YandexMarketOrderSyncLogsCell =
  ({row}: MyRowProps<YandexMarketOrderModel>) =>
    <StoreOrderSyncLogsCell type={IntegrationType.YandexMarket} row={row}/>