import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { ScreenLoader } from './ScreenLoader'

import { useLogic } from '../hooks/storeHook'
import { useQuery } from '../hooks/routeHooks'
import { useLazyEffect } from '../hooks/commonHooks'

import { required } from '../common/objectUtils'
import { sendMoySkladAppHeight } from '../common/msAppUtils'

import { MoySkladAppScreenParams } from '../types/moySkladAppTypes'
import { MoySkladAppType } from '../server/mpsklad_common/MoySkladAppType'

export const MoySkladAppAuthScreen =
  observer(() => {
    const logic = useLogic()

    const {appType} = useParams<MoySkladAppScreenParams>()
    const appTypeValue = MoySkladAppType[appType ?? 'OzonAndWb']

    const query = useQuery()
    const contextKey = required(query.get('contextKey'))

    useEffect(sendMoySkladAppHeight)

    useLazyEffect(() => logic.loginMoySkladApp(appTypeValue, contextKey))

    return (
      <Box minHeight={400}>
        <ScreenLoader/>
      </Box>
    )
  })