import React from 'react'
import { observer } from 'mobx-react-lite'

import { Link } from '@material-ui/core'
import { OpenInNewOutlined } from '@material-ui/icons'

import { AppNotificationBar } from './AppNotificationBar'

import { useStore } from '../hooks/storeHook'

import { msAppUrls } from '../common/urlUtility'
import { AmountInsertStyle, pluralize } from '../common/stringUtility'

import { UserAuthModel } from '../server/mpsklad_core/Models/UserAuthModel'

export const MoySkladAppTrialNotificationBar =
  observer(() => {
    const {user} = useStore()

    if (!user || user.moySkladAppTrialRemainingDays == null || user.moySkladAppTrialRemainingDays > 3) {
      return null
    }

    return (
      <AppNotificationBar severity="warning" notificationId={createTrialNotificationId(user)}>
        Внимание! До окончания тестового периода {
        pluralize(user.moySkladAppTrialRemainingDays, 'остался', 'осталось', 'осталось', AmountInsertStyle.None)} {
        pluralize(user.moySkladAppTrialRemainingDays, 'день', 'дня', 'дней')
      }. <Link href={msAppUrls.payments} target="_blank">Продлите тариф в МоёмСкладе.<OpenInNewOutlined/></Link>
      </AppNotificationBar>
    )
  })

const createTrialNotificationId =
  // Email is used to show notifications to admins
  (user: UserAuthModel) => `msAppTrial_${user.email}_${user.moySkladAppTrialRemainingDays}`