import React from 'react'
import { observer } from 'mobx-react-lite'

import { SyncStateRow } from './SyncStateRow'
import { SyncStatesProps } from './PullProductsStateRow'

export const PushOrdersStateRow =
  observer(
    ({type, syncStates}: SyncStatesProps) =>
      <SyncStateRow title="Отправка заказов" type={type} state={syncStates.pushOrdersState}/>
  )