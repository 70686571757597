export const wait = (timeoutMs: number) =>
  new Promise(resolve => setTimeout(resolve, timeoutMs))

export const waitAnimationFrame = () =>
  new Promise(resolve => requestAnimationFrame(resolve))

export const isLocalStorageAllowed = (() => {
  try {
    localStorage.getItem('MPsklad')
    localStorage.setItem('MPsklad', 'MPsklad')
    return true
  } catch (e) {
    console.error(
      typeof e === 'object' && !!e && 'name' in e
      && e?.name === 'SecurityError'
      ? 'localStorage is not allowed'
      : 'localStorage test failed', e)
    return false
  }
})()