export const required = <T>(value: T | null | undefined): T => {
  if (value === null || value === undefined) {
    throw new Error('Value is null or undefined')
  }

  return value
}

export const requiredAs = <T = never>(value: unknown): T =>
  required(value as T | null | undefined)

export const ensure = <T>(value: T | null | undefined): asserts value is T => {
  required(value)
}