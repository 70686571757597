//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MsProductProp {
  Id = 1,
  Article = 2,
  Name = 3,
  BarcodeGtin = 4,
  BarcodeEan8 = 5,
  BarcodeEan13 = 6,
  BarcodeCode128 = 7,
  Code = 8
}
