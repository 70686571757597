import React, { FormEvent } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, Typography } from '@material-ui/core'

import { ImportProductsProps } from './ImportScreen'

import { useImportEvents } from '../hooks/importHooks'
import { usePaperStyles } from '../hooks/paperStylesHook'

import { IServerTableData } from '../types/tableTypes'
import { StoreAccountBaseProps } from '../types/accountProps'
import { StoreProductModel } from '../server/mpsklad_core/Models/StoreProductModel'
import { ImportProductsOptionsModel } from '../server/mpsklad_core/Models/ImportModels/ImportProductsModels/ImportProductsOptionsModel'

export type ImportProductsFormProps<
  TProducts extends IServerTableData<TRow, TColumnId>, TRow extends StoreProductModel, TColumnId> =
  StoreAccountBaseProps
  & ImportProductsProps<TProducts, TRow, TColumnId>
  & {
  importProductsFunc: (accountId: number, productOptionsModel: ImportProductsOptionsModel) => Promise<void>
}

export const ImportProductsForm =
  observer(
    <TProducts extends IServerTableData<TRow, TColumnId>, TRow extends StoreProductModel, TColumnId>
    ({
       account,
       importProductsFunc,
       products
     }: ImportProductsFormProps<TProducts, TRow, TColumnId>) => {
      const classes = usePaperStyles()

      const {onProductImport, isImportDisabled} = useImportEvents(account, products)

      const productImportOptions =
        useLocalObservable<ImportProductsOptionsModel>(
          () => ({
            addNewProducts: true,
            addOptions: {
              variantsAsStandalone: false,
              images: true,
              barcodes: true,
              prices: true
            },
            updateExistingProducts: false,
            updateOptions: {
              name: true,
              article: true,
              code: true,
              images: true,
              barcodes: true,
              prices: true
            },
            productIds: []
          } satisfies ImportProductsOptionsModel))

      const onImportProducts = onProductImport(importProductsFunc, productImportOptions)

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()
          await onImportProducts()
        }

      // TODO: Extract components for checkboxes
      return (
        <Paper className={classes.paper}>
          <Box padding={2}>
            <form onSubmit={onSubmit}>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="h6">
                    Импорт товаров в МойСклад
                  </Typography>
                </Grid>

                <Grid item container direction="row">
                  <Grid item xs={6}>
                    <FormControlLabel
                      label="Добавить новые товары в МойСклад"
                      control={
                        <Checkbox
                          color="primary"
                          checked={productImportOptions.addNewProducts}
                          name="addNewProducts"
                          onChange={e => productImportOptions.addNewProducts = e.target.checked}
                        />
                      }
                    />

                    {
                      productImportOptions.addNewProducts &&
                      <Box display="flex" flexDirection="column" ml={3}>
                        <FormControlLabel
                          color="primary"
                          label="Создать модификации товаров отдельными карточками"
                          control={
                            <Checkbox
                              checked={productImportOptions.addOptions.variantsAsStandalone}
                              onChange={e => productImportOptions.addOptions.variantsAsStandalone = e.target.checked}
                            />
                          }
                        />

                        <FormControlLabel
                          color="primary"
                          label="Добавить картинки"
                          control={
                            <Checkbox
                              checked={productImportOptions.addOptions.images}
                              onChange={e => productImportOptions.addOptions.images = e.target.checked}
                            />
                          }
                        />

                        <FormControlLabel
                          color="primary"
                          label="Добавить баркод"
                          control={
                            <Checkbox
                              checked={productImportOptions.addOptions.barcodes}
                              onChange={e => productImportOptions.addOptions.barcodes = e.target.checked}
                            />
                          }
                        />

                        <FormControlLabel
                          color="primary"
                          label="Добавить цену"
                          control={
                            <Checkbox
                              checked={productImportOptions.addOptions.prices}
                              onChange={e => productImportOptions.addOptions.prices = e.target.checked}
                            />
                          }
                        />
                      </Box>
                    }
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                      disabled
                      label="Обновить существующие в МоёмСкладе товары (скоро™)"
                      control={
                        <Checkbox
                          color="primary"
                          checked={productImportOptions.updateExistingProducts}
                          name="updateExistingProducts"
                          onChange={e => productImportOptions.updateExistingProducts = e.target.checked}
                        />
                      }
                    />

                    {
                      productImportOptions.updateExistingProducts &&
                      <Box display="flex" flexDirection="column" ml={3}>
                        <FormControlLabel
                          color="primary"
                          label="Добавить картинки"
                          control={
                            <Checkbox
                              checked={productImportOptions.updateOptions.images}
                              onChange={e => productImportOptions.updateOptions.images = e.target.checked}
                            />
                          }
                        />

                        <FormControlLabel
                          color="primary"
                          label="Добавить баркод"
                          control={
                            <Checkbox
                              checked={productImportOptions.updateOptions.barcodes}
                              onChange={e => productImportOptions.updateOptions.barcodes = e.target.checked}
                            />
                          }
                        />

                        <FormControlLabel
                          color="primary"
                          label="Добавить цену"
                          control={
                            <Checkbox
                              checked={productImportOptions.updateOptions.prices}
                              onChange={e => productImportOptions.updateOptions.prices = e.target.checked}
                            />
                          }
                        />
                      </Box>
                    }
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      isImportDisabled
                      || !(productImportOptions.addNewProducts || productImportOptions.updateExistingProducts)}>
                    {isImportDisabled ? 'Импортируем...' : 'Импортировать товары'}
                  </Button>

                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      )
    })