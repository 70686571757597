import React, { ChangeEvent, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, Input } from '@material-ui/core'

import { ButtonLoader } from './ButtonLoader'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { pluralize } from '../common/stringUtility'

import { WbAccountIdProps } from '../types/accountProps'

export const WbUploadProductsForm =
  observer(
    ({wbAccountId}: WbAccountIdProps) => {
      const {uploadWbProducts} = useLogic()
      const {showSuccess, showError} = useMessages()

      const [productsFile, setProductsFile] = useState<File | null>(null)

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()

      const fileInputRef = useRef<HTMLInputElement>()

      const onFileChange =
        (e: ChangeEvent<HTMLInputElement>) =>
          setProductsFile(e.target.files?.[0] ?? null)

      const onSubmit = async () => {
        if (!productsFile) {
          showError('Выберите файл')
          return
        }

        setSubmitting()

        try {
          const {successCount, errorCount} = await uploadWbProducts(wbAccountId, productsFile)

          if (errorCount > 0) {
            showError(
              `${pluralize(errorCount, 'товар', 'товара', 'товаров')} с ошибкой - смотрите в логах синхронизации`)
          }

          showSuccess(`Загрузили ${pluralize(successCount, 'товар', 'товара', 'товаров')}`)

          // Reset the file input
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
            setProductsFile(null)
          }
        } finally {
          setSubmitted()
        }
      }

      return <>
        <Input
          disableUnderline
          type="file"
          title="Выбрать файл отчёта"
          inputProps={{accept: '.xls,.xlsx', ref: fileInputRef}}
          disabled={isSubmitting}
          onChange={onFileChange}
        />

        <Button
          variant="contained"
          color={productsFile ? 'secondary' : 'default'}
          disabled={!productsFile || isSubmitting}
          onClick={onSubmit}>
          {
            !!productsFile
            ? isSubmitting
              ? <ButtonLoader text="Загружаем..."/>
              : 'Загрузить'
            : 'Выберите файл'
          }
        </Button>
      </>
    })