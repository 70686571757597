import React from 'react'
import { observer } from 'mobx-react-lite'

import { OzonSyncScreen } from './OzonSyncScreen'
import { StoreScreenTabs, StoreScreenTabsBaseProps } from './StoreScreenTabs'

import { useLogic, useStore } from '../hooks/storeHook'

import { required } from '../common/objectUtils'
import { makeOzonAccountName } from '../common/accountUtils'

import { MoySkladAppType } from '../server/mpsklad_common/MoySkladAppType'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'

export type OzonScreenTabsProps = StoreScreenTabsBaseProps<OzonAccountModel>

export const OzonScreenTabs =
  observer(
    ({canAddAccount, ...props}: OzonScreenTabsProps) => {
      const {syncStore: {ozonAccounts}, user} = useStore()
      const logic = useLogic()

      const {moySkladAppType} = required(user)

      canAddAccount = canAddAccount && (
        moySkladAppType == null
        || moySkladAppType === MoySkladAppType.Ozon
        || moySkladAppType === MoySkladAppType.OzonAndWb
        || (moySkladAppType === MoySkladAppType.Mini && ozonAccounts.length < 2))

      return <StoreScreenTabs
        storeType={IntegrationType.Ozon}
        storeAccounts={ozonAccounts}
        canAddAccount={canAddAccount}
        onRemoveAccount={logic.deleteOzonAccount}
        makeAccountName={makeOzonAccountName}
        AddAccountComponent={OzonSyncScreen}
        {...props}
      />
    })