import React from 'react'
import { observer } from 'mobx-react-lite'

import { SyncStatesTable } from './SyncStatesTable'
import { AccountSyncStatesProps } from './OzonSyncStates'
import { PullStocksStateRow } from './PullStocksStateRow'
import { PullOrdersStateRow } from './PullOrdersStateRow'
import { PushOrdersStateRow } from './PushOrdersStateRow'
import { PullProductsStateRow } from './PullProductsStateRow'

export const MoySkladSyncStates =
  observer(
    ({account: {syncStates}}: AccountSyncStatesProps) =>
      <SyncStatesTable>
        <PullProductsStateRow type="MoySklad" syncStates={syncStates}/>

        <PullStocksStateRow type="MoySklad" syncStates={syncStates}/>

        <PullOrdersStateRow type="MoySklad" syncStates={syncStates}/>

        <PushOrdersStateRow type="MoySklad" syncStates={syncStates}/>
      </SyncStatesTable>
  )