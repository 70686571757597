import React from 'react'

import { LoadOrdersForm } from './LoadOrdersForm'

import { useApi } from '../hooks/storeHook'
import { WbAccountIdProps } from '../types/accountProps'

export const WbLoadOrdersForm =
  ({wbAccountId}: WbAccountIdProps) => {
    const api = useApi()

    return <LoadOrdersForm accountId={wbAccountId} loadOrders={api.userSync.loadWbOrders}/>
  }