import React, { ChangeEvent, useRef } from 'react'
import uniqueId from 'lodash/uniqueId'

import { CircularProgress, Switch } from '@material-ui/core'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

export type StoreMultiProductSwitchProps = {
  allowMultiProducts: boolean

  setMultiProducts: (allow: boolean) => Promise<void>
}

export const StoreMultiProductSwitch =
  ({allowMultiProducts, setMultiProducts}: StoreMultiProductSwitchProps) => {
    const {showSuccess, showError} = useMessages()
    const [isSubmitting, setSubmitting, setFinished] = useBoolState()

    const switchName = useRef(uniqueId('StoreMultiProductSwitch_')).current

    const onAllowMultiChange =
      async (e: ChangeEvent<HTMLInputElement>) => {
        const allow = e.target.checked

        setSubmitting()

        try {
          await setMultiProducts(allow)
          showSuccess(allow ? 'Разрешено' : 'Запрещено')
        } catch (e) {
          console.log('Failed to toggle multi products', e)
          showError('Ошибка при сохранении')
        } finally {
          setFinished()
        }
      }

    return isSubmitting
           ? <CircularProgress size={19}/>
           : <Switch
             size="small"
             checked={allowMultiProducts}
             onChange={onAllowMultiChange}
             name={switchName}
             color="primary"
           />
  }