import { makeObservable, observable } from 'mobx'

import { MyProductsTableData } from './myProductsTableData'
import { createServerTableData, ServerTableData, TablePageLoader, TablePageLoader2 } from './serverTableData'

import { required } from '../common/objectUtils'

import { WbProductColumnId } from '../types/wbColumns'
import { OzonProductColumnId } from '../types/ozonColumns'
import { MoySkladProductColumnId } from '../types/moySkladColumns'
import { YandexMarketProductColumnId } from '../types/yandexMarketColumns'

import { MyProductModel } from '../server/mpsklad_core/Models/MyProductModel'
import { WbProductModel } from '../server/mpsklad_core/Models/WbProductModel'
import { OzonProductModel } from '../server/mpsklad_core/Models/OzonProductModel'
import { MyProductDiffModel } from '../server/mpsklad_core/Models/MyProductDiffModel'
import { MyProductsInfoModel } from '../server/mpsklad_core/Models/MyProductsInfoModel'
import { MoySkladProductModel } from '../server/mpsklad_core/Models/MoySkladProductModel'
import { TablePageOptionsBase } from '../server/mpsklad_core/Models/TablePageOptionsBase'
import { ProductsTablePageOptions } from '../server/mpsklad_core/Models/ProductsTablePageOptions'
import { YandexMarketProductModel } from '../server/mpsklad_core/Models/YandexMarketProductModel'
import { MyProductsTableOrderColumn } from '../server/mpsklad_core/Models/MyProductsTableOrderColumn'
import { MyProductsTableFilterColumn } from '../server/mpsklad_core/Models/MyProductsTableFilterColumn'
import { OzonProductsTablePageOptions } from '../server/mpsklad_core/Models/OzonProductsTablePageOptions'
import { OzonProductsTableFilterColumn } from '../server/mpsklad_core/Models/OzonProductsTableFilterColumn'
import { StoreProductsTableFilterColumn } from '../server/mpsklad_core/Models/StoreProductsTableFilterColumn'

export type StoreProductsLoader<TProduct extends object> =
  (accountId: number) => TablePageLoader<TProduct, typeof StoreProductsTableFilterColumn, never>

export type OzonProductsLoader =
  (accountId: number) => TablePageLoader<OzonProductModel, typeof OzonProductsTableFilterColumn, never>

export type OzonProductsTableData =
  ServerTableData<OzonProductModel, OzonProductColumnId, typeof OzonProductsTableFilterColumn, never>

export type WbProductsTableData =
  ServerTableData<WbProductModel, WbProductColumnId, typeof StoreProductsTableFilterColumn, never>

export type YandexMarketProductsTableData =
  ServerTableData<YandexMarketProductModel, YandexMarketProductColumnId, typeof StoreProductsTableFilterColumn, never>

export class ProductStore {
  readonly myProducts: MyProductsTableData

  readonly moySkladProducts =
    createServerTableData<MoySkladProductModel, MoySkladProductColumnId>('moySkladProducts')()

  @observable
  productDiffs: MyProductDiffModel[] = []

  @observable
  myProductsInfo: MyProductsInfoModel | null

  private ozonProductsPageLoader: OzonProductsLoader | null = null

  private wbProductsPageLoader: StoreProductsLoader<WbProductModel> | null = null

  private yandexMarketProductsPageLoader: StoreProductsLoader<YandexMarketProductModel> | null = null

  constructor(myProducts: MyProductsTableData) {
    makeObservable(this)

    this.myProducts = myProducts
    this.myProductsInfo = null
  }

  init =
    (myProductsPageLoader: TablePageLoader<MyProductModel, typeof MyProductsTableFilterColumn, typeof MyProductsTableOrderColumn>,
     ozonProductsPageLoader: TablePageLoader2<OzonProductModel, OzonProductsTablePageOptions>,
     wbProductsPageLoader: TablePageLoader2<WbProductModel, ProductsTablePageOptions>,
     yandexMarketProductsPageLoader: TablePageLoader2<YandexMarketProductModel, ProductsTablePageOptions>,
     msProductsPageLoader: TablePageLoader2<MoySkladProductModel, TablePageOptionsBase>) => {
      this.myProducts.init(myProductsPageLoader)

      this.ozonProductsPageLoader = accountId =>
        (options, config) => ozonProductsPageLoader({accountId, ...options}, config)

      this.wbProductsPageLoader = accountId =>
        (options, config) => wbProductsPageLoader({accountId, ...options}, config)

      this.yandexMarketProductsPageLoader = accountId =>
        (options, config) => yandexMarketProductsPageLoader({accountId, ...options}, config)

      this.moySkladProducts.init(msProductsPageLoader)
    }

  findProduct =
    (productId: number) =>
      required(this.myProducts.data.find(_ => _.id === productId))

  replaceProduct =
    (product: MyProductModel) => {
      const productIndex = this.myProducts.data.findIndex(_ => _.id === product.id)

      if (productIndex < 0) {
        throw new Error(`Product #${product.id} not found`)
      }

      this.myProducts.data[productIndex] = product
    }

  createOzonProductsTable =
    (ozonAccountId: number): OzonProductsTableData =>
      createServerTableData<OzonProductModel, OzonProductColumnId>('ozonProducts')(OzonProductsTableFilterColumn)
        .init(required(this.ozonProductsPageLoader)(ozonAccountId))
        .setPropFilter('isArchived', JSON.stringify(false))

  createWbProductsTable =
    (wbAccountId: number): WbProductsTableData =>
      createServerTableData<WbProductModel, WbProductColumnId>('wbProducts')(StoreProductsTableFilterColumn)
        .init(required(this.wbProductsPageLoader)(wbAccountId))

  createYandexMarketProductsTable =
    (yandexMarketAccountId: number): YandexMarketProductsTableData =>
      createServerTableData<YandexMarketProductModel, YandexMarketProductColumnId>('yandexMarketProducts')(
        StoreProductsTableFilterColumn)
        .init(required(this.yandexMarketProductsPageLoader)(yandexMarketAccountId))
}