import { createColumns } from './columnGroupCreator'
import { TableColumn } from './tableTypes'

import { TableDateCell } from '../components/tableCells/TableDateCell'
import { MoySkladIdCell } from '../components/tableCells/MoySkladIdCell'
import { OzonOrderSyncLogsCell } from '../components/tableCells/StoreOrderSyncLogsCell'
import { OzonOrderSyncErrorCell } from '../components/tableCells/OzonOrderSyncErrorCell'

import { formatPrice } from '../common/numberUtils'

import { OzonOrderModel } from '../server/mpsklad_core/Models/Orders/OzonOrderModel'

export type OzonOrderColumnId =
  keyof OzonOrderModel
  | 'moySkladId'
  | 'logs'

export type OzonOrderColumn = TableColumn<OzonOrderColumnId, OzonOrderModel>

export const ozonOrderColumns: OzonOrderColumn[] =
  createColumns<OzonOrderColumnId, OzonOrderModel>({
    id: 'id',
    title: 'Id'
  }, {
    id: 'createdAt',
    title: 'Дата создания',
    CellComponent: TableDateCell
  }, {
    id: 'ozonId',
    title: 'Id в Ozon'
  }, {
    id: 'postingNumber',
    title: 'Номер отправления'
  }, {
    id: 'products',
    title: 'Товары',
    accessor: _ => _.products.map(p => `${p.code} - ${p.name} (${p.quantity})`)
  }, {
    id: 'price',
    title: 'Цена продажи, ₽',
    accessor: _ => formatPrice(_.price)
  }, {
    id: 'commissionFee',
    title: 'Комиссия, ₽',
    accessor: _ => formatPrice(_.commissionFee)
  }, {
    id: 'logisticsMagistralFee',
    title: 'Магистраль, ₽',
    accessor: _ => formatPrice(_.logisticsMagistralFee)
  }, {
    id: 'logisticsLastMileFee',
    title: 'Последняя миля, ₽',
    accessor: _ => formatPrice(_.logisticsLastMileFee)
  }, {
    id: 'logisticsRestFee',
    title: 'Сборка, ₽',
    accessor: _ => formatPrice(_.logisticsRestFee)
  }, {
    id: 'status',
    title: 'Статус'
  }, {
    id: 'moySkladId',
    title: 'GUID в МоёмСкладе',
    CellComponent: MoySkladIdCell
  }, {
    id: 'syncErrorId',
    title: 'Ошибки',
    CellComponent: OzonOrderSyncErrorCell
  }, {
    id: 'logs',
    title: 'Логи',
    CellComponent: OzonOrderSyncLogsCell
  })