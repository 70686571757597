//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CommissionsRecordingType {
  Nothing = 0,
  Commission = 1,
  LogisticAndFees = 2,
  CommissionLogisticAndFees = 3
}
