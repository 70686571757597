import React from 'react'

import { Clear } from '@material-ui/icons'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CircularProgress, IconButton } from '@material-ui/core'

import { useBoolState } from '../../hooks/commonHooks'

export type ExternalProductLabelProps = {
  label: string

  onRemove: () => void | Promise<void>
}

export const ExternalProductLabel =
  ({label, onRemove}: ExternalProductLabelProps) => {
    const classes = useStyles()

    const [isLoading, setLoading, setLoaded] = useBoolState()

    const onRemoveClick = async () => {
      const removeResult = onRemove()

      if (removeResult instanceof Promise) {
        setLoading()

        try {
          await removeResult
        } finally {
          setLoaded()
        }
      }
    }

    return (
      <div className={classes.wrap}>
        <span>
          {label}
        </span>

        {
          isLoading
          ?
          <div>
            <CircularProgress size={18}/>
          </div>
          :
          <IconButton title="Разматчить" size="small" onClick={onRemoveClick} className={classes.removeIcon}>
            <Clear/>
          </IconButton>
        }

      </div>
    )
  }

const useStyles =
  makeStyles(theme =>
    createStyles({
      wrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // TODO: Pass through via props?
        minWidth: 270,
        maxWidth: 300,
        padding: 4,
        paddingLeft: 10,
        margin: '3px 0'
      },
      removeIcon: {
        '&:hover': {
          color: theme.palette.warning.dark
        }
      }
    }))