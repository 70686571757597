import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { PasswordResetCodeForm } from './PasswordResetCodeForm'
import { PasswordResetEmailForm } from './PasswordResetEmailForm'
import { PasswordResetNewPasswordForm } from './PasswordResetNewPasswordForm'

import { useStore } from '../hooks/storeHook'

import { PasswordResetState } from '../types/passwordResetState'

export const PasswordResetForm =
  observer(() => {
    const {passwordResetStore: {state}} = useStore()

    switch (state) {
      case PasswordResetState.RequestEmail:
        return <PasswordResetEmailForm/>

      case PasswordResetState.RequestCode:
        return <PasswordResetCodeForm/>

      case PasswordResetState.RequestNewPassword:
        return <PasswordResetNewPasswordForm/>

      case PasswordResetState.Finished:
        return <Box padding={3}>Пароль успешно изменён!</Box>
    }
  })