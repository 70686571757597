import React from 'react'
import { observer } from 'mobx-react-lite'

import { WbSyncScreen } from './WbSyncScreen'
import { StoreScreenTabs, StoreScreenTabsBaseProps } from './StoreScreenTabs'

import { useLogic, useStore } from '../hooks/storeHook'

import { required } from '../common/objectUtils'
import { makeWbAccountName } from '../common/accountUtils'

import { MoySkladAppType } from '../server/mpsklad_common/MoySkladAppType'
import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

export type WbScreenTabsProps = StoreScreenTabsBaseProps<WbAccountModel>

export const WbScreenTabs =
  observer(
    ({canAddAccount, ...props}: WbScreenTabsProps) => {
      const {syncStore: {wbAccounts}, user} = useStore()

      const logic = useLogic()

      const {moySkladAppType} = required(user)

      canAddAccount = canAddAccount && (
        moySkladAppType == null
        || moySkladAppType === MoySkladAppType.Wb
        || moySkladAppType === MoySkladAppType.OzonAndWb
        || (moySkladAppType === MoySkladAppType.Mini && wbAccounts.length < 2))

      return <StoreScreenTabs
        storeType={IntegrationType.Wildberries}
        storeAccounts={wbAccounts}
        canAddAccount={canAddAccount}
        onRemoveAccount={logic.deleteWbAccount}
        makeAccountName={makeWbAccountName}
        AddAccountComponent={WbSyncScreen}
        {...props}
      />
    })