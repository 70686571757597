import React from 'react'

import { TableCell } from '@material-ui/core'

import { SyncLogsButton } from './SyncLogsButton'

import { MyRowProps } from '../../types/tableTypes'

import { WbProductModel } from '../../server/mpsklad_core/Models/WbProductModel'
import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'
import { OzonProductModel } from '../../server/mpsklad_core/Models/OzonProductModel'
import { StoreProductModel } from '../../server/mpsklad_core/Models/StoreProductModel'
import { YandexMarketProductModel } from '../../server/mpsklad_core/Models/YandexMarketProductModel'

export type StoreProductSyncLogsCellProps =
  MyRowProps<StoreProductModel>
  & {
  type: Exclude<IntegrationType, IntegrationType.MoySklad>
}

// TODO: Do we need a combined button for MS + one specific store product?
export const StoreProductSyncLogsCell =
  ({row, type}: StoreProductSyncLogsCellProps) =>
    <TableCell>
      <SyncLogsButton themed type={type} accountId={row.accountId} productId={row.id}/>
    </TableCell>

export const OzonProductSyncLogsCell =
  ({row}: MyRowProps<OzonProductModel>) =>
    <StoreProductSyncLogsCell type={IntegrationType.Ozon} row={row}/>

export const WbProductSyncLogsCell =
  ({row}: MyRowProps<WbProductModel>) =>
    <StoreProductSyncLogsCell type={IntegrationType.Wildberries} row={row}/>

export const YandexMarketProductSyncLogsCell =
  ({row}: MyRowProps<YandexMarketProductModel>) =>
    <StoreProductSyncLogsCell type={IntegrationType.YandexMarket} row={row}/>