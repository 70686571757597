import React from 'react'
import { observer } from 'mobx-react-lite'

import { ProductSyncSwitch } from '../ProductSyncSwitch'

import { useLogic } from '../../hooks/storeHook'

import { OzonProductModel } from '../../server/mpsklad_core/Models/OzonProductModel'

export type ProductOzonSyncSwitchProps = {
  msProductId: number

  ozonProduct: OzonProductModel
}

export const ProductOzonSyncSwitch =
  observer(
    ({msProductId, ozonProduct}: ProductOzonSyncSwitchProps) => {
      const {setOzonProductSync} = useLogic()

      return (
        <ProductSyncSwitch
          syncEnabled={ozonProduct.syncEnabled}
          onChange={syncEnabled => setOzonProductSync(msProductId, ozonProduct.id, syncEnabled)}
        />
      )
    })