import React from 'react'
import { observer } from 'mobx-react-lite'

import { PropFilters } from './PropFilters'
import { PropFilterItem } from './PropFilter'

import { useStore } from '../hooks/storeHook'
import { useLazyUnEffect } from '../hooks/commonHooks'

import { exact } from '../common/tsUtils'

import { NavStoreType } from '../server/mpsklad_core/Models/NavStoreType'

export const MyProductsPropFilter =
  observer(() => {
    const {
      homeHasStoreType,
      productStore: {myProducts: {setPropFilter, resetPropFilters, findPropFilterValue}}
    } = useStore()

    useLazyUnEffect(resetPropFilters)

    const filters =
      exact<Array<PropFilterItem | null>>([{
        placeholder: 'Название',
        value: findPropFilterValue('name'),
        setValue: _ => setPropFilter('name', _)
      }, {
        placeholder: 'Артикул',
        value: findPropFilterValue('article'),
        setValue: _ => setPropFilter('article', _)
      }, {
        placeholder: 'Штрихкод',
        value: findPropFilterValue('barcodes'),
        setValue: _ => setPropFilter('barcodes', _)
      }])

    if (homeHasStoreType(NavStoreType.Ozon)) {
      filters.push({
        placeholder: 'Артикул Ozon',
        value: findPropFilterValue('ozonArticle'),
        setValue: _ => setPropFilter('ozonArticle', _)
      }, {
        placeholder: 'Синхронизация с Ozon',
        isBoolean: true,
        allowMixed: true,
        value: findPropFilterValue('syncOzon'),
        setValue: _ => setPropFilter('syncOzon', _)
      })
    }

    if (homeHasStoreType(NavStoreType.Wildberries)) {
      filters.push({
        placeholder: 'Код размера Wildberries',
        value: findPropFilterValue('wbChrtId'),
        setValue: _ => setPropFilter('wbChrtId', _)
      }, {
        placeholder: 'Синхронизация с Wildberries',
        isBoolean: true,
        allowMixed: true,
        value: findPropFilterValue('syncWb'),
        setValue: _ => setPropFilter('syncWb', _)
      })
    }

    if (homeHasStoreType(NavStoreType.YandexMarket)) {
      filters.push({
        placeholder: 'SKU в YandexMarket',
        value: findPropFilterValue('yandexMarketShopSku'),
        setValue: _ => setPropFilter('yandexMarketShopSku', _)
      }, {
        placeholder: 'Синхронизация с YandexMarket',
        isBoolean: true,
        allowMixed: true,
        value: findPropFilterValue('syncYandexMarket'),
        setValue: _ => setPropFilter('syncYandexMarket', _)
      })
    }

    return <PropFilters filters={filters}/>
  })