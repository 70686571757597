import React, { ComponentType } from 'react'
import { observer } from 'mobx-react-lite'

import { ProductMultiPickerBaseProps } from './ProductMultiPicker'
import { ProductSinglePickerBaseProps } from './Tables/ExternalProductPicker'

import { ExternalProductOption } from '../server/mpsklad_core/Models/ExternalProductOption'

export type ProductPickerProps =
  ProductMultiPickerBaseProps & {
  msProductId: number | undefined
}

export type ProductAnyPickerProps =
  ProductPickerProps & {
  allowMultiple?: boolean

  SinglePicker: ComponentType<ProductSinglePickerBaseProps>

  MultiPicker: ComponentType<ProductMultiPickerBaseProps>
}

export const ProductAnyPicker = observer(
  ({
     msProductId,
     allowMultiple,
     products,
     onChange,
     MultiPicker,
     SinglePicker,
     ...passProps
   }: ProductAnyPickerProps) => {
    if (allowMultiple || products.length > 1) {
      return <MultiPicker products={products} onChange={onChange} {...passProps}/>
    }

    const singleProduct = products.length > 0 ? products[0] : undefined

    const onSingleChange =
      (option: ExternalProductOption | undefined): void | Promise<void> =>
        onChange(option ? [option] : [])

    return (
      <SinglePicker
        msProductId={msProductId}
        externalId={singleProduct?.id}
        text={singleProduct?.label ?? ''}
        onChange={onSingleChange}
        {...passProps}
      />
    )
  })