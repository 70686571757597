import debounce from 'lodash/debounce'
import { action, comparer, computed, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'

import { TableDataBase } from './tableDataBase'

import { required } from '../common/objectUtils'
import { EnumConstraint, EnumKey } from '../common/tsUtils'
import { CancellableAxiosRequestMethod, createPreemptiveRequest } from '../common/requestUtils'

import { IServerTableData } from '../types/tableTypes'
import { ResponseCancelledError } from '../types/responseCancelledError'

import { TablePageModel } from '../server/mpsklad_core/Models/TablePageModel'
import { TablePropFilter } from '../server/mpsklad_core/Models/TablePropFilter'
import { TablePageOptions } from '../server/mpsklad_core/Models/TablePageOptions'
import { TablePageOptionsBase } from '../server/mpsklad_core/Models/TablePageOptionsBase'

export type TablePageLoader<TRow extends object, TFilterEnum, TOrderEnum> =
  CancellableAxiosRequestMethod<TablePageOptions<TFilterEnum, TOrderEnum>, TablePageModel<TRow>>

export type TablePageLoader2<TRow extends object, TOptions extends TablePageOptionsBase> =
  CancellableAxiosRequestMethod<TOptions, TablePageModel<TRow>>

export interface TablePropFilterControlled<TColumn> extends TablePropFilter<TColumn> {
  inputValue: string
}

export const createServerTableData =
  <TRow extends object, TColumnId>(name: string) =>
    // Double lambdas are used to infer generic enums
    <TFilterEnum extends EnumConstraint<TFilterEnum> = never,
      TOrderEnum extends EnumConstraint<TOrderEnum> = never>(
      filterEnum: TFilterEnum | null = null, orderEnum: TOrderEnum | null = null
    ) => new ServerTableData<TRow, TColumnId, TFilterEnum, TOrderEnum>(name, filterEnum, orderEnum)

export class ServerTableData<//
  TRow extends object,
  TColumnId,
  TFilterEnum extends EnumConstraint<TFilterEnum>,
  TOrderEnum extends EnumConstraint<TOrderEnum>>
  extends TableDataBase<TRow, TColumnId>
  implements IServerTableData<TRow, TColumnId> {
  @observable
  wasLoaded: boolean

  @observable
  private _isMounted: boolean

  @observable
  private _isLoadingUi: boolean

  @observable
  private _pageRows: TRow[]

  @observable
  private _totalRowCount: number

  @observable
  private _filteredRowCount: number

  @observable
  private _propFilters: TablePropFilterControlled<TFilterEnum>[]

  @observable
  private _orderColumn: TOrderEnum | null

  private _loadPage: TablePageLoader<TRow, TFilterEnum, TOrderEnum> | null

  private readonly _filterEnum: TFilterEnum | null

  private readonly _orderEnum: TOrderEnum | null

  constructor(name: string, filterEnum: TFilterEnum | null, orderEnum: TOrderEnum | null,
              globalFilter: string | null = null,
              propFilters: TablePropFilterControlled<TFilterEnum>[] = []) {
    super(name, globalFilter)

    makeObservable(this)

    this._filterEnum = filterEnum
    this._orderEnum = orderEnum

    this._loadPage = null

    this._isMounted = false
    this.wasLoaded = false
    this._isLoadingUi = false
    this._pageRows = []
    this._totalRowCount = 0
    this._filteredRowCount = 0

    this._propFilters = propFilters
    this._orderColumn = null

    // Don't rely on pageIndex in other reactions - this reaction won't fire if pageIndex doesn't change
    reaction(() => this.pageIndex, this.load)

    reaction(() => this.pageSize, this.loadWithPageReset)

    reaction(() => this.order, this.loadWithPageReset)

    reaction(() => this.globalFilter, this.loadWithPageReset, {delay: 500})

    reaction(() => toJS(this._propFilters), this.loadWithPageReset, {equals: comparer.structural})
  }

  @computed
  get data() {
    return this.pageRows
  }

  @computed
  get pageRows() {
    return this._pageRows
  }

  @computed
  get totalRowCount() {
    return this._totalRowCount
  }

  @computed
  get filteredRowCount() {
    return this._filteredRowCount
  }

  @computed
  get isLoading() {
    return this._isLoadingUi
  }

  /**
   * This method exists to avoid circular initialization of store/logic.
   */
  init =
    (loadPage: TablePageLoader<TRow, TFilterEnum, TOrderEnum>) => {
      this._loadPage = createPreemptiveRequest(loadPage)
      return this
    }

  mount = () => {
    this._isMounted = true
    this.load()
    return this.unmount
  }

  @action
  unmount = () => {
    this._isMounted = false

    this.wasLoaded = false
    this._isLoadingUi = false
    this._pageRows.length = 0
    this.pageIndex = 0
    this._totalRowCount = 0
    this._filteredRowCount = 0
    this.globalFilter = null
    this._selectedRows.clear()

    this.setOrder(null)
    this.resetPropFilters()
  }

  protected loadImmediate = async () => {
    if (this._loadPage === null) {
      throw new Error('ServerTableData was not initialized!')
    }

    if (!this._isMounted) {
      return
    }

    // Show loader only for long requests
    let loadingUiShownAt = null

    const loadingUiTimeoutId = setTimeout(() => {
      this._isLoadingUi = true
      loadingUiShownAt = new Date().valueOf()
    }, 100)

    this._selectedRows.clear()

    let isSuccessful = false

    try {
      const order = this.order !== null ? {
        column: required(this._orderColumn),
        isAscending: this.order.isAscending
      } : undefined

      const requestPromise = this._loadPage({
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        globalFilter: this.globalFilter ?? undefined,
        propFilters: this._propFilters.map(_ => ({
          prop: _.prop,
          textFilter: _.textFilter,
          flagFilter: _.flagFilter
        })),
        order
      })

      const {rows, totalRowCount, filteredRowCount} = await requestPromise

      if (!this._isMounted) {
        return
      }

      runInAction(() => {
        this._pageRows = rows
        this._totalRowCount = totalRowCount
        this._filteredRowCount = filteredRowCount
        this.wasLoaded = true
      })

      isSuccessful = true
    } catch (e) {
      if (e instanceof ResponseCancelledError) {
        // Ignore
        return
      }
    } finally {
      clearTimeout(loadingUiTimeoutId)

      if (loadingUiShownAt === null || !isSuccessful) {
        this._isLoadingUi = false
      } else {
        // Show loader for at least half a second
        const loadingUiShownFor = new Date().valueOf() - loadingUiShownAt

        setTimeout(() => this._isLoadingUi = false, 500 - loadingUiShownFor)
      }
    }
  }

  protected loadWithPageReset = () => {
    this.pageIndex = 0
    this.load()
  }

  load =
    debounce(this.loadImmediate, 50)

  @action
  reload =
    (onBeforeLoad?: VoidFunction) => {
      if (!this._isMounted) {
        return
      }

      this.wasLoaded = false
      this._pageRows.length = 0
      this.pageIndex = 0
      this._totalRowCount = 0
      this._filteredRowCount = 0
      this._selectedRows.clear()

      this.setOrder(null)
      this.resetPropFilters()

      onBeforeLoad?.()
      this.load()
    }

  @action
  setPropFilter =
    (key: EnumKey<TFilterEnum>, filter: string | undefined) => {
      const prop = this.getPropFilterByKey(key)
      this._propFilters = this._propFilters.filter(_ => _.prop !== prop)

      if (filter) {
        this._propFilters.push(
          filter === JSON.stringify(true)
          ? {prop, flagFilter: true, inputValue: filter}
          : filter === JSON.stringify(false)
            ? {prop, flagFilter: false, inputValue: filter}
            : {prop, textFilter: filter, inputValue: filter})
      }

      return this
    }

  resetPropFilters = () =>
    this._propFilters.length = 0

  findPropFilterValue =
    (key: EnumKey<TFilterEnum>) => {
      const prop = this.getPropFilterByKey(key)

      return this._propFilters.find(_ => _.prop === prop)?.inputValue
    }

  private getPropFilterByKey =
    (key: EnumKey<TFilterEnum>) =>
      required(required(this._filterEnum)[key])

  protected onOrderChange() {
    // TODO: Add exhaustive tests for this
    this._orderColumn = this.order === null
                        ? null
                        : required(required(this._orderEnum)[this.order.column.id as unknown as EnumKey<TOrderEnum>])
  }
}