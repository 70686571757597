import React, { FormEvent, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { ButtonLoader } from './ButtonLoader'
import { DropdownItemProps } from './DropdownButton'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { pluralize } from '../common/stringUtility'
import { fixMsAppScrollProps } from '../common/msAppUtils'

export type MatchProductsButtonProps<TFromProp extends number, TToProp extends number> =
  DropdownItemProps & {
  buttonText: string

  title: string

  fromLabel: string

  toLabel: string

  fromProps: Array<[TFromProp, string]>

  toProps: Array<[TToProp, string]>

  accountIdFrom: number

  accountIdTo: number

  submit: (fromProp: TFromProp, toProp: TToProp, accountIdFrom: number, accountIdTo: number) => Promise<number>
}

export const MatchProductsButton =
  <TFromProp extends number, TToProp extends number>(
    {
      buttonText, title, fromLabel, toLabel,
      fromProps, toProps,
      submit, setClosed,
      accountIdFrom, accountIdTo
    }: MatchProductsButtonProps<TFromProp, TToProp>) => {
    const classes = useStyles()
    const {showSuccess, showWarning} = useMessages()

    const [isOpen, openModal, closeModal] = useBoolState()

    const [isLoading, setLoading, setDone] = useBoolState()

    const [fromProp, setFromProp] = useState(fromProps[0][0])

    const [toProp, setToProp] = useState(toProps[0][0])

    const onClose = () => {
      closeModal()
      setClosed()
    }

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()
        setLoading()

        const productCount = await submit(fromProp, toProp, accountIdFrom, accountIdTo)

        if (productCount > 0) {
          showSuccess(pluralize(productCount, 'товар сопоставлен', 'товара сопоставлено', 'товаров сопоставлено'))
        } else {
          showWarning('Товары с совпадающими полями не найдены')
        }

        setDone()
        onClose()
      }

    return <>
      <ListItem button onClick={openModal}>
        <ListItemText primary={buttonText}/>
      </ListItem>

      <Dialog open={isOpen} onClose={closeModal} classes={{paper: classes.dialogPaper}}>
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <Box paddingY={1}>
            <form onSubmit={onSubmit}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="from-label">
                    {fromLabel}
                  </InputLabel>

                  <Select
                    labelId="from-label"
                    value={fromProp}
                    onChange={e => setFromProp(e.target.value as TFromProp)}
                    MenuProps={fixMsAppScrollProps}
                  >
                    {
                      fromProps.map(prop => <MenuItem key={prop[0]} value={prop[0]}>{prop[1]}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>

              <Box marginTop={3}>
                <FormControl fullWidth>
                  <InputLabel id="to-label" shrink>
                    {toLabel}
                  </InputLabel>

                  <Select
                    labelId="to-label"
                    value={toProp}
                    onChange={e => setToProp(e.target.value as TToProp)}
                    MenuProps={fixMsAppScrollProps}
                  >
                    {
                      toProps.map(prop => <MenuItem key={prop[0]} value={prop[0]}>{prop[1]}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>

              <Box marginTop={3}>
                <FormHelperText>
                  Сопоставление может занять пару минут для большого количества товаров.
                </FormHelperText>
              </Box>

              <Box marginTop={3}>
                <Button
                  type="button"
                  variant="contained"
                  disabled={isLoading}
                  onClick={onClose}
                  style={{marginRight: 20}}
                >
                  Отмена
                </Button>

                <Button type="submit" variant="contained" color="secondary" disabled={isLoading}>
                  {
                    isLoading
                    ? <ButtonLoader text="Сопоставляем..."/>
                    : 'Сопоставить'
                  }
                </Button>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  }

const useStyles =
  makeStyles((/*theme: Theme*/) =>
    createStyles({
      dialogPaper: {
        // At the top
        alignSelf: 'flex-start'
      }
    }))