import React, { ReactNode, useState } from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import { SyncLogsDialog } from '../SyncLogsDialog'

import { useBoolState } from '../../hooks/commonHooks'
import { useMessages } from '../../hooks/snackbarHooks'

import { SyncLogModel } from '../../server/mpsklad_core/Models/SyncLogModel'

export type SyncLogsButtonBaseProps = {
  title: string

  icon: ReactNode

  loadLogs: () => Promise<SyncLogModel[]>
}

export const SyncLogsButtonBase =
  ({title, icon, loadLogs}: SyncLogsButtonBaseProps) => {
    const {showError} = useMessages()

    const [logs, setLogs] = useState<SyncLogModel[] | null>(null)
    const [isLoading, setLoading, setLoaded] = useBoolState()

    const classes = useStyles()

    const resetLogs = () => setLogs(null)

    const onLoadClick =
      async () => {
        setLoading()

        try {
          setLogs(await loadLogs())
        } catch (e) {
          console.error('Failed to load logs', e)
          showError('Не удалось загрузить логи')
        } finally {
          setLoaded()
        }
      }

    // NOTE: Box is slower that div with styles, useStyles is about the same as div
    return <>
      <div
        className={classes.iconWrap}
        title={title}
        onClick={onLoadClick}
      >
        {icon}
      </div>

      <SyncLogsDialog isLoading={isLoading} logs={logs} reset={resetLogs}/>
    </>
  }

const useStyles =
  makeStyles(
    theme => createStyles({
      iconWrap: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        display: 'inline-flex',
        cursor: 'pointer'
      }
    }))