import { makeObservable, reaction } from 'mobx'

import { ServerTableData } from './serverTableData'

import { HomeNavStore } from '../types/homeNavStore'
import { MyProductColumnId } from '../types/myProductColumnIds'

import { MyProductModel } from '../server/mpsklad_core/Models/MyProductModel'
import { MyProductsTableFilterColumn } from '../server/mpsklad_core/Models/MyProductsTableFilterColumn'
import { MyProductsTableOrderColumn } from '../server/mpsklad_core/Models/MyProductsTableOrderColumn'

export class MyProductsTableData
  extends ServerTableData<MyProductModel, MyProductColumnId,
    typeof MyProductsTableFilterColumn, typeof MyProductsTableOrderColumn> {
  constructor(getHomeNav: () => HomeNavStore | null) {
    super('myProducts', MyProductsTableFilterColumn, MyProductsTableOrderColumn)

    makeObservable(this)

    reaction(() => getHomeNav()?.storeType, () => this.reload())

    reaction(() => getHomeNav()?.accountId, () => this.reload())

    reaction(() => getHomeNav()?.tab, this.loadWithPageReset)
  }
}