import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountToggleRow } from './AccountToggleRow'

import { StoreAccountToggleRowProps } from '../types/accountProps'

export const StoreAutoShipmentRow =
  observer(
    ({account, onSubmit}: StoreAccountToggleRowProps) =>
      <AccountToggleRow
        title="Автоматическая отгрузка заказов"
        helperText='Создание документов отгрузки при переходе заказа в статус "Отгружен".'
        account={account}
        onSubmit={onSubmit}
        getAccountToggle={() => account.autoShipmentEnabled}
        setAccountToggle={isEnabled => account.autoShipmentEnabled = isEnabled}
        getSuccessMessage={isEnabled => `Автоотгрузка ${isEnabled ? 'включена' : 'отключена'}`}
      />)