import React from 'react'
import { observer } from 'mobx-react-lite'

import { Link, TableCell } from '@material-ui/core'

import { useApi } from '../hooks/storeHook'

import { MyRowProps } from '../types/tableTypes'
import { UserModel } from '../server/mpsklad_core/Models/UserModel'

export const UserImpersonateCell =
  observer(
    ({row}: MyRowProps<UserModel>) => {
      const api = useApi()

      return (
        <TableCell className="table-border">
          <Link href={api.admin.impersonateUrl(row.id)}>
            Посмотреть
          </Link>
        </TableCell>
      )
    })