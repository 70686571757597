import React, { FunctionComponent, ReactNode, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Add, Clear } from '@material-ui/icons'
import { Box, IconButton } from '@material-ui/core'

import { AutoTabs } from './AutoTabs'
import { BoxLoader } from './BoxLoader'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'

import { integrationTypeNames } from '../types/integrationTypeName'
import { AccountOptionalProps, StoreAccountRequiredProps } from '../types/accountProps'

import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'

export interface StoreScreenTabsBaseProps<TAccount extends StoreAccountModelBase> {
  canAddAccount?: boolean

  canRemoveAccount?: boolean,

  ScreenComponent: FunctionComponent<StoreAccountRequiredProps<TAccount>>
}

export interface StoreScreenTabsProps<TAccount extends StoreAccountModelBase>
  extends StoreScreenTabsBaseProps<TAccount> {
  storeType: IntegrationType.Ozon | IntegrationType.Wildberries | IntegrationType.YandexMarket

  storeAccounts: TAccount[]

  onRemoveAccount: (accountId: number) => Promise<void>

  makeAccountName: (account: TAccount) => string

  AddAccountComponent: FunctionComponent<AccountOptionalProps<TAccount>>
}

export const StoreScreenTabs =
  observer(
    <TAccount extends StoreAccountModelBase>
    ({
       storeType, storeAccounts,
       canAddAccount, canRemoveAccount, onRemoveAccount,
       makeAccountName, ScreenComponent, AddAccountComponent
     }: StoreScreenTabsProps<TAccount>) => {
      const {syncStore: {moySkladAccount}} = useStore()
      const logic = useLogic()

      const {showSuccess} = useMessages()

      const [activeTab, setActiveTab] = useState(0)

      const [isDeleting, setDeleting, setDeleted] = useBoolState()

      if (moySkladAccount == null) {
        return <Box padding={3}>Добавьте аккаунт МоегоСклада для работы с маркетплейсами.</Box>
      }

      if (!canAddAccount && storeAccounts.length === 0) {
        return <Box padding={3}>У вас нет аккаунтов {integrationTypeNames[storeType]}.</Box>
      }

      if (isDeleting) {
        return <BoxLoader text="Аккаунт удаляется. Пожалуйста, подождите."/>
      }

      const onAccountDelete =
        async (e: React.MouseEvent, account: TAccount) => {
          e.preventDefault()
          e.stopPropagation()

          if (await logic.showDialog(
            'Удалённый аккаунт невозможно восстановить!', {title: 'Удалить аккаунт?', acceptButton: 'Удалить'})) {
            setDeleting()

            try {
              await onRemoveAccount(account.id)
              showSuccess('Аккаунт удалён')
            } finally {
              setActiveTab(0)
              setDeleted()
            }
          }
        }

      // TODO: Extract components?
      const titles: ReactNode[] = storeAccounts.map(
        (account, index) =>
          <Box display="inline-flex" alignItems="center">
            {makeAccountName(account)}

            {
              canRemoveAccount && index === activeTab &&
              <IconButton
                size="small"
                component="div"
                title="Удалить"
                onClick={(e: React.MouseEvent) => onAccountDelete(e, account)}
                style={{marginLeft: 5}}
              >
                <Clear/>
              </IconButton>
            }
          </Box>
      )

      if (canAddAccount) {
        titles.push(
          <Box display="inline-flex" alignItems="center">
            <Add style={{marginRight: 5}}/>
            Добавить
          </Box>
        )
      }

      return (
        <AutoTabs secondary titles={titles} tab={activeTab} onTabChange={setActiveTab}>
          {
            storeAccounts.length
            ? storeAccounts.map(account => <ScreenComponent key={account.id} account={account}/>)
            : null
          }

          {
            canAddAccount &&
            <AddAccountComponent account={null}/>
          }
        </AutoTabs>
      )
    })