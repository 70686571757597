import React from 'react'
import { observer } from 'mobx-react-lite'

import { Menu } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { MyProductsColumnItem } from './MyProductsColumnItem'

import { useStore } from '../hooks/storeHook'

import { keysOf } from '../common/tsUtils'
import { fixMsAppScrollProps } from '../common/msAppUtils'

export type MyProductsColumnsMenuProps = {
  anchorEl: HTMLElement | null

  onClose: VoidFunction
}

export const MyProductsColumnsMenu =
  observer(
    ({anchorEl, onClose}: MyProductsColumnsMenuProps) => {
      const classes = useStyles()
      const {myProductColumnsGrouped} = useStore()

      return (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={onClose}
          PaperProps={{style: {maxHeight: '70vh'}}}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
          getContentAnchorEl={null}
          classes={{list: classes.menu}}
          {...fixMsAppScrollProps}
        >
          {
            keysOf(myProductColumnsGrouped).map(groupId =>
              !!myProductColumnsGrouped[groupId].length && <MyProductsColumnItem key={groupId} groupId={groupId}/>)
          }
        </Menu>
      )
    })

const useStyles =
  makeStyles((/*theme: Theme*/) =>
    createStyles({
      menu: {
        paddingTop: 0,
        paddingBottom: 0
      }
    }))