import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, LinearProgress, Table, TableBody, TableContainer } from '@material-ui/core'

import { MyTableRow } from './MyTableRow'
import { MyTableHead } from './Tables/MyTableHead'
import { TablePagination2 } from './TablePagination'

import { useTableStyles } from '../hooks/tableStylesHook'

import { MyTableProps } from '../types/tableTypes'

export const MyTable =
  observer(
    <TColumnId extends string, TRow extends {id: React.Attributes['key']}>
    ({
       classes, tableProps, table, columns,
       HeadComponent,
       labelRowsPerPage, labelNoRows, labelNotFound,
       hideTopPagination
     }: MyTableProps<TColumnId, TRow>) => {
      const tableClasses = useTableStyles()

      // TODO: Split components to optimize rendering? Consider react context to pass the table down.
      return (
        <Box className={tableClasses.tableWrap}>
          {
            table.isLoading &&
            <LinearProgress color="secondary" className={tableClasses.loader}/>
          }

          {
            !hideTopPagination &&
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <TablePagination2 table={table} labelRowsPerPage={labelRowsPerPage}/>
            </Box>
          }

          <TableContainer className={classes?.tableContainer}>
            <Table className={classes?.table} {...tableProps}>
              {
                HeadComponent
                ? <HeadComponent/>
                : <MyTableHead table={table} visibleColumns={columns}/>
              }

              <TableBody>
                {
                  table.pageRows.map(
                    row => <MyTableRow key={row.id} row={row} table={table} visibleColumns={columns}/>)
                }
              </TableBody>
            </Table>
          </TableContainer>

          {
            table.wasLoaded && !table.isLoading && table.totalRowCount > 0 && table.filteredRowCount === 0 &&
            <Box padding={3}>
              {labelNotFound}
            </Box>
          }

          {
            table.wasLoaded && !table.isLoading && table.totalRowCount === 0 &&
            <Box padding={3}>
              {labelNoRows}
            </Box>
          }

          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <TablePagination2 table={table} labelRowsPerPage={labelRowsPerPage}/>
          </Box>
        </Box>
      )
    })