import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { OzonProductsTable } from './OzonProductsTable'

import { useStore } from '../hooks/storeHook'

import { ozonProductColumns } from '../types/ozonColumns'
import { OzonAccountRequiredProps } from '../types/accountProps'

export const OzonAccountInfo =
  observer(
    ({account}: OzonAccountRequiredProps) => {
      const {productStore: {createOzonProductsTable}} = useStore()

      const ozonProducts = useMemo(
        () => createOzonProductsTable(account.id),
        [createOzonProductsTable, account])

      return <OzonProductsTable products={ozonProducts} columns={ozonProductColumns}/>
    })