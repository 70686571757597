export type HandlerResult =
  void | Promise<void>

export type HandlerFunc =
  () => HandlerResult

export class HandlersContainer {
  private readonly handlers: Set<HandlerFunc> = new Set()

  /**
   * Adds the `onReload` to the list of handlers that will be triggered by the {@link trigger} method.
   * @param onReload
   * @returns Cleanup function.
   */
  addHandler =
    (onReload: HandlerFunc): VoidFunction => {
      this.handlers.add(onReload)
      return () => this.handlers.delete(onReload)
    }

  /**
   * Triggers all handlers that were added by the {@link addHandler} method.
   */
  trigger =
    async (): Promise<void> => {
      const resulst = Array.from(this.handlers).map(handler => handler())
      await Promise.all(resulst)
    }
}