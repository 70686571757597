import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, TableCell } from '@material-ui/core'

import { ProductWbSyncSwitch } from './ProductWbSyncSwitch'

import { MyRowProps } from '../../types/tableTypes'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'

export const MyProductWbSyncCell =
  observer(
    ({row}: MyRowProps<MyProductModel>) =>
      <TableCell className="table-border" align="center" style={{padding: '8px'}}>
        {
          row.wbProducts.map(wbProduct =>
            <Box key={wbProduct.id}>
              <ProductWbSyncSwitch msProductId={row.id} wbProduct={wbProduct}/>
            </Box>
          )
        }
      </TableCell>
  )