import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid } from '@material-ui/core'

import { MoySkladSyncInfo } from './MoySkladSyncInfo'
import { MoySkladAccountForm } from './MoySkladAccountForm'
import { MoySkladWebhookPanel } from './MoySkladWebhookPanel'
import { MoySkladProductsTable } from './MoySkladProductsTable'
import { MoySkladStatusMapPanel } from './MoySkladStatusMapPanel'
import { MoySkladAttributesPanel } from './MoySkladAttributesPanel'

import { useStore } from '../hooks/storeHook'

export const MoySkladSyncScreen =
  observer(
    () => {
      const {hasMoySkladApp} = useStore()

      return (
        <Box padding={3}>
          <Grid container direction="column" spacing={3}>
            <Grid item lg={10} xl={8}>
              <MoySkladSyncInfo/>
            </Grid>

            {
              !hasMoySkladApp &&
              <Grid item lg={10} xl={8}>
                <MoySkladAccountForm/>
              </Grid>
            }

            <Grid item lg={8} xl={6}>
              <MoySkladStatusMapPanel/>
            </Grid>

            <Grid item lg={8} xl={6}>
              <MoySkladAttributesPanel/>
            </Grid>

            <Grid item lg={8} xl={6}>
              <MoySkladWebhookPanel/>
            </Grid>

            <Grid item lg={12}>
              <MoySkladProductsTable/>
            </Grid>
          </Grid>
        </Box>
      )
    })