import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid, Paper, TextField, Typography } from '@material-ui/core'

import { MyServerTable } from './MyServerTable'
import { OzonProductsArchivedFilter } from './OzonProductsArchivedFilter'
import { OzonTableHasMsProductFilter } from './OzonTableHasMsProductFilter'

import { OzonProductsTableData } from '../store/productStore'

import { TableColumn } from '../types/tableTypes'
import { OzonProductColumnId } from '../types/ozonColumns'
import { OzonProductModel } from '../server/mpsklad_core/Models/OzonProductModel'

export type OzonProductsTableProps = {
  products: OzonProductsTableData

  columns: Array<TableColumn<OzonProductColumnId, OzonProductModel>>
}

export const OzonProductsTable =
  observer(
    ({products, columns}: OzonProductsTableProps) =>
      <Grid item lg={12} xl={10}>
        <Paper>
          <Box paddingTop={1} marginY={2} marginLeft={2} display="flex">
            <Typography variant="h6">Товары</Typography>

            <Box marginLeft={25}>
              <TextField
                id="search-ozon"
                size="small"
                variant="outlined"
                label="Поиск"
                helperText="Id / Артикул / Наименование / Штрихкод"
                onChange={e => products.setGlobalFilter(e.target.value)}
              />
            </Box>

            <Box marginTop={1} marginLeft={5}>
              <OzonTableHasMsProductFilter products={products}/>
            </Box>

            <Box marginTop={1} marginX={5}>
              <OzonProductsArchivedFilter products={products}/>
            </Box>
          </Box>

          <MyServerTable
            tableProps={{size: 'small'}}
            table={products}
            columns={columns}
            labelNoRows="Нет товаров"
            labelNotFound="Товары не найдены"
            labelRowsPerPage="Товаров на странице:"
          />
        </Paper>
      </Grid>)