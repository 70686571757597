import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid, Paper, TextField, Typography } from '@material-ui/core'

import { MyServerTable } from './MyServerTable'
import { StoreTableHasMsProductFilter } from './StoreTableHasMsProductFilter'

import { WbProductsTableData } from '../store/productStore'

import { TableColumn } from '../types/tableTypes'
import { WbProductColumnId } from '../types/wbColumns'
import { WbProductModel } from '../server/mpsklad_core/Models/WbProductModel'

export type WbProductsTableProps = {
  products: WbProductsTableData

  columns: Array<TableColumn<WbProductColumnId, WbProductModel>>
}

export const WbProductsTable =
  observer(
    ({products, columns}: WbProductsTableProps) =>
      <Grid item lg={12} xl={10}>
        <Paper>
          <Box paddingTop={1} marginY={2} marginLeft={2} display="flex">
            <Typography variant="h6">Товары</Typography>

            <Box marginLeft={25}>
              <TextField
                id="search-wb-products"
                size="small"
                variant="outlined"
                label="Поиск"
                helperText="Id / Код размера (chrtId) / Артикул / Наименование / Штрихкод"
                onChange={e => products.setGlobalFilter(e.target.value)}
              />
            </Box>

            <Box marginTop={1} marginLeft={5} marginRight={2}>
              <StoreTableHasMsProductFilter products={products}/>
            </Box>
          </Box>

          <MyServerTable
            tableProps={{size: 'small'}}
            table={products}
            columns={columns}
            labelNoRows="Нет товаров"
            labelNotFound="Товары не найдены"
            labelRowsPerPage="Товаров на странице:"
          />
        </Paper>
      </Grid>)