import React, { FormEvent, useState } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box, Button, Paper, TextField, Typography } from '@material-ui/core'

import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

export const MoySkladAccountForm =
  observer(
    () => {
      const {showSuccess} = useMessages()

      const {syncStore} = useStore()
      const {moySkladAccount} = syncStore

      const logic = useLogic()
      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const [accessToken, setAccessToken] = useState(moySkladAccount?.accessToken ?? '')

      useLazyEffect(
        () => reaction(
          // NOTE: Access through the syncStore to trigger the reaction
          () => syncStore.moySkladAccount?.accessToken,
          accessToken => setAccessToken(accessToken ?? '')
        ))

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()
          setIsLoading()

          try {
            await logic.editMoySkladAccount({accessToken})
            showSuccess('Сохранено!')
          } catch (e) {
            console.error('Failed to save MoySklad account', e)
          } finally {
            setNotLoading()
          }
        }

      return (
        <Paper>
          <Box padding={2}>
            <Box marginBottom={3}>
              <Typography variant="h6">
                Аккаунт
              </Typography>
            </Box>

            <form noValidate autoComplete="off" onSubmit={onSubmit}>
              <Box>
                <TextField
                  fullWidth
                  id="accessToken"
                  label="API Токен"
                  placeholder="be0813e983834c519ebfdecafbad013fc4afdab2"
                  InputLabelProps={{shrink: true}}
                  value={accessToken}
                  onChange={e => setAccessToken(e.target.value)}
                />
              </Box>

              <Box marginTop={3}>
                <Button variant="contained" type="submit" disabled={isLoading}>
                  {isLoading ? 'Сохраняем...' : 'Сохранить'}
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
      )
    })