import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, TextField } from '@material-ui/core'

import { ReactiveTabs } from './ReactiveTabs'
import { MyServerTable } from './MyServerTable'
import { OzonScreenTabs } from './OzonScreenTabs'
import { PullOrdersWithErrorsButton } from './PullOrdersWithErrorsButton'

import { useLogic, useStore } from '../hooks/storeHook'

import { ozonOrderColumns } from '../types/ozonOrderColumns'
import { OzonAccountRequiredProps } from '../types/accountProps'

export const OzonOrdersTabsScreen = () =>
  <OzonScreenTabs ScreenComponent={OzonOrdersScreen}/>

// TODO: Generalize with WB, change filters? See OzonProductsTablePageOptions for ideas.
enum OzonOrdersTableTab {
  Active = 0,
  Cancelled = 1,
  WithErrors = 2
}

const tabItems = [{
  name: 'Активные',
  value: OzonOrdersTableTab.Active
}, {
  name: 'Отменённые',
  value: OzonOrdersTableTab.Cancelled
}, {
  name: 'С ошибками',
  value: OzonOrdersTableTab.WithErrors
}]

const tabTitles = tabItems.map(_ => _.name)

export const OzonOrdersScreen =
  observer(
    ({account}: OzonAccountRequiredProps) => {
      const {api, ozonOrderLoaders} = useLogic()
      const {orderStore: {createOzonOrdersTable}} = useStore()

      const ozonOrders = useMemo(
        () => createOzonOrdersTable(account.id),
        [createOzonOrdersTable, account])

      useEffect(() => ozonOrderLoaders.addHandler(ozonOrders.load), [ozonOrderLoaders, ozonOrders])

      const onTabChange =
        (newTabIndex: number) =>
          ozonOrders.reload(() => {
            switch (newTabIndex as OzonOrdersTableTab) {
              case OzonOrdersTableTab.Active:
                ozonOrders.setPropFilter('hasError', JSON.stringify(false))
                return

              case OzonOrdersTableTab.Cancelled:
                ozonOrders.setPropFilter('isCancelled', JSON.stringify(true))
                return

              case OzonOrdersTableTab.WithErrors:
                ozonOrders.setPropFilter('hasError', JSON.stringify(true))
                return

              default:
                throw new Error(`Unhandled value of OzonOrdersTableTab: ${newTabIndex}`)
            }
          })

      const currentTab =
        ozonOrders.findPropFilterValue('isCancelled') === JSON.stringify(true)
        ? OzonOrdersTableTab.Cancelled
        : ozonOrders.findPropFilterValue('hasError') === JSON.stringify(true)
          ? OzonOrdersTableTab.WithErrors
          : OzonOrdersTableTab.Active

      return (
        <ReactiveTabs titles={tabTitles} tab={currentTab} onTabChange={onTabChange}>
          <Box margin={2} display="flex" justifyContent="space-between">
            <Box>
              <TextField
                id="search-ozon-orders"
                size="small"
                variant="outlined"
                label="Поиск"
                helperText="Id / Id в Ozon / Номер отправления / Артикул товара"
                onChange={e => ozonOrders.setGlobalFilter(e.target.value)}
                style={{minWidth: 400, maxWidth: 400}}
              />
            </Box>

            {
              currentTab === OzonOrdersTableTab.WithErrors &&
              <PullOrdersWithErrorsButton
                ordersTable={ozonOrders}
                pullOrders={() => api.order.pullOzonOrdersWithErrors(account.id)}
              />
            }
          </Box>

          <MyServerTable
            table={ozonOrders}
            columns={ozonOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />
        </ReactiveTabs>
      )
    })