import React from 'react'

import { TableCell } from '@material-ui/core'

import { MyRowProps } from '../../types/tableTypes'
import { StoreOrderModelBase } from '../../server/mpsklad_core/Models/Orders/StoreOrderModelBase'

export const MoySkladIdCell =
  ({row}: MyRowProps<StoreOrderModelBase>) =>
    <TableCell className="table-border">
      {row.moySkladExternalOrderId ?? null}
      <br/>
      {row.moySkladOrderId ? `(${row.moySkladOrderId})` : null}
    </TableCell>