import React from 'react'
import { observer } from 'mobx-react-lite'

import { StoreMultiProductSwitch } from './StoreMultiProductSwitch'

import { useLogic, useStore } from '../hooks/storeHook'

export const OzonMultiProductSwitch =
  observer(() => {
    const {user} = useStore()
    const {setOzonMultiProducts} = useLogic()

    if (!user) {
      throw new Error('Not authorized')
    }

    return <StoreMultiProductSwitch
      allowMultiProducts={user.allowMultiOzonProducts}
      setMultiProducts={setOzonMultiProducts}
    />
  })